import {
    React,
    useState,
    useEffect,
    useRef,
    useCallback,
    useMemo,
    useContext,
} from 'react';
import reactDOM from 'react-dom';
import { Helmet } from 'react-helmet';

import Rooms from '../Rooms.js';
import Map from '../SimpleGoogleMap/SimpleGoogleMap.js';
import { Icon } from '../../common/Icon/Icon';

import { format as formatDate, parseISO } from 'date-fns';
import enLocale from 'date-fns/locale/en-US';

import { getCountryName, OccupancyString } from '../../helpers.js';

import { useNavigate, useSearchParams } from 'react-router-dom';

import './Supplier.css';
import Amenities from '../Amenities/Amenities.js';
import ChargerInfoCards from '../ChargerInfoCards/ChargerInfoCards.js';
import Address from '../Address/Address.js';
import { useLocaleContext } from '../../core/context/localeContext';
import PageLoader from '../../common/PageLoader/PageLoader.js';
import DesktopSearchbar from '../Search/DesktopSearchbar/DesktopSearchbar.js';
import { useSearchContext } from '../../core/context/searchContext.js';
import Gallery from '../Gallery/Gallery.js';
import ModalHOC from '../../common/ModalHOC/ModalHOC.js';
import MobileSearch from '../Search/MobileSearch/MobileSearch.js';
import { ThemeContext } from '../../providers/ThemeProvider.js';
import { useUserContext } from '../../core/context/userContext.js';
import SearchUSP from '../SearchUSP/SearchUSP.js';

const BASE_URL =
    process.env.REACT_APP_BASE_URL || 'https://www.sleepercharger.com';

export default function Supplier(props) {
    const navigate = useNavigate();
    const {
        state,
        meta,
        queryParams,
        item,
        setItem,
        fetchSupplier,
        findMobileOpen,
        setFindMobileOpen,
        isLoading,
        fromPop,
        setFromPop,
        openMobileSearch,
        abortController,
        fromMobileSearch,
        setFromMobileSearch,
        dontSearch,
        setDontSearch,
    } = useSearchContext();
    const [searchParams, setSearchParams] = useSearchParams();
    const { t, locale } = useLocaleContext();
    const [fromGallery, setFromGallery] = useState(false);
    const mobileSearchBtnRef = useRef(null);
    const { setPage } = useContext(ThemeContext);
    const { userDetails } = useUserContext();

    const [roomFilters, setRoomFilters] = useState({
        with_cancellation: false,
        with_breakfast: false,
    });
    const [mapZoom, setMapZoom] = useState(16);

    const isMobile = props.isMobile;

    const { dest, f_amenities, ...params } = useMemo(
        () => ({
            ...queryParams,
        }),
        [queryParams]
    );

    const galleryParams = useMemo(
        () => ({
            gallery: searchParams.get('gallery'),
            gallery_item: searchParams.get('gallery_item'),
        }),
        [searchParams]
    );

    useEffect(() => {
        fetchSupplier(state, {
            shouldNavigate: false,
            shouldAbort: true,
        });

        return () => {
            setItem(null);
        };
    }, [userDetails.token, locale]);

    useEffect(() => {
        setPage('supplier');

        return () => {
            setPage('');
            if (abortController?.current) abortController.current.abort();
        };
    }, []);

    useEffect(() => {
        if (!galleryParams.gallery) setFromGallery(false);
        setFromMobileSearch(false);
        if (fromMobileSearch) {
            fetchSupplier(state, {
                shouldNavigate: true,
                shouldAbort: true,
            });
            setDontSearch(true);
            return;
        }
        if (!fromGallery && !findMobileOpen && !dontSearch && fromPop)
            fetchSupplier(state, {
                shouldNavigate: false,
                shouldAbort: true,
            });
        if (!findMobileOpen) setDontSearch(false);
        setFromPop(false);
        // eslint-disable-next-line
    }, [state, fromMobileSearch]);

    useEffect(() => {
        window.addEventListener('popstate', () => setFromPop(true));

        return () => {
            window.removeEventListener('popstate', () => setFromPop(true));
        };
    }, [setFromPop]);

    const [mapCenter, setMapCenter] = useState({
        lat: item ? item.loc.coordinates[1] : 0,
        lng: item ? item.loc.coordinates[0] : 0,
    });

    useEffect(() => {
        setMapCenter({
            lat: item ? item.loc.coordinates[1] : 0,
            lng: item ? item.loc.coordinates[0] : 0,
        });
    }, [item]);

    let supplierLatlng = useMemo(() => {
        return {
            lat: item ? item.loc.coordinates[1] : 0,
            lng: item ? item.loc.coordinates[0] : 0,
        };
    }, [item]);

    let chargerMarkers = useMemo(() => {
        return item
            ? item.chargers_nearby.map((e) => {
                  return {
                      ...e,
                      latlng: {
                          lat: e.loc.coordinates[1],
                          lng: e.loc.coordinates[0],
                      },
                      type: 'charger',
                  };
              })
            : [];
    }, [item]);

    let markers = useMemo(
        () =>
            [
                {
                    latlng: supplierLatlng,
                    name: item ? item.name : '',
                },
            ].concat(chargerMarkers),
        [chargerMarkers, item, supplierLatlng]
    );

    const handleCheckboxChange = useCallback(
        (evt) => {
            let newFilters = {
                ...roomFilters,
                [evt.target.name]: evt.target.checked,
            };
            setRoomFilters(newFilters);
        },
        [roomFilters]
    );

    const openSearch = useCallback(() => {
        openMobileSearch(mobileSearchBtnRef);
    }, [openMobileSearch]);

    const closeMobileSearch = useCallback(() => {
        navigate(-1);
    }, [navigate]);

    useEffect(() => {
        if (findMobileOpen) {
            setFindMobileOpen(false);
            window.history.go(-1);
        }
        // eslint-disable-next-line
    }, [isMobile]);

    let parsedCheckin = parseISO(meta.checkin);
    let parsedCheckout = parseISO(meta.checkout);
    if (parsedCheckin === 'Invalid Date' || parsedCheckout === 'Invalid Date') {
        console.error('Dates are invalid.');
    }

    const bannerLoader = (
        <div className="loading-banner">
            <div className="loading-banner__title block-loader"></div>
            <div className="loading-banner__info block-loader"></div>
            <div className="loading-banner__gallery block-loader"></div>
        </div>
    );

    const infoLoader = (
        <div className="loading-info">
            <div className="loading-info__rooms block-loader"></div>
            <div className="loading-info__amenities block-loader"></div>
        </div>
    );

    const mapLoader = (
        <div className="loading-supplier-map">
            <div className="loading-supplier-map__title block-loader"></div>
            <div className="loading-supplier-map__title block-loader"></div>
            <div className="loading-supplier-map__chargers">
                <div className="loading-supplier-map__chargers-block block-loader"></div>
                <div className="loading-supplier-map__chargers-block block-loader"></div>
            </div>
            <div className="loading-supplier-map__title block-loader"></div>
            <div className="loading-supplier-map__chargers">
                <div className="loading-supplier-map__chargers-block block-loader"></div>
                <div className="loading-supplier-map__chargers-block block-loader"></div>
            </div>
            <div className="loading-supplier-map__map block-loader"></div>
            <div className="loading-supplier-map__address block-loader"></div>
        </div>
    );

    const title =
        item && item.name
            ? t('%{name} with EV Charger', { name: item.name })
            : 'Accommodation';

    const metaTitle =
        item && item.name
            ? t('%{name} with EV Charger in %{city}, %{country}', {
                  name: item.name,
                  city: item.address?.city,
                  country: getCountryName(
                      item.address?.country_code,
                      locale,
                      'region'
                  ),
              })
            : 'Accommodation';

    const roomsSlides = useMemo(
        () => (
            <>
                <div className="supplier__recommendations-header">
                    <h2 className="h3">{t('Room recommendations')}</h2>
                    {item?.roomsAvailable
                        ? item?.roomsAvailable?.length && (
                              <div className="supplier__recommendations-filters">
                                  <div className="supplier__recommendations-filter">
                                      <label className="toggle">
                                          <input
                                              type="checkbox"
                                              name={'with_cancellation'}
                                              value={
                                                  roomFilters.with_cancellation
                                              }
                                              onChange={handleCheckboxChange}
                                          />
                                          <span>
                                              {t('Refundable rooms only')}
                                          </span>
                                          <span className="toggle__checkmark" />
                                      </label>
                                  </div>
                                  {/* Disable for now */}
                                  {false && (
                                      <div className="supplier__recommendations-filter">
                                          <label className="toggle toggle--reverse">
                                              <input
                                                  type="checkbox"
                                                  name={'with_breakfast'}
                                                  value={
                                                      roomFilters.with_breakfast
                                                  }
                                                  onChange={
                                                      handleCheckboxChange
                                                  }
                                              />
                                              <span>
                                                  {t('Breakfast included')}
                                              </span>
                                              <span className="toggle__checkmark" />
                                          </label>
                                      </div>
                                  )}
                              </div>
                          )
                        : null}
                </div>
                {item?.roomsAvailable ? (
                    item?.roomsAvailable?.length && null
                ) : (
                    <div className="supplier__recommendations-info info">
                        <Icon.InfoCircle className="icon-small icon-left" />
                        <span>
                            {t(
                                'Enter check-in and check-out date to see room prices and cancellation policy'
                            )}
                        </span>
                    </div>
                )}
                <Rooms
                    item={item}
                    queryParams={params}
                    roomFilters={roomFilters}
                    onClick={null}
                />
            </>
        ),
        [handleCheckboxChange, item, params, roomFilters, t]
    );

    const description = useMemo(() => {
        if (!item) return null;
        return (
            item.descriptions ||
            t('Welcome to our charming hotel, where every detail is meticulously crafted to provide you with a delightful and memorable stay. From our elegantly designed rooms to our attentive staff, we strive to create a warm and inviting atmosphere that embraces you with genuine hospitality and ensures your utmost comfort throughout your visit.')
        );
    }, [item, t]);

    const canonicalLink = useMemo(
        () =>
            `${BASE_URL}${
                locale !== 'en' ? `/${locale}` : ''
            }${window.location.pathname && window.location.pathname.replace(
                /(\/en|\/nl|\/de)/,
                ''
            )}?${document.location.search && document.location.search
                .split('&')
                .filter((el) => el.includes('sid'))[0]
                .replace('?', '')}`,
        [locale]
    );

    const structuredData = useMemo(() => {
        if (!item) return null;
        const data = {
            '@type': 'Hotel',
            // TODO: Add price
            // priceRange: t('Prices start at RSD 89,745 per night'),
            '@context': 'http://schema.org',
            description,
            image:
                item.images.find((el) => el.hero_image)?.links['1000px']
                    ?.href ||
                item.images.find((el) => el.hero_image)?.links['350px']?.href,
            aggregateRating: item.ratings?.guest?.count && item.overallGuestRating ? {
                reviewCount: item.ratings?.guest?.count,
                '@type': 'AggregateRating',
                ratingValue: +item.overallGuestRating,
                bestRating: 10,
            } : null,
            address: {
                addressLocality: item.address?.line_1,
                postalCode: item.address?.postal_code,
                addressCountry: getCountryName(
                    item.address?.country_code,
                    locale,
                    'region'
                ),
                streetAddress: `${item.address?.line_1}, ${item.address?.postal_code} ${item.address?.city}`,
                '@type': 'PostalAddress',
                addressRegion: item.address?.state_province_name,
            },
            name: item.name,
            url: canonicalLink,
        };
        return JSON.stringify(data, null, ' ');
    }, [canonicalLink, description, item, locale]);

    return (
        <section className="supplier">
            <Helmet>
                <title>{metaTitle} | SleeperCharger</title>
                <meta
                    name="description"
                    content={
                        `${metaTitle} | SleeperCharger` ||
                        'SleeperCharger Accommodation'
                    }
                />
                <link rel="canonical" href={canonicalLink} />
                <script type="application/ld+json">{structuredData}</script>
            </Helmet>
            {item ? null : isLoading ? <PageLoader /> : null}
            <div className="wrap wrap--md">
                {!item && !isLoading ? (
                    <div className="info" style={{ marginTop: '40px' }}>
                        {t('This hotel is not available, try searching again.')}
                    </div>
                ) : null}
                <div className="supplier__banner">
                    {/* {isMobile ? null : (
                        <div className="supplier__nav">
                            <button
                                className="supplier__back link"
                                type="button"
                                onClick={() => {
                                    navigate(-1);
                                }}
                            >
                                <Icon.Arrow className="icon-small icon-left" />
                                {t('Back to results')}
                            </button>
                        </div>
                    )} */}
                    {item ? (
                        <>
                            {title ? (
                                <h1 className="supplier__title h2">{title}</h1>
                            ) : null}
                            <div className="supplier__banner-info">
                                {item.overallGuestRating ? (
                                    <span className="supplier__rating rating">
                                        <Icon.Smiley />
                                        {item.overallGuestRating}
                                        {item.ratings?.guest?.count && (
                                            <> ({item.ratings?.guest?.count})</>
                                        )}
                                    </span>
                                ) : null}
                                <Address item={item} hideTitle />
                            </div>
                        </>
                    ) : isLoading ? (
                        bannerLoader
                    ) : null}
                </div>
                <div className="supplier__container">
                    <div className="supplier__info-container">
                        {item ? (
                            <>
                                <Gallery
                                    item={item}
                                    galleryParams={galleryParams}
                                    isMobile={isMobile}
                                    setFromGallery={setFromGallery}
                                />
                                <div className="supplier__recommendations">
                                    {!isMobile ? (
                                        <DesktopSearchbar
                                            meta={meta}
                                            supplierPage
                                            fieldApply
                                        />
                                    ) : (
                                        <button
                                            className="search__open"
                                            onClick={openSearch}
                                            ref={mobileSearchBtnRef}
                                        >
                                            {!meta.checkin || !meta.checkout ? (
                                                <span className="form-label">
                                                    Checkin - Checkout
                                                </span>
                                            ) : (
                                                <span className="form-label">
                                                    <span>
                                                        <span
                                                            style={{
                                                                whiteSpace:
                                                                    'nowrap',
                                                            }}
                                                        >
                                                            {formatDate(
                                                                parsedCheckin,
                                                                `d ${
                                                                    formatDate(
                                                                        parsedCheckin,
                                                                        'MMM'
                                                                    ) ===
                                                                    formatDate(
                                                                        parsedCheckout,
                                                                        'MMM'
                                                                    )
                                                                        ? ''
                                                                        : 'MMM'
                                                                }`,
                                                                {
                                                                    locale: enLocale,
                                                                }
                                                            )}{' '}
                                                            -{' '}
                                                            {formatDate(
                                                                parsedCheckout,
                                                                'd MMM',
                                                                {
                                                                    locale: enLocale,
                                                                }
                                                            )}
                                                        </span>
                                                    </span>
                                                </span>
                                            )}
                                            <span
                                                style={{
                                                    display: 'flex',
                                                    flexWrap: 'wrap',
                                                }}
                                            >
                                                {/* {
                                                        moodList.find(
                                                            (item) => item.value === meta.fcatid
                                                        ).title
                                                    }
                                                    <span style={{ margin: '0 8px' }}>•</span> */}
                                                <span
                                                    style={{
                                                        whiteSpace: 'nowrap',
                                                    }}
                                                >
                                                    {OccupancyString({
                                                        adults: Number(
                                                            meta.adults
                                                        ),
                                                        t,
                                                    })}
                                                    ,
                                                </span>
                                                {meta.child_age.length ? (
                                                    <span
                                                        style={{
                                                            whiteSpace:
                                                                'nowrap',
                                                        }}
                                                    >
                                                        {' '}
                                                        {OccupancyString({
                                                            children:
                                                                meta.child_age
                                                                    .length,
                                                            t,
                                                        })}
                                                        ,
                                                    </span>
                                                ) : null}
                                                <span
                                                    style={{
                                                        whiteSpace: 'nowrap',
                                                    }}
                                                >
                                                    {' '}
                                                    {OccupancyString({
                                                        rooms: Number(
                                                            meta.rooms
                                                        ),
                                                        t,
                                                    })}
                                                </span>
                                            </span>
                                        </button>
                                    )}
                                    {isLoading ? (
                                        <h3 className="supplier__recommendations-message h3">
                                            {t('Loading...')}
                                        </h3>
                                    ) : item.roomsAvailable ? (
                                        item.roomsAvailable.length ? (
                                            roomsSlides
                                        ) : (
                                            <h3 className="supplier__recommendations-message h3">
                                                {t('No rooms available.')}
                                            </h3>
                                        )
                                    ) : item.rooms && item.rooms.length ? (
                                        roomsSlides
                                    ) : (
                                        <h3 className="supplier__recommendations-message h3">
                                            {t('No rooms available.')}
                                        </h3>
                                    )}
                                </div>
                                {item.checkin || item.checkout ? (
                                    <div className="supplier__checkin-info">
                                        {!item.checkin ? null : (
                                            <div>
                                                <h2 className="supplier__checkin-title h3">
                                                    {t('Check-in')}
                                                </h2>
                                                <div>{item.checkin}</div>
                                            </div>
                                        )}
                                        {!item.checkout ? null : (
                                            <div>
                                                <h2 className="supplier__checkin-title h3">
                                                    {t('Check-out')}
                                                </h2>
                                                <div>{item.checkout}</div>
                                            </div>
                                        )}
                                    </div>
                                ) : null}
                                {!description ? null : (
                                    <p className="supplier__description">
                                        {description}
                                    </p>
                                )}
                                <Amenities amenities={item.amenityArray} />
                            </>
                        ) : isLoading ? (
                            infoLoader
                        ) : null}
                    </div>
                    <div className="supplier__map-container">
                        {item ? (
                            <>
                                <div className="supplier__map">
                                    <div className="supplier__map-label h3">
                                        {t('Chargers')}
                                    </div>
                                    <Map
                                        google={props.google}
                                        matches={markers}
                                        center={mapCenter}
                                        setCenter={setMapCenter}
                                        showRadius="donut"
                                        radius={200}
                                        confirmedChargers={
                                            item.confirmed_chargers
                                        }
                                        smallMap={true}
                                        disableClick={true}
                                        zoom={mapZoom}
                                        setZoom={setMapZoom}
                                        mapStyle={{
                                            height: isMobile ? 343 : 424,
                                            borderRadius: '24px',
                                            overflow: 'hidden',
                                        }}
                                    />
                                </div>
                                {/* <h2 className="supplier__subtitle h3">
                                    {t('Chargers')}
                                </h2> */}
                                <ChargerInfoCards
                                    item={item}
                                    checkin={
                                        meta.checkin
                                            ? parseISO(meta.checkin)
                                            : null
                                    }
                                />
                                <SearchUSP />
                            </>
                        ) : isLoading ? (
                            mapLoader
                        ) : null}
                    </div>
                </div>
            </div>
            {reactDOM.createPortal(
                <ModalHOC
                    isOpen={findMobileOpen}
                    clear={true}
                    openBtnRef={mobileSearchBtnRef}
                    closeModal={closeMobileSearch}
                >
                    <MobileSearch closeMobileSearch={closeMobileSearch} />
                </ModalHOC>,
                document.getElementById('modal')
            )}
        </section>
    );
}
