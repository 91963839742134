import PageNotFoundBlock from   '../Components/PageNotFound/PageNotFoundBlock';

function PageNotFound() {
    // const content = [
    //     {
    //         "id": 5,
    //         "__component": "content-blocks.textblock",
    //         "text": "Sorry, we cannot find the page you requested.",
    //         "alignment": "left",
    //         "showAsTip": false,
    //         "stylingType": "Default Text Block",
    //         "image": {}
    //     }
    // ]

    return (
        <>
            <PageNotFoundBlock />
        </>
    );
}

export default PageNotFound;
