import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import qs from 'qs';

import Hero from '../../Components/Hero/Hero.js';
import { useLocaleContext } from '../../core/context/localeContext.js';
import PageBuilder from '../../Components/PageBuilder.js';
import useLoader from '../../core/hooks/useLoader.js';

const BASE_URL = process.env.REACT_APP_BASE_URL || 'https://www.sleepercharger.com';

function BlogIndex(props) {
    const [page, setPage] = useState(null);
    const { locale } = useLocaleContext();
    useLoader(page);

    useEffect(() => {
        const getPage = async () => {
            const query = qs.stringify(
                {
                    locale: locale,
                    populate: {
                        hero: {
                            populate: ['image', 'imageMobile'],
                        },
                        builder: {
                            populate: '*',
                        },
                        pageMeta: {
                            populate: '*',
                        },
                    },
                },
                {
                    encodeValuesOnly: true, // prettify URL
                }
            );
            await axios
                .get(`${process.env.REACT_APP_API_URL}/api/story?${query}`, {
                    headers: {
                        Authorization: process.env.REACT_APP_API_KEY,
                    },
                })
                .then((res) => {
                    if (
                        res &&
                        res.data &&
                        res.data.data &&
                        res.data.data.attributes
                    ) {
                        setPage(res.data.data.attributes);
                    }
                })
                .catch((err) => {
                    setPage(null);
                    console.error('err', err);
                });
        };

        if (locale) getPage();
    }, [locale]);

    return (
        <>
            {page && (
                <>
                    <Helmet>
                        <title>
                            {page?.pageMeta?.title || page.hero?.title} |
                            SleeperCharger
                        </title>
                        <meta
                            name="description"
                            content={
                                page?.pageMeta?.description ||
                                'Hotel with guaranteed electric vehicle chargers. Book now only at SleeperCharger.'
                            }
                        />
                        <link
                            rel="canonical"
                            href={
                                page?.pageMeta?.canonical ||
                                `${BASE_URL}${
                                    locale !== 'en' ? `/${locale}` : ''
                                }/blog`
                            }
                        />
                    </Helmet>
                    {page.hero && (
                        <Hero data={page.hero} isMobile={props.isMobile} />
                    )}
                    {page.builder.length && (
                        <PageBuilder blocks={page.builder} />
                    )}
                </>
            )}
        </>
    );
}

export default BlogIndex;
