import React, { useContext, useEffect } from 'react';
import { ThemeContext } from '../../providers/ThemeProvider';
import DividerHero from '../DividerHero';

import './Hero.css';

function Hero({ data }) {
    const { setHeroColor, setPageColor, setHeroWithSearch } =
        useContext(ThemeContext);

    useEffect(() => {
        setHeroColor(data?.showBackgroundGraphic ? 'hero-dark' : 'hero-light');
        setPageColor(
            !data?.showBackgroundGraphic && data?.background !== 'Parallax'
                ? 'background-color'
                : ''
        );
        if (data) setHeroWithSearch(data?.showSearchBar ? true : false);

        return () => {
            setHeroColor('');
            setPageColor('');
        };
    }, [
        data,
        data?.background,
        data?.showBackgroundGraphic,
        data?.showSearchBar,
        setHeroColor,
        setHeroWithSearch,
        setPageColor,
    ]);

    return (
        <section
            className={`hero${
                data?.showBackgroundGraphic ? ' hero--dark' : ''
            }`}
        >
            <div className="wrap">
                <div className="hero__container">
                    <h1 className="hero__title h1">{data?.title}</h1>
                    {data?.subtitle && (
                        <p className="hero__desc h3">{data.subtitle}</p>
                    )}
                </div>
            </div>
            <DividerHero />
        </section>
    );
}

export default Hero;
