import React from 'react';

export default function SearchInput({
    style,
    className,
    value,
    onFocus,
    handleEnter,
    innerRef,
    name,
    onBlur,
    maxLength,
    placeholder,
    handleInput,
    label,
    register,
    errors,
    disabled,
    readonly,
    type = 'text',
    labelClassName,
    onClick,
    formFocused,
    isCompact,
    supplierPage,
    compactLabel,
}) {
    const handleOnChange = (name, val) => {
        handleInput(name, { value: val });
    };

    return (
        <div className="input-component" onClick={onClick}>
            <div className="input-component__wrapper" style={style}>
                <label
                    className={`input-component__label${
                        labelClassName ? ' ' + labelClassName : ''
                    }`}
                >
                    <span className="input-component__label-text">
                        {label}
                    </span>
                    <input
                        className={className}
                        value={value}
                        name={name}
                        onFocus={onFocus}
                        onKeyDown={handleEnter}
                        onChange={(e) => handleOnChange(name, e.target.value)}
                        onBlur={onBlur}
                        autoComplete="off"
                        type={type}
                        disabled={disabled}
                        readOnly={readonly}
                        maxLength={maxLength}
                        ref={innerRef}
                        placeholder={placeholder}
                        id={name}
                    />
                </label>
            </div>
        </div>
    );
}
