import { forwardRef, useCallback } from 'react';

import { useLocaleContext } from '../../../../core/context/localeContext';
import { useSearchContext } from '../../../../core/context/searchContext';
import { Icon } from '../../../../common/Icon/Icon';

import './MobileSearchButton.css';

const MobileSearchButton = forwardRef((props, ref) => {
    const { openDestinationMobile } = useSearchContext();
    const { t } = useLocaleContext();

    const openSearch = useCallback(() => {
        openDestinationMobile(ref);
    }, [openDestinationMobile, ref]);

    return (
        <button
            className="mobile-search-btn"
            onClick={openSearch}
            type="button"
            ref={ref}
        >
            <span className="mobile-search-btn__label">
                <span className="form-label">{t('Where?')}</span>
                {t('Enter your destination')}
            </span>
            <span className="mobile-search-btn__icon">
                <Icon.Arrow className="icon-small" />
            </span>
        </button>
    );
});

export default MobileSearchButton;
