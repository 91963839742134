import './Loader.css';

const Loader = ({size, color, bgColor, className}) => {
    return (
        <div className={`loader-container${className ? ' ' + className : ''}`} style={{'--color': color || 'var(--primary)', '--bg_color': bgColor || 'white', '--size': size + 'px' || '20px'}}>
            <div className="loader">Loading...</div>
        </div>
    );
}

export default Loader;