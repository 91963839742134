import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import { useLocaleContext } from '../../../core/context/localeContext';
import { Icon } from '../../../common/Icon/Icon';
import ToggleButton from '../../../common/ToggleButton/ToggleButton';
import ToggleButtonItem from '../../../common/ToggleButton/ToggleButtonItem/ToggleButtonItem';
import { useUserContext } from '../../../core/context/userContext';
import DetectClickOutside from '../../../core/hooks/detectClickOutside.js';
import GroupForm from './GroupForm/GroupForm';
import NewTraveller from '../NewTraveller/NewTraveller';

import './Groups.css';

const BASE_URL = process.env.REACT_APP_BASE_URL || 'https://www.sleepercharger.com';

const Groups = () => {
    const { locale, t } = useLocaleContext();
    const { userDetails, setUserDetails } = useUserContext();
    const [editGroup, setEditGroup] = useState(null);
    const [editTraveller, setEditTraveller] = useState(null);
    const [showTravellers, setShowTravellers] = useState(false);
    const [showTravellerForm, setShowTravellerForm] = useState(false);

    const stepBack = useCallback(() => {
        setEditGroup(null);
    }, []);

    useEffect(() => {
        window.addEventListener('popstate', stepBack);
        return () => {
            window.removeEventListener('popstate', stepBack);
        };
    }, [stepBack]);

    return (
        <>
            <Helmet>
                <title>{`${t('Add Travel Group')} | SleeperCharger`}</title>
                <meta
                    name="description"
                    content={`${t('Add Travel Group')} | SleeperCharger`}
                />
                <link
                    rel="canonical"
                    href={`${BASE_URL}${
                        locale !== 'en' ? `/${locale}` : ''
                    }/profile/groups`}
                />
            </Helmet>
            <section className="groups">
                <div className="wrap wrap--sm">
                    {!userDetails?.groups?.length ? (
                        <>
                            <Link className="link" to="../profile">
                                <Icon.Arrow className="icon-small icon-left flip-x" />
                                {t('Back to profile')}
                            </Link>
                            <div className="content-container">
                                <h1 className="groups__title h2">
                                    {t('Add Travel Group')}
                                </h1>
                                <GroupForm
                                    userDetails={userDetails}
                                    setEditGroup={setEditGroup}
                                />
                            </div>
                        </>
                    ) : editGroup === null ? (
                        <>
                            <Link className="link" to="../profile">
                                <Icon.Arrow className="icon-small icon-left flip-x" />
                                Back to profile overview
                            </Link>
                            <ToggleButton
                                label="Groups and travellers"
                                onChange={setShowTravellers}
                                preSelected={0}
                            >
                                <ToggleButtonItem value={false}>
                                    Travel Groups
                                </ToggleButtonItem>
                                <ToggleButtonItem value={true}>
                                    Travellers
                                </ToggleButtonItem>
                            </ToggleButton>
                            {showTravellers ? (
                                <div className="content-container">
                                    <div className="groups__header">
                                        <h1 className="h2">Travellers</h1>
                                        <DetectClickOutside
                                            onClick={() => {
                                                if (showTravellerForm)
                                                    setShowTravellerForm(false);
                                            }}
                                            detectTab={true}
                                        >
                                            <div className="relative">
                                                <button
                                                    className="btn btn--blue"
                                                    type="button"
                                                    onClick={() =>
                                                        setShowTravellerForm(
                                                            (prevState) =>
                                                                !prevState
                                                        )
                                                    }
                                                    disabled={
                                                        editTraveller !== null
                                                    }
                                                >
                                                    Add Traveller{' '}
                                                    <Icon.Plus className="icon-small icon-right" />
                                                </button>
                                                {!showTravellerForm ? null : (
                                                    <NewTraveller
                                                        setShowTravellerForm={
                                                            setShowTravellerForm
                                                        }
                                                    />
                                                )}
                                            </div>
                                        </DetectClickOutside>
                                    </div>
                                    <ul className="groups__list">
                                        {userDetails.travellers.map(
                                            (traveller) => (
                                                <li
                                                    className="groups__item"
                                                    key={traveller.id}
                                                >
                                                    {editTraveller !==
                                                    traveller.id ? (
                                                        <button
                                                            className="groups__item-btn"
                                                            type="button"
                                                            onClick={() => {
                                                                setEditTraveller(
                                                                    traveller.id
                                                                );
                                                            }}
                                                            disabled={
                                                                editTraveller !==
                                                                null
                                                            }
                                                        >
                                                            <span className="groups__item-label">
                                                                <span className="h4">
                                                                    {
                                                                        traveller.name
                                                                    }
                                                                </span>
                                                            </span>
                                                            <span className="link">
                                                                Edit
                                                            </span>
                                                        </button>
                                                    ) : (
                                                        <>
                                                            <button
                                                                className="groups__item-btn"
                                                                type="button"
                                                                onClick={() => {
                                                                    setEditTraveller(
                                                                        null
                                                                    );
                                                                }}
                                                            >
                                                                <span className="groups__item-label">
                                                                    <span className="h4">
                                                                        {
                                                                            traveller.name
                                                                        }
                                                                    </span>
                                                                </span>
                                                                <span className="link">
                                                                    Cancel
                                                                </span>
                                                            </button>
                                                            <NewTraveller
                                                                inline
                                                                traveller={userDetails.travellers.find(
                                                                    (
                                                                        traveller
                                                                    ) =>
                                                                        traveller.id ===
                                                                        editTraveller
                                                                )}
                                                                setEditTraveller={
                                                                    setEditTraveller
                                                                }
                                                            />
                                                        </>
                                                    )}
                                                </li>
                                            )
                                        )}
                                    </ul>
                                </div>
                            ) : (
                                <div className="content-container">
                                    <div className="groups__header">
                                        <h1 className="h2">Travel Groups</h1>
                                        <button
                                            className="btn btn--blue"
                                            onClick={() => {
                                                setEditGroup('new');
                                                window.history.pushState(
                                                    {},
                                                    null
                                                );
                                            }}
                                        >
                                            Add Travel Group
                                            <Icon.Plus className="icon-small icon-right" />
                                        </button>
                                    </div>
                                    <ul className="groups__list">
                                        {userDetails.groups.map((group) => (
                                            <li
                                                className="groups__item"
                                                key={group.id}
                                            >
                                                <button
                                                    className="groups__item-btn"
                                                    type="button"
                                                    onClick={() => {
                                                        setEditGroup(group.id);
                                                        window.history.pushState(
                                                            {},
                                                            null
                                                        );
                                                    }}
                                                >
                                                    <span className="groups__item-label">
                                                        <span className="h4">
                                                            {group.name}
                                                        </span>
                                                        {!group.travellers
                                                            ?.length
                                                            ? null
                                                            : group.travellers
                                                                  .map(
                                                                      (
                                                                          traveller
                                                                      ) =>
                                                                          userDetails.travellers.find(
                                                                              (
                                                                                  userTraveller
                                                                              ) =>
                                                                                  userTraveller.id ===
                                                                                  traveller
                                                                          ).name
                                                                  )
                                                                  ?.join(', ')}
                                                    </span>
                                                    <span className="link">
                                                        Edit
                                                        <Icon.Arrow className="icon-small icon-right" />
                                                    </span>
                                                </button>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                        </>
                    ) : (
                        <>
                            <button
                                className="link"
                                onClick={() => setEditGroup(null)}
                            >
                                <Icon.Arrow className="icon-small icon-left flip-x" />
                                All Travel Groups
                            </button>
                            <div className="content-container">
                                <h1 className="groups__title h2">
                                    {editGroup === 'new'
                                        ? 'Add Travel Group'
                                        : 'Edit Travel Group'}
                                </h1>
                                <GroupForm
                                    userDetails={userDetails}
                                    editGroup={editGroup}
                                    setEditGroup={setEditGroup}
                                />
                            </div>
                        </>
                    )}
                </div>
            </section>
        </>
    );
};

export default Groups;
