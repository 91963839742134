import { React } from 'react';
import { Outlet } from 'react-router-dom';
import useLoader from '../core/hooks/useLoader';

export default function AdminLayout(props) {
    useLoader({});

    return (
        <div className="page-layout theme-dark">
            <Outlet />
        </div>
    );
}
