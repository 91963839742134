import React from 'react';
import { Icon } from '../Icon/Icon';

const CustomSelect = ({
    id,
    label,
    value,
    options,
    onChange,
    name,
    error,
    emptyMessage,
    invalidMessage,
}) => {
    return (
        <div
            className={`input-container input-container--select${
                !error ? '' : ' error'
            }`}
        >
            <label className="input-label" htmlFor={id}>
                {label}
            </label>
            <div className="input-wrapper">
                <select
                    className="input-field input-field--select"
                    autoComplete="off"
                    id={id}
                    name={name}
                    value={value}
                    onChange={onChange}
                >
                    {options}
                </select>
                <Icon.Chevron className="input-container__icon icon-small" />
            </div>
            {!error ? null : (
                <div className="input-error">
                    <span>
                        {error === 'empty'
                            ? emptyMessage
                            : error === 'invalid'
                            ? invalidMessage
                            : ''}
                    </span>
                </div>
            )}
        </div>
    );
};

export default CustomSelect;
