import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CustomInput from '../../../common/CustomInput/CustomInput';

import axios from 'axios';

import Loader from '../../../common/Loader/Loader';
import { useLocaleContext } from '../../../core/context/localeContext';

export default function ResetPassword({ handleResult, setSentEmail }) {
    const navigate = useNavigate();
    const { t } = useLocaleContext();
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');

    const handleSubmit = useCallback(
        (email) => {
            if (email) {
                const func = async (email) => {
                    setLoading(true);
                    const payload = {
                        email,
                    };
                    let result = await axios.post(
                        `${process.env.REACT_APP_SC_API_BASE}/user/send-reset-password-link`,
                        payload
                    );

                    setLoading(false);
                    handleResult(result);
                    setSentEmail(email);
                };

                func(email).catch((err) => {
                    setLoading(false);
                    console.error({ err });
                    handleResult(err.response);
                    setSentEmail('');
                });
            }
        },
        [email, navigate]
    );

    const handleChange = useCallback(
        (evt) => {
            let name = evt.target.name;
            let value = evt.target.value;

            setEmail(value);
        },
        [email, setEmail]
    );

    const [errors, setErrors] = useState({
        email: '',
    });

    const handleFormSubmit = useCallback(
        (e) => {
            e.preventDefault();
            handleSubmit(email);
        },
        [email]
    );

    return (
        <>
            <div className="signup__header">
                <h1 className="signup__title h2">{t('Reset Password')}</h1>
                <p className="signup__desc h4">
                    {t('Enter your e-mail to send a password reset link.')}
                </p>
            </div>
            <form onSubmit={handleFormSubmit}>
                <CustomInput
                    id="email"
                    label="Email address"
                    name="email"
                    type="email"
                    value={email}
                    autoComplete="on"
                    onChange={(e) => {
                        handleChange(e);
                    }}
                    placeholder="Email address"
                    error={errors.email}
                    emptyMessage="Please fill in your email address"
                    invalidMessage="Please enter a valid email address"
                />

                <button
                    className="signup__btn btn btn--blue"
                    disabled={loading}
                >
                    {t('Send reset password link')}
                    {loading ? (
                        <Loader
                            size={20}
                            bgColor={'var(--secondary90)'}
                            color={'var(--primary)'}
                            className="icon-right"
                        />
                    ) : null}
                </button>
            </form>
        </>
    );
}
