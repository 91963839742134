import {
    React,
    useRef,
    useEffect,
    useCallback,
    useMemo,
    useState,
} from 'react';

import { Icon } from '../../common/Icon/Icon';
import DialogLabel from '../../common/DialogLabel/DialogLabel';
import { useLocaleContext } from '../../core/context/localeContext.js';
import { useUserContext } from '../../core/context/userContext';
import { ToBool, getCountryName, RoundNumberByDecimals } from '../../helpers';

import './Recommendation.css';
import DiscountDialog from '../../common/DialogLabel/DiscountDialog/DiscountDialog';

const REACT_APP_SHOW_USERS = process.env.REACT_APP_SHOW_USERS;

export default function Recommendation(props) {
    let guestRating;
    const { t, locale } = useLocaleContext();
    const recommendationRef = useRef(null);
    const [showDiscountDialog, setShowDiscountDialog] = useState(false);
    const { userDetails } = useUserContext();
    const isLoggedIn = ToBool(userDetails?.token);

    if (props.item.ratings) {
        if (props.item.overallGuestRating) {
            guestRating = (
                <span className="recommendation__rating">
                    <Icon.Smiley />
                    <span>
                        {props.item.overallGuestRating} (
                        {props.item.ratings.guest.count})
                    </span>
                </span>
            );
        }
    }

    const confirmedChargers = props.item.confirmed_chargers?.reduce(
        (accumulator, charger) => accumulator + charger.evses.length,
        0
    );
    const chargersNearby = props.item.chargers?.reduce(
        (accumulator, charger) => accumulator + charger.evses.length,
        0
    );

    useEffect(() => {
        if (!props.shouldFocus || !recommendationRef.current) return;
        recommendationRef.current.focus();
    }, [props.shouldFocus]);

    const handleKeyDown = useCallback(
        (e) => {
            if (e.keyCode === 13 || e.keyCode === 32) {
                e.preventDefault();
                props.onClick();
            }
        },
        [props]
    );

    const starRating = useMemo(() => {
        const rating = Number(props.item?.ratings?.property?.rating);
        const stars = [];

        for (let i = 0; i < Math.floor(rating); i++) {
            stars.push(
                <Icon.Star className="recommendation__star-icon" key={i} />
            );
        }
        if (rating % 1)
            stars.push(
                <Icon.StarHalf
                    className="recommendation__star-icon"
                    key={'star-half'}
                />
            );

        return stars;
    }, [props.item?.ratings?.property?.rating]);

    return (
        <div
            className={`recommendation${
                props.onMap ? ' recommendation--map' : ''
            }${
                props.isHotItem || showDiscountDialog
                    ? ' recommendation--hot'
                    : ''
            }`}
            style={{zIndex: showDiscountDialog ? '2' : ''}}
            onMouseEnter={props.onMouseEnter}
            onMouseLeave={props.onMouseLeave}
            onKeyDown={handleKeyDown}
            onClick={props.onClick}
            role="link"
            tabIndex={0}
            aria-label={props.item.name}
            ref={recommendationRef}
        >
            {!props.item.images ? null : (
                <div className="recommendation__img-container">
                    <img
                        className="cover-img"
                        src={
                            props.item.images[0].links['1000px']?.href ||
                            props.item.images[0].links['350px']?.href
                        }
                        alt={props.item.images[0].caption}
                    />
                </div>
            )}
            <div className="recommendation__content">
                {confirmedChargers || chargersNearby ? (
                    <div className="recommendation__details-item recommendation__details-item--chargers caption">
                        <Icon.Bolt className="recommendation__details-icon icon-small" />
                        <div>
                            {!confirmedChargers ? null : (
                                <div>
                                    <span>{confirmedChargers}</span>{' '}
                                    {t(
                                        '%{count} verified chargers at property',
                                        {
                                            count: confirmedChargers,
                                        }
                                    )}
                                </div>
                            )}
                            {!chargersNearby ? null : (
                                <div>
                                    <span>{chargersNearby}</span>{' '}
                                    {t('%{count} chargers nearby', {
                                        count: chargersNearby,
                                    })}
                                </div>
                            )}
                        </div>
                    </div>
                ) : null}
                <h2 className="recommendation__title h4">{props.item.name}</h2>
                <h3 className="recommendation__subtitle caption">
                    {props.item?.address?.city},{' '}
                    {getCountryName(
                        props.item?.address?.country_code,
                        locale,
                        'region'
                    )}
                </h3>
                {props.item.isAlt ? (
                    <div className="recommendation__details-item">
                        Alternative suggestion
                    </div>
                ) : null}
                <div className="recommendation__rating-container">
                    {!props.item?.ratings?.property ? null : (
                        <div className="recommendation__star-container">
                            {starRating}
                        </div>
                    )}
                    {guestRating}
                </div>

                {!props.item.totalPriceInc ? null : (
                    <div className="recommendation__footer">
                        <div>
                            <div className="recommendation__price-container">
                                <div className="recommendation__info-price form-label">
                                    €
                                    {RoundNumberByDecimals(
                                        props.item.totalPriceInc /
                                            props.item.numNights,
                                        2
                                    ).toFixed(2)}{' '}
                                    {t('night')}
                                </div>
                                {!props.item.strikethroughPrice ? null : (
                                    <div className="recommendation__strikethrough-price">
                                        €
                                        {RoundNumberByDecimals(
                                            props.item.strikethroughPrice /
                                                props.item.numNights,
                                            2
                                        ).toFixed(2)}
                                    </div>
                                )}
                            </div>
                            <div className="recommendation__price-container recommendation__price-container--total">
                                <div className="recommendation__info-price recommendation__info-price--total form-label">
                                    €{(+props.item.totalPriceInc).toFixed(2)}{' '}
                                    {t('total')}
                                </div>
                                {!props.item.strikethroughPrice ? null : (
                                    <div className="recommendation__strikethrough-price">
                                        €
                                        {(+props.item
                                            .strikethroughPrice).toFixed(2)}
                                    </div>
                                )}
                            </div>
                        </div>

                        {props.item.member_deal_available &&
                        REACT_APP_SHOW_USERS ? (
                            <DialogLabel
                                text={t('Get discount')}
                                icon={<Icon.Percentage className="icon-xs" />}
                                openState={showDiscountDialog}
                                setOpenState={setShowDiscountDialog}
                            >
                                <DiscountDialog
                                    setOpenState={setShowDiscountDialog}
                                />
                            </DialogLabel>
                        ) : null}
                        {props.item.strikethroughPrice &&
                        REACT_APP_SHOW_USERS ? (
                            <DialogLabel
                                text={`- ${props.item.discountPercentage}% off`}
                                icon={null}
                                openState={showDiscountDialog}
                                setOpenState={setShowDiscountDialog}
                            >
                                <DiscountDialog
                                    setOpenState={setShowDiscountDialog}
                                />
                            </DialogLabel>
                        ) : null}
                    </div>
                )}
            </div>
        </div>
    );
}
