import React, { useMemo } from 'react';
import { useLocaleContext } from '../../../core/context/localeContext';

import { Icon } from '../../../common/Icon/Icon';

export const moodList = [
    {
        icon: <Icon.Magic className="icon-small" />,
        value: 'unique',
    },
    {
        icon: <Icon.Diamond className="icon-small" />,
        value: 'luxury',
    },
    {
        icon: <Icon.Tree className="icon-small" />,
        value: 'adventure',
    },
    {
        icon: <Icon.Hand className="icon-small" />,
        value: 'hosted',
    },
    {
        icon: <Icon.Hotel className="icon-small" />,
        value: 'hotel',
    },
    {
        icon: <Icon.Resort className="icon-small" />,
        value: 'resort',
    },
    {
        icon: <Icon.StarHollow className="icon-small" />,
        value: 'surpriseme',
    },
]

export default function MoodList({ mood, onClick }) {
    const { t } = useLocaleContext();

    const moodListItems = useMemo(() => {
        return moodList.map((item, i) => (
            <li
                className={`mood-list__item${
                    mood === item.value ? ' active' : ''
                }`}
                key={i}
            >
                <button
                    className="mood-list__btn"
                    type="button"
                    onClick={() => onClick(item.value)}
                >
                    {item.icon}
                    <span className="mood-list__title form-label">
                        {t(`mood.${item.value}.title`)}
                    </span>
                    <span className="mood-list__desc">{t(`mood.${item.value}.desc`)}</span>
                </button>
            </li>
        ));
    }, [mood, onClick]);

    return <ul className="mood-list">{moodListItems}</ul>;
}
