import { useRef, useContext } from 'react';
import { CSSTransition } from 'react-transition-group';
import { ReactComponent as Logo } from '../../images/logo-mobile.svg';

import styles from './PageLoader.module.css';
import { ThemeContext } from '../../providers/ThemeProvider';

const PageLoader = () => {
    const nodeRef = useRef(null);
    const { loading } = useContext(ThemeContext);

    return (
        <CSSTransition
            nodeRef={nodeRef}
            in={loading}
            timeout={400}
            classNames="page-loader"
            unmountOnExit
        >
            <div className={styles['page-loader']} ref={nodeRef}>
                <Logo />
            </div>
        </CSSTransition>
    );
};

export default PageLoader;
