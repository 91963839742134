import { useMemo, useRef } from 'react';
import DetectClickOutside from '../../core/hooks/detectClickOutside.js';
import { hasRoom } from '../../helpers';

import styles from './DialogLabel.module.css';

const DialogLabel = ({ children, text, icon, openState, setOpenState }) => {
    const discountBtnRef = useRef(null);
    const dialogContainerRef = useRef(null);
    const dialogRef = useRef(null);

    const toggleDiscountDialog = () => {
        setOpenState((prevState) => !prevState);
    };

    const closeDialog = () => {
        setOpenState(false);
    };

    const dialog = useMemo(
        () => (
            <DetectClickOutside onClick={closeDialog} detectTab={true}>
                <div
                    className={`${styles.dialog} ${
                        openState ? styles.visible : ''
                    }`}
                    ref={dialogRef}
                    style={
                        hasRoom(
                            dialogContainerRef.current,
                            dialogRef.current,
                            true
                        )
                            ? { left: '50%', transform: 'translateX(-50%)' }
                            : { right: '0' }
                    }
                >
                    {children}
                </div>
            </DetectClickOutside>
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [openState]
    );

    return (
        <div
            className="label"
            ref={dialogContainerRef}
            onClick={(e) => e.stopPropagation()}
            onMouseDown={(e) => {
                if (openState) e.stopPropagation();
            }}
            onKeyDown={(e) => e.stopPropagation()}
        >
            <button
                className="label__btn"
                onClick={toggleDiscountDialog}
                ref={discountBtnRef}
            >
                {!text ? null : <span className="caption">{text}</span>}
                {icon && icon}
            </button>
            {dialog}
        </div>
    );
};

export default DialogLabel;
