import { React, useMemo } from 'react';

import RoomRecommendation from './RoomRecommendation/RoomRecommendation.js';

const APP_BUILD_ENV = process.env.REACT_APP_BUILD_ENV;

export default function Rooms (props) {
    let items = props.item.roomsAvailable || props.item.rooms;
    let info = props.item.rooms;

    let itemData = useMemo(() =>
        items.map((e, i) => {
            // Note(gb) backwards compatibility, should be array always from 23 sep 2022 onward
            let details = Array.isArray(info)
                ? info.find((it) => it.id === e.id)
                : info[ e.id ];

            // @TODO(gb) this can happen when expedia returns room ids other than we have in our database for this property (property_id: 1180105)
            if (!details) return undefined;

            let img = ''; // @TODO server-side
            if (details.images) {
                let hero = details.images.find((e) => e.hero_image);
                if (hero && hero.links) {
                    if (hero.links[ '350px' ]) {
                        img = hero.links[ '350px' ].href;
                    } else {
                        // @TODO handle when images are too low quality
                    }
                }
            } else {
                img = props.item.images[ 0 ]?.links[ '350px' ]?.href;
            }

            // @TODO fix strange way to get amenities
            let amenities = props.item.roomAmenities && props.item.roomAmenities[ i ];
            let square_meters = details.area
                ? details.area.square_meters
                : '';

            return {
                ...e,
                id: e.id,
                heroImg: img,
                images: details.images || props.item.images,
                amenities: amenities?.map((e) => e.name).join(', '),
                allRoomAmenities: details.amenities,
                area: square_meters,
            };
        })
            .filter((e) => e !== undefined),
        [ info, items, props.item.images, props.item.roomAmenities ]
    );

    if (itemData.length) {
        return itemData.map((item, i) => (
            <RoomRecommendation
                queryParams={props.queryParams}
                key={item.id + i}
                item={item}
                info={info}
                roomFilters={props.roomFilters}
            />
        ));
    }

    return <h3 className="h3">No rooms available.</h3>;
}
