import { useState, useEffect, useMemo, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { useParams, useNavigate } from 'react-router-dom';
import { useUserContext } from '../../../core/context/userContext';
import axios from 'axios';
import LoginForm from '../Login/LoginForm';

const State = {
    VERIFYING: 0,
    VERIFIED: 1,
    ERROR: 2
}

const UserVerify = () => {
    const navigate = useNavigate();

    const { hash } = useParams();

    const { setUserDetails, setLocalSessionToken, setSessionStorageToken } = useUserContext();

    const [ loading, setLoading ] = useState(false);
    const [ result, setResult ] = useState(State.VERIFYING);
    const [ email, setEmail ] = useState('')

    useEffect(() => {
        setLoading(true);

        const maybeVerify = async (userData) => {
            let result = await axios.get(
                `${process.env.REACT_APP_SC_API_BASE}/user/verify/${hash}`
            );

            setLoading(false);

            if (result.status === 200) {
                setResult(State.VERIFIED)
                setEmail(result.data.email)
            }

        };

        maybeVerify().catch((err, res) => {
            setLoading(false);

            if (err.response.status === 400) {
                setResult(2)

            } else {
                console.error(err.response);
            }
        });

    }, []);


    const handleLoginResult = useCallback((result, doRemember) => {

        if (result.status === 200) {

            let token = result.data.token;
            let prev_login = result.data.prev_login;

            if (doRemember) {
                setLocalSessionToken(token)
            } else {
                setSessionStorageToken(token)
            }

            setUserDetails({ token, prev_login })
            navigate('../profile')
        }
    }, [])

    const element = useMemo(() => {
        if (result === State.VERIFYING) {
            return (
                <>
                    <h1 className="signup__title h2">Hold on...</h1>
                    <p className="signup__desc h3">
                        Verifying your e-mail...
                    </p>
                </>
            )
        } else if (result === State.VERIFIED) {
            // verified
            return (
                <>
                    <div className="wrap wrap--md">
                        <div className="profile__message">You have successfully verified you account. Log-in to continue.</div>
                    </div>

                    <LoginForm email={email} handleResult={handleLoginResult} />
                </>

            )
        } else if (result === State.ERROR) {
            return (
                <>
                    <div className="wrap wrap--md">

                        <div className="profile__message profile__message__warning">
                            This e-mail verification link is incorrect or expired.
                        </div>
                    </div>
                    <LoginForm handleResult={handleLoginResult} />
                </>
            )
        } else {
            <>
                <h1 className="signup__title h2">Hmm...</h1>
                <p className="signup__desc h3">
                    Something weird happened...
                </p>
            </>
        }
    }, [ result ])


    return (
        <>
            <Helmet>
                <title>{`Verify Account | SleeperCharger`}</title>
                <meta
                    name="description"
                    content="Verify Acoount | SleeperCharger"
                />
            </Helmet>
            <section className="signup">
                <div className="wrap wrap--sm">
                    <div className="content-container">
                        {element}
                    </div>
                </div>
            </section>
        </>
    );
};

export default UserVerify;
