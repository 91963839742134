import React from 'react';

const DashedBorder = ({ available, unavailable, unknown }) => {
    const circumference = Math.PI * 38 + 4 * 42;
    const numbOfDashes = available + unavailable + unknown;
    const gap = numbOfDashes === 1 ? 0 : numbOfDashes > 20 ? 3 : 10;
    const green = available;
    const red = unavailable;

    return (
        <svg
            className="dashed-border"
            viewBox="0 0 86 86"
            xmlns="http://www.w3.org/2000/svg"
        >
            <foreignObject x="0" y="0" width="86" height="86">
                <div
                    xmlns="http://www.w3.org/1999/xhtml"
                    style={{
                        width: '100%',
                        height: '100%',
                        background: `conic-gradient(#20F58F 0deg, #20F58F ${
                            (360 * green) / numbOfDashes
                        }deg, #F53054 ${
                            (360 * green) / numbOfDashes
                        }deg, #F53054 ${
                            (360 * (red + green)) / numbOfDashes
                        }deg, #CECCCA ${
                            (360 * (red + green)) / numbOfDashes
                        }deg, #CECCCA 360deg)`,
                    }}
                />
            </foreignObject>
            <g
                style={{
                    mixBlendMode: 'lighten',
                }}
            >
                <rect
                    x="0"
                    y="0"
                    width="86"
                    height="86"
                    fill="white"
                />
                <path
                    d="M 22 3 h 42 a 19 19 0 0 1 19 19 v 42 a 19 19 0 0 1 -19 19 h -42 a 19 19 0 0 1 -19 -19 v -42 a 19 19 0 0 1 19 -19"
                    strokeDasharray={`${
                        (circumference - gap * numbOfDashes) / numbOfDashes
                    } ${gap}`}
                    strokeDashoffset={numbOfDashes > 20 ? "-22.5" : "-26"}
                    strokeWidth={3}
                    stroke="black"
                    fill="transparent"
                    strokeLinecap={numbOfDashes > 20 ? "inherit" : "round"}
                />
            </g>
        </svg>
    );
};

export default DashedBorder;
