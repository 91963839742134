import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { Helmet } from 'react-helmet';

import PillButton from '../../common/PillButton/PillButton.js';
import UserDetails from '../UserDetails.js';
import UserDetailsDev from './UserDetails/UserDetails.js';

import {
    ToBool,
    IsValidEmail,
    isEmpty,
    buildParams,
    formatHotelPath,
} from '../../helpers.js';

import { Icon } from '../../common/Icon/Icon.js';
import PropertyInfo from '../PropertyInfo/PropertyInfo.js';
import NewsletterSignup from '../NewsletterSignup/NewsletterSignup.js';
import { useLocaleContext } from '../../core/context/localeContext.js';
import { useHeaderFooterDataContext } from '../../core/context/headerFooterDataContext.js';
import PageLoader from '../../common/PageLoader/PageLoader.js';
import { ThemeContext } from '../../providers/ThemeProvider.js';
import { useUserContext } from '../../core/context/userContext.js';
import SearchUSP from '../SearchUSP/SearchUSP.js';
import useScreenSize from '../../core/hooks/useScreenSize.js';

import './Booking.css';
import { useSearchContext } from '../../core/context/searchContext.js';

const DISABLE_BOOKINGS = ToBool(process.env.REACT_APP_DISABLE_BOOKINGS);
const REACT_APP_SHOW_USERS = ToBool(process.env.REACT_APP_SHOW_USERS);

export const PAGE_STATUS = {
    loading: 'loading',
    error: 'error',
    loaded: 'loaded',
};
const INITIAL_DETAILS = {
    firstname: '',
    lastname: '',
    phone_country_code: '31',
    phone: '',
    email: '',
    special_requests: [],
    is_business_trip: false,
    company: {
        name: '',
        address: {
            line_1: '',
            postal_code: '',
            city: '',
            country_code: '',
        },
    },
    agree_to_terms_and_conditions: false,
}

export default function Booking(props) {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [isLoading, setIsLoading] = useState(true);
    const [isBookingLoading, setIsBookingLoading] = useState(false);
    const [bookingInfo, setBookingInfo] = useState(null);
    const [item, setItem] = useState(0);
    const [meta, setMeta] = useState(0);
    const [errors, setErrors] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const { locale, urlPrefix, t, translateToLocale } = useLocaleContext();
    const { headerFooterData } = useHeaderFooterDataContext();
    const { setPage } = useContext(ThemeContext);
    const [selectedBedGroup, setSelectedBedGroup] = useState(null);
    const { userDetails } = useUserContext();
    const { isMobile } = useScreenSize();
    const { queryParams } = useSearchContext();
    const [changeDetails, setChangeDetails] = useState(false);

    useEffect(() => {
        if (isEmpty(bookingInfo?.chosenRate.bed_groups)) return;
        setSelectedBedGroup({
            id: Object.values(bookingInfo.chosenRate.bed_groups)[0].id,
            token: Object.values(bookingInfo.chosenRate.bed_groups)[0].token,
        });
    }, [item]);

    const [details, setDetails] = useState(INITIAL_DETAILS);
    const [newDetails, setNewDetails] = useState(INITIAL_DETAILS);

    useEffect(() => {
        let isSubscribed = true;

        const doPriceCheck = async () => {
            try {
                setIsLoading(true);

                let params = {
                    sid: searchParams.get('sid'),
                    room_id: searchParams.get('room_id'),
                    rate_id: searchParams.get('rate_id'),
                    bgid: searchParams.get('bgid'),
                    rooms: searchParams.get('rooms'),
                    checkin: searchParams.get('checkin'),
                    checkout: searchParams.get('checkout'),
                    child_age: searchParams.getAll('child_age'),
                    adults: searchParams.get('adults'),
                    lang: locale,
                    m: props.isMobile,
                };

                let headers = userDetails?.token
                    ? {
                          Authorization: `Bearer ${userDetails?.token}`,
                      }
                    : {};

                let result = await axios.get(
                    `${process.env.REACT_APP_SC_API_BASE}/price_check`,
                    {
                        params,
                        headers,
                    }
                );

                setIsLoading(false);

                if (isSubscribed) {
                    setBookingInfo(result?.data?.data?.bookingInfo);
                    setItem(result?.data?.data?.supplierInfo);
                    setMeta(result?.data?.data?.meta);
                }
            } catch (error) {
                setIsLoading(false);
            }
        };

        doPriceCheck().catch(console.error);
    }, [searchParams, locale, userDetails.token, props.isMobile]);

    // simple client-side validation
    const checkDetails = useCallback(() => {
        let _errors = [];
        const currentDetails =
            changeDetails || !userDetails.token ? newDetails : details;

        if (currentDetails.is_business_trip && !userDetails?.company?.added) {
            currentDetails.company = newDetails.company;
        }

        if (!currentDetails.firstname.trim()) {
            _errors.push('firstname');
        }
        if (!currentDetails.lastname.trim()) {
            _errors.push('lastname');
        }
        if (!currentDetails.email || !IsValidEmail(currentDetails.email)) {
            _errors.push('email');
        }
        if (!currentDetails.phone.trim()) {
            _errors.push('phone');
        }
        if (!currentDetails.agree_to_terms_and_conditions) {
            _errors.push('agree_to_terms_and_conditions');
        }

        if (currentDetails.is_business_trip) {
            if (!currentDetails.company.name.trim()) {
                _errors.push('company_name');
            }
            if (!currentDetails.company.address.line_1.trim()) {
                _errors.push('company_line_1');
            }
            if (!currentDetails.company.address.postal_code.trim()) {
                _errors.push('company_postal_code');
            }
            if (!currentDetails.company.address.city.trim()) {
                _errors.push('company_city');
            }
            if (!currentDetails.company.address.country_code.trim()) {
                _errors.push('company_country_code');
            }
        }

        setErrors(_errors);
        return _errors;
    }, [details, newDetails, changeDetails]);

    // COPYPASTE FROM SUPPLIER
    // COPYPASTE FROM SUPPLIER
    // COPYPASTE FROM SUPPLIER

    const doBooking = async () => {
        const _errors = checkDetails();
        if (_errors.length) return;

        setIsBookingLoading(true);

        const url = `${process.env.REACT_APP_SC_API_BASE}/create_booking`;

        const currentDetails =
            changeDetails || !userDetails.token ? newDetails : details;

        if (currentDetails.is_business_trip && !userDetails?.company?.added) {
            currentDetails.company = newDetails.company;
        }

        let result = await axios
          .post(
            url,
            {
              user_details: currentDetails,
              booking_info: {
                sid: meta.sid,
                room_id: meta.room_id,
                rate_id: meta.rate_id,
                checkin: meta.checkin,
                checkout: meta.checkout,
                occupancy: meta.occupancy,
                bgid: selectedBedGroup.id,
                token: selectedBedGroup.token,
              },
            },
            {
              headers: {
                Authorization: `Bearer ${userDetails.token}`,
              },
            }
          )
          .catch((error) => {
            setIsBookingLoading(false);
            setErrorMessage(t(error?.response?.data?.message));
          });
        setIsBookingLoading(false);

        if (
            currentDetails.is_business_trip &&
            userDetails?.token &&
            !userDetails?.company?.added
        ) {
            axios
              .patch(
                `${process.env.REACT_APP_SC_API_BASE}/user`,
                {
                  company: {
                    ...currentDetails.company,
                    added: true,
                  },
                },
                {
                  headers: {
                    Authorization: `Bearer ${userDetails.token}`,
                  },
                }
              )
              .catch((error) => {
                setIsBookingLoading(false);
                setErrorMessage(t(error?.response?.data?.message));
              });
        }

        // If all went well, redirect to payment options
        if (result?.data?.data?.booking_hash) {
            let booking_hash = result.data.data.booking_hash;
            navigate({
                pathname: `${urlPrefix}/my-reservation?h=${booking_hash}`,
            });
        }
    };

    useEffect(() => {
        setPage('booking');

        return () => {
            setPage('');
        };
    }, [setPage]);

    const handleCheckboxChange = useCallback(
        (evt) => {
            setDetails((prevState) => ({
                ...prevState,
                [evt.target.name]: evt.target.checked,
            }));
            setNewDetails((prevState) => ({
                ...prevState,
                [evt.target.name]: evt.target.checked,
            }));
        },
        [setNewDetails, setDetails]
    );

    const formLoader = (
        <div className="loading-form">
            <div className="loading-form__title block-loader"></div>
            <div className="loading-form__row">
                <div className="loading-form__field">
                    <div className="loading-form__label block-loader"></div>
                    <div className="loading-form__input block-loader"></div>
                </div>
                <div className="loading-form__field">
                    <div className="loading-form__label block-loader"></div>
                    <div className="loading-form__input block-loader"></div>
                </div>
            </div>
            <div className="loading-form__field">
                <div className="loading-form__label block-loader"></div>
                <div className="loading-form__input block-loader"></div>
            </div>
            <div className="loading-form__field">
                <div className="loading-form__label block-loader"></div>
                <div className="loading-form__input block-loader"></div>
            </div>
            <div className="loading-form__business block-loader"></div>
            <div className="loading-form__trip block-loader"></div>
            <div className="loading-form__terms block-loader"></div>
            <div className="loading-form__btn block-loader"></div>
        </div>
    );

    const messageLoader = (
        <div className="loading-message">
            <div className="loading-message__title block-loader"></div>
            <div className="loading-message__desc block-loader"></div>
            <div className="loading-message__title block-loader"></div>
            <div className="loading-message__form block-loader"></div>
        </div>
    );

    const pageStatus = isLoading
        ? PAGE_STATUS.loading
        : !item || !bookingInfo || !meta
        ? PAGE_STATUS.error
        : PAGE_STATUS.loaded;

    const goBack = useCallback(() => {
        if (!item) return;
        let { dest, radius, zoom, ...params } = {
            ...queryParams,
        };
        let searchParams = buildParams(params);
        const hotelPath = `${urlPrefix}${formatHotelPath({
            name: item.name,
            city: item.address?.city,
            country: item.address?.country_code,
            locale,
            translateToLocale,
        })}`;

        navigate({
            pathname: hotelPath,
            search: `?${searchParams}`,
        });
    }, [item, locale, navigate, queryParams, translateToLocale, urlPrefix]);

    return (
        <section className="booking">
            <Helmet>
                <title>
                    {meta?.metatags?.title || 'Booking | SleeperCharger'}
                </title>
                <meta
                    name="description"
                    content={
                        meta?.metatags?.description ||
                        'Book accommodation with chargers nearby'
                    }
                />
            </Helmet>
            {pageStatus === PAGE_STATUS.loading ? <PageLoader /> : null}
            <div className="wrap wrap--md">
                <div className="booking__nav">
                    <button
                        className="booking__back link"
                        type="button"
                        onClick={goBack}
                    >
                        <Icon.Arrow className="icon-small icon-left" />
                        {t('Back to supplier')}
                    </button>
                </div>

                <h1 className="booking__title h2">{t('Reserve your stay')}</h1>
                <div className="booking__container">
                    <div className="booking__details">
                        {DISABLE_BOOKINGS ? (
                            pageStatus === PAGE_STATUS.loading ? (
                                messageLoader
                            ) : (
                                <>
                                    <h2 className="booking__subtitle h3">
                                        Thank You
                                    </h2>
                                    <p>
                                        SleeperCharger is almost live and ready
                                        to book through. Just a few little
                                        things to finish. Please subscribe to
                                        our mailing list below to stay informed
                                        about when we go live. See you soon!
                                    </p>
                                    <NewsletterSignup
                                        theme="light"
                                        contactData={
                                            headerFooterData &&
                                            headerFooterData.contactBlock
                                        }
                                    />
                                </>
                            )
                        ) : pageStatus === PAGE_STATUS.loading ? (
                            formLoader
                        ) : pageStatus === PAGE_STATUS.loaded ? (
                            <>
                                {REACT_APP_SHOW_USERS ? (
                                    <UserDetailsDev
                                        details={details}
                                        setDetails={setDetails}
                                        newDetails={newDetails}
                                        setNewDetails={setNewDetails}
                                        changeDetails={changeDetails}
                                        setChangeDetails={setChangeDetails}
                                        isMobile={props.isMobile}
                                        handleCheckboxChange={
                                            handleCheckboxChange
                                        }
                                        errors={errors}
                                    />
                                ) : (
                                    <UserDetails
                                        details={details}
                                        setDetails={setDetails}
                                        isMobile={props.isMobile}
                                        handleCheckboxChange={
                                            handleCheckboxChange
                                        }
                                    />
                                )}
                                {Object.keys(bookingInfo.chosenRate.bed_groups)
                                    .length < 2 || !selectedBedGroup ? null : (
                                    <>
                                        <h2 className="booking__subtitle h3">
                                            {t('Bed type request')}
                                        </h2>
                                        <div className="booking__element-container">
                                            <div className="booking__radio-container">
                                                {Object.values(
                                                    bookingInfo.chosenRate
                                                        .bed_groups
                                                ).map((e, i) => (
                                                    <label
                                                        className="booking__radio radio"
                                                        key={e.id}
                                                    >
                                                        <input
                                                            type="radio"
                                                            name={item.id}
                                                            value={
                                                                e.description
                                                            }
                                                            onChange={() =>
                                                                setSelectedBedGroup(
                                                                    {
                                                                        id: e.id,
                                                                        token: e.token,
                                                                    }
                                                                )
                                                            }
                                                            checked={
                                                                selectedBedGroup.id ===
                                                                e.id
                                                            }
                                                        />
                                                        <span className="radio__checkmark" />
                                                        <span className="radio__label">
                                                            {e.description}
                                                        </span>
                                                    </label>
                                                ))}
                                            </div>

                                            {Object.values(
                                                item?.room.bed_groups
                                            ).length > 1 ? (
                                                <div className="booking__bedtype-disclaimer">
                                                    {t('bed_type_disclaimer')}
                                                </div>
                                            ) : null}
                                        </div>
                                    </>
                                )}
                                <div
                                    className={`input-container${
                                        !errors.includes(
                                            'agree_to_terms_and_conditions'
                                        )
                                            ? ''
                                            : ' error'
                                    }`}
                                >
                                    <label className="checkbox">
                                        <input
                                            type="checkbox"
                                            name={
                                                'agree_to_terms_and_conditions'
                                            }
                                            checked={
                                                details.agree_to_terms_and_conditions
                                            }
                                            onChange={handleCheckboxChange}
                                        />
                                        <span className="checkbox__checkmark" />
                                        <span
                                            className="checkbox__label"
                                            dangerouslySetInnerHTML={{
                                                __html: t(
                                                    'I have seen and agree to the %{link_to_terms}',
                                                    {
                                                        link_to_terms: `<a href="/terms-and-conditions" target="_blank">${t(
                                                            'Terms and Conditions'
                                                        )}</a>`,
                                                    }
                                                ),
                                            }}
                                        />
                                    </label>
                                </div>
                                {!errorMessage ? null : (
                                    <div className="booking__error error-item">
                                        <Icon.Alert className="icon-small" />
                                        {errorMessage}
                                    </div>
                                )}
                                <PillButton
                                    className="booking__btn btn btn--large btn--blue"
                                    disabled={isBookingLoading}
                                    onClick={doBooking}
                                    isLoading={isBookingLoading}
                                >
                                    {t('Reserve this room')}
                                </PillButton>{' '}
                                {!errors.length ? null : (
                                    <div className="booking__error info info--red">
                                        <Icon.Alert className="icon-small icon-left" />
                                        {t('Please fill in all the required fields')}
                                    </div>
                                )}{' '}
                                {meta.test_mode ? (
                                    <span className="booking__testmode">
                                        {'Server is in test mode'}
                                    </span>
                                ) : (
                                    ''
                                )}
                                {isMobile ? null : <SearchUSP />}
                            </>
                        ) : pageStatus === PAGE_STATUS.error ? (
                            <div className="info">
                                {t(
                                    'This booking is not available, try searching again.'
                                )}
                            </div>
                        ) : null}
                    </div>

                    {pageStatus === PAGE_STATUS.error ? null : (
                        <div className="booking__price">
                            {pageStatus === PAGE_STATUS.loading ? null : (
                                <h2 className="booking__subtitle h3">
                                    {t('Booking details')}
                                </h2>
                            )}
                            <PropertyInfo
                                isMobile={props.isMobile}
                                item={item}
                                bookingInfo={bookingInfo}
                                pricing={bookingInfo?.rooms?.map(
                                    (room) => room.pricing
                                )}
                                meta={meta}
                                roomData={item?.room}
                                pageStatus={pageStatus}
                            />
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
}
