import { React, useEffect, useState } from 'react';

import { useLocaleContext } from '../../core/context/localeContext';

import { RangeArray } from '../../helpers.js';
import CustomDropdown from '../../common/Dropdown/CustomDropdown.js';
import GroupedButtons from '../../common/GroupedButtons/GroupedButtons.js';

import './RoomLayout.css';
import { useSearchContext } from '../../core/context/searchContext';

export default function RoomLayout() {
    const { state, setState } = useSearchContext();
    let [numAdults, setNumAdults] = useState(state.adults);
    let [childAges, setChildAges] = useState(state.child_age);
    let [numRooms, setNumRooms] = useState(state.rooms);

    const { t } = useLocaleContext();

    const handleChange = (i, value) => {
        setAge(i, value);
    };

    const setNumChildren = (i, val) => {
        let ages = [...childAges].slice(0, i);

        if (i > ages.length) {
            ages.push(-1);
        }
        setChildAges(ages);
    };

    const setAge = (i, val) => {
        let ages = [...childAges];
        ages[i] = val;
        setChildAges(ages);
    };

    useEffect(() => {
        setState((prevState) => ({
            ...prevState,
            adults: numAdults,
            child_age: childAges,
            rooms: numRooms,
        }));

        // setCookie(
        //     'guests',
        //     JSON.stringify({
        //         adults: numAdults,
        //         child_age: childAges,
        //         rooms: numRooms,
        //     }),
        //     90
        // );
    }, [childAges, numAdults, numRooms, setState]);

    const returnDropdownOption = (option) => {
        if (option === -1) {
            return t('Age');
        } else if (option === 0) {
            return t('Under 1');
        } else return option;
    };

    const options = RangeArray(-1, 17).map((e) => {
        return { label: returnDropdownOption(e), value: e };
    });

    let childrenDiv = childAges.map((e, i) => {
        return (
            <div className="room-layout__item room-layout__item--child" key={i}>
                <div className="room-layout__label">
                    <span className="form-label">{t('Child')} {i + 1}</span>
                    <span>{t('What age?')}</span>
                </div>

                <CustomDropdown
                    items={options}
                    selected={childAges[i]}
                    greyoutFirst
                    handleChange={(e, value) => {
                        handleChange(i, value);
                    }}
                />
            </div>
        );
    });

    return (
        <div className="room-layout">
            <div className="room-layout__item">
                <div className="room-layout__label">
                    <span className="form-label">{t('Adults')}</span>
                    <span>{t('18 or older')}</span>
                </div>

                <GroupedButtons
                    min={1}
                    num={numAdults}
                    setNum={setNumAdults}
                    label={t('Number of adults')}
                    singularLabel="an adult"
                    pluralLabel="adults"
                />
            </div>

            <div className="room-layout__item">
                <div className="room-layout__label">
                    <span className="form-label">{t('Children')}</span>
                    <span>{t('Age')} 0 - 17</span>
                </div>

                <GroupedButtons
                    min={0}
                    num={childAges.length}
                    setNum={setNumChildren}
                    label={t('Number of children')}
                    singularLabel="a child"
                    pluralLabel="children"
                />
            </div>

            {childAges.length ? childrenDiv : null}

            <div className="room-layout__item">
                <div className="room-layout__label">
                    <span className="form-label">{t('Rooms')}</span>
                    <span>{t('How many')}</span>
                </div>

                <GroupedButtons
                    min={1}
                    num={numRooms}
                    setNum={setNumRooms}
                    label={t('Number of rooms')}
                    singularLabel="a room"
                    pluralLabel="rooms"
                />
            </div>
        </div>
    );
}
