import { useEffect, useState, useCallback, useMemo } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { parseISO, format, isBefore, isAfter, isSameDay } from 'date-fns';
import { enGB, de, nl } from 'date-fns/locale';
import { useLocaleContext } from '../../core/context/localeContext';
import { useSearchContext } from '../../core/context/searchContext';
import ToggleButton from '../../common/ToggleButton/ToggleButton';
import ToggleButtonItem from '../../common/ToggleButton/ToggleButtonItem/ToggleButtonItem';
import useScreenSize from '../../core/hooks/useScreenSize';

import './DatePickerRange.css';

const DatePickerRange = ({ selecting, setSelecting }) => {
    const { locale, t } = useLocaleContext();
    const { state, setState } = useSearchContext();
    const { isMobile } = useScreenSize();
    const [numOfMonths, setNumOfMonths] = useState(4);

    const changeDate = useCallback(
        (e) => {
            if (selecting === 0) {
                if (
                    state.checkout &&
                    (isAfter(e, parseISO(state.checkout)) ||
                        isSameDay(e, parseISO(state.checkout)))
                ) {
                    setState((prevState) => ({
                        ...prevState,
                        checkin: format(e, 'yyyy-MM-dd'),
                        checkout: null,
                    }));
                } else {
                    setState((prevState) => ({
                        ...prevState,
                        checkin: format(e, 'yyyy-MM-dd'),
                    }));
                }
                setSelecting(1);
            } else {
                if (state.checkin && isBefore(e, parseISO(state.checkin))) {
                    setState((prevState) => ({
                        ...prevState,
                        checkin: format(e, 'yyyy-MM-dd'),
                        checkout: null,
                    }));
                } else if (
                    state.checkin &&
                    isSameDay(e, parseISO(state.checkin))
                ) {
                    return;
                } else {
                    setState((prevState) => ({
                        ...prevState,
                        checkout: format(e, 'yyyy-MM-dd'),
                    }));
                    setSelecting(0);
                }
            }
        },
        [selecting, setSelecting, setState, state.checkin, state.checkout]
    );

    let date_locale = enGB; //@mutates
    if (locale === 'de') date_locale = de;
    if (locale === 'nl') date_locale = nl;

    useEffect(() => {
        registerLocale(date_locale);
    }, [date_locale]);

    const toggleStartEnd = useCallback((val) => {
        setSelecting(val);
    }, [setSelecting]);

    const startDate = useMemo(
        () => (!state.checkin ? null : parseISO(state.checkin)),
        [state.checkin]
    );
    const endDate = useMemo(
        () => (!state.checkout ? null : parseISO(state.checkout)),
        [state.checkout]
    );

    return (
        <>
            {isMobile ? null : (
                <div className="datepicker__header">
                    <ToggleButton
                        onChange={toggleStartEnd}
                        preSelected={selecting}
                    >
                        <ToggleButtonItem value={0}>
                            {t('Check-in')}
                            {!state.checkin ? null : (
                                <span className="datepicker__date">
                                    {format(parseISO(state.checkin), 'dd LLL')}
                                </span>
                            )}
                        </ToggleButtonItem>
                        <ToggleButtonItem value={1}>
                            {t('Check-out')}
                            {!state.checkout ? null : (
                                <span className="datepicker__date">
                                    {format(parseISO(state.checkout), 'dd LLL')}
                                </span>
                            )}
                        </ToggleButtonItem>
                    </ToggleButton>
                </div>
            )}
            <div className='datepicker__container'>
                <DatePicker
                    onSelect={changeDate}
                    startDate={startDate}
                    endDate={endDate}
                    monthsShown={isMobile ? numOfMonths : 2}
                    inline
                    selectsEnd={selecting}
                    selectsRange
                    locale={date_locale}
                    minDate={new Date()}
                    formatWeekDay={(day) =>
                        format(day, 'EEEEEE', { locale: date_locale })
                    }
                />
                {!isMobile ? null : (
                    <button
                        type="button"
                        className="datepicker__more btn btn--outline-dark"
                        onClick={() =>
                            setNumOfMonths((prevState) => (prevState += 4))
                        }
                    >
                        {t('Load more')}
                    </button>
                )}
            </div>
        </>
    );
};

export default DatePickerRange;
