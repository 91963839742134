import React, { useCallback, useEffect, useRef, useState } from "react";

import './ToggleButton.css';

export default function ToggleButton ({label, onChange, children, preSelected}) {
  const btnsRef = useRef([]);
  const [selected, setSelected] = useState(preSelected || 0);
  const [indicator, setIndicator] = useState({
    width: btnsRef.current[selected]?.offsetWidth,
    left: btnsRef.current[selected]?.offsetLeft
  });

  useEffect(() => {
    setSelected(preSelected || 0)
  }, [preSelected]);

  const handleClick = useCallback((i, value) => {
    setSelected(i);
    onChange(value);
  }, [onChange]);

  useEffect(() => {
    setIndicator({
      width: btnsRef.current[selected]?.offsetWidth,
      left: btnsRef.current[selected]?.offsetLeft
    })
  }, [btnsRef, selected, children]);

  return (
    <div className="toggle-button" role="group" aria-label={label} style={{
      "--width": `${indicator.width}px`,
      "--left": `${indicator.left}px`
    }}>
      {React.Children.map(children, (child, i) => (
        <child.type
          {...child.props}
          key={i}
          ref={(ref) => {
            btnsRef.current[i] = ref;
          }}
          handleClick={(value, width) => handleClick(i, value, width)}
          pressed={selected === i}
        />
      ))}
    </div>
  )
}