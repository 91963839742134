import React, { useRef, useEffect } from "react";

/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideAlerter ({ ref, onClick, detectTab }) {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside (event) {
            if (ref.current && !ref.current.contains(event.target)) {
                
                onClick();
            }
        }
        function handleTabOutside (event) {
            if (event.keyCode === 9 || (event.shiftKey && event.keyCode === 9)) {
                if (!ref.current.contains(event.target)) {
                    onClick();
                }
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        if (detectTab) {
            document.addEventListener("keyup", handleTabOutside);
        }
        return () => {
            
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
            if (detectTab) {
                document.removeEventListener("keyup", handleTabOutside);
            }
        };
    }, [ref, onClick, detectTab]);
}

/**
 * Component that alerts if you click outside of it
 */
export default function OutsideAlerter (props) {
    const wrapperRef = useRef(null);
    useOutsideAlerter({ ref: wrapperRef, onClick: props.onClick, detectTab: props.detectTab });

    return <div ref={wrapperRef}>{props.children}</div>;
}