import { parseISO, format as formatDate } from 'date-fns';

import AdminChargerInfoCard from '../AdminMap/AdminChargerInfoCard.js';
import { Icon } from '../../common/Icon/Icon.js';

import { CompareArrays, Pluralize, IsValidEmail } from '../../helpers.js';

import './AdminMap.css';
import CustomInput from '../../common/CustomInput/CustomInput.js';

import { PROPERTY_FILTER } from './AdminMap';

export default function AdminSupplierInfo ({
    selectedItem,
    handleChargerCardClick,
    selectedChargers,
    confirmChargers,
    clearConfirmation,
    note,
    setNote,
    email,
    setEmail,
    updateSupplier,
    goPrev,
    goNext,
    groupedItems,
    propertyFilter,
    showNote,
    setShowNote,
    hotItemId,
    getPlaceDetails,
}) {
    let chargerList;
    let updated;
    let selected = [];
    const showAdditionalInfo = false;

    if (
        selectedItem.meta &&
        selectedItem.meta.ids &&
        selectedItem.meta.updated
    ) {
        updated = {
            at: formatDate(
                parseISO(selectedItem.meta.updated.at),
                'E d MMMM yyyy HH:mm:ss'
            ),
            by: selectedItem.meta.updated.by,
        };
        

        chargerList = selectedItem.meta.ids?.map((cid) => {
            let inSelected = selectedChargers.find(
                (selectedCharger) => selectedCharger._id === cid
            );

            return (
                <li key={cid}>
                    {cid} {!inSelected ? ' (click confirm to remove)' : null}
                </li>
            );
        });
    }

    if (selectedChargers.length) {
        selected = selectedChargers
            .map((selected) => {
                if (selectedItem.meta) {
                    let inConfirmed = selectedItem.meta.ids?.find(
                        (cid) => selected._id === cid
                    );

                    if (!inConfirmed) {
                        return (
                            <li key={selected._id}>
                                {selected._id} (click confirm to add)
                            </li>
                        );
                    }
                    return null;
                } else {
                    return (
                        <li key={selected._id}>
                            {selected._id} (click confirm to add)
                        </li>
                    );
                }
            })
            .filter((e) => e !== undefined);
    }

    const CHARGER_AMENITY_ID = '1073743315';
    let hasChargerAmenity = Object.keys(selectedItem.amenities).includes(
        CHARGER_AMENITY_ID
    );

    const hasConfirmed = selectedItem.meta && selectedItem.meta.ids;
    // const hasConfirmedChargers = hasConfirmed ? hasConfirmed.ids.length : 0;
    // const hasSelected = selectedChargers.length;

    let confirmChargerBtn =
        <button
            className="btn btn--green"
            type="button"
            onClick={updateSupplier}
        >{`Save and next`}</button>

    const onChangeNote = (event) => {
        setNote(event.target.value);
    };

    const onChangeEmail = (event) => {
        setEmail(event.target.value);
    };

    return (
        <>
            <div className="admin-map__info-container">
                <div className="admin-map__card-header">
                    {groupedItems[ propertyFilter ]?.indexOf(selectedItem) + 1}/
                    {groupedItems[ propertyFilter ]?.length}
                    {groupedItems[ PROPERTY_FILTER.unconfirmed ]?.indexOf(
                        selectedItem
                    ) >= 0 ? (
                        <span className="admin-map__card-label admin-map__card-label--red">
                            To do
                        </span>
                    ) : (
                        <span className="admin-map__card-label admin-map__card-label--green">
                            Confirmed
                            {selectedItem.meta?.updated?.by?.name
                                ? ' by ' +
                                selectedItem.meta?.updated?.by
                                    ?.name
                                : ''}
                        </span>
                    )}
                </div>
                <h2 className="h3">{selectedItem.name}</h2>
                {showAdditionalInfo ? <div>_id: {selectedItem._id}</div> : null}
                <div>
                    {selectedItem.address.line_1}
                    <br />
                    {selectedItem.address.postal_code}{' '}
                    {selectedItem.address.city}
                    <br />
                    
                    {showAdditionalInfo ? (
                        selectedItem.email ? (
                            <a
                                href={`mailto:${selectedItem.email}`}
                                className="address__link"
                            >
                                {selectedItem.email}
                            </a>
                        ) : (
                            'E-mail unknown'
                        )
                    ) : null}

                    <a href={`tel:${selectedItem.phone}`}>
                        {selectedItem.phone}
                    </a>
                    <br />
                    <a href={selectedItem.meta?.url} target="_blank" rel="noreferrer" style={{wordBreak: 'break-all'}}>{selectedItem.meta?.url}</a>
                    
                </div>
                {showAdditionalInfo ? (
                    <div>
                        Rating:{' '}
                        {selectedItem.ratings?.guest?.overall
                            ? selectedItem.ratings.guest.overall * 2
                            : 'N/A'}
                    </div>
                ) : null}

                <div className="admin-map__info-email">
                    <CustomInput
                        type="email"
                        id="email"
                        name="email"
                        placeholder="Email address"
                        value={email}
                        onChange={onChangeEmail}
                    />


                </div>
                <button
                    className="btn btn--grey"
                    type="button"
                    onClick={() => {
                        getPlaceDetails(selectedItem._id)
                    }}
                >
                    Get Details
                </button>

                {showAdditionalInfo ? (
                    <>
                        <div>
                            <span className="form-label">
                                Has Expedia charger amenity:
                            </span>{' '}
                            {hasChargerAmenity ? 'yes' : 'no'}
                        </div>

                        {selectedItem.meta ? (
                            <>
                                <div>
                                    <span className="form-label">
                                        Confirmed by:
                                    </span>
                                    <br />
                                    {updated?.by.name || 'Deleted user'}
                                </div>
                                <div>
                                    <span className="form-label">
                                        Confirmed chargers at property:
                                    </span>
                                    <br />
                                    <ul>{chargerList}</ul>
                                </div>
                            </>
                        ) : (
                            <div>Nothing confirmed yet!</div>
                        )}
                    </>
                ) : null}

                <span className="form-label">Chargers at the property</span>
                {updated ? (
                    <div>Confirmed at: {updated.at}</div>
                ) : null}
                {selectedItem.chargers && selectedItem.chargers.length
                    ? selectedItem.chargers.map((charger) => {
                        let selectedIndex = selectedChargers.findIndex(
                            (el) => el._id === charger._id
                        );
                        return (
                            <AdminChargerInfoCard
                                key={charger._id}
                                selected={selectedIndex >= 0}
                                item={charger}
                                onClick={() => {
                                    handleChargerCardClick(charger);
                                }}
                                isHot={hotItemId === charger._id}
                            />
                        );
                    })
                    : null}
                <div className="admin-map__info-note-container">
                    {showNote ? (
                        <>
                            <label
                                className="admin-map__info-label form-label"
                                htmlFor="note"
                            >
                                Note:
                            </label>
                            <textarea
                                className="admin-map__info-note"
                                rows="6"
                                value={note}
                                placeholder="Enter additional note"
                                onChange={onChangeNote}
                                id="note"
                            />
                        </>
                    ) : (
                        <button
                            onClick={() => setShowNote(true)}
                            className="btn btn--outline-dark"
                            type="button"
                        >
                            Add note{' '}
                            <Icon.Plus className="icon-small icon-right" />
                        </button>
                    )}
                </div>

                {showAdditionalInfo ? (
                    selected.length ? (
                        <div>
                            Add to confirmed chargers:
                            <br />
                            {selected}
                        </div>
                    ) : null
                ) : null}
            </div>

            <div className="admin-map__card-footer">
                {confirmChargerBtn}

                {hasConfirmed ? (
                    <button
                        className="btn btn--red"
                        onClick={() => { updateSupplier({ unset: true }) }}
                    >
                        Unconfirm
                    </button>
                ) : null}

                <div>
                    <button
                        className="btn btn--white btn--small"
                        type="button"
                        onClick={goPrev}
                    >
                        <span className="sr-only">Previous property</span>
                        <Icon.Arrow className="icon-small flip-x" />
                    </button>
                    <button
                        className="btn btn--white"
                        type="button"
                        onClick={goNext}
                    >
                        Next property
                        <Icon.Arrow className="icon-small icon-right" />
                    </button>
                </div>
            </div>
        </>
    );
}
