import { Icon } from '../../common/Icon/Icon';
import useScreenSize from '../../core/hooks/useScreenSize';
import { useLocaleContext } from '../../core/context/localeContext';
import DashedBorder from './DashedBorder';

export default function ChargerConnectorInfo ({ connector, chargerInfo }) {

    const { isMobile } = useScreenSize();
    const { t } = useLocaleContext();

    const stringsToRemove = ['IEC 60309-2 ', 'IEC 62196 '];
    let stripedLabel = connector.label;
    stringsToRemove.forEach(string => stripedLabel = stripedLabel.replaceAll(string, ''));

    let icon = 'Chademo';
    switch (connector.standard) {
        case 'DOMESTIC_A':
            icon = 'DomesticA'
            break;
        case 'DOMESTIC_B':
            icon = 'DomesticB'
            break;
        case 'DOMESTIC_C':
            icon = 'DomesticC'
            break;
        case 'DOMESTIC_D':
            icon = 'DomesticD'
            break;
        case 'DOMESTIC_E':
            icon = 'DomesticE'
            break;
        case 'DOMESTIC_F':
            icon = 'DomesticF'
            break;
        case 'DOMESTIC_G':
            icon = 'DomesticG'
            break;
        case 'DOMESTIC_H':
            icon = 'DomesticH'
            break;
        case 'DOMESTIC_I':
            icon = 'DomesticI'
            break;
        case 'DOMESTIC_J':
            icon = 'DomesticJ'
            break;
        case 'DOMESTIC_K':
            icon = 'DomesticK'
            break;
        case 'DOMESTIC_L':
            icon = 'DomesticL'
            break;
        case 'DOMESTIC_X':
            icon = 'DomesticX'
            break;
        case 'DOMESTIC_Y':
            icon = 'DomesticY'
            break;
        case 'DOMESTIC_Z':
            icon = 'DomesticZ'
            break;
        case 'IEC_60309_2_single_16':
            icon = 'IEC_60309_2_single_16'
            break;
        case 'IEC_60309_2_three_16':
            icon = 'IEC_60309_2_three_16'
            break;
        case 'IEC_60309_2_three_32':
            icon = 'IEC_60309_2_three_32'
            break;
        case 'IEC_60309_2_three_64':
            icon = 'IEC_60309_2_three_64'
            break;
        case 'IEC_62196_T1':
            icon = 'IEC_62196_T1'
            break;
        case 'IEC_62196_T1_COMBO':
            icon = 'IEC_62196_T1_COMBO'
            break;
        case 'IEC_62196_T2':
            icon = 'IEC_62196_T2'
            break;
        case 'IEC_62196_T2_COMBO':
            icon = 'IEC_62196_T2_COMBO'
            break;
        case 'IEC_62196_T3A':
            icon = 'IEC_62196_T3A'
            break;
        case 'IEC_62196_T3C':
            icon = 'IEC_62196_T3C'
            break;
        case 'TESLA_R':
            icon = 'TESLA_R'
            break;
        case 'TESLA_S':
            icon = 'TESLA_S'
            break;
        default:
            break;
    }

    const IconElement = Icon[icon];

    const connectors = chargerInfo?.evses?.map(item => item.connectors).flat().filter(item => item.standard === connector.standard).filter(item => item.max_power === connector.max_power);

    const availableConnectors = connectors.filter(connector => connector.status === 'AVAILABLE');
    const unknown = ['UNKNOWN', 'OUTOFORDER', 'INOPERATIVE', 'REMOVED'];
    const unknownConnectors = connectors.filter(connector => unknown.some(item => connector.status === item));
    const unavailable = ['BLOCKED', 'CHARGING', 'PLANNED', 'RESERVED'];
    const unavailableConnectors = connectors.filter(connector => unavailable.some(item => connector.status === item));

    return (
        <div className="charger-connector">
            <div className="charger-connector__icon-container">
                <DashedBorder available={availableConnectors.length} unavailable={unavailableConnectors.length} unknown={unknownConnectors.length}/>
                <IconElement className={`charger-connector__icon${unknownConnectors.length === connectors.length ? ' unknown' : ''}`}/>
            </div>
            <div className="charger-connector__info-group">
                <div className="charger-connector__info">{stripedLabel}</div>
                <div className="charger-connector__info">{unknownConnectors.length === connectors.length ? t('No data') : `${availableConnectors.length}/${connectors.length}`} {t('available')}</div>
                {!isMobile
                    ? (
                        <>
                            <div className="charger-connector__info">{(Number(connector.max_power) / 1000).toFixed(1)} kW</div>
                            <div className="charger-connector__info">{connector.amperage} A</div>
                        </>
                    )
                    : <div className="charger-connector__info">{(Number(connector.max_power) / 1000).toFixed(1)} kW, <span>{connector.amperage} A</span></div>
                }
            </div>

        </div>
    );
};


