import { debounce } from "../../helpers";
import { useEffect, useState } from "react";
import { BREAKPOINT } from "./constants";

const getWindowSize = () => {
  return {
    width: window.innerWidth,
    height: window.innerHeight,
    isMobile: window.innerWidth < BREAKPOINT,
  }
}

export default function useScreenSize() {
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    const handleResize = debounce(() => {
      setWindowSize(getWindowSize());
    }, 200);

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
}