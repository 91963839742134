import { useMemo } from 'react';
import { useLocaleContext } from '../../../core/context/localeContext';
import Loader from '../../../common/Loader/Loader';

const UserDetailsField = ({
    children,
    label,
    value,
    resetFields,
    setActiveField,
    activeField,
    add,
    noChanges,
    noEdit,
    loading,
}) => {
    const isActive = useMemo(
        () => activeField && activeField === label,
        [activeField, label]
    );
    const { t } = useLocaleContext();

    return (
        <div
            className={`user-field${
                activeField && activeField !== label ? ' disabled' : ''
            }`}
        >
            <div className="user-field__header">
                <div className="h4">{label}</div>
                <button
                    className="user-field__edit link"
                    type="button"
                    onClick={() => {
                        if (isActive) {
                            resetFields();
                            setActiveField('');
                        } else {
                            setActiveField(label);
                        }
                    }}
                    disabled={activeField && activeField !== label}
                >
                    {isActive ? t('Cancel') : add ? t('Add') : noEdit ? '' : t('Edit')}
                </button>
            </div>
            {!isActive ? (
                <div className="user-field__value">{value}</div>
            ) : (
                <>
                    {children}
                    <button
                        className="btn btn--blue"
                        type="submit"
                        disabled={noChanges || loading}
                    >
                        {t('Save')}
                        {!loading ? null : <Loader className="icon-right" size={16} bgColor='#06DBFF'/>}
                    </button>
                </>
            )}
        </div>
    );
};

export default UserDetailsField;
