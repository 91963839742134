import Loader from '../Loader/Loader';

import './PillButton.css';

export default function PillButton({ onClick, children, isLoading, ...props }) {
    return (
        <button {...props} onClick={onClick} type="button">
            {children}
            {isLoading ? <Loader size="16" className="pill-loader" /> : null}
        </button>
    );
}
