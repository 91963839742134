import { useEffect, useCallback, useRef, useState, useMemo } from 'react';
import useScreenSize from '../../core/hooks/useScreenSize';

import './HomeParallaxContainer.css';
import layer1Day from '../../images/parallax/layer1-day.svg';
import layer2Day from '../../images/parallax/layer2-day.svg';
import layer3Day from '../../images/parallax/layer3-day.svg';
import layer4Day from '../../images/parallax/layer4-day.svg';
import layer5Day from '../../images/parallax/layer5-day.svg';
import layer6Day from '../../images/parallax/layer6-day.svg';
import layer7Day from '../../images/parallax/layer7-day.svg';
import layer1Night from '../../images/parallax/layer1-night.svg';
import layer2Night from '../../images/parallax/layer2-night.svg';
import layer3Night from '../../images/parallax/layer3-night.svg';
import layer4Night from '../../images/parallax/layer4-night.svg';
import layer5Night from '../../images/parallax/layer5-night.svg';
import layer6Night from '../../images/parallax/layer6-night.svg';
import layer7Night from '../../images/parallax/layer7-night.svg';
import layer1Winter from '../../images/parallax/winter/layer1-winter.svg';
import layer2Winter from '../../images/parallax/winter/layer2-winter.svg';
import layer3Winter from '../../images/parallax/winter/layer3-winter.svg';
import layer4Winter from '../../images/parallax/winter/layer4-winter.svg';
import layer5Winter from '../../images/parallax/winter/layer5-winter.svg';
import layer6Winter from '../../images/parallax/winter/layer6-winter.svg';
import layer7Winter from '../../images/parallax/winter/layer7-winter.svg';
import { useDaytimeContext } from '../../core/context/daytimeContext';
import { useHeaderFooterDataContext } from '../../core/context/headerFooterDataContext';

const getRandom = (min, max) => {
    return Math.random() * (max - min) + min;
};

const HomeParallaxContainer = (props) => {
    const { width, isMobile } = useScreenSize();
    const parallaxRef = useRef(null);
    const bgRef = useRef(null);
    const [yPosition, setYposition] = useState(0);
    const { isDark } = useDaytimeContext();
    const { season } = useHeaderFooterDataContext();
    const snowflakeRef = useRef([]);

    const layers = useMemo(
        () => ({
            layer1: isDark
                ? layer1Night
                : season === 'Winter Snow'
                ? layer1Winter
                : layer1Day,
            layer2: isDark
                ? layer2Night
                : season === 'Winter Snow'
                ? layer2Winter
                : layer2Day,
            layer3: isDark
                ? layer3Night
                : season === 'Winter Snow'
                ? layer3Winter
                : layer3Day,
            layer4: isDark
                ? layer4Night
                : season === 'Winter Snow'
                ? layer4Winter
                : layer4Day,
            layer5: isDark
                ? layer5Night
                : season === 'Winter Snow'
                ? layer5Winter
                : layer5Day,
            layer6: isDark
                ? layer6Night
                : season === 'Winter Snow'
                ? layer6Winter
                : layer6Day,
            layer7: isDark
                ? layer7Night
                : season === 'Winter Snow'
                ? layer7Winter
                : layer7Day,
        }),
        [isDark, season]
    );

    const handleScroll = useCallback(
        (e) => {
            if (!bgRef.current || !parallaxRef.current) return;
            const bgYPosition = bgRef.current.getBoundingClientRect().top;
            const height = parallaxRef.current.offsetHeight;
            const offset = props.isOnTopOfPage ? 0 : height * 0.5;

            if (bgYPosition <= offset && bgYPosition > height * -1) {
                setYposition((bgYPosition - offset) * height * 0.0015);
            } else if (bgYPosition < offset) {
                setYposition(height);
            } else {
                setYposition(0);
            }
        },
        [props.isOnTopOfPage]
    );

    useEffect(() => {
        if (!parallaxRef.current) return;

        document.addEventListener('scroll', handleScroll);

        return () => {
            document.removeEventListener('scroll', handleScroll);
        };
    }, [parallaxRef, width, handleScroll]);

    const renderSnow = useMemo(() => {
        const snowflakeNumb = isMobile ? 20 : 52;
        snowflakeRef.current = [];
        const snow = [];

        for (let i = 0; i < snowflakeNumb; i++) {
            const size = getRandom(7, 11).toFixed(1);
            const time = getRandom(15, 20);
            const right = getRandom(-100, 100).toFixed(2);
            const left = getRandom(-100, 100).toFixed(2);
            snow.push(
                <div
                    className="snowflake"
                    ref={(e) => {
                        snowflakeRef.current.push(e);
                    }}
                    key={i}
                    style={{
                        width: `${size}px`,
                        height: `${size}px`,
                        left: `${getRandom(0, 100).toFixed(0)}%`,
                        '--left': left,
                        '--from': `translate3d(${right}px, -20px, 0)`,
                        animationIterationCount: 'infinite',
                        animationTimingFunction: 'linear',
                        animationDelay: `-${getRandom(0, time).toFixed(2)}s`,
                        animationDuration: `${time.toFixed(2)}s`,
                    }}
                />
            );
        }
        return snow;
    }, [isMobile]);

    useEffect(() => {
        if (!parallaxRef.current) return;
        const height = parallaxRef.current.offsetHeight;

        snowflakeRef.current.forEach((flake) => {
            if (!flake) return;
            const leftPosition =
                getComputedStyle(flake).getPropertyValue('--left');
            flake.style.setProperty(
                '--to',
                `translate3d(${leftPosition}px, ${height}px, 0)`
            );
            flake.style.setProperty('animation-name', 'snow');
        });
    }, [season, props.children.props.data, isMobile]);

    return (
        <div ref={parallaxRef} className="home-parallax">
            <div className="home-parallax__body">
                <div
                    className="home-parallax__children"
                    style={{
                        transform: isMobile
                            ? ''
                            : `translateY(${yPosition * -0.2}px)`,
                    }}
                >
                    {props.children}
                </div>
            </div>
            <div ref={bgRef} className="home-parallax__background-container">
                {!season ? null : (
                    <>
                        <div
                            className="home-parallax__background-layer background"
                            style={{
                                backgroundImage: `url(${layers.layer7})`,
                                transform: isMobile ? 'translateX(-2px)' : '',
                            }}
                        ></div>
                        <div
                            className="home-parallax__background-layer"
                            style={{
                                backgroundImage: `url(${layers.layer6})`,
                                transform: `translateY(${yPosition * -0.6}px)${
                                    isMobile ? ' translateX(-2px)' : ''
                                }`,
                            }}
                        ></div>
                        <div
                            className="home-parallax__background-layer"
                            style={{
                                backgroundImage: `url(${layers.layer5})`,
                                transform: `translateY(${yPosition * -0.5}px)${
                                    isMobile ? ' translateX(-2px)' : ''
                                }`,
                            }}
                        ></div>
                        <div
                            className="home-parallax__background-layer"
                            style={{
                                backgroundImage: `url(${layers.layer4})`,
                                transform: `translateY(${yPosition * -0.4}px)${
                                    isMobile ? ' translateX(-2px)' : ''
                                }`,
                            }}
                        ></div>
                        <div
                            className="home-parallax__background-layer"
                            style={{
                                backgroundImage: `url(${layers.layer3})`,
                                transform: `translateY(${yPosition * -0.2}px)${
                                    isMobile ? ' translateX(-2px)' : ''
                                }`,
                            }}
                        ></div>
                        <div
                            className="home-parallax__background-layer"
                            style={{
                                backgroundImage: `url(${layers.layer2})`,
                                transform: `translateY(${yPosition * -0.1}px)${
                                    isMobile ? ' translateX(-2px)' : ''
                                }`,
                            }}
                        ></div>
                        {season !==
                          'Winter Snow' ? null : (
                            <div className="home-parallax__background-layer-snow">
                                {renderSnow}
                            </div>
                        )}
                        <div
                            className="home-parallax__background-layer"
                            style={{
                                backgroundImage: `url(${layers.layer1})`,
                                transform: `translateY(2px)${
                                    isMobile ? ' translateX(-2px)' : ''
                                }`,
                            }}
                        ></div>
                    </>
                )}
            </div>
        </div>
    );
};

export default HomeParallaxContainer;
