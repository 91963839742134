import React from 'react';
import { Link } from 'react-router-dom';
import { Icon } from '../../../common/Icon/Icon';
import { useLocaleContext } from '../../../core/context/localeContext';
import './UserMenu.css';

const UserMenu = () => {
    const { urlPrefix, t } = useLocaleContext();

    return (
        <ul className="user-menu">
            <li className="user-menu__item">
                <Link to={`${urlPrefix}/user`} className="user-menu__link">
                    <span className="user-menu__icon-container">
                        <Icon.User className="user-menu__icon" />
                    </span>
                    <span className="h4">{t('Personal information')}</span>
                    <span>{t('Change personal information')}</span>
                </Link>
            </li>
            <li className="user-menu__item">
                <Link to={`${urlPrefix}/bookings`} className="user-menu__link">
                    <span className="user-menu__icon-container">
                        <Icon.Bed className="user-menu__icon" />
                    </span>
                    <span className="h4">{t('Bookings')}</span>
                    <span>{t('Check your bookings')}</span>
                </Link>
            </li>
            {/* <li className="user-menu__item">
                <Link to={`${urlPrefix}/groups`} className="user-menu__link">
                    <span className="user-menu__icon-container">
                        <Icon.Family className="user-menu__icon" />
                    </span>
                    <span className="h4">Travel Groups and Travellers</span>
                    <span>Edit and add Travel Groups</span>
                </Link>
            </li> */}
            {/* <li className="user-menu__item">
                <Link to={`${urlPrefix}/preferences`} className="user-menu__link">
                    <span className="user-menu__icon-container">
                        <Icon.Car className="user-menu__icon" />
                    </span>
                    <span className="h4">Driving preferences</span>
                    <span>Edit and add driving preferences</span>
                </Link>
            </li> */}
            <li className="user-menu__item">
                <Link to={`${urlPrefix}/change-password`} className="user-menu__link">
                    <span className="user-menu__icon-container">
                        <Icon.Lock className="user-menu__icon" />
                    </span>
                    <span className="h4">{t('Security')}</span>
                    <span>{t('Change your password')}</span>
                </Link>
            </li>
        </ul>
    );
};

export default UserMenu;
