import React, { useCallback, useEffect, useRef, useState } from 'react';

import './ModalHOC.css';

export const MODAL_TRANSITION = 250;

const ModalHOC = ({
    children,
    isOpen,
    callback,
    clear,
    slideIn,
    openBtnRef,
    closeModal,
}) => {
    const [show, setShow] = useState(false);
    const [open, setOpen] = useState(false);
    const [visible, setVisible] = useState(false);
    const modalRef = useRef(null);
    const focusableElementsRef = useRef(null);

    useEffect(() => {
        const scrollbarWidth =
            window.innerWidth - document.documentElement.clientWidth;

        if (isOpen) {
            setShow(true);
            setOpen(true);
            if (callback) callback();
            if (!clear) return;
            document.body.style.overflow = 'hidden';
            document.body.style.inset = `${
                document.body.getBoundingClientRect().top
            }px 0 0 0`;
            document.body.style.insetInlineEnd = `${scrollbarWidth}px`;
            document.body.style.position = 'fixed';
            document.querySelector(
                '.nav'
            ).style.width = `calc(100% - ${scrollbarWidth}px)`;
        } else {
            setShow(false);
        }
    }, [isOpen]);

    const close = useCallback(() => {
        if (isOpen) {
            setVisible(true);
            return;
        }
        const y = document.body.getBoundingClientRect().top;
        setOpen(false);
        setVisible(false);
        setTimeout(() => {
            openBtnRef?.current?.focus({
                preventScroll: true,
            });
        }, 0)
        if (!clear) return;
        document.body.style.inset = '';
        document.body.style.overflow = '';
        document.body.style.insetInlineEnd = '';
        document.body.style.position = '';
        document.querySelector('.nav').style.width = '';
        window.scrollTo(0, -y);
    }, [isOpen, openBtnRef, clear]);

    useEffect(() => {
        focusableElementsRef.current =
            visible && modalRef.current
                ? [
                      ...modalRef.current.querySelectorAll(
                          'a[href], button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])'
                      ),
                  ].filter(
                      (el) =>
                          !el.hasAttribute('disabled') &&
                          !el.getAttribute('aria-hidden')
                  )
                : [];
    }, [visible]);

    const handleKeyDown = useCallback((e) => {
        focusableElementsRef.current = modalRef.current
            ? [
                  ...modalRef.current.querySelectorAll(
                      'a[href], button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])'
                  ),
              ].filter(
                  (el) =>
                      !el.hasAttribute('disabled') &&
                      !el.getAttribute('aria-hidden')
              )
            : [];

        if (!e.shiftKey && e.keyCode === 9) {
            if (
                e.target ===
                    focusableElementsRef.current[
                        focusableElementsRef.current.length - 2
                    ] ||
                // Check if it is a dropdown item
                e.target.parentNode.previousSibling ===
                    focusableElementsRef.current[
                        focusableElementsRef.current.length - 2
                    ]
            ) {
                setTimeout(() => {
                    focusableElementsRef.current[1].focus();
                }, 0);
            }
        }

        if (e.shiftKey && e.keyCode === 9) {
            if (e.target === focusableElementsRef.current[1]) {
                focusableElementsRef.current[
                    focusableElementsRef.current.length - 1
                ].focus();
            }
        }

        if (e.keyCode === 27) closeModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (focusableElementsRef.current.length) {
            focusableElementsRef.current[1].focus();
        }

        if (open) {
            modalRef.current?.addEventListener('keydown', handleKeyDown);
        } else {
            modalRef.current?.removeEventListener('keydown', handleKeyDown);
        }
        return () => {
            modalRef.current?.removeEventListener('keydown', handleKeyDown);
        }
    }, [open, handleKeyDown, visible]);

    if (!open) return null;

    return (
        <div
            ref={modalRef}
            className={`modal-container${slideIn ? ' slide-in' : ''}`}
            onTransitionEnd={close}
            onAnimationEnd={close}
            style={{
                opacity: show ? 1 : 0,
                transform: slideIn
                    ? show
                        ? 'translateY(0)'
                        : 'translateY(100%)'
                    : '',
                animation: `${
                    slideIn ? 'slide-from-bottom' : 'fade-in'
                } ${MODAL_TRANSITION}ms`,
            }}
            tabIndex={-1}
        >
            <span tabIndex={0} />
            {React.Children.map(children, (child, i) => (
                <child.type {...child.props} key={i} open={open} />
            ))}
            <span tabIndex={0} />
        </div>
    );
};

export default ModalHOC;
