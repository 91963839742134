import React from 'react'

export default function SuggestionsContainer({
  toggleModal,
  suggestionsContainerClass,
  suggestionsContentClass,
  suggestionsContainerName,
  children,
}) {
  return (
    <div className={`suggestions-container ${suggestionsContainerClass} `}>
      <div
        className={`suggestions-content-container ${suggestionsContentClass}`}
      >
        {children}
      </div>
    </div>
  )
}
