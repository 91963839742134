import React, { useCallback, useRef } from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFade, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/pagination';
import './TipsCarousel.css';
import TextBlock from '../TextBlock';
import { Icon } from '../../common/Icon/Icon';
import { useLocaleContext } from '../../core/context/localeContext.js';
import { useContext } from 'react';
import { ThemeContext } from '../../providers/ThemeProvider';

const UPLOADS_URL = process.env.REACT_APP_UPLOADS_URL || '';


const TipsCarousel = ({ data, isMobile }) => {
    const swiperRef = useRef(null);
    const { t } = useLocaleContext();
    const { isPrinting } = useContext(ThemeContext);


    const handlePrev = useCallback(() => {
        if (!swiperRef.current) return;
        swiperRef.current.swiper.slidePrev();
    }, [swiperRef]);

    const handleNext = useCallback(() => {
        if (!swiperRef.current) return;
        swiperRef.current.swiper.slideNext();
    }, [swiperRef]);

    return (
        <section className="tips-carousel section">
            {data.tips.length > 1 ? (
                <>
                    <Swiper
                        ref={swiperRef}
                        className="tips-carousel__slider"
                        effect={'fade'}
                        modules={[EffectFade, Pagination]}
                        fadeEffect={{ crossFade: true }}
                        loop={!isPrinting}
                        pagination={{
                            type: 'fraction',
                        }}
                    >
                        {data.tips.map((tip, i) => (
                            <SwiperSlide
                                key={i}
                                className="tips-carousel__slide"
                            >
                                <div
                                    className={`tips-carousel__card ${tip.alignment}`}
                                >
                                    {!tip.image.data ? null : (
                                        <div className="tips-carousel__img-container">
                                            <img
                                                className="cover-img"
                                                src={`${UPLOADS_URL}${tip.image.data.attributes.url}`}
                                                alt={
                                                    tip.image.data.attributes
                                                        .alternativeText
                                                }
                                            />
                                        </div>
                                    )}
                                    {!tip.text ? null : (
                                        <TextBlock
                                            className="tips-carousel__content"
                                            content={tip.text}
                                            fullWidth={true}
                                        />
                                    )}
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                    <div className="tips-carousel__controls">
                        <button
                            className="tips-carousel__arrow tips-carousel__arrow--prev btn btn--outline-dark"
                            onClick={handlePrev}
                        >
                            <Icon.Arrow className="icon-small icon-left" />
                            <span className={isMobile ? 'sr-only' : ''}>
                                {t("Previous tip")}
                            </span>
                        </button>
                        <button
                            className="tips-carousel__arrow tips-carousel__arrow--next btn btn--outline-dark"
                            onClick={handleNext}
                        >
                            <span className={isMobile ? 'sr-only' : ''}>
                                {t("Next tip")}
                            </span>
                            <Icon.Arrow className="icon-small icon-right" />
                        </button>
                    </div>
                </>
            ) : (
                <div
                    className={`tips-carousel__card ${data.tips[0].alignment} single`}
                >
                    {!data.tips[0].image.data ? null : (
                        <div className="tips-carousel__img-container">
                            <img
                                className="cover-img"
                                src={`${UPLOADS_URL}${data.tips[0].image.data.attributes.url}`}
                                alt={
                                    data.tips[0].image.data.attributes
                                        .alternativeText
                                }
                            />
                        </div>
                    )}
                    {!data.tips[0].text ? null : (
                        <TextBlock
                            content={data.tips[0].text}
                            fullWidth={true}
                        />
                    )}
                </div>
            )}
        </section>
    );
};

export default TipsCarousel;
