import React, { useState, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Icon } from '../../common/Icon/Icon';
import Overview from '../../Components/Profile/Overview/Overview';
import Welcome from '../../Components/Profile/Welcome/Welcome';
import { useLocaleContext } from '../../core/context/localeContext';
import { useUserContext } from '../../core/context/userContext';
import './Profile.css';

import LoginForm from '../../Components/Registration/Login/LoginForm';

const BASE_URL = process.env.REACT_APP_BASE_URL || 'https://www.sleepercharger.com';

const Profile = () => {
    const navigate = useNavigate();
    const { t, locale } = useLocaleContext();
    const { userDetails, setUserDetails, loading, setLocalSessionToken, setSessionStorageToken } = useUserContext();
    const [ showMessage, setShowMessage ] = useState(true);

    const handleLoginResult = useCallback((result, doRemember) => {

        if (result.status === 200) {

            let token = result.data.token;
            let prev_login = result.data.prev_login;

            if (doRemember) {
                setLocalSessionToken(token)
            } else {
                setSessionStorageToken(token)
            }

            setUserDetails({ token, prev_login })
            navigate('../profile')
        }
    }, [])


    const element = useMemo(() => {

        if (loading) {
            // userContext is trying to retrieve the user data
            return (
                <div className="wrap wrap--sm">
                    {t('Fetching profile...')}
                </div>
            )
        } else {
            if (userDetails?.email) {
                if (!userDetails.verified) {
                    return (<div className="wrap wrap--sm">

                        <div className="profile__message">
                            <Icon.Check className="icon-small" />
                            Your account is succesfully created, check your
                            e-mail to confirm your registration
                        </div>

                        <Welcome userDetails={userDetails} />
                    </div>)
                } else {
                    return (<div className="wrap wrap--md">
                        <h1 className="profile__title h2">
                            Welcome {userDetails.firstname}
                        </h1>
                        {showMessage && !userDetails.last_login ? (
                            <div className="profile__message">
                                <Icon.Check className="icon-small" />
                                Your account is succesfully confirmed, you can
                                now update your profile and book your next trip
                                faster
                            </div>
                        ) : null}
                        <Overview userDetails={userDetails} />
                    </div>)
                }
            } else {

                return (
                    <div className="wrap wrap--sm">
                        <div className="content-container">
                            <LoginForm handleResult={handleLoginResult} />
                        </div>
                    </div>)

            }
        }

    }, [ userDetails ]);

    return (
        <>
            <Helmet>
                <title>{`Profile | SleeperCharger`}</title>
                <meta
                    name="description"
                    content="Profile | SleeperCharger"
                />
                <link
                    rel="canonical"
                    href={`${BASE_URL}${locale !== 'en' ? `/${locale}` : ''
                        }/profile`}
                />
            </Helmet>
            <section className="profile">
                {element}
            </section>

        </>
    );
};

export default Profile;
