import ReactMarkdown from 'react-markdown';

import { Icon } from '../../common/Icon/Icon';

import './DiscoverImage.css';

const UPLOADS_URL = process.env.REACT_APP_UPLOADS_URL || '';

function DiscoverImage({ data }) {
    const renderUSPItems = (usp_items) => {
        if (usp_items.length) {
            return (
                <ul className="discover-image__list">
                    {usp_items.map((item, index) => {
                        const IconElement =
                            Icon[
                                item.uspIcon.charAt(0).toUpperCase() +
                                    item.uspIcon.slice(1)
                            ];
                        return (
                            <li className="discover-image__item h4" key={index}>
                                <IconElement className="icon-small" />
                                <span>{item.uspItem}</span>
                            </li>
                        );
                    })}
                </ul>
            );
        }
    };

    const renderImage = (image) => {
        if (!image) return null;

        return (
            <div className="discover-image__img-container">
                <img
                    className="discover-image__img cover-img"
                    src={UPLOADS_URL + image.data.attributes.url}
                    alt={image.data.attributes.alternativeText}
                />
            </div>
        );
    };

    return (
        <>
            <section className="discover-image section">
                <div className="wrap wrap--md">
                    <div className="discover-image__container">
                        {renderImage(data.image)}
                        <div className="discover-image__content">
                            {!data.text ? null : (
                                <ReactMarkdown className="discover-image__title text-container" linkTarget="_blank">
                                    {data.text}
                                </ReactMarkdown>
                            )}
                            {renderUSPItems(data.uspItems)}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default DiscoverImage;
