import React from 'react';
import { createRoot } from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { AppContextProvider } from './core/context/appContextProvider';
import { Wrapper } from '@googlemaps/react-wrapper';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from './providers/ThemeProvider';
import './index.css';
import './print.css';
import AppRoutes from './AppRoutes';

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_PLACES_API_KEY;
const container = document.getElementById('root');
const root = createRoot(container);

if (process.env.REACT_APP_GIT_COMMIT_HASH) {
    console.log('version', process.env.REACT_APP_GIT_COMMIT_HASH);
}

if (process.env.REACT_APP_BUILD_ENV === 'production') {
    console.log = () => {};
    console.error = () => {};
    console.debug = () => {};
}

root.render(
    <ThemeProvider>
        <Wrapper
            apiKey={GOOGLE_MAPS_API_KEY}
            libraries={['places', 'geometry']}
        >
            <BrowserRouter>
                <AppContextProvider>
                    <AppRoutes />
                </AppContextProvider>
            </BrowserRouter>
        </Wrapper>
    </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
