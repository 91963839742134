import { useCallback, useEffect, useState, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import axios from 'axios';

import CustomInput from '../../../common/CustomInput/CustomInput';
import { Icon } from '../../../common/Icon/Icon';
import Loader from '../../../common/Loader/Loader';
import { useLocaleContext } from '../../../core/context/localeContext';
import { useUserContext } from '../../../core/context/userContext';
import { COUNTRY_INFO_BY_INDEX } from '../../../core/hooks/constants';
import useScreenSize from '../../../core/hooks/useScreenSize';
import PhoneField from '../../PhoneField/PhoneField';
import {
    DeepMerge,
    DeepEqual,
    DotStringToObject,
    IsValidEmail,
    IsValidYear,
    ToBool,
    sortObjKeys,
} from '../../../helpers';

import './UserDetails.css';
import UserDetailsField from './UserDetailsField';

const BASE_URL =
    process.env.REACT_APP_BASE_URL || 'https://www.sleepercharger.com';

const ResultState = {
    IDLE: 0,
    UPDATE_OK: 1,
    ERROR: 2,
};

const UserDetails = () => {
    const navigate = useNavigate();
    const { locale, t, urlPrefix } = useLocaleContext();
    const { isMobile } = useScreenSize();
    const [loading, setLoading] = useState(false);
    const { userDetails, setUserDetails, removeUser } = useUserContext();
    const [activeField, setActiveField] = useState('');
    const [result, setResult] = useState(ResultState.IDLE);
    const [details, setDetails] = useState(null);
    const [errors, setErrors] = useState({
        email: '',
        firstname: '',
        lastname: '',
        phone: '',
        birth_year: '',
        company: {
            added: false,
            name: '',
            address: {
                country_code: '',
                line_1: '',
                postal_code: '',
                city: '',
            },
        },
    });

    let noChanges = useMemo(() => {
        const result = DeepEqual(
            sortObjKeys({ ...userDetails, ...details }),
            sortObjKeys({ ...userDetails })
        );
        return result;
    }, [userDetails, details]);

    const resetFields = () => {
        setDetails({
            firstname: userDetails.firstname || '',
            lastname: userDetails.lastname || '',
            phone_country_code: userDetails.phone_country_code || '31',
            phone: userDetails.phone || '',
            email: userDetails.email || '',
            birth_year: userDetails.birth_year || '',
            company: {
                added: userDetails.company?.added || false,
                name: userDetails.company?.name || '',
                address: {
                    country_code:
                        userDetails.company?.address?.country_code || '',
                    line_1: userDetails.company?.address?.line_1 || '',
                    postal_code:
                        userDetails.company?.address?.postal_code || '',
                    city: userDetails.company?.address?.city || '',
                },
            },
        });
        setErrors({
            email: '',
            firstname: '',
            lastname: '',
            phone: '',
            birth_year: '',
            company: {
                added: false,
                name: '',
                address: {
                    country_code: '',
                    line_1: '',
                    postal_code: '',
                    city: '',
                },
            },
        });
    };

    useEffect(() => {
        if (!userDetails?.token) return;

        resetFields();
    }, [userDetails]);

    const submitData = useCallback(() => {
        setLoading(true);

        const doSubmit = async () => {
            // did we change anything? otherwise let's not submit anything
            if (noChanges) {
                return;
            }

            let result = await axios.patch(
                `${process.env.REACT_APP_SC_API_BASE}/user`,
                {
                    ...details,
                },
                {
                    headers: {
                        Authorization: `Bearer ${userDetails.token}`,
                    },
                }
            );

            setLoading(false);
            setActiveField('');

            if (result.status === 204) {
                setResult(ResultState.UPDATE_OK);
                setUserDetails({
                    ...userDetails,
                    ...details,
                });
            } else {
                setResult(ResultState.ERROR);
                console.error(result);
            }

            setTimeout(() => {
                window.scrollTo(0, 0);
            }, 0);
        };

        doSubmit(details).catch((err) => {
            setLoading(false);
            // TODO: send error message in response data
            setResult(ResultState.ERROR);

            setTimeout(() => {
                window.scrollTo(0, 0);
            }, 0);
        });
    }, [details, navigate, setUserDetails, userDetails.token]);

    const handleFormSubmit = useCallback(
        (e) => {
            e.preventDefault();
            let errorNum = 0;
            const _errors = {
                email: '',
                firstname: '',
                lastname: '',
                phone: '',
                birth_year: '',
                company: {
                    name: '',
                    address: {
                        line_1: '',
                        postal_code: '',
                        city: '',
                        country_code: '',
                    },
                },
            };

            if (!details.firstname.trim()) {
                errorNum++;
                _errors.firstname = 'empty';
            }
            if (!details.lastname.trim()) {
                errorNum++;
                _errors.lastname = 'empty';
            }
            if (!details.email.trim()) {
                errorNum++;
                _errors.email = 'empty';
            } else if (!IsValidEmail(details.email)) {
                errorNum++;
                _errors.firstname = 'invalid';
            }
            if (!details.birth_year.trim()) {
                // errorNum++;
                // _errors.birth_year = 'empty';
            } else if (!IsValidYear(details.birth_year)) {
                errorNum++;
                _errors.birth_year = 'invalid';
            }
            if (!details.phone.trim()) {
                errorNum++;
                _errors.phone = 'empty';
            }
            if (details.company.added) {
                if (!details.company.name.trim()) {
                    errorNum++;
                    _errors.company.name = 'empty';
                }
                if (!details.company.address.line_1.trim()) {
                    errorNum++;
                    _errors.company.address.line_1 = 'empty';
                }
                if (!details.company.address.postal_code.trim()) {
                    errorNum++;
                    _errors.company.address.postal_code = 'empty';
                }
                if (!details.company.address.city.trim()) {
                    errorNum++;
                    _errors.company.address.city = 'empty';
                }
                if (!details.company.address.country_code.trim()) {
                    errorNum++;
                    _errors.company.address.country_code = 'empty';
                }
            }

            setErrors(_errors);

            if (errorNum === 0) submitData();
        },
        [details, submitData]
    );

    useEffect(() => {
        if (!details?.company) return;

        if (
            !details.company.added &&
            (Object.values(details.company.address)
                .map((val) => ToBool(val))
                .includes(true) ||
                details.company.name)
        ) {
            setDetails((prevState) => ({
                ...prevState,
                company: {
                    ...prevState.company,
                    added: true,
                },
            }));
        }

        if (
            details.company.added &&
            !Object.values(details.company.address)
                .map((val) => ToBool(val))
                .includes(true) &&
            !details.company.name
        ) {
            setDetails((prevState) => ({
                ...prevState,
                company: {
                    ...prevState.company,
                    added: false,
                },
            }));
        }
    }, [details]);

    const handleChange = useCallback(
        (evt) => {
            let name = evt.target.name;
            let value = evt.target.value;

            let property = DotStringToObject(name, value);

            let newDetails = DeepMerge({ ...details }, property);

            setDetails(newDetails);
        },
        [details, setDetails]
    );

    const phoneCountryOptions = COUNTRY_INFO_BY_INDEX.phone.map((e, i) => {
        return (
            <option key={i} value={e}>
                {COUNTRY_INFO_BY_INDEX.display[i]}
            </option>
        );
    });

    const message = useMemo(() => {
        if (result === ResultState.IDLE) {
            return null;
        } else if (result === ResultState.UPDATE_OK) {
            return (
                <div className="wrap wrap--md">
                    <div className="profile__message profile__message__success">
                        {t('Your profile has been updated successfully.')}
                    </div>
                </div>
            );
        } else {
            return (
                <div className="wrap wrap--md">
                    <div className="profile__message profile__message__warning">
                        {t('Something went wrong while updating your profile.')}
                    </div>
                </div>
            );
        }
    }, [result]);

    if (!details) return null;

    return (
        <>
            <Helmet>
                <title>{`${t('Personal information')} | SleeperCharger`}</title>
                <meta
                    name="description"
                    content={`${t('Personal information')} | SleeperCharger`}
                />
                <link
                    rel="canonical"
                    href={`${BASE_URL}${
                        locale !== 'en' ? `/${locale}` : ''
                    }/user`}
                />
            </Helmet>
            <section className="signup user-details">
                <div className="wrap wrap--sm">
                    <Link
                        className="signup__back link"
                        to={`${urlPrefix}/profile`}
                    >
                        <Icon.Arrow className="icon-small icon-left flip-x" />
                        {t('Back to profile')}
                    </Link>
                    <div className="content-container">
                        {message}
                        <div className="signup__header">
                            <h1 className="signup__title h2">
                                {t('Personal information')}
                            </h1>
                        </div>

                        <form onSubmit={handleFormSubmit}>
                            <UserDetailsField
                                label={t('Full name')}
                                value={`${userDetails.firstname} ${userDetails.lastname}`}
                                resetFields={resetFields}
                                setActiveField={setActiveField}
                                activeField={activeField}
                                noChanges={noChanges}
                                loading={loading}
                            >
                                <div
                                    style={{
                                        display: 'grid',
                                        gridTemplateColumns: isMobile
                                            ? '1fr'
                                            : '1fr 1fr',
                                        gap: isMobile ? '0' : '16px',
                                    }}
                                >
                                    <CustomInput
                                        id="firstname"
                                        label={t('First name')}
                                        name="firstname"
                                        value={details.firstname}
                                        onChange={(e) => {
                                            handleChange(e);
                                            setErrors((prevState) => {
                                                return {
                                                    ...prevState,
                                                    firstname: '',
                                                };
                                            });
                                        }}
                                        placeholder={t('First name')}
                                        error={errors.firstname}
                                        emptyMessage="Please fill in your first name"
                                    />
                                    <CustomInput
                                        id="lastname"
                                        label={t('Last name')}
                                        name="lastname"
                                        value={details.lastname}
                                        onChange={(e) => {
                                            handleChange(e);
                                            setErrors((prevState) => {
                                                return {
                                                    ...prevState,
                                                    lastname: '',
                                                };
                                            });
                                        }}
                                        placeholder={t('Last name')}
                                        error={errors.lastname}
                                        emptyMessage="Please fill in your last name"
                                    />
                                </div>
                            </UserDetailsField>

                            <UserDetailsField
                                label={t('Birth year')}
                                value={userDetails.birth_year}
                                resetFields={resetFields}
                                setActiveField={setActiveField}
                                activeField={activeField}
                                noChanges={noChanges}
                                loading={loading}
                            >
                                <CustomInput
                                    type="number"
                                    id="birth_year"
                                    label={t('Birth year')}
                                    name="birth_year"
                                    value={details.birth_year}
                                    onChange={(e) => {
                                        handleChange(e);
                                        setErrors((prevState) => {
                                            return {
                                                ...prevState,
                                                birth_year: '',
                                            };
                                        });
                                    }}
                                    placeholder="yyyy"
                                    error={errors.birth_year}
                                    emptyMessage="Please fill in your birth year"
                                    invalidMessage="Please enter a valid birth year"
                                />
                            </UserDetailsField>

                            <UserDetailsField
                                label={t('Phone number')}
                                value={`+${userDetails.phone_country_code} ${userDetails.phone}`}
                                resetFields={resetFields}
                                setActiveField={setActiveField}
                                activeField={activeField}
                                noChanges={noChanges}
                                loading={loading}
                            >
                                <div
                                    className={`input-container${
                                        !errors.phone ? '' : ' error'
                                    }`}
                                >
                                    <label className="input-label">
                                        {t('Phone number')}
                                    </label>

                                    <PhoneField
                                        handleChange={(e) => {
                                            handleChange(e);
                                            setErrors((prevState) => {
                                                return {
                                                    ...prevState,
                                                    phone: '',
                                                };
                                            });
                                        }}
                                        countryOptions={phoneCountryOptions}
                                        countryValue={
                                            details.phone_country_code
                                        }
                                        phoneValue={details.phone}
                                    />
                                    {!errors.phone ? null : (
                                        <div className="input-error">
                                            Please fill in your phone number
                                        </div>
                                    )}
                                </div>
                            </UserDetailsField>

                            <UserDetailsField
                                noEdit
                                label={t('Email address')}
                                value={userDetails.email}
                                resetFields={resetFields}
                                setActiveField={setActiveField}
                                activeField={activeField}
                                noChanges={noChanges}
                                loading={loading}
                            >
                                <CustomInput
                                    id="email"
                                    label={t('Email address')}
                                    name="email"
                                    value={details.email}
                                    onChange={(e) => {
                                        handleChange(e);
                                        setErrors((prevState) => {
                                            return { ...prevState, email: '' };
                                        });
                                    }}
                                    placeholder={t('Email address')}
                                    error={errors.email}
                                    emptyMessage={t(
                                        'Please fill in your email address'
                                    )}
                                    invalidMessage={t(
                                        'Please enter a valid email address'
                                    )}
                                />
                            </UserDetailsField>

                            <UserDetailsField
                                label={t('Company details')}
                                value={
                                    userDetails?.company?.added
                                        ? `${userDetails.company.name} · ${userDetails.company.address.country_code} · ${userDetails.company.address.line_1}, ${userDetails.company.address.postal_code} ${userDetails.company.address.city}`
                                        : t('No details added')
                                }
                                resetFields={resetFields}
                                setActiveField={setActiveField}
                                activeField={activeField}
                                add={!details.company.added}
                                noChanges={noChanges}
                                loading={loading}
                            >
                                <CustomInput
                                    id="company_name"
                                    label={t('Company name')}
                                    name="company.name"
                                    value={details.company.name}
                                    onChange={(e) => {
                                        handleChange(e);
                                        setErrors((prevState) => {
                                            return {
                                                ...prevState,
                                                company: {
                                                    ...prevState.company,
                                                    name: '',
                                                },
                                            };
                                        });
                                    }}
                                    placeholder={t('Company name')}
                                    error={errors.company.name}
                                    emptyMessage="Please fill in your company name"
                                />
                                <CustomInput
                                    id="country_code"
                                    label={t('Country')}
                                    name="company.address.country_code"
                                    value={details.company.address.country_code}
                                    onChange={(e) => {
                                        handleChange(e);
                                        setErrors((prevState) => {
                                            return {
                                                ...prevState,
                                                company: {
                                                    ...prevState.company,
                                                    address: {
                                                        ...prevState.company
                                                            .address,
                                                        country_code: '',
                                                    },
                                                },
                                            };
                                        });
                                    }}
                                    placeholder={t('Country')}
                                    error={errors.company.address.country_code}
                                    emptyMessage="Please fill in your company country"
                                />
                                <CustomInput
                                    id="line_1"
                                    label={t('Company address')}
                                    name="company.address.line_1"
                                    value={details.company.address.line_1}
                                    onChange={(e) => {
                                        handleChange(e);
                                        setErrors((prevState) => {
                                            return {
                                                ...prevState,
                                                company: {
                                                    ...prevState.company,
                                                    address: {
                                                        ...prevState.company
                                                            .address,
                                                        line_1: '',
                                                    },
                                                },
                                            };
                                        });
                                    }}
                                    placeholder={t('Street + house number')}
                                    error={errors.company.address.line_1}
                                    emptyMessage="Please fill in your company address"
                                />
                                <div
                                    style={{
                                        display: 'grid',
                                        gridTemplateColumns: '2fr 5fr',
                                        gap: 10,
                                    }}
                                >
                                    <CustomInput
                                        id="postal_code"
                                        label={t('Zipcode')}
                                        name="company.address.postal_code"
                                        value={
                                            details.company.address.postal_code
                                        }
                                        onChange={(e) => {
                                            handleChange(e);
                                            setErrors((prevState) => {
                                                return {
                                                    ...prevState,
                                                    company: {
                                                        ...prevState.company,
                                                        address: {
                                                            ...prevState.company
                                                                .address,
                                                            postal_code: '',
                                                        },
                                                    },
                                                };
                                            });
                                        }}
                                        placeholder={t('Postal code')}
                                        error={
                                            errors.company.address.postal_code
                                        }
                                        emptyMessage="Please fill in your company zipcode"
                                    />
                                    <CustomInput
                                        id="city"
                                        label={t('City')}
                                        name="company.address.city"
                                        value={details.company.address.city}
                                        onChange={(e) => {
                                            handleChange(e);
                                            setErrors((prevState) => {
                                                return {
                                                    ...prevState,
                                                    company: {
                                                        ...prevState.company,
                                                        address: {
                                                            ...prevState.company
                                                                .address,
                                                            city: '',
                                                        },
                                                    },
                                                };
                                            });
                                        }}
                                        placeholder={t('City')}
                                        error={errors.company.address.city}
                                        emptyMessage="Please fill in your company city"
                                    />
                                </div>
                            </UserDetailsField>
                        </form>

                        <button
                            className="user-details__remove link"
                            type="button"
                            onClick={removeUser}
                            disabled={activeField}
                        >
                            {t('Remove my account')}
                            <Icon.Bin className="icon-small icon-right" />
                        </button>
                    </div>
                </div>
            </section>
        </>
    );
};

export default UserDetails;
