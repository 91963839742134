import { useRef } from 'react';
import ReactMarkdown from 'react-markdown';

import useScreenSize from '../../core/hooks/useScreenSize';
import DesktopSearchbar from '../Search/DesktopSearchbar/DesktopSearchbar';
import MobileSearchButton from '../Search/MobileSearch/MobileSearchButton/MobileSearchButton';

import './SearchBlock.css';

const SearchBlock = ({ data }) => {
    const { isMobile } = useScreenSize();
    const openBtnRef = useRef(null);

    return (
        <section  className="search-block section">
            <div className="wrap wrap--sm">
                <div className="search-block__content">
                    <ReactMarkdown className="search-block__text text-container" linkTarget="_blank">{data.text}</ReactMarkdown>
                    {isMobile ? (
                        <MobileSearchButton ref={openBtnRef}/>
                    ) : (
                        <DesktopSearchbar isCompact={true} />
                    )}
                </div>
            </div>
        </section >
    );
};

export default SearchBlock;
