import { useCallback, useState } from 'react';
import CustomInput from '../../common/CustomInput/CustomInput';
import { useLocaleContext } from '../../core/context/localeContext';
import { IsValidEmail } from '../../helpers';
import Loader from '../../common/Loader/Loader';
import axios from 'axios';
import { Icon } from '../../common/Icon/Icon';

const FindBookingForm = ({ bookingId }) => {
    const { t } = useLocaleContext();
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        email: '',
        booking_id: bookingId || '',
    });
    const [errors, setErrors] = useState({
        email: false,
        booking_id: false,
        fetch: false,
    });

    const checkFields = useCallback(() => {
        let errNumb = 0;
        setErrors({
            email: false,
            booking_id: false,
            fetch: false,
        });
        if (!formData.email.trim()) {
            setErrors((prevState) => ({
                ...prevState,
                email: 'empty',
            }));
            errNumb++;
        } else if (!IsValidEmail(formData.email)) {
            setErrors((prevState) => ({
                ...prevState,
                email: 'invalid',
            }));
            errNumb++;
        }
        if (!formData.booking_id.trim()) {
            setErrors((prevState) => ({
                ...prevState,
                booking_id: 'empty',
            }));
            errNumb++;
        }

        return errNumb === 0;
    }, [formData, setErrors]);

    const handleSubmit = useCallback(
        (e) => {
            e.preventDefault();
            const inputsValid = checkFields();
            if (!inputsValid) return;
            const getHash = async () => {
                setLoading(true);

                const response = await axios.post(
                    `${process.env.REACT_APP_SC_API_BASE}/get_booking`,
                    {
                        ...formData,
                    }
                );
                setLoading(false);

                if (response?.status === 200 && response?.data?.data) {
                    window.location.href = response.data.data;
                }
                if (response?.status === 204) {
                    setErrors((prevState) => ({
                        ...prevState,
                        fetch: t("Booking ID and E-mail address don't match"),
                    }));
                }
            };
            getHash().catch(() => {
                setLoading(false);
            });
        },
        [checkFields, formData, t]
    );

    let handleChange = (evt) => {
        setFormData((prevState) => ({
            ...prevState,
            [evt.target.name]: evt.target.value,
        }));
        setErrors((prevState) => ({
            ...prevState,
            [evt.target.name]: null,
        }));
    };

    return (
        <form onSubmit={handleSubmit}>
            <CustomInput
                id="booking_id"
                label="Booking ID"
                name="booking_id"
                type="text"
                value={formData.booking_id}
                onChange={handleChange}
                placeholder="Booking ID"
                error={errors.booking_id}
                emptyMessage={t('Please fill in a booking id')}
            />
            <CustomInput
                id="email"
                label="E-mail address"
                name="email"
                type="text"
                autoComplete="on"
                value={formData.email}
                onChange={handleChange}
                placeholder="E-mail address"
                error={errors.email}
                emptyMessage={t('Please fill in your email address')}
                invalidMessage={t('Please enter a valid email address')}
            />
            <button
                className="signup__btn btn btn--blue"
                type="submit"
                disabled={loading}
            >
                {t('Find my Booking')}
                {!loading ? null : (
                    <Loader
                        className="icon-right"
                        size={16}
                        bgColor={'var(--secondary90)'}
                        color={'var(--primary)'}
                    />
                )}
            </button>
            {!errors.fetch ? null : (
                <div className="info info--red" style={{ marginTop: '20px' }}>
                    <Icon.Alert className="icon-small icon-left" />
                    {errors.fetch}
                </div>
            )}
        </form>
    );
};

export default FindBookingForm;
