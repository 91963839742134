import { Navigate, Outlet } from "react-router-dom";
import { useLocaleContext } from "../core/context/localeContext";

const ProtectedRoute = ({user, redirectPath, children}) => {
  const {urlPrefix} = useLocaleContext();

  if (!user.token) {
    return <Navigate to={redirectPath || `${urlPrefix}/`} replace />;
  }

  if (!user.email) {
    return null;
  }

  return children ? children : <Outlet />;
}

export default ProtectedRoute;