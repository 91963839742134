import { useState, useContext } from 'react';
import { Icon } from '../../common/Icon/Icon';
import { ThemeContext } from '../../providers/ThemeProvider';

import './Question.css';

function Question({question, answer, open}) {
    const [isOpen, setIsOpen] = useState(open);
    const {isPrinting} = useContext(ThemeContext);

    return (
        <div className={`question${isOpen ? " open" : ""}`}>
            <button className="question__btn" type="button" onClick={() => setIsOpen(!isOpen)}>
                <h3 className="h4">{question}</h3>
                { isOpen ? <Icon.Minus /> : <Icon.Plus /> }
            </button>

            { (isOpen || isPrinting) && <div className="question__answer">{answer}</div> }
        </div>
    );
}

export default Question;