import React, { createContext, useContext, useState } from 'react';

const DaytimeContext = createContext();

export function useDaytimeContext() {
    return useContext(DaytimeContext);
}

export function DaytimeContextProvider({ children }) {
    const [isDark, setIsDark] = useState(false);

    // useEffect(() => {
    //     if (
    //         (window.matchMedia &&
    //             window.matchMedia('(prefers-color-scheme: dark)').matches) ||
    //         new Date().getHours() >= 19 ||
    //         new Date().getHours() < 7
    //     ) {
    //         setIsDark(true);
    //     }
    // }, []);

    // window
    //     .matchMedia('(prefers-color-scheme: dark)')
    //     .addEventListener('change', (e) => {
    //         console.log(e.matches);
    //         setIsDark(
    //             e.matches ||
    //                 new Date().getHours() >= 19 ||
    //                 new Date().getHours() < 7
    //         );
    //     });

    return (
        <DaytimeContext.Provider value={{ isDark }}>
            {children}
        </DaytimeContext.Provider>
    );
}
