import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import qs from 'qs';

import Hero from '../Components/Hero/Hero.js';
import PageBuilder from '../Components/PageBuilder.js';
import { useLocaleContext } from '../core/context/localeContext';
import useLoader from '../core/hooks/useLoader.js';

const BASE_URL =
    process.env.REACT_APP_BASE_URL || 'https://www.sleepercharger.com';

function AboutUs (props) {
    const [ page, setPage ] = useState();
    const { locale, urlPrefix } = useLocaleContext();
    const navigate = useNavigate();
    useLoader(page);

    useEffect(() => {
        const getFaqItems = async () => {
            const query = qs.stringify(
                {
                    locale: locale,
                    populate: {
                        hero: {
                            populate: [ 'image', 'imageMobile' ],
                        },
                        builder: {
                            populate: '*',
                        },
                        pageMeta: {
                            populate: '*',
                        },
                    },
                },
                {
                    encodeValuesOnly: true, // prettify URL
                }
            );
            await axios
                .get(`${process.env.REACT_APP_API_URL}/api/about-us?${query}`, {
                    headers: {
                        Authorization: process.env.REACT_APP_API_KEY,
                    },
                })
                .then((res) => {
                    setPage(res.data.data.attributes);
                })
                .catch(() => {
                    setPage(null);
                    navigate(`${urlPrefix}/`, { replace: true });
                });
        };

        if (locale) getFaqItems();
    }, [ locale, navigate, urlPrefix ]);

    let canonicalUrl = page?.pageMeta?.canonical ||
        `${BASE_URL}${locale !== 'en' ? `/${locale}` : ''
        }/about-us`

    return (
        <>
            {page && (
                <>
                    <Helmet>
                        <title>
                            {page?.pageMeta?.title || 'About Us'} |
                            SleeperCharger
                        </title>
                        <meta
                            name="description"
                            content={
                                page?.pageMeta?.description ||
                                'Hotel with guaranteed electric vehicle chargers. Book now only at SleeperCharger.'
                            }
                        />
                        <meta
                            property="og:title"
                            content={page?.pageMeta?.title || 'About Us'}
                        />
                        <meta
                            property="og:url"
                            content={canonicalUrl}
                        />
                        <link
                            rel="canonical"
                            href={canonicalUrl}
                        />
                    </Helmet>
                    {page.hero && (
                        <Hero data={page.hero} isMobile={props.isMobile} />
                    )}
                    {page.builder.length && (
                        <PageBuilder blocks={page.builder} />
                    )}
                </>
            )}
        </>
    );
}

export default AboutUs;
