import { useState, useEffect, useMemo } from 'react';

import axios from 'axios';

import { getDay } from 'date-fns';

import { Icon } from '../../common/Icon/Icon';
import { useLocaleContext } from '../../core/context/localeContext';
import { useSearchContext } from '../../core/context/searchContext';
import AvailabilityChart from './AvailabilityChart';
import ChargerEVSEInfo from './ChargerEVSEInfo.js';

import './ChargerDetails.css';

const ChargerDetails = ({ item, atProperty }) => {
    const [chargerInfo, setChargerInfo] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useLocaleContext();
    const { meta } = useSearchContext();

    useEffect(() => {
        let isSubscribed = true;

        const fetchChargerInfo = async (params) => {
            setIsLoading(true);
            let result = await axios.get(
                `${process.env.REACT_APP_SC_API_BASE}/get_charger_info`,
                {
                    params: params,
                }
            );
            setIsLoading(false);

            if (isSubscribed) {
                setChargerInfo(result.data);
            }
        };

        fetchChargerInfo({ charger_id: item.id }).catch((error) => {
            setIsLoading(false);
            console.error(error);
        });
    }, [item.id]);

    const checkinWeekday = meta.checkin ? getDay(new Date(meta.checkin)) : -1;

    let openingTimes = t('Open 24 hours, 7 days a week');
    if (item.opening_times) {
        if (item.opening_times.twentyfourseven) {
            // this is default
        } else if (item.opening_times.Text) {
            openingTimes = item.opening_times.Text;
        }
    }

    let reliability = null;
    if (item.reliability_score_2) {
        let percent = (Number(item.reliability_score_2) / 5) * 100;
        reliability = t(`%{percent}% reliable`, { percent });
    }

    let powers = item.powers;
    let str = `${(powers[0] / 1000).toFixed(1)} kW`;
    if (powers.length > 1) {
        str += ` - ${(powers[powers.length - 1] / 1000).toFixed(1)} kW`;
    }

    let accepted_cards = chargerInfo?.roaming
        ? chargerInfo.roaming
              .sort((a, b) => a.emsp.localeCompare(b.emsp))
              .map((e) => {
                  return (
                      <li key={e.emsp} className="charger-details__cards-item">
                          <span>{e.emsp}</span>
                      </li>
                  );
              })
        : process.env.REACT_APP_SHOW_DEBUG
        ? 'Unknown'
        : null;

    let adhoc_payment =
        chargerInfo?.adhoc_authorisation_payment_method &&
        chargerInfo?.adhoc_authorisation_payment_method.length
            ? chargerInfo.adhoc_authorisation_payment_method.map((e) => {
                  return (
                      <li key={e.code} className="charger-details__cards-item">
                          {e.code}
                      </li>
                  );
              })
            : '---';

    let pricing =
        item.adhoc_payment && item.adhoc_payment.length ? item.pricing : null;

    const googleLink = encodeURI(
        `${item.address},${item.postal_code},${item.city}`
    );

    const infoText = useMemo(() => {
        return item.owner.name.toLowerCase() === 'tesla'
            ? t('tesla_disclaimer')
            : null;
    }, [item.owner.name, t]);

    return (
        <div className="charger-details">
            <h3 className="charger-details__title h2">{item.owner.name}</h3>
            <div className="charger-details__info-box">
                <ul className="charger-details__info-list">
                    <li className="charger-details__info-item">
                        <Icon.Map className="icon-small icon-left" />
                        {!atProperty ? (
                            <a
                                className="link"
                                href={`https://www.google.com/maps/dir/?api=1&destination=${googleLink}`}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {item.address}, {item.postal_code} {item.city}
                            </a>
                        ) : (
                            'At the property'
                        )}
                    </li>
                    {item.support_phone_number ? (
                        <li className="charger-details__info-item">
                            <Icon.Phone className="icon-small icon-left" />
                            <a
                                href={`tel:${item.support_phone_number}`}
                                className="link"
                            >
                                {item.support_phone_number}
                            </a>
                        </li>
                    ) : null}
                    <li className="charger-details__info-item">
                        <Icon.Clock className="icon-small icon-left" />
                        {openingTimes}
                    </li>
                    <li className="charger-details__info-item">
                        <Icon.Plug className="icon-small icon-left" />
                        {t('%{count} chargers', { count: item.evses.length })}
                    </li>
                    <li className="charger-details__info-item">
                        <Icon.Bolt className="icon-small icon-left" />
                        {str}
                    </li>
                    {!reliability ? null : (
                        <li className="charger-details__info-item">
                            <Icon.Check className="icon-small icon-left" />
                            {reliability}
                        </li>
                    )}
                </ul>
            </div>
            <div className="charger-details__info-group">
                {isLoading ? (
                    <div className="charger-details__connector-loader block-loader" />
                ) : !chargerInfo?.distinct_connectors ? null : (
                    <>
                        <h3 className="charger-details__title h3">
                            {t('Plug types')}
                        </h3>
                        <div className="charger-details__connector-container">
                            {chargerInfo.distinct_connectors?.map((e, i) => (
                                <ChargerEVSEInfo
                                    key={i}
                                    index={i}
                                    pricing={pricing}
                                    distinctConnectors={e}
                                    chargerInfo={chargerInfo}
                                />
                            ))}
                        </div>
                    </>
                )}
                {!infoText ? null : (
                    <div className="charger-details__info-warning">
                        <Icon.Alert className="icon-small" />
                        <p>{infoText}</p>
                    </div>
                )}
            </div>
            {chargerInfo?.predicted_occupancy ? (
                <div className="charger-details__info-group">
                    <AvailabilityChart
                        checkinWeekday={checkinWeekday}
                        occupancy={chargerInfo.predicted_occupancy}
                    />
                </div>
            ) : null}
            <div className="charger-details__info-group">
                {!accepted_cards && !process.env.REACT_APP_SHOW_DEBUG ? null : (
                    <>
                        <h3 className="charger-details__title h3">
                            {t('Payment')}
                        </h3>
                        <h4 className="charger-details__subtitle h4">
                            {t('Cards accepted')}
                        </h4>
                        <ul className="charger-details__cards-list">
                            {accepted_cards}
                        </ul>
                        {process.env.REACT_APP_SHOW_DEBUG ? (
                            <>
                                <h4 className="charger-details__subtitle h4">
                                    {t('Ad-hoc payment')}
                                </h4>
                                <ul className="charger-details__cards-list">
                                    {adhoc_payment}
                                </ul>
                            </>
                        ) : null}
                    </>
                )}
            </div>
        </div>
    );
};

export default ChargerDetails;
