import { Outlet } from 'react-router-dom';
import useLoader from '../core/hooks/useLoader';
import { useUserContext } from '../core/context/userContext';

const LoaderLayout = () => {
    const { loading } = useUserContext();
    useLoader(!loading ? {} : false);
    return <Outlet />;
};

export default LoaderLayout;
