import React from 'react';
import Improve from '../../Registration/Improve/Improve';
import LatestBooking from '../LatestBooking/LatestBooking';
import UserMenu from '../UserMenu/UserMenu';

const Overview = ({ userDetails }) => {
    return (
        <>
            {!userDetails.travellersAdded || !userDetails.preferencesAdded ? (
                null// <Improve userDetails={userDetails} />
            ) : null}
            <div className="profile__container">
                <UserMenu />
                <div className="profile__detail">
                    <LatestBooking userDetails={userDetails} />
                </div>
            </div>
        </>
    );
};

export default Overview;
