import './ImageBlock.css';

const UPLOADS_URL = process.env.REACT_APP_UPLOADS_URL || '';

export default function ImageBlock(props)
{
    if (!props.data) return null;

    const imageUrl = `${UPLOADS_URL}${props.data.attributes.url}`

    return (
        <div className="image-block section">
            <div className="image-block__img-container">
                <img
                    className="image-block__img cover-img"
                    src={imageUrl}
                    alt={props.data.attributes.alternativeText}/>
            </div>
        </div>
    );
}

