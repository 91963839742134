function Title(props) {
    return (
        <h2
            className="h2 section"
            style={{
                width: '100%',
                maxWidth: '944px',
                marginLeft: 'auto',
                marginRight: 'auto',
            }}
        >
            {props.title}
        </h2>
    );
}

export default Title;
