// import { format as formatDate } from 'date-fns';
// import enLocale from 'date-fns/locale/en-US';

import { Icon } from '../../../../common/Icon/Icon';
import TextBlock from '../../../../Components/TextBlock';

import './BlogHero.css';

const UPLOADS_URL = process.env.REACT_APP_UPLOADS_URL || '';

export default function BlogHero(props) {
    // const date = new Date(props.date);
    const imageUrl =  props.image_src ? 
        `${UPLOADS_URL}${props.image_src}`
        : "/../images/blog-image-default.png";

    const summaryElement = props.journeySummary
    ? (
        <ul className="blog-hero__list">
            <li className="blog-hero__item"><Icon.Distance />{props.journeySummary.route}</li>
            <li className="blog-hero__item"><Icon.Moon />{props.journeySummary.duration}</li>
            <li className="blog-hero__item"><Icon.Car />{props.journeySummary.drivingConditions}</li>
            <li className="blog-hero__item"><Icon.Magic />{props.journeySummary.environment}</li>
        </ul>
    )
    : null;

    return (
        <div className="blog-hero">
            <div className="blog-hero__content">
                <h1 className="blog-hero__title h1">{props.title}</h1>
                {/* <time
                    className="blog-hero__date caption"
                    dateTime={formatDate(date, 'd MMMM yyyy', {
                        locale: enLocale,
                    })}
                >
                    {' '}
                    {formatDate(date, 'd MMMM yyyy', {
                        locale: enLocale,
                    })}
                </time> */}
                <TextBlock
                    content={props.summary}
                    alignment="left"
                    fullWidth={true}
                />
                {!props.isMobile
                    ? summaryElement
                    : null
                }
            </div>
            <div className="blog-hero__visual">
                <div className="blog-hero__img-container">
                    <img
                        className="blog-hero__img cover-img"
                        src={imageUrl}
                        alt={props.title}
                    />
                </div>
                {props.isMobile
                    ? summaryElement
                    : null
                }
            </div>
        </div>
    )
}