import React from 'react';
import Loader from '../Loader/Loader';
import './PageLoader.css';

const PageLoader = () => {
    return (
        <div className="page-loader">
            <div className="page-loader__container">
                <Loader size={30} bgColor="white" />
            </div>
        </div>
    );
};

export default PageLoader;
