import React, { useState } from 'react';
import CustomInput from '../common/CustomInput/CustomInput';

export default function Login({ tryLogin }) {
    const [login, setLogin] = useState({
        email: '',
        password: '',
    });

    let handleChange = (evt) => {
        let newLogin = {
            ...login,
            [evt.target.name]: evt.target.value,
        };
        setLogin(newLogin);
    };

    let onSubmit = (evt) => {
        evt.preventDefault();
        tryLogin(login);
    };

    return (
        <>
            <section className="signup">
                <div className="wrap wrap--sm">
                    <div className="content-container">
                        <div className="signup__header">
                            <h1 className="signup__title h2">Log-in</h1>
                        </div>
                        <form onSubmit={onSubmit}>
                            <CustomInput
                                id="email"
                                label="E-mail address"
                                name="email"
                                type="email"
                                autoComplete="on"
                                value={login.email}
                                onChange={handleChange}
                                placeholder="E-mail address"
                            />
                            <CustomInput
                                id="password"
                                label="Password"
                                name="password"
                                type="password"
                                value={login.password}
                                onChange={handleChange}
                                placeholder="Password"
                            />
                            <button
                                className="signup__btn btn btn--blue"
                                type="submit"
                            >
                                Log in
                            </button>
                        </form>
                       
                    </div>
                </div>
            </section>
        </>
    );
}
