import { React, useState, useEffect, useMemo, useContext, useRef } from 'react';
import reactDOM from 'react-dom';
import { useNavigate } from 'react-router-dom';

import Results from '../Results.js';

import './Search.css';

import { format as formatDate, differenceInDays, parseISO } from 'date-fns';
import enLocale from 'date-fns/locale/en-US';

import {
    buildParams,
    formatHotelPath,
    getCountryName,
    OccupancyString,
} from '../../helpers.js';
import Map from '../SimpleGoogleMap/SimpleGoogleMap.js';
import { Icon } from '../../common/Icon/Icon';
import ToggleButton from '../../common/ToggleButton/ToggleButton.js';
import ToggleButtonItem from '../../common/ToggleButton/ToggleButtonItem/ToggleButtonItem.js';
import SearchFilters from '../SearchFilters/SearchFilters.js';
import { useLocaleContext } from '../../core/context/localeContext.js';
import { useCallback } from 'react';
import DesktopSearchbar from './DesktopSearchbar/DesktopSearchbar.js';
// import { moodList } from '../Mood/MoodList/MoodList.js';
import Loader from '../../common/Loader/Loader.js';
import { useSearchContext } from '../../core/context/searchContext.js';
import { ThemeContext } from '../../providers/ThemeProvider.js';
import { Helmet } from 'react-helmet';
import ModalHOC from '../../common/ModalHOC/ModalHOC.js';
import MobileSearch from './MobileSearch/MobileSearch.js';
import DetectClickOutside from '../../core/hooks/detectClickOutside.js';
import { useUserContext } from '../../core/context/userContext.js';
import CustomDropdown from '../../common/Dropdown/CustomDropdown.js';

const BASE_URL =
    process.env.REACT_APP_BASE_URL || 'https://www.sleepercharger.com';

const SORT_VALUES = {
    default: 'default',
    asc: 'acs',
    desc: 'desc',
};
const CHARGERS_VALUES = {
    all: '',
    atProperty: 'at_property',
    tesla: 'tesla',
    nonTesla: 'non_tesla',
};

const LOCATION_ON_MAP_TRANSLATIONS = [
    'Location on map',
    'Lage auf Karte',
    'Lokatie op de kaart',
];

let geocoder;

export default function Search(props) {
    const {
        state,
        setState,
        meta,
        setMeta,
        queryParams,
        fetchSuppliers,
        isLoading,
        matches,
        fromPop,
        setFromPop,
        abortController,
        setFromLocation,
        setShouldPan,
        findMobileOpen,
        setFindMobileOpen,
        dontSearch,
        setDontSearch,
        openMobileSearch,
        mobileOpenSearchBtnRef,
        fromMobileSearch,
        setFromMobileSearch,
    } = useSearchContext();
    const isMobile = props.isMobile;
    const google = window.google;
    const { userDetails } = useUserContext();
    const navigate = useNavigate();
    const { setPage } = useContext(ThemeContext);
    const [showMap, setShowMap] = useState(isMobile ? false : true);
    const [amountShow, setAmountShow] = useState(6);
    const { urlPrefix, t, translateToLocale, locale } = useLocaleContext();
    const [hotItemId, setHotItemId] = useState('');
    const [filtersOpen, setFiltersOpen] = useState(false);
    const [propertyAmenitiesFilter, setPropertyAmenitiesFilter] = useState([]);
    const [sort, setSort] = useState(SORT_VALUES.default);
    const [chargerFilter, setChargerFilter] = useState(CHARGERS_VALUES.all);
    const mobileSearchBtnRef = useRef(null);
    const firstUpdate = useRef(true);

    const [mapCenter, setMapCenter] = useState({
        lat: meta.lat ? +meta.lat : +state.lat,
        lng: meta.lng ? +meta.lng : +state.lng,
    });

    const handleIdle = useCallback(
        async (centerLatLng, bounds, zoom, initial) => {
            setState((prevState) => ({
                ...prevState,
                lat: centerLatLng.lat,
                lng: centerLatLng.lng,
                zoom,
                ...bounds,
            }));

            const { sid, location, ...params } = {
                ...{
                    ...state,
                    lat: centerLatLng.lat,
                    lng: centerLatLng.lng,
                    zoom,
                    ...bounds,
                },
            };

            setFromMobileSearch(false);

            fetchSuppliers(params, {
                shouldNavigate: !fromPop || fromMobileSearch,
                replace: initial,
                scrollTop: !showMap || !isMobile,
            });
        },
        [
            fetchSuppliers,
            setState,
            showMap,
            state,
            fromPop,
            fromMobileSearch,
            setFromMobileSearch,
            isMobile,
        ]
    );

    useEffect(() => {
        geocoder = new google.maps.Geocoder();
    }, [google]);

    const handleMyLocation = useCallback(
        async (centerLatLng) => {
            const result = await geocoder.geocode({ location: centerLatLng });

            setState((prevState) => ({
                ...prevState,
                lat: centerLatLng.lat,
                lng: centerLatLng.lng,
                dest:
                    result?.results[1]?.formatted_address ||
                    t('Location on map'),
                location:
                    result?.results[1]?.formatted_address ||
                    t('Location on map'),
                zoom: 13,
            }));

            const { sid, location, ...params } = {
                ...{
                    ...state,
                    lat: centerLatLng.lat,
                    lng: centerLatLng.lng,
                    dest: t('Location on map'),
                    zoom: 13,
                },
            };
            setFromLocation(true);

            setMeta(params);
        },
        [setFromLocation, setMeta, setState, state, t]
    );

    useEffect(() => {
        if (!meta.lat || !meta.lng) return;
        if (+meta.lat !== mapCenter.lat || +meta.lng !== mapCenter.lng) {
            setMapCenter({
                lat: +meta.lat,
                lng: +meta.lng,
            });
        }
        // eslint-disable-next-line
    }, [meta]);

    useEffect(() => {
        setPage('search');

        return () => {
            setPage('');
            if (abortController?.current) abortController.current.abort();
        };
    }, []);

    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }
        fetchSuppliers(state);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userDetails.token]);

    useEffect(() => {
        const handlePop = () => {
            setFromPop(true);
            setShouldPan(true);
        };
        window.addEventListener('popstate', handlePop);

        return () => {
            window.removeEventListener('popstate', handlePop);
        };
    }, [setFromPop, setShouldPan]);

    useEffect(() => {
        if (fromPop && dontSearch && !findMobileOpen) {
            setShouldPan(false);
            setDontSearch(false);
            setFromPop(false);
        }
    }, [
        state,
        fromPop,
        dontSearch,
        findMobileOpen,
        setShouldPan,
        setDontSearch,
        setFromPop,
    ]);

    useEffect(() => {
        setPropertyAmenitiesFilter(meta.f_amenities);
        setChargerFilter(meta.f_chargers || '');
    }, [meta]);

    const parsedCheckin = parseISO(meta.checkin);
    const parsedCheckout = parseISO(meta.checkout);
    const numNights = differenceInDays(parsedCheckout, parsedCheckin);

    let onClick = (e) => {
        let { dest, radius, lat0, lat1, lng0, lng1, zoom, ...params } = {
            ...queryParams,
        };
        const { name, address, _id, rooms } = e;
        params.sid = _id;
        params.rooms = rooms;

        let searchParams = buildParams(params);
        const hotelPath = `${urlPrefix}${formatHotelPath({
            name: name,
            city: address?.city,
            country: address?.country_code,
            locale,
            translateToLocale,
        })}`;

        if (process.env.REACT_APP_ALL_IN_SAME_TAB) {
            setState((prevState) => ({
                ...prevState,
                sid: _id,
            }));
            navigate({
                pathname: hotelPath,
                search: `?${searchParams}`,
            });
        } else {
            window.open(`${hotelPath}?${searchParams}`, '_blank');
        }
    };

    let toggleMap = (value) => {
        setShowMap(value);
    };

    const matchesToShow = useMemo(
        () => [...matches].slice(0, matches.length),
        [matches]
    );

    const sortedMatches =
        sort === SORT_VALUES.default
            ? matchesToShow
            : [...matchesToShow].sort((a, b) =>
                  sort === SORT_VALUES.asc
                      ? a.totalPriceInc - b.totalPriceInc
                      : b.totalPriceInc - a.totalPriceInc
              );

    const loadingRecommendations = useCallback(() => {
        const element = []; // @mutates
        for (let i = 0; i < 5; i++) {
            element.push(
                <div className="loading-recommendation" key={i}>
                    <div className="loading-recommendation__left block-loader"></div>
                    <div className="loading-recommendation__right">
                        <div className="loading-recommendation__top block-loader"></div>
                        <div className="loading-recommendation__bottom block-loader"></div>
                    </div>
                </div>
            );
        }
        return element;
    }, []);

    const toggleFilters = useCallback((val) => {
        setFiltersOpen(val);
    }, []);

    const openSearch = useCallback(() => {
        openMobileSearch(mobileSearchBtnRef);
    }, [openMobileSearch]);

    useEffect(() => {
        document.body.style.overflow =
            findMobileOpen && isMobile ? 'hidden' : '';

        return () => {
            document.body.style.overflow = '';
        };
    }, [isMobile, findMobileOpen]);

    useEffect(() => {
        if (findMobileOpen) {
            setFindMobileOpen(false);
            window.history.go(-1);
        }
        // eslint-disable-next-line
    }, [isMobile]);

    const onResultHover = useCallback((item) => {
        setHotItemId(item._id);
    }, []);

    const applySearchFilters = useCallback(
        (val) => {
            setState((prevState) => ({
                ...prevState,
                f_amenities: val,
            }));
            const { sid, location, ...params } = {
                ...{
                    ...state,
                    f_amenities: val,
                },
            };
            fetchSuppliers(params, {
                shouldNavigate: true,
                replace: false,
            });
            setFiltersOpen(false);
        },
        [fetchSuppliers, setState, state]
    );

    const applyChargerFilters = useCallback(
        (val) => {
            setState((prevState) => ({
                ...prevState,
                f_chargers: val,
            }));
            const { sid, location, ...params } = {
                ...{
                    ...state,
                    f_chargers: val,
                },
            };
            fetchSuppliers(params, {
                shouldNavigate: true,
                replace: false,
            });
        },
        [fetchSuppliers, setState, state]
    );

    const closeMobileSearch = useCallback(() => {
        navigate(-1);
    }, [navigate]);

    const handleFilterOutsideClick = useCallback(() => {
        toggleFilters(false);
    }, [filtersOpen, toggleFilters]);

    const sortOptions = [
        {
            label: t('Most chargers'),
            value: SORT_VALUES.default,
        },
        {
            label: t('Lowest price first'),
            value: SORT_VALUES.asc,
        },
        {
            label: t('Highest price first'),
            value: SORT_VALUES.desc,
        },
    ];

    const chargersOptions = [
        {
            label: t('All'),
            value: CHARGERS_VALUES.all,
        },
        {
            label: t('At the property'),
            value: CHARGERS_VALUES.atProperty,
        },
        {
            label: 'Tesla',
            value: CHARGERS_VALUES.tesla,
        },
        {
            label: 'non-Tesla',
            value: CHARGERS_VALUES.nonTesla,
        },
    ];

    const sortItems = (e, val) => {
        setSort(val);
    };

    const filterChargers = (e, val) => {
        setChargerFilter(val);
        applyChargerFilters(val);
    };

    const metaTitle = useMemo(
        () =>
            `${t('Hotels with EV chargers')}${
                LOCATION_ON_MAP_TRANSLATIONS.includes(meta.dest?.trim())
                    ? ''
                    : ` ${t('in')} ${meta.dest}`
            }`,
        [meta, t]
    );

    return (
        <section className="search">
            <Helmet>
                <title>
                    {`${metaTitle} | SleeperCharger` ||
                        'Search | SleeperCharger'}
                </title>
                <meta
                    name="description"
                    content={metaTitle || 'SleeperCharger Search'}
                />
                <link
                    rel="canonical"
                    href={`${BASE_URL}${
                        locale !== 'en' ? `/${locale}` : ''
                    }${window.location.pathname.replace(
                        /(\/en|\/nl|\/de)/,
                        ''
                    )}${document.location.search}`}
                />
            </Helmet>
            <div className="search__container">
                <div
                    className={`search__content${
                        showMap ? '' : ' search__content--full'
                    }`}
                >
                    {isMobile ? (
                        <div
                            className={`search__open-container${
                                showMap ? ' map-open' : ''
                            }`}
                        >
                            <button
                                className="search__open"
                                onClick={openSearch}
                                ref={mobileSearchBtnRef}
                            >
                                <span className="form-label">
                                    <span>{meta.dest}</span>
                                </span>
                                <span
                                    style={{
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                    }}
                                >
                                    {/* {
                                        moodList.find(
                                            (item) => item.value === meta.fcatid
                                        ).title
                                    }
                                    <span style={{ margin: '0 8px' }}>•</span> */}
                                    {!meta.checkin || !meta.checkout ? (
                                        <span
                                            style={{
                                                color: 'var(--primary80)',
                                            }}
                                        >
                                            {t('Add dates')}
                                        </span>
                                    ) : (
                                        <>
                                            <span
                                                style={{
                                                    whiteSpace: 'nowrap',
                                                }}
                                            >
                                                {formatDate(
                                                    parsedCheckin,
                                                    `d ${
                                                        formatDate(
                                                            parsedCheckin,
                                                            'MMM'
                                                        ) ===
                                                        formatDate(
                                                            parsedCheckout,
                                                            'MMM'
                                                        )
                                                            ? ''
                                                            : 'MMM'
                                                    }`,
                                                    {
                                                        locale: enLocale,
                                                    }
                                                )}{' '}
                                                -{' '}
                                                {formatDate(
                                                    parsedCheckout,
                                                    'd MMM',
                                                    {
                                                        locale: enLocale,
                                                    }
                                                )}
                                            </span>
                                        </>
                                    )}
                                    <span style={{ margin: '0 8px' }}>•</span>
                                    <span style={{ whiteSpace: 'nowrap' }}>
                                        {OccupancyString({
                                            adults: Number(meta.adults),
                                            t,
                                        })}
                                        ,
                                    </span>
                                    {meta.child_age.length ? (
                                        <span style={{ whiteSpace: 'nowrap' }}>
                                            {' '}
                                            {OccupancyString({
                                                children: meta.child_age.length,
                                                t,
                                            })}
                                            ,
                                        </span>
                                    ) : null}

                                    <span style={{ whiteSpace: 'nowrap' }}>
                                        {' '}
                                        {OccupancyString({
                                            rooms: Number(meta.rooms),
                                            t,
                                        })}
                                    </span>
                                </span>
                            </button>
                        </div>
                    ) : (
                        <div className="search__searchbar-container">
                            <DesktopSearchbar
                                fieldApply
                                meta={meta}
                                propertyAmenitiesFilter={
                                    propertyAmenitiesFilter
                                }
                                onMyLocation={handleMyLocation}
                            />
                        </div>
                    )}

                    <div className="search__header">
                        <div className="search__header-top">
                            <div className="search__filter-container">
                                <div className="search-filters">
                                    <DetectClickOutside
                                        onClick={handleFilterOutsideClick}
                                        detectTab={true}
                                    >
                                        <button
                                            className={`btn btn--filter${
                                                filtersOpen ? ' active' : ''
                                            }`}
                                            type="button"
                                            onClick={() =>
                                                toggleFilters(!filtersOpen)
                                            }
                                        >
                                            <Icon.Filter
                                                className={`icon icon-small${
                                                    !isMobile
                                                        ? ' icon-left'
                                                        : ''
                                                }`}
                                            />
                                            {isMobile ? (
                                                <span className="sr-only">
                                                    {t('Filters')}
                                                </span>
                                            ) : (
                                                t('Filters')
                                            )}
                                            {!meta.f_amenities
                                                ?.length ? null : (
                                                <span className="btn__info btn__info--green">
                                                    {meta.f_amenities.length}
                                                </span>
                                            )}
                                        </button>
                                        {!filtersOpen ? null : (
                                            <SearchFilters
                                                propertyAmenitiesFilter={
                                                    propertyAmenitiesFilter
                                                }
                                                meta={meta}
                                                setPropertyAmenitiesFilter={
                                                    setPropertyAmenitiesFilter
                                                }
                                                applySearchFilters={
                                                    applySearchFilters
                                                }
                                                filtersOpen={filtersOpen}
                                            />
                                        )}
                                    </DetectClickOutside>
                                </div>
                                <CustomDropdown
                                    items={chargersOptions}
                                    selected={chargerFilter}
                                    handleChange={filterChargers}
                                    prefix={`${t('Chargers')}:`}
                                    ariaLabel={t('Chargers')}
                                />
                                {!matches[0]?.totalPriceInc ? null : (
                                    <CustomDropdown
                                        items={sortOptions}
                                        selected={sort}
                                        handleChange={sortItems}
                                        prefix={`${t('Sort by')}:`}
                                        ariaLabel={t('Sort by')}
                                    />
                                )}
                            </div>
                            {!isMobile ? (
                                <button
                                    onClick={() => toggleMap(!showMap)}
                                    className="search__map-toggle btn btn--filter"
                                    type="button"
                                >
                                    <Icon.Map className="icon-small icon-left" />
                                    {t(showMap ? 'Hide map' : 'Show map')}
                                </button>
                            ) : (
                                <ToggleButton
                                    label={t('Results view')}
                                    onChange={toggleMap}
                                    preSelected={showMap ? 1 : 0}
                                >
                                    <ToggleButtonItem value={false}>
                                        <Icon.List />
                                        {t('list')}
                                    </ToggleButtonItem>
                                    <ToggleButtonItem value={true}>
                                        <Icon.Map />
                                        {t('map')}
                                    </ToggleButtonItem>
                                </ToggleButton>
                            )}
                        </div>
                        {!isLoading && !matchesToShow.length ? (
                            <h1 className="search__title h3">
                                {t('No accommodations available.')}
                            </h1>
                        ) : (
                            <h1 className="search__title h3">{metaTitle}</h1>
                        )}
                    </div>

                    <div className="search__results">
                        {(!isLoading || matches.length) &&
                        (!showMap || !isMobile) ? (
                            <>
                                <div
                                    className={`search__results-container${
                                        isLoading ? ' disabled' : ''
                                    }`}
                                >
                                    <Results
                                        f_amenities={state.f_amenities}
                                        matches={sortedMatches}
                                        onClick={onClick}
                                        hotItemId={hotItemId}
                                        onMouseEnter={onResultHover}
                                        onMouseLeave={() => {
                                            setHotItemId('');
                                        }}
                                    />
                                </div>
                                {!isLoading ? null : (
                                    <div className="search__loading-overlay">
                                        <div className="search__loading-container">
                                            <Loader
                                                size={30}
                                                bgColor="white"
                                                color="var(--primary)"
                                            />
                                        </div>
                                    </div>
                                )}
                            </>
                        ) : isLoading ? (
                            <div className="search__loader">
                                <Loader size={30} bgColor="white" />
                            </div>
                        ) : null}
                        {!isLoading &&
                        (!showMap || !isMobile) &&
                        matchesToShow.length < matches.length ? (
                            <button
                                className="search__more link"
                                onClick={() => setAmountShow(12)}
                                type="button"
                            >
                                {t('Show more recommendations')}
                            </button>
                        ) : null}
                    </div>
                </div>
                {true && (
                    <div
                        className={`search__map${
                            showMap || !isMobile ? '' : ' hidden'
                        }`}
                        inert={!showMap ? '' : null}
                    >
                        <Map
                            google={props.google}
                            matches={matchesToShow}
                            onClick={onClick}
                            hotItemId={hotItemId}
                            onMouseOverMarker={(e) => {
                                setHotItemId(e);
                            }}
                            hideMap={!showMap}
                            center={mapCenter}
                            zoom={meta.zoom}
                            radius={state.radius}
                            showRadius="donut"
                            containerStyle={{
                                height: isMobile ? '686px' : '100%',
                                position: 'relative',
                                marginTop: isMobile ? '-530px' : '',
                            }}
                            mapStyle={{
                                height: isMobile ? 660 : '100%',
                            }}
                            handleIdle={handleIdle}
                        />
                    </div>
                )}
            </div>
            {reactDOM.createPortal(
                <ModalHOC
                    isOpen={findMobileOpen}
                    clear={true}
                    openBtnRef={mobileOpenSearchBtnRef}
                    closeModal={closeMobileSearch}
                >
                    <MobileSearch closeMobileSearch={closeMobileSearch} />
                </ModalHOC>,
                document.getElementById('modal')
            )}
        </section>
    );
}
