import React, { useCallback, useState } from 'react'

import { useLocaleContext } from '../../core/context/localeContext';

import PillButton from '../../common/PillButton/PillButton.js';
import './SearchFilters.css';

export default function SearchFilters ({ meta, propertyAmenitiesFilter, setPropertyAmenitiesFilter, applySearchFilters, filtersOpen }) {

    const { t } = useLocaleContext();

    const [ isLoading, setIsLoading ] = useState(false);

    const amenityList = [
    {
        id: 'fitness',
        label: 'Fitness'
    },
    {
        id: 'breakfast',
        label: 'Breakfast'
    },
    {
        id: 'parking',
        label: 'Parking'
    },
    {
        id: 'restaurant',
        label: 'Restaurant'
    },
    {
        id: 'bar',
        label: 'Bar'
    },
    {
        id: 'pool',
        label: 'Pool'
    },
    {
        id: 'spa',
        label: 'Spa'
    },
    {
        id: 'accessibility',
        label: 'Accessibility'
    },
    {
        id: 'airco',
        label: 'Airco'
    },
    {
        id: 'free_wifi',
        label: 'Free Wifi'
    },
    {
        id: 'non_smoking',
        label: 'Non-smoking'
    },
    {
        id: 'pets_allowed',
        label: 'Pets Allowed'
    },
    ]
    const handleCheckboxChange = useCallback((evt) => {

        let name = evt.target.name
        let checked = evt.target.checked
        let newAmenities = propertyAmenitiesFilter.filter(e => e !== name)
        if (checked) newAmenities.push(name)

        setPropertyAmenitiesFilter(newAmenities)

    }, [ propertyAmenitiesFilter, setPropertyAmenitiesFilter ]);

    return (
        <div className={`search-filters__dropdown${filtersOpen ? ' visible' : ''}`}>
            <ul className="search-filters__list">
                {amenityList.map(amenity => <li key={amenity.id} className="search-filters__item input-container">
                    <label className="checkbox">
                        <input
                            type='checkbox'
                            name={amenity.id}
                            checked={(propertyAmenitiesFilter.indexOf(amenity.id) >= 0) ? true : false}
                            onChange={handleCheckboxChange}
                        />
                        <span className="checkbox__checkmark" />
                        <span className="checkbox__label">{t(amenity.label)}</span>
                    </label>
                </li>)}
            </ul>

            <div className="search-filters__footer">
                <button type="button" className="link" onClick={() => applySearchFilters([])}>{t('Remove all filters')}</button>
                <PillButton
                    className="btn btn--blue"
                    disabled={isLoading}
                    onClick={() => applySearchFilters(propertyAmenitiesFilter)}
                    isLoading={isLoading}
                >
                    {t('Apply filters')}
                </PillButton>
            </div>

        </div>
    );

};
