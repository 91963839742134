import React, { useEffect, useState } from 'react';

import { Icon } from '../../../../common/Icon/Icon';
import { useLocaleContext } from '../../../../core/context/localeContext';
import { useSearchContext } from '../../../../core/context/searchContext';
import LocationSearchMobile from '../../../LocationSearch/LocationSearchMobile';

import './MobileDestination.css';

const MobileDestination = ({ standalone, closeDestination }) => {
    const [errors, setErrors] = useState([]);
    const { t } = useLocaleContext();
    const { setDestinationMobileOpen, setFromMobileSearch } =
        useSearchContext();

    useEffect(() => {
        const handlePopState = () => {
            setDestinationMobileOpen(false);
        };

        window.addEventListener('popstate', handlePopState);
        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, [setDestinationMobileOpen, setFromMobileSearch]);

    return (
        <div className="mobile-destination">
            <div className="mobile-destination__header">
                {standalone ? (
                    <>
                        <span className="mobile-destination__header-placeholder" />
                        <span className="mobile-destination__title h4">
                            {t('Enter your destination')}
                        </span>
                        <button
                            className="mobile-destination__close"
                            type="button"
                            onClick={closeDestination}
                        >
                            <Icon.Plus className="icon-small r-45" />
                            <span className="sr-only">{t('close')}</span>
                        </button>
                    </>
                ) : (
                    <button
                        className="mobile-destination__back"
                        onClick={closeDestination}
                        type="button"
                    >
                        <Icon.Arrow className="icon-small flip-x" />
                        <span className="sr-only">{t('Back')}</span>
                    </button>
                )}
            </div>
            <div className="mobile-destination__body">
                <LocationSearchMobile
                    standalone={standalone}
                    setErrors={setErrors}
                />
            </div>
        </div>
    );
};

export default MobileDestination;
