
import {
    parseISO,
    format as formatDate,
    isSameDay,
} from 'date-fns';
import { Icon } from './common/Icon/Icon';

export function buildParams (data) {
    const params = new URLSearchParams()

    Object.entries(data).forEach(([ key, value ]) => {
        if (!value) return;
        if (Array.isArray(value)) {
            value.forEach(value => params.append(key, value.toString()))
        } else {
            params.append(key, value.toString())
        }
    });

    return params.toString()
}

export function Pluralize (amount, singular, plural) {
    amount = Number(amount)
    let result = `${amount}`;
    if (amount === 1) {
        result += ` ${singular}`
    } else {
        result += ` ${plural}`
    }

    return result;

}

export function OccupancyString ({ nights, adults, children, rooms, t }) {
    let result = [] // @Mutates

    if (adults) {
        result.push(t('%{count} adults', { count: adults }))
    }
    if (children) {
        result.push(t('%{count} children', { count: children }))
    }
    if (rooms) {
        result.push(t('%{count} rooms', { count: rooms }))
    }
    if (nights) {
        result.push(t('%{count} nights', { count: nights }))
    }

    return result.join(', ');
}

export function RoomOccupancyString (adults, childAges, t) {
    let result = [] //@mutates

    if (adults) {
        result.push(t('%{count} adults', { count: adults }))
    }

    if (childAges && childAges.length) {
        result.push(', ' + t('%{count} children', { count: childAges.length }))
        result.push(' <span>(' + t('ages %{ages}', { ages: childAges }) + ')</span>');
    }

    return result.join('');
}

export function IsValidEmail (email) {
    const regex =
        // eslint-disable-next-line no-useless-escape
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (!email || regex.test(email) === false) {
        return false;
    }
    return true;
};

export function IsValidYear (year) {
    const regex =
        /^\d{4}$/;
    if (!year || regex.test(year) === false) {
        return false;
    }
    return true;
};

export function isValidDate (d) {
    return d instanceof Date && !isNaN(d);
}


export function RangeArray (start, end, inclusive = true) {
    let result = []

    if (inclusive) end += 1;
    for (let i = start; i < end; i++) {
        result.push(i);
    }

    return result;
}

export function CompareArrays (a, b) {
    return (
        a.length === b.length &&
        a.every((value, index) => value === b[ index ])
    );
}

export function DeepCloneObject (a) {
    return JSON.parse(JSON.stringify(a))
}

export function DeepEqual (a, b) {
    return JSON.stringify(a) === JSON.stringify(b)
}

export function ObjectKeysToArray (a) {
    return Object.keys(a).map(e => a[ e ])
}

export function Difference (a, b) {
    let result = a.filter(el => !b.includes(el));
    return result;
}

export function StringifyArray (a) {
    let result = a.map(e => JSON.stringify(e));
    return result;
}

export function Overlap (a, b) {
    let result = a.filter(el => b.includes(el));
    return result
}

export function GeoJsonToLatLng (loc) {
    return {
        lat: loc.coordinates[ 1 ],
        lng: loc.coordinates[ 0 ]
    }
}
export const ToBool = (str) => {
    if (str === undefined || str === null) str = '';
    str = str.toString();
    switch (str.toLowerCase()) {
        case "false":
        case "no":
        case "0":
        case "": {
            return false;
        }

        default: {
            return true;
        }
    }
}

export function DotStringToObject (str, value) {

    let parts = str.split('.');
    let obj = {};
    let pointer = obj;
    for (let i = 0; i < parts.length; ++i) {
        let part = parts[ i ];
        pointer[ part ] = pointer[ part ] || {}
        if (i === parts.length - 1) pointer[ part ] = value;
        pointer = pointer[ part ]
    }

    return obj;
}

function IsObject (item) {
    return (item && typeof item === 'object' && !Array.isArray(item));
}

export function DeepMerge (target, ...sources) {
    // recursive
    if (!sources.length) return target;
    const source = sources.shift();

    if (IsObject(target) && IsObject(source)) {
        for (const key in source) {
            if (IsObject(source[ key ])) {
                if (!target[ key ]) Object.assign(target, { [ key ]: {} });
                DeepMerge(target[ key ], source[ key ]);
            } else {
                Object.assign(target, { [ key ]: source[ key ] });
            }
        }
    }

    return DeepMerge(target, ...sources);
}

export function chunkIntoN (arr, n) {
    let result = [];
    for (let i = n; i > 0; i--) {
        result.push(arr.splice(0, Math.ceil(arr.length / i)));
    }
    return result;
}

// function CreateEnum (values) {
//     const enumObject = {};
//     for (const val of values) {
//         enumObject[ val ] = val;
//     }
//     return Object.freeze(enumObject);
// }

export function setCookie (cname, cvalue, expire_days) {
    const d = new Date();
    d.setTime(d.getTime() + (expire_days * 24 * 60 * 60 * 1000));
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export function getCookie (cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[ i ];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

export function formatCancelPenalties (rate, t) {

    let result = { //@Mutates
        header: '',
        body: []
    }

    if (!rate.refundable) {
        result.header = <span className="notice caption"><Icon.Alert className="icon-xs"/>{t('Non-refundable')}</span>;
        result.body.push(<ul key={result.body.length}><li>
            {t('zero_cancellation_policy_text')}
        </li></ul>)
    } else {
        if (!rate.cancel_penalties) return null;

        let cancelPenalties = rate.cancel_penalties;

        let penalty = cancelPenalties[ 0 ]; // @hardcoded first policy

        result.header = <span className="confirmation caption"><Icon.Check />{t('Fully refundable before %{date}', {
            date: formatDate(
                parseISO(penalty.start),
                'd MMM yyyy'
            )
        })}</span>;

        let startTime = formatDate(new Date(penalty.start), 'd MMMM yyyy HH:mm')


        let body = result.body;
        // @Hardcoded This should be a loop since we can have multiple penalties
        if (penalty.amount) {
            body.push(
                <li key={body.length}>
                    {t("Cancellations made after %{startTime} will result in a %{penalty_amount} %{penalty_currency} fee.", { startTime: startTime, penalty_amount: penalty.amount, penalty_currency: penalty.currency })}
                </li>
            );
        } else if (penalty.percent) {
            body.push(
                <li key={body.length}>
                    {t("Cancellations made after %{startTime} will result in a %{penalty_percent} penalty of the stay charges and fees.", { startTime: startTime, penalty_percent: penalty.percent })}
                </li>
            );
        } else if (penalty.nights) {
            // also ignore if zero
            body.push(
                <li key={body.length}>
                    {t("Cancellations made after %{startTime} are subject to a %{penalty_nights} night penalty plus taxes and fees.", {
                        startTime: startTime, penalty_nights: penalty.nights
                    })}
                </li>
            );
        }

        if (false && penalty.end) {
            let endTime = formatDate(new Date(penalty.end), 'd MMMM yyyy HH:mm')

            body.push(
                <li key={body.length}>
                    {t("The end time for the cancellation window is %{endTime}, at which time the booking will become fully non-refundable.", { endTime })}
                </li>
            )
        }

        if (rate.nonrefundable_date_ranges) {

            let formattedDates = rate.nonrefundable_date_ranges.map(date_range => {
                let start = formatDate(new Date(date_range.start), 'd MMMM yyyy');
                let end = formatDate(new Date(date_range.end), 'd MMMM yyyy');
                return `${start} - ${end}`
            })

            body.push(
                <li key={body.length}>
                    {t('Any booked nights during the non-refundable date ranges (%{ranges}) are completely non-refundable from time of booking.', { ranges: formattedDates.join(' and ') })}
                </li>
            )
        }

        body.push(
            <li key={body.length}>
                {t("cancellation_policy_general")}
            </li>
        );

        result.body = <ul>{result.body}</ul>
    }

    return result;
}

export function formatDateString (dates) {

    let checkinDate = dates[ 0 ];
    let checkoutDate = dates[ 1 ]; //@mutates

    if (checkoutDate && isSameDay(checkinDate, checkoutDate)) {
        checkoutDate = null;
    }

    let checkinFormatted = (checkinDate) ? formatDate(checkinDate, 'yyyy-MM-dd') : ''
    let checkoutFormatted = (checkoutDate) ? formatDate(checkoutDate, 'yyyy-MM-dd') : ''

    let result = [ checkinFormatted, checkoutFormatted ]

    return result;

}

export function getCountryName (code, locale, type) {
    if (Intl.DisplayNames) {
        const displayNames = new Intl.DisplayNames([ locale ], { type });
        return displayNames.of(code);
    }
    return code.toUpperCase();
}

export function hasRoom(container, element, center) {
    const vw = Math.max(
        document.documentElement.clientWidth || 0,
        window.innerWidth || 0
    );

    return (
        vw >
        container?.getBoundingClientRect().left +
            (center ? container?.offsetWidth / 2 : 0) +
            element?.offsetWidth / (center ? 2 : 1) +
            24
    );
}

export function isEmpty(obj) {
    if (
        !obj ||
        (obj &&
            Object.keys(obj).length === 0 &&
            Object.getPrototypeOf(obj) === Object.prototype)
    ) {
        return true;
    }
    return false;
}

export function RoundNumberByDecimals(num, scale) {
    if(!("" + num).includes("e")) {
      return +(Math.round(Number(num + "e+" + scale))  + "e-" + scale);
    } else {
      var arr = ("" + num).split("e");
      var sig = ""
      if(+arr[1] + scale > 0) {
        sig = "+";
      }
      return +(Math.round(Number(+arr[0] + "e" + sig + (+arr[1] + scale))) + "e-" + scale);
    }
  }

export function sortObjKeys (unordered) {
    let out = {};
    if (Array.isArray(unordered)) {
        return unordered.map((item) => {
            return sortObjKeys(item);
        });
    }
    if (unordered instanceof Object) {
        Object.keys(unordered)
            .sort()
            .forEach((key) => {
                out[key] = sortObjKeys(unordered[key]);
            }, {});
        return out;
    }
    return unordered;
};

export function formatHotelPath({
    name,
    city,
    country,
    locale,
    translateToLocale,
}) {
    if (!name || !city || !country) return '';

    return `/hotel/${name
        .replace(/ - | \/ /g, '-')
        .replace(/,|\//g, '')
        .split(' ')
        .join('-')}-${translateToLocale(locale)('with-ev-charger')}-${city
        .split(' ')
        .join('-')}-${getCountryName(country, locale, 'region')
        .split(' ')
        .join('-')}`.toLowerCase();
}

export function debounce(func, waitFor) {
    let timeout;
    return (...args) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => func(...args), waitFor);
    };
}