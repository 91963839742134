import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { useLocaleContext } from '../../../core/context/localeContext';
import { useUserContext } from '../../../core/context/userContext';
import { Helmet } from 'react-helmet';

import RegistrationForm from '../../../Components/Registration/Registration/RegistrationForm';

const BASE_URL = process.env.REACT_APP_BASE_URL || 'https://www.sleepercharger.com';

const Registration = () => {
    const navigate = useNavigate();
    const { locale, t } = useLocaleContext();
    const { userDetails, setUserDetails } = useUserContext();

    const handleRegistrationResult = useCallback((result) => {
        console.log(result);
        if (result.status === 201) {
            setUserDetails({
                ...userDetails,
                email: result.data.email,
                status: result.data.status,
            });
            navigate('../profile');
        } else {
            console.error(result);
        }
    }, []);

    return (
        <>
            <Helmet>
                <title>{`${t('Create account')} | SleeperCharger`}</title>
                <meta
                    name="description"
                    content={`${t('Create account')} | SleeperCharger`}
                />
                <link
                    rel="canonical"
                    href={`${BASE_URL}${
                        locale !== 'en' ? `/${locale}` : ''
                    }/create-account`}
                />
            </Helmet>
            <section className="signup">
                <div className="wrap wrap--sm">
                    <div className="content-container">
                        <div className="signup__header">
                            <h1 className="signup__title h2">
                                {t('Create account')}
                            </h1>
                        </div>
                        <RegistrationForm
                            handleResult={handleRegistrationResult}
                        />
                    </div>
                </div>
            </section>
        </>
    );
};

export default Registration;
