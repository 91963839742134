import React, { useCallback } from 'react';

import { useLocaleContext } from '../core/context/localeContext';
import { DotStringToObject, DeepMerge } from '../helpers.js';

import { COUNTRY_INFO_BY_INDEX } from '../core/hooks/constants.js';
import PhoneField from './PhoneField/PhoneField';

export default function UserDetails({
    details,
    setDetails,
    handleCheckboxChange,
    isMobile,
}) {
    const { t } = useLocaleContext();

    const handleChange = useCallback(
        (evt) => {
            let name = evt.target.name;
            let value = evt.target.value;

            let property = DotStringToObject(name, value);

            let newDetails = DeepMerge({ ...details }, property);

            setDetails(newDetails);
        },
        [details, setDetails]
    );

    const phoneCountryOptions = COUNTRY_INFO_BY_INDEX.phone.map((e, i) => {
        return (
            <option key={i} value={e}>
                {COUNTRY_INFO_BY_INDEX.display[i]}
            </option>
        );
    });

    return (
        <>
            <div className="booking__header">
                <h2 className="booking__subtitle h3">
                    {t('Your personal details')}
                </h2>
            </div>
            <div className="booking__element-container">
                <div
                    style={{
                        display: 'grid',
                        gridTemplateColumns: isMobile ? '1fr' : '1fr 1fr',
                        gap: isMobile ? '0' : '20px',
                    }}
                >
                    <div className="input-container">
                        <label className="input-label">{t('First name')}</label>
                        <input
                            type="text"
                            autoComplete="off"
                            name="firstname"
                            value={details.firstname}
                            onChange={handleChange}
                            placeholder={t('First name')}
                            className="input-field"
                        />
                    </div>

                    <div className="input-container">
                        <label className="input-label">{t('Last name')}</label>
                        <input
                            autoComplete="off"
                            type="text"
                            name="lastname"
                            value={details.lastname}
                            onChange={handleChange}
                            placeholder={t('Last name')}
                            className="input-field"
                        />
                    </div>
                </div>

                <div className="input-container">
                    <label className="input-label">{t('E-mail address')}</label>
                    <input
                        autoComplete="off"
                        type="email"
                        name="email"
                        value={details.email}
                        onChange={handleChange}
                        placeholder={t('E-mail address')}
                        className="input-field"
                    />
                </div>

                <div className="input-container">
                    <label className="input-label">{t('Phone number')}</label>

                    <PhoneField
                        handleChange={handleChange}
                        countryOptions={phoneCountryOptions}
                        countryValue={details.phone_country_code}
                        phoneValue={details.phone}
                    />
                </div>

                <div className="input-container">
                    <label className="toggle">
                        <input
                            type="checkbox"
                            name={'is_business_trip'}
                            value={details.is_business_trip}
                            onChange={handleCheckboxChange}
                        />
                        <span className="toggle__label">
                            {t('Business trip')}
                        </span>
                        <span className="toggle__checkmark" />
                    </label>
                </div>

                {details.is_business_trip ? (
                    <>
                        <div className="input-container">
                            <label className="input-label">
                                {t('Company name')}
                            </label>
                            <input
                                autoComplete="off"
                                type="text"
                                name="company.name"
                                value={details.company.name}
                                onChange={handleChange}
                                placeholder={t('Company name')}
                                className="input-field"
                            />
                        </div>

                        <div className="input-container">
                            <label className="input-label">
                                {t('Country')}
                            </label>
                            <input
                                autoComplete="off"
                                type="text"
                                name="company.address.country_code"
                                value={details.company.address.country_code}
                                onChange={handleChange}
                                placeholder={t('Country')}
                                className="input-field"
                            />
                        </div>

                        <div className="input-container">
                            <label className="input-label">
                                {t('Company address')}
                            </label>
                            <input
                                autoComplete="off"
                                type="text"
                                name="company.address.line_1"
                                value={details.company.address.line_1}
                                onChange={handleChange}
                                placeholder={t('Street + house number')}
                                className="input-field"
                            />
                        </div>

                        <div
                            style={{
                                display: 'grid',
                                gridTemplateColumns: '2fr 5fr',
                                gap: 10,
                            }}
                        >
                            <div className="input-container">
                                <label className="input-label">
                                    {t('Zipcode')}
                                </label>
                                <input
                                    autoComplete="off"
                                    type="text"
                                    name="company.address.postal_code"
                                    value={details.company.address.postal_code}
                                    onChange={handleChange}
                                    placeholder={t('Postal code')}
                                    className="input-field"
                                />
                            </div>

                            <div className="input-container">
                                <label className="input-label">
                                    {t('City')}
                                </label>
                                <input
                                    autoComplete="off"
                                    type="text"
                                    name="company.address.city"
                                    value={details.company.address.city}
                                    onChange={handleChange}
                                    placeholder={t('City')}
                                    className="input-field"
                                />
                            </div>
                        </div>
                    </>
                ) : null}
            </div>
        </>
    );
}
