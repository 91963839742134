import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { compareDesc, parseISO } from 'date-fns';
import { useLocaleContext } from '../../../core/context/localeContext';
import useScreenSize from '../../../core/hooks/useScreenSize';
import DesktopSearchbar from '../../Search/DesktopSearchbar/DesktopSearchbar';
import MobileSearchButton from '../../Search/MobileSearch/MobileSearchButton/MobileSearchButton';
import BookingItem from '../BookingItem.js';

import './LatestBooking.css';

const LatestBooking = ({ userDetails }) => {
    const { isMobile } = useScreenSize();
    const { t } = useLocaleContext();
    const openBtnRef = useRef(null);

    const bookings = userDetails?.bookings ? [...userDetails.bookings] : [];

    const latestBooking = bookings.sort((a, b) =>
        compareDesc(parseISO(a.booking_created), parseISO(b.booking_created))
    )[0];

    return (
        <div className="latest-booking">
            <div className="latest-booking__header">
                <h2 className="h3">{t('Latest booking')}</h2>
                {!userDetails?.bookings.length ? null : (
                    <Link to="/bookings" className="latest-booking__link link">
                        {t('See all bookings')}
                    </Link>
                )}
            </div>
            {!bookings?.length ? (
                <>
                    <p className="latest-booking__desc">
                        {t(
                            "You don't have any bookings yet, search for your next trip here"
                        )}
                    </p>
                    {isMobile ? (
                        <MobileSearchButton ref={openBtnRef} />
                    ) : (
                        <DesktopSearchbar isCompact />
                    )}
                </>
            ) : (
                <BookingItem item={latestBooking} />
            )}
        </div>
    );
};

export default LatestBooking;
