import { useState, useEffect, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';
import Login from '../Login.js';
import { useUserContext } from '../../core/context/userContext';
import '../AdminMap/AdminMap.css';

export default function AdminDashboard (props) {
    const { userDetails, setUserDetails, loading } = useUserContext();
    const [ isLoading, setIsLoading ] = useState(false);


    const tryLogin = async (payload) => {
        setIsLoading(true);

        let result = await axios.post(
            `${process.env.REACT_APP_SC_API_BASE}/login2`,
            payload
        );

        setIsLoading(false);

        if (result.status === 200) {
            const token = result.data.user.token
            sessionStorage.setItem('authenticationToken', JSON.stringify(token));
            
        }
    };

    if (!userDetails.email) return (
        <div>
            <Login tryLogin={tryLogin} />
        </div>
    )

    return (
        <div className="admin-map">
            <div className="wrap">

                <div className="h4">Logged in as: {userDetails.email}</div>
                <div className="admin-map__container" style={{ marginTop: 20 }}>

                </div>
            </div>
        </div>
    );
}
