import { useMemo, useState } from 'react';
import { Icon } from '../../common/Icon/Icon';
import useScreenSize from '../../core/hooks/useScreenSize';
import { chunkIntoN, formatCancelPenalties } from '../../helpers';
import { useLocaleContext } from '../../core/context/localeContext';

import HeroSlider from '../HeroSlider/HeroSlider';
import './RoomDetails.css';

const RoomDetails = ({ room, amenities, info, bookBtn, rate }) => {
    const [showAmenities, setShowAmenities] = useState(false);
    const { isMobile } = useScreenSize();
    const { t } = useLocaleContext();

    const includesList = useMemo(
        () =>
            !amenities
                ? []
                : Array.isArray(amenities)
                ? chunkIntoN(amenities, 2)
                : [amenities],
        [amenities]
    );

    const amenitiesList = useMemo(
        () =>
            chunkIntoN(
                Object.values(room.allRoomAmenities).map((e) => (
                    <li
                        className="amenities__all-amenity-item caption"
                        key={e.id}
                    >
                        <Icon.Check className="icon-small" />
                        {e.name}
                    </li>
                )),
                2
            ),
        [room.allRoomAmenities]
    );

    // let cancelPolicy = formatCancelPenalties(rate, t);

    return (
        <div className="room-details">
            <HeroSlider item={room} isMobile={isMobile} />
            <div className="room-details__header">
                <div className="room-details__info">{info(true)}</div>
                {!isMobile && bookBtn ? bookBtn(t('Reserve this room')) : null}
            </div>
            {!includesList.length ? null : (
                <div className="room-details__includes">
                    {includesList.map((item, i) => (
                        <ul className="room-details__list caption" key={i}>
                            {item}
                        </ul>
                    ))}
                </div>
            )}

            {/* {!rate.cancel_penalties ? null : (
                <>
                    <div className="room-details__policy h4">
                        {cancelPolicy.header}
                    </div>
                    <div className="room-details__policy caption">
                        {cancelPolicy.body}
                    </div>
                </>
            )} */}

            {isMobile && bookBtn ? bookBtn(t('Reserve this room')) : null}
            {isMobile ? (
                <button
                    className="room-details__more link"
                    type="button"
                    onClick={() => setShowAmenities((prevState) => !prevState)}
                >
                    {showAmenities
                        ? 'Hide all aminities'
                        : 'Show all aminities'}
                    <Icon.Chevron
                        className="icon-small icon-right"
                        style={{ transform: showAmenities ? 'scaleY(-1)' : '' }}
                    />
                </button>
            ) : null}
            {!isMobile || showAmenities ? (
                <div className="room-details__amenities">
                    {amenitiesList.map((list, i) => (
                        <ul className="amenities__all-amenity-list" key={i}>
                            {list}
                        </ul>
                    ))}
                </div>
            ) : null}
        </div>
    );
};

export default RoomDetails;
