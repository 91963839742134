import React, { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Icon } from '../../../common/Icon/Icon';
import { useLocaleContext } from '../../../core/context/localeContext';

import './GalleryModal.css';

const GalleryModal = ({
    galleryParams,
    galleryItemBtnRef,
    images,
    closeGalleryModal,
}) => {
    const { t } = useLocaleContext();
    const [searchParams, setSearchParams] = useSearchParams();

    const openImage = useCallback(
        (index) => {
            if (galleryParams.gallery_item) return;
            searchParams.set('gallery_item', index);
            setSearchParams(searchParams);
        },
        [galleryParams.gallery_item, searchParams, setSearchParams]
    );

    return (
        <div className="gallery-modal">
            <div className="wrap wrap--sm">
                <button className="link" onClick={closeGalleryModal}>
                    <Icon.Arrow className="icon-small icon-left flip-x" />
                    {t('Back to overview')}
                </button>
            </div>

            <div className="gallery-modal__overflow">
                <div className="wrap wrap--sm">
                    <div className="gallery-modal__container">
                        {images.map((image, i) => (
                            <button
                                className="gallery-modal__img-btn"
                                type="button"
                                key={i}
                                onClick={(e) => {
                                    openImage(i + 1);
                                    galleryItemBtnRef.current = e.target;
                                }}
                            >
                                <span className="gallery-modal__img-container image-loader">
                                    <img
                                        className="gallery-modal__img cover-img"
                                        src={
                                            image.links['1000px']?.href ||
                                            image.links['350px']?.href
                                        }
                                        alt={image.caption}
                                        loading="lazy"
                                        decoding="async"
                                    />
                                </span>
                            </button>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GalleryModal;
