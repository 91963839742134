import React, { useMemo, useState } from 'react';
import { Icon } from '../../common/Icon/Icon';
import useScreenSize from '../../core/hooks/useScreenSize';
import { chunkIntoN } from '../../helpers';
import { useLocaleContext } from '../../core/context/localeContext';

import './Amenities.css';

const Amenities = ({ amenities }) => {
    const [ showAllAmenities, setShowAllAmenities ] = useState(false);
    const { isMobile } = useScreenSize();
    const { t } = useLocaleContext();

    // const icons = {
    //     '1073743315': <Icon.Bolt />,
    //     '2063': <Icon.Services />,
    //     '2074': <Icon.Swim />,
    //     '9': <Icon.Gym />,
    //     '19': <Icon.Food />,
    //     '3': <Icon.Bar />,
    //     '2137': null,
    // }

    const [ popularAmenities, otherAmenities ] = useMemo(() => {
        const popularIds = [
            '1073743315',
            '2063',
            '2074',
            '9',
            '19',
            '3',
            '2137',
        ];

        const getPopularAmenities = (items) => {
            const itemsCopy = [ ...items ];
            let popular = [];

            popularIds.forEach((id) => {
                const indexOfID = itemsCopy.map((item) => item.id).indexOf(id);
                if (indexOfID !== -1 && popular.length < 6) popular.push(itemsCopy.splice(indexOfID, 1)[ 0 ]);
            });

            return [ popular, itemsCopy ];
        };

        return getPopularAmenities(amenities);
    }, [ amenities ]);

    const popularAmenitiesList = useMemo(
        () =>
            isMobile ?
                chunkIntoN(
                    popularAmenities.map((e) => (
                        <li className="amenities__item" key={e.id}>
                            <Icon.Check className="icon-small" />
                            {e.name}
                        </li>
                    )),
                    1
                ) :
                chunkIntoN(
                    popularAmenities.map((e) => (
                        <li className="amenities__item" key={e.id}>
                            <Icon.Check className="icon-small" />
                            {e.name}
                        </li>
                    )),
                    3
                ),
        [ popularAmenities, isMobile ]
    );

    const otherAmenitiesList = useMemo(
        () =>
            chunkIntoN(
                otherAmenities.map((e) => (
                    <li className="amenities__all-amenity-item caption" key={e.id}>
                        <Icon.Check className="icon-small" />
                        {e.name}
                    </li>
                )),
                2
            ),
        [ otherAmenities ]
    );

    return (
        <div className="amenities">
            {popularAmenities.length > 0 ? (<>
                <h2 className="amenities__title h3">{t('Popular Amenities')}</h2>
                <div className="amenities__list-container">
                    {popularAmenitiesList.map((list, i) => (
                        <ul className="amenities__list" key={i}>
                            {list}
                        </ul>
                    ))}
                </div>
            </>) : null}
            <button
                className="amenities__more link"
                type="button"
                onClick={() => setShowAllAmenities((prevState) => !prevState)}
            >
                {showAllAmenities ? t('Hide all amenities') : t('Show all amenities')}
                <Icon.Chevron
                    className="icon-small icon-right"
                    style={{ transform: showAllAmenities ? 'scaleY(-1)' : '' }}
                />
            </button>
            {showAllAmenities ? (
                <div className="amenities__all-amenities">
                    <h2 className="amenities__title h3">{t('All Amenities')}</h2>
                    <div className="amenities__all-amenity-container">
                        <h3 className="amenities__subtitle h4">
                            {t('Property amenities')}
                        </h3>
                        <div className="amenities__list-container amenities__list-container--all">
                            {otherAmenitiesList.map((list, i) => (
                                <ul className="amenities__all-amenity-list" key={i}>
                                    {list}
                                </ul>
                            ))}
                        </div>
                    </div>
                </div>
            ) : null}
        </div>
    );
};

export default Amenities;
