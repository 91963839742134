import React from 'react';

import { useLocaleContext } from '../../../../core/context/localeContext';
import { useSearchContext } from '../../../../core/context/searchContext';
import { OccupancyString } from '../../../../helpers';
import { Icon } from '../../../../common/Icon/Icon';
import RoomLayout from '../../../RoomLayout/RoomLayout';
import { FIELDS } from '../MobileSearch';

const MobileOccupancy = ({ activeField, openOccupancy, error }) => {
    const { state } = useSearchContext();
    const { t } = useLocaleContext();

    return activeField !== FIELDS.occupancy ? (
        <button
            className="mobile-search__btn"
            type="button"
            onClick={openOccupancy}
        >
            <span
                className={`mobile-search__btn-label form-label${
                    error?.name === 'childrenAge' ? ' error' : ''
                }`}
            >
                {t('Who?')}
            </span>
            <span className="mobile-search__btn-value">
                {OccupancyString({
                    adults: state.adults,
                    t,
                })}
                {', '}
                {state.child_age.length ? (
                    <>
                        {OccupancyString({
                            children: state.child_age.length,
                            t,
                        })}
                        {', '}
                    </>
                ) : null}
                {OccupancyString({
                    rooms: state.rooms,
                    t,
                })}
            </span>
        </button>
    ) : (
        <div className="mobile-search__btn active">
            <span
                className={`mobile-search__btn-label form-label${
                    error?.name === 'childrenAge' ? ' error' : ''
                }`}
            >
                {t('Who?')}
            </span>
            <RoomLayout />
            {error?.name === 'childrenAge' ? (
                <div
                    className="visible error-container"
                    id="error-box"
                >
                    <div className="error-item">
                        <Icon.Alert className="icon-small" />
                        <span>{t(error?.value)}</span>
                    </div>
                </div>
            ) : null}
        </div>
    );
};

export default MobileOccupancy;
