import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import qs from 'qs';

import { Link } from 'react-router-dom';
import TextBlock from '../../Components/TextBlock';

import './Legal.css';
import { useLocaleContext } from '../../core/context/localeContext';
import useLoader from '../../core/hooks/useLoader';

const BASE_URL = process.env.REACT_APP_BASE_URL || 'https://www.sleepercharger.com';

function Legal(props) {
    const pageId = props.pageId;
    const [legal, setLegalContent] = useState({});
    const { locale, urlPrefix } = useLocaleContext();
    useLoader(Object.keys(legal).length);

    useEffect(() => {
        const getLegal = async () => {
            const query = qs.stringify(
                {
                    locale: locale,
                    populate: {
                        pageMetaTerms: '*',
                        pageMetaPrivacy: '*',
                    },
                },
                {
                    encodeValuesOnly: true, // prettify URL
                }
            );

            await axios
                .get(`${process.env.REACT_APP_API_URL}/api/legal?${query}`, {
                    headers: {
                        Authorization: process.env.REACT_APP_API_KEY,
                    },
                })
                .then((res) => {
                    const legalResponse = res.data.data.attributes;
                    setLegalContent({
                        'terms-and-conditions': {
                            title: legalResponse.titleTermsAndConditions,
                            content: legalResponse.textTermsAndConditions,
                            pageMeta: legalResponse.pageMetaTerms,
                        },
                        'privacy-policy': {
                            title: legalResponse.titlePrivacy,
                            content: legalResponse.textPrivacy,
                            pageMeta: legalResponse.pageMetaPrivacy,
                        },
                    });
                })
                .catch((err) => {
                    // console.error('err', err);
                    setLegalContent(null);
                });
        };
        if (locale) getLegal();
    }, [locale]);

    return (
        legal[pageId] && (
            <>
                <Helmet>
                    <title>
                        {legal[pageId]?.pageMeta?.title || 'Legal'} |
                        SleeperCharger
                    </title>
                    <meta
                        name="description"
                        content={
                            legal[pageId]?.pageMeta?.description ||
                            'Hotel with guaranteed electric vehicle chargers. Book now only at SleeperCharger.'
                        }
                    />
                    <link
                        rel="canonical"
                        href={
                            legal[pageId]?.pageMeta?.canonical ||
                            `${BASE_URL}${
                                locale !== 'en' ? `/${locale}` : ''
                            }/${pageId}`
                        }
                    />
                </Helmet>
                <section className="privacy">
                    <div className="wrap wrap--xs">
                        <div className="privacy__nav">
                            <Link
                                to={`${urlPrefix}/terms-and-conditions`}
                                className={`privacy__btn btn ${
                                    pageId === 'terms-and-condition'
                                        ? 'btn--green'
                                        : 'btn--grey'
                                }`}
                            >
                                {legal['terms-and-conditions'] &&
                                    legal['terms-and-conditions'].title}
                            </Link>
                            <Link
                                to={`${urlPrefix}/privacy-policy`}
                                className={`privacy__btn btn ${
                                    pageId === 'privacy-policy'
                                        ? 'btn--green'
                                        : 'btn--grey'
                                }`}
                            >
                                {legal['privacy-policy'] &&
                                    legal['privacy-policy'].title}
                            </Link>
                        </div>
                        <h1 className="privacy__title h1">
                            {legal[pageId] && legal[pageId].title}
                        </h1>
                        <div className="privacy__content ">
                            <TextBlock
                                content={legal[pageId] && legal[pageId].content}
                                alignment="left"
                                fullWidth={true}
                            />
                        </div>
                    </div>
                </section>
            </>
        )
    );
}

export default Legal;
