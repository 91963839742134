import localeEN from './locale-en.json'

const SHOW_DEBUG = process.env.REACT_APP_SHOW_DEBUG
const SERVER_BASE_URL = process.env.REACT_APP_SC_API_BASE

let fallbackLanguage = 'en'

const getLocale = async (url) => {
    const res = await fetch(url);
    const data = await res.json();
    return data;
}

let translations = {
    'en': localeEN,
    'de': undefined,
    'nl': undefined,
}

getLocale(`${SERVER_BASE_URL}/locale/nl`).then(res => {translations.nl = res});
getLocale(`${SERVER_BASE_URL}/locale/de`).then(res => {translations.de = res});

function filterVariables (str) {
    let results = [];
    let re = /\%{([^}]+)}/g;
    let text;
    while (text = re.exec(str)) {
        results.push(text[ 1 ]);
    }
    return results;
}

function replaceVariables (message, variables = {}) {

    Object.keys(variables).forEach(key => {
        let value = variables[ key ]

        if (typeof message === 'object') {
            // this has child nodes like 'one' and 'other'
            if (typeof value === 'number') {
                // if the replacement value is a number, check for plurals
                if (value === 1) {
                    message = message.one
                } else {
                    message = message.other
                }
            } else if (Array.isArray(value)) {
                // if the replacement value is an array, use the plural based on length,
                // and join the values in the array with a comma
                if (value.length === 1) {
                    message = message.one
                } else {
                    message = message.other
                }

                value = value.join(', ')
            } else {
                console.log(`value is not a supported type (${typeof value}), message: ${message.one}`)
                return '';
            }
        } else if (Array.isArray(message)) {
            // TODO this is a list item, replace per item?
            // for now we return as is
            return message
        }

        message = message.replace(`%{${key}}`, value)
    })
    return message;
}

export default function translate (locale) {
    let translation = translations[ locale ]
    if (!translation) {
        if (SHOW_DEBUG) {
            console.log({ locale, msg: `Did not find locale: ${locale}, using fallback language: ${fallbackLanguage}` })
        }
        translation = translations[ fallbackLanguage ]
    }

    return (id, variables = {}) => {
        let message = translation.messages[ id ]
        if (!message) {
            // try fallback
            if (SHOW_DEBUG) {
                console.log({ id, locale, msg: `Did not find message id: ${id} in locale: ${locale}, trying fallback language: ${fallbackLanguage}.` })
            }
            message = translations[ fallbackLanguage ].messages[ id ]

            if (SHOW_DEBUG && message) {
                // if we fell back, tell us in debug mode, so we know this string is known pending translation
                message = '_' + message;
            }
        }
        if (!message) {
            // if we still did not find anything, return the id itself?
            if (SHOW_DEBUG) {
                console.log({ id, msg: `Did not find message id: ${id} in any locales.` })
            }
            message = id
        }


        let result = replaceVariables(message, variables)

        return result
    }
};