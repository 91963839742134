import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';

import { useLocaleContext } from '../../core/context/localeContext';
import { useSearchContext } from '../../core/context/searchContext';

import SuggestedLocationItem from '../SuggestionsContainer/SuggestedLocationItem/SuggestedLocationItem';

let predictionOrderNr = 0;
let autoCompleteService;

const LocationPredictions = ({
    onLocationClick,
    predictions,
    setPredictions,
}) => {
    let google = window.google;
    const GOOGLE_SUCCESS_STATUS = google.maps.places.PlacesServiceStatus.OK;
    const { state } = useSearchContext();
    const { locale } = useLocaleContext();
    const [ searchTimerId, setSearchTimerId ] = useState(null);


    useEffect(() => {
        autoCompleteService = new google.maps.places.AutocompleteService();
        predictionOrderNr = 0;
    }, [google]);

    const fetchPredictions = useCallback(() => {
        // NOTE(gb) we use a closure to keep track of the order here because Google does not return the callbacks in order.
        predictionOrderNr++;
        if (state.location) {
            autoCompleteService.getPlacePredictions(
                {
                    input: state.location,
                    language: locale,
                    types: ['(cities)'],
                    bounds: {
                        north: 75,
                        south: 20,
                        west: -20,
                        east: 60,
                    },
                },
                // use closure to add in the ordernumber
                ((orderNr) => {
                    return async (predictions, status) => {
                        if (orderNr === predictionOrderNr) {
                            // TODO better error handling
                            predictions = predictions || [];

                            let formattedPredictions = predictions.map((e) => {
                                return {
                                    description: e.description,
                                    place_id: e.place_id,
                                    structured_formatting:
                                        e.structured_formatting,
                                };
                            });

                            // NOTE TEMP continue if we dont get any results from google back
                            // if (status !== GOOGLE_SUCCESS_STATUS && status !== GOOGLE_ZERO_RESULTS) {
                            //     setPredictions([]);
                            //     return [];
                            // }

                            if (formattedPredictions.length < 5) {
                                // NOTE(gb) we use a closure to keep track of the order here because calls do not return in order.
                                // predictionOrderNr++;
                                await (async (orderNr) => {
                                    let suppliersPredictions =
                                        await fetchSupplierPredictions();

                                    if (orderNr === predictionOrderNr) {
                                        let cappedPredictions =
                                            suppliersPredictions.slice(
                                                0,
                                                5 - predictions.length
                                            );

                                        formattedPredictions =
                                            formattedPredictions.concat(
                                                cappedPredictions
                                            );
                                    }
                                })(predictionOrderNr);
                            }

                            // @TODO FIXME when we get to here, the predictionOrder can have changed again
                            if (orderNr === predictionOrderNr) {
                                setPredictions(formattedPredictions);
                            }
                            return formattedPredictions;
                        }
                    };
                })(predictionOrderNr)
            );
        } else {
            setPredictions([]);
            return [];
        }
    }, [state.location, locale, GOOGLE_SUCCESS_STATUS]);

    const fetchSupplierPredictions = useCallback(async () => {
        let url = `${process.env.REACT_APP_SC_API_BASE}/suggest`;
        let result = await axios.post(url, {
            text: state.location,
        });

        if (result.status === 200) {
            return result.data;
        }

        // @TODO error handling
    }, [state.location, locale, GOOGLE_SUCCESS_STATUS]);

    // Call these functions when user changes the input
    useEffect(() => {
        const SEARCH_TIMER_IN_MS = 50;
        if (searchTimerId) {
            clearTimeout(searchTimerId);
        }

        let timerId = window.setTimeout(() => {
            fetchPredictions();
        }, SEARCH_TIMER_IN_MS);
        setSearchTimerId(timerId);
    }, [fetchPredictions]);

    return (
        <>
            {predictions?.map((prediction, index) => (
                <SuggestedLocationItem
                    key={index}
                    description={prediction.description}
                    structured_formatting={prediction.structured_formatting}
                    prediction={prediction}
                    index={index}
                    onLocationClick={onLocationClick}
                />
            ))}
        </>
    );
};

export default LocationPredictions;
