import { Link } from 'react-router-dom';
import { useLocaleContext } from '../core/context/localeContext';
import useScreenSize from '../core/hooks/useScreenSize';
import { ReactComponent as LogoDesktop } from '../images/logo-desktop.svg';
import { ReactComponent as LogoMobile } from '../images/logo-mobile.svg';

function Logo() {
    const { isMobile } = useScreenSize();
    const { urlPrefix, t } = useLocaleContext();

    return (
        <Link to={`${urlPrefix}/`} className="nav__logo">
            {isMobile ? <LogoMobile /> : <LogoDesktop />}
            <span className="sr-only">{t('home page')}</span>
        </Link>
    );
}

export default Logo;
