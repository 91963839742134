import { Link } from 'react-router-dom';
import DraftTag from '../DraftTag/DraftTag';

// import { format as formatDate } from 'date-fns';
// import enLocale from 'date-fns/locale/en-US';

import './Card.css';

const UPLOADS_URL = process.env.REACT_APP_UPLOADS_URL || '';

function Card(props) {
    // const date = new Date(props.date);

    //check if large format is available, if not, use the default image
    const imageUrl = props.image ?
        props.image.formats && props.image.formats.large ?
            `${UPLOADS_URL}${props.image.formats.large.url}`
            :
            `${UPLOADS_URL}${props.image.url}`
        : "/../images/blog-image-default.png";

    return (
        <Link to={props.to} key={props.id} className="card" aria-label={props.title}>
            <div className="card__container">
                <div className="card__img-container">
                    <img
                        className="card__img cover-img"
                        src={imageUrl}
                        alt={props.image && props.image.alternativeText}
                    />
                </div>
                <div className="card__content">
                    {props.draft && <DraftTag type='card' />}
                    <div className="card__header">
                        <span className="caption">{props.subtitle}</span>
                        {/* <time
                            className="card__date caption"
                            dateTime={formatDate(date, 'd MMMM yyyy', {
                                locale: enLocale,
                            })}
                        >
                            {' '}
                            {formatDate(date, 'dd MMMM yyyy', {
                                locale: enLocale,
                            })}
                        </time> */}
                    </div>
                    <h3 className="card__title cta">{props.title}</h3>
                </div>
            </div>
        </Link>
    );
}

export default Card;
