import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import axios from 'axios';

import Loader from '../../../common/Loader/Loader';
import { useLocaleContext } from '../../../core/context/localeContext';
import CustomInput from '../../../common/CustomInput/CustomInput';

export default function NewPassword({ handleResult }) {
    const navigate = useNavigate();
    const { hash } = useParams();

    const [loading, setLoading] = useState(false);
    const { t } = useLocaleContext();
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState({
        password: '',
    });

    // Send the new password
    const handleSubmit = useCallback(() => {
        const func = async () => {
            setLoading(true);

            const payload = {
                password,
            };

            let result = await axios.post(
                `${process.env.REACT_APP_SC_API_BASE}/user/reset-password/${hash}`,
                payload
            );

            setLoading(false);

            // if all is well, we get the new token immediately and are logged in
            handleResult(result);
        };

        func().catch((err) => {
            setLoading(false);
            console.error(err);
        });
    }, [navigate, password]);

    // validate form
    const handleFormSubmit = useCallback(
        (e) => {
            e.preventDefault();
            let errorNumb = 0;

            if (!password) {
                errorNumb++;
                setErrors((prevState) => {
                    return { ...prevState, password: 'empty' };
                });
            } else if (password.length < 8) {
                errorNumb++;
                setErrors((prevState) => {
                    return { ...prevState, password: 'invalid' };
                });
            }

            if (errorNumb === 0) handleSubmit();
        },
        [handleSubmit, password]
    );

    return (
        <>
            <div className="signup__header">
                <h1 className="signup__title h2">{t('Create new password')}</h1>
                <p className="signup__desc h4">{t('Enter a new password')}</p>
            </div>
            <form onSubmit={handleFormSubmit}>
                <CustomInput
                    id="password"
                    label="Password"
                    name="password"
                    type="password"
                    value={password}
                    autoComplete="off"
                    onChange={(e) => {
                        setPassword(e.target.value);
                        setErrors((prevState) => {
                            return {
                                ...prevState,
                                password: '',
                            };
                        });
                    }}
                    placeholder="Password"
                    error={errors.password}
                    emptyMessage="Please fill in your new password"
                    invalidMessage="Please enter a valid password"
                    info="Password should contain minimally 8 characters, etc."
                />

                <button
                    className="signup__btn btn btn--blue"
                    disabled={loading}
                >
                    {t('Save new password')}
                    {loading ? (
                        <Loader
                            size={20}
                            bgColor={'var(--secondary90)'}
                            color={'var(--primary)'}
                            className="icon-right"
                        />
                    ) : null}
                </button>
            </form>
        </>
    );
}
