import React, { useCallback, useEffect, useMemo, useState } from 'react';
import CustomDropdown from '../../common/Dropdown/CustomDropdown';
import { useLocaleContext } from '../../core/context/localeContext';

const AvailabilityChart = ({ checkinWeekday, occupancy }) => {
    const { t } = useLocaleContext();
    const times = useMemo(
        () => [
            '06:00Z',
            '09:00Z',
            '12:00Z',
            '15:00Z',
            '18:00Z',
            '21:00Z',
            '00:00Z',
            '03:00Z',
        ],
        []
    );
    const weekdayLabels = useMemo(
        () => [
            'Sunday',
            'Monday',
            'Tuesday',
            'Wednesday',
            'Thursday',
            'Friday',
            'Saturday',
        ],
        []
    );
    const labels = useMemo(
        () => [
            '00:00',
            '03:00',
            '06:00',
            '09:00',
            '12:00',
            '15:00',
            '18:00',
            '21:00',
            '00:00',
        ],
        []
    );
    const [weekday, setWeekday] = useState(
        checkinWeekday < 0 ? 1 : checkinWeekday === 0 ? 7 : checkinWeekday
    );
    const [weekdaysOccupancy, setWeekdaysOccupancy] = useState(
        occupancy.filter((item) => {
            return (
                item.weekday === weekday &&
                times.some((time) => item.period_begin.includes(time))
            );
        })
    );

    const indexes = useMemo(() => [0, 1, 2, 3, 4, 5, 6, 7, 0, 0], []);

    const values = useMemo(
        () => indexes.map((index) => weekdaysOccupancy[index].occupancy),
        [indexes, weekdaysOccupancy]
    );

    const coords = [18, 107, 198, 287, 377, 468, 556, 646, 735, 752];
    const convertedValues = useMemo(
        () => values.map((value) => 5 + value * 5.5),
        [values]
    );
    const [valueToUse, setValueToUse] = useState([
        64, 64, 64, 64, 64, 64, 64, 64, 64, 64,
    ]);

    const [indicator, setIndicator] = useState({
        visible: false,
        value: valueToUse[0],
        coords: {
            x: 0,
            y: valueToUse[0],
        },
    });

    useEffect(() => {
        setIndicator((prevState) => ({
            ...prevState,
            coords: {
                ...prevState.coords,
                y: valueToUse[0],
            },
        }));
    }, [valueToUse]);

    useEffect(() => {
        setValueToUse(convertedValues);
    }, [convertedValues]);

    useEffect(() => {
        setWeekdaysOccupancy(
            occupancy.filter((item) => {
                return (
                    item.weekday === weekday &&
                    times.some((time) => item.period_begin.includes(time))
                );
            })
        );
    }, [occupancy, times, weekday]);

    const computeControlPoints = (K) => {
        const p1 = [];
        const p2 = [];
        const n = K.length - 1;

        /*rhs vector*/
        const a = [];
        const b = [];
        const c = [];
        const r = [];

        /*left most segment*/
        a[0] = 0;
        b[0] = 2;
        c[0] = 1;
        r[0] = K[0] + 2 * K[1];

        /*internal segments*/
        for (let i = 1; i < n - 1; i++) {
            a[i] = 1;
            b[i] = 4;
            c[i] = 1;
            r[i] = 4 * K[i] + 2 * K[i + 1];
        }

        /*right segment*/
        a[n - 1] = 2;
        b[n - 1] = 7;
        c[n - 1] = 0;
        r[n - 1] = 8 * K[n - 1] + K[n];

        /*solves Ax=b with the Thomas algorithm (from Wikipedia)*/
        for (let i = 1; i < n; i++) {
            const m = a[i] / b[i - 1];
            b[i] = b[i] - m * c[i - 1];
            r[i] = r[i] - m * r[i - 1];
        }

        p1[n - 1] = r[n - 1] / b[n - 1];
        for (let i = n - 2; i >= 0; --i) {
            p1[i] = (r[i] - c[i] * p1[i + 1]) / b[i];
        }

        /*we have p1, now compute p2*/
        for (let i = 0; i < n - 1; i++) {
            p2[i] = 2 * K[i + 1] - p1[i + 1];
        }

        p2[n - 1] = 0.5 * (K[n] + p1[n - 1]);

        return { p1: p1, p2: p2 };
    };

    const getPath = (px1, py1, px2, py2, x2, y2) => {
        return (
            ' C ' +
            px1 +
            ' ' +
            py1 +
            ', ' +
            px2 +
            ' ' +
            py2 +
            ', ' +
            x2 +
            ' ' +
            y2 +
            ' '
        );
    };

    const px = computeControlPoints(coords);
    const py = computeControlPoints(valueToUse);

    let path = `M 0 ${valueToUse[0]}`;

    for (let i = 0; i < coords.length - 1; i++) {
        path += getPath(
            px.p1[i],
            py.p1[i],
            px.p2[i],
            py.p2[i],
            coords[i + 1],
            valueToUse[i + 1]
        );
    }

    const fillPath = path + ` V 70 H 0 V ${valueToUse[0]}`;

    const dropdownItems = [
        {
            label: t('Monday'),
            value: 1,
        },
        {
            label: t('Tuesday'),
            value: 2,
        },
        {
            label: t('Wednesday'),
            value: 3,
        },
        {
            label: t('Thursday'),
            value: 4,
        },
        {
            label: t('Friday'),
            value: 5,
        },
        {
            label: t('Saturday'),
            value: 6,
        },
        {
            label: t('Sunday'),
            value: 7,
        },
    ];

    const moveIndicator = useCallback(
        (coord, i) => {
            setIndicator((prevState) => ({
                ...prevState,
                value: 100 - values[i] * 10,
                coords: {
                    x: `calc(100% * ${coord} / 752)`,
                    y: valueToUse[i],
                },
            }));
        },
        [valueToUse, values]
    );

    return (
        <div className="availability-chart">
            <div className="availability-chart__header">
                <div>
                    <h3 className="charger-details__title h3">
                        {t('Predicted availability')}
                    </h3>
                    {checkinWeekday < 0 ? null : (
                        <div>
                            {t('You are arriving on a %{day}', {
                                day: t(weekdayLabels[checkinWeekday]),
                            })}
                        </div>
                    )}
                </div>
                <CustomDropdown
                    handleChange={(e, value) => setWeekday(value)}
                    items={dropdownItems}
                    selected={weekday}
                />
            </div>
            <div className="availability-chart__container">
                <div className="availability-chart__scale caption">
                    <span>100%</span>
                    <span>0%</span>
                </div>
                <div className="availability-chart__overlay-container">
                    <div className="availability-chart__overflow">
                        <div className="availability-chart__chart-container">
                            <svg
                                className="availability-chart__chart"
                                viewBox="0 0 752 70"
                                xmlns="http://www.w3.org/2000/svg"
                                preserveAspectRatio="none"
                            >
                                <linearGradient
                                    id="gradient"
                                    x1="0"
                                    x2="70"
                                    gradientUnits="userSpaceOnUse"
                                    gradientTransform="rotate(90)"
                                >
                                    <stop offset="0.02" stopColor="#20F58F" />
                                    <stop offset="0.36" stopColor="#F8D65E" />
                                    <stop offset="0.86" stopColor="#FF965B" />
                                    <stop offset="1.18" stopColor="#F52020" />
                                </linearGradient>
                                <path
                                    d={path}
                                    strokeWidth={3}
                                    stroke="url(#gradient)"
                                    fill="transparent"
                                    strokeLinecap="round"
                                />
                                <path
                                    d={fillPath}
                                    stroke="transparent"
                                    fill="url(#gradient)"
                                    style={{
                                        opacity: 0.2,
                                        transform: 'translateY(1px)',
                                    }}
                                />
                            </svg>
                            <div className="availability-chart__labels">
                                {labels.map((label, i) => {
                                    return (
                                        <span
                                            key={i}
                                            className="availability-chart__label caption"
                                        >
                                            {label}
                                        </span>
                                    );
                                })}
                            </div>
                            <div
                                className="availability-chart__overlay"
                                onMouseEnter={() =>
                                    setIndicator((prevState) => ({
                                        ...prevState,
                                        visible: true,
                                    }))
                                }
                                onMouseLeave={() =>
                                    setIndicator((prevState) => ({
                                        ...prevState,
                                        visible: false,
                                    }))
                                }
                            >
                                <span
                                    className="availability-chart__overlay-indicator caption"
                                    style={{
                                        opacity: indicator.visible ? 1 : 0,
                                        left: indicator.coords.x,
                                        top: indicator.coords.y,
                                    }}
                                >
                                    {indicator.value}% {t('chance')}
                                </span>
                                {coords.map((coord, i) => {
                                    if (i === coords.length - 1) return null;
                                    return (
                                        <div
                                            key={i}
                                            className="availability-chart__overlay-section"
                                            onMouseOver={() =>
                                                moveIndicator(coord, i)
                                            }
                                        />
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AvailabilityChart;
