import { React, useContext, useMemo, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import Navigation from './Components/Navigation/Navigation.js';
import ScrollToTop from './Components/ScrollToTop';
import Footer from './Components/Footer/Footer.js';

import './App.css';
import useScreenSize from './core/hooks/useScreenSize.js';
import { useLocaleContext } from './core/context/localeContext.js';
import { useEffect } from 'react';
import { useHeaderFooterDataContext } from './core/context/headerFooterDataContext.js';

import PageLayout from './Pages/PageLayout';
import PageNotFound from './Components/PageNotFound.js';
import { useDaytimeContext } from './core/context/daytimeContext.js';
import { ThemeContext } from './providers/ThemeProvider.js';
import { Helmet } from 'react-helmet';
import { Divider, POSITION } from './common/Divider/Divider.js';
import { debounce } from './helpers.js';
import PageLoader from './Components/PageLoader/PageLoader.js';

export default function App(props) {
    const { headerFooterData, season } = useHeaderFooterDataContext();
    const { isMobile } = useScreenSize();
    const { lang, pageSlug } = useParams();
    const { setUrlPrefix, locale, setLocale, setPreview, locales, setLocales } =
        useLocaleContext();
    const navigate = useNavigate();
    const langPattern = useMemo(() => /^(en|de|nl)$/, []);
    const [notFound, setNotFound] = useState();
    const { isDark } = useDaytimeContext();
    const { pageColor } = useContext(ThemeContext);

    useEffect(() => {
        if (headerFooterData) {
            setLocales(headerFooterData.availableLanguages);
        }
    }, [headerFooterData, setLocales]);

    const pageID = useMemo(() => pageSlug ? pageSlug : '', [pageSlug]);

    useEffect(() => {
        setPreview(props.preview);
        if (!lang) {
            setLocale('en');
            setUrlPrefix('');
            setNotFound(false);
            return;
        }
        if (!langPattern.test(lang)) {
            setLocale('en');
            setUrlPrefix('');
            setNotFound(true);
        } else {
            setLocale(lang);
            setNotFound(false);
            setUrlPrefix(props.preview ? `/preview/${lang}` : `/${lang}`);
        }
    }, [lang, setLocale, langPattern, locales, navigate]);

    const cssIsMobile = isMobile ? ' is-mobile' : '';
    const cssisDark = isDark ? ' is-dark' : '';
    const cssBackground =
        pageColor === 'background-color' ? ' light-background' : '';

    let content = <PageLayout />;

    if (notFound) {
        content = <PageLayout page={<PageNotFound />} />;
    } else {
        //(merijn): check to show content only if locale and pageSlug are both processed. Can this be done better?
        if (
            locale &&
            pageID != undefined &&
            (pageID === '' || pageID == pageSlug)
        ) {
            content = <Outlet context={[pageID]} />;
        }
    }

    const darkColors = useMemo(
        () => ({
            '--primary': '#062442',
            '--primary90': '#0D467E',
            '--primary80': '#2466A8',
            '--primary70': '#2E7ECD',
            '--primary60': '#71A8DD',
            '--primary50': '#99BEE1',
        }),
        []
    );

    useEffect(() => {
        if (isDark) {
            for (const color in darkColors) {
                document.documentElement.style.setProperty(
                    color,
                    darkColors[color]
                );
            }
        }

        return () => {};
    }, [isDark, darkColors]);

    const handleResize = debounce(() => {
        document.documentElement.style.setProperty(
            '--vh',
            `${window.innerHeight * 0.01}px`
        );
        document.documentElement.style.setProperty(
            '--vw',
            `${window.innerWidth * 0.01}px`
        );
    }, 100);

    useEffect(() => {
        document.documentElement.style.setProperty(
            '--vh',
            `${window.innerHeight * 0.01}px`
        );
        document.documentElement.style.setProperty(
            '--vw',
            `${window.innerWidth * 0.01}px`
        );

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        document.body.style.overflow = '';
        document.body.style.inset = '';
        document.body.style.insetInlineEnd = '';
        document.body.style.position = '';
    }, [navigate]);

    return (
        <div
            id="content"
            className={`content${cssIsMobile}${cssisDark}${cssBackground}`}
        >
            <Helmet htmlAttributes={{ lang: locale }}></Helmet>

            <PageLoader />

            <Navigation
                isMobile={isMobile}
                navItems={headerFooterData && headerFooterData.navMenu}
                showLangSelector={
                    headerFooterData && headerFooterData.showLanguageSelector
                }
                pageNotFound={notFound}
            />
            <ScrollToTop />
            {content}
            {!season ? null : <Divider position={POSITION.lightDarkColor} />}
            <Footer
                companyDetails={
                    headerFooterData && headerFooterData.companyDetails
                }
                footerLinks={
                    headerFooterData ? headerFooterData.footerLinks : []
                }
                contactBlock={
                    headerFooterData ? headerFooterData.contactBlock : []
                }
                legalLinks={headerFooterData ? headerFooterData.legalLinks : []}
            />
        </div>
    );
}
