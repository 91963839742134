import React from 'react';

import './Address.css';

const Address = ({ item, hideTitle }) => {
    const googleLink = encodeURI(
        `${item.name},${item.address.line_1},${item.address.postal_code},${item.address.city},${item.address.country_code}`
    );

    return (
        <div className="address">
            <address>
                {hideTitle ? null : (
                    <div className="address__title h4">{item.name}</div>
                )}
                <a
                    className="address__link"
                    href={`https://www.google.com/maps/dir/?api=1&destination=${googleLink}`}
                    target="_blank"
                    rel="noreferrer"
                >
                    {item.address.line_1}, {item.address.postal_code}{' '}
                    {item.address.city}
                </a>
            </address>
        </div>
    );
};

export default Address;
