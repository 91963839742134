import { React } from 'react';

import Recommendation from './Recommendation/Recommendation.js';
import { Difference } from '../helpers.js';
import { useLocaleContext } from '../core/context/localeContext.js';

export default function Results({
    f_amenities,
    matches,
    onClick,
    hotItemId,
    onMouseEnter,
    onMouseLeave,
}) {
    const { t } = useLocaleContext();

    let filterMatchResults = matches.filter((e) => {
        let filtersUsed = e.filters;
        return Difference(f_amenities, filtersUsed).length === 0;
    });

    let filterMissedResults = matches.filter((e) => {
        let filtersUsed = e.filters;
        return Difference(f_amenities, filtersUsed).length > 0;
    });

    let recommendations = filterMatchResults.map((e, i) => {
        return (
            <Recommendation
                key={i}
                item={e}
                isHotItem={hotItemId === e._id}
                onMouseEnter={() => onMouseEnter(e)}
                onMouseLeave={() => onMouseLeave(e)}
                onClick={() => onClick(e)}
            />
        );
    });

    let goodResults = filterMissedResults.map((e, i) => {
        return (
            <Recommendation
                key={i}
                item={e}
                isHotItem={hotItemId === e._id}
                onMouseEnter={() => onMouseEnter(e)}
                onMouseLeave={() => onMouseLeave(e)}
                onClick={() => onClick(e)}
            />
        );
    });

    if (recommendations.length || goodResults.length) {
        return !f_amenities.length ? (
            recommendations
        ) : (
            <>
                {!recommendations.length ? null : (
                    <>
                        <span className="search__subtitle h4">
                            {t('%{count} perfect matches', {
                                count: recommendations.length,
                            })}{' '}
                            ({t('Matching all your filters')})
                        </span>
                        {!recommendations.length ? (
                            <div
                                style={{ width: '100%', marginBottom: '24px' }}
                            />
                        ) : (
                            recommendations
                        )}
                    </>
                )}
                {!goodResults.length ? null : (
                    <>
                        <span className="search__subtitle h4">
                            {t('%{count} good matches', {
                                count: goodResults.length,
                            })}{' '}
                            (
                            {t(
                                'Not matching all your filters, but still great'
                            )}
                            )
                        </span>
                        {goodResults}
                    </>
                )}
            </>
        );
    }

    return null;
}
