import React from 'react';
import ChargerInfoCard from '../ChargerInfoCard/ChargerInfoCard';
import { useLocaleContext } from '../../core/context/localeContext';

import './ChargerInfoCards.css';

export default function ChargerInfoCards ({ item, checkin}) {
    const { t } = useLocaleContext();

    let chargersAtProperty = item.confirmed_chargers
        ? item.confirmed_chargers.map((e, i) => {
            return (
                <ChargerInfoCard
                    item={e}
                    atProperty={true}
                    key={i}
                    color="green"
                    fullWidth={item.confirmed_chargers.length === 1}
                />
            );
        })
        : 'No chargers found.';

    let chargerDetails = item.chargers_nearby.length
        ? item.chargers_nearby.map((e, i) => {
            return <ChargerInfoCard
                item={e}
                key={i}
                fullWidth={item.chargers_nearby.length === 1}
            />;
        })
        : 'No chargers found.';

    return (
        <div className="charger-info-cards">
            {item.confirmed_chargers && item.confirmed_chargers.length ? (
                <div className="charger-info-cards__chargers">
                    <h3 className="charger-info-cards__subtitle h4">
                        {t('At the property')}
                    </h3>
                    {chargersAtProperty}
                </div>
            ) : null}
            {item.chargers_nearby && item.chargers_nearby.length ? (
                <div className="charger-info-cards__chargers">
                    <h3 className="charger-info-cards__subtitle h4">
                        {t('Nearby')}
                    </h3>
                    {chargerDetails}
                </div>
            ) : null}

        </div>

    );
};

