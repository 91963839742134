import { Link } from 'react-router-dom';
import { Icon } from '../../common/Icon/Icon.js';

import { useLocaleContext } from "../../core/context/localeContext";
import useLoader from '../../core/hooks/useLoader.js';

import "./PageNotFound.css";

function PageNotFoundBlock() {
    const { urlPrefix } = useLocaleContext();
    useLoader({});

    return (
        <div className="wrap wrap--md">
            <div className="pagenotfound__block">
                <div className="pagenotfound__text h2">
                    Oops, page not found
                </div>
                <Link
                    to={`${urlPrefix}/`}
                    className="pagenotfound__link btn btn--blue"
                >
                    Go to homepage
                    <Icon.Arrow className="icon-small icon-right" />
                </Link>
            </div>
        </div>

    )
}

export default PageNotFoundBlock;