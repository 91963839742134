import React, { useCallback, useState } from 'react';
import axios from 'axios';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import CustomInput from '../../../common/CustomInput/CustomInput';
import { Icon } from '../../../common/Icon/Icon';
import Loader from '../../../common/Loader/Loader';
import { useLocaleContext } from '../../../core/context/localeContext';
import { useUserContext } from '../../../core/context/userContext';

import { DeepMerge, DotStringToObject, IsValidEmail } from '../../../helpers';

import './LoginForm.css';

export default function LoginForm({ email, handleResult, linkAway, register }) {
    const navigate = useNavigate();
    const { state } = useLocation();
    const { locale, t, urlPrefix } = useLocaleContext();
    const {
        userDetails,
        setUserDetails,
        setLocalSessionToken,
        setSessionStorageToken,
    } = useUserContext();
    const [type, setType] = useState('password');
    const [loading, setLoading] = useState(false);
    const [details, setDetails] = useState({
        email: state?.email || email || '',
        password: '',
        remember: false,
    });
    const [fieldErrors, setFieldErrors] = useState({
        email: '',
        password: '',
    });
    const [loginError, setLoginError] = useState('');

    const handleChange = useCallback(
        (evt) => {
            let name = evt.target.name;
            let value = evt.target.value;

            let property = DotStringToObject(name, value);

            let newDetails = DeepMerge({ ...details }, property);

            setDetails(newDetails);
        },
        [details, setDetails]
    );

    const handleCheckboxChange = useCallback(
        (evt) => {
            let name = evt.target.name;
            let value = evt.target.checked;

            let property = DotStringToObject(name, value);

            let newDetails = DeepMerge({ ...details }, property);

            setDetails(newDetails);
        },
        [details, setDetails]
    );

    const handleLoginSubmit = useCallback(
        (userData) => {
            setLoading(true);
            setLoginError('');
            const tryLogin = async (userData) => {
                let result = await axios.post(
                    `${process.env.REACT_APP_SC_API_BASE}/login`,
                    {
                        ...details,
                    }
                );

                setLoading(false);

                handleResult(result, details.remember);
            };

            tryLogin(userData).catch((err) => {
                setLoading(false);
                console.error({ err });
                setLoginError(err.response?.data?.message);
                handleResult(err.response);
            });
        },
        [details, navigate]
    );

    const handleFormSubmit = useCallback(
        (e) => {
            e.preventDefault();
            let errorNumb = 0;

            for (const error in fieldErrors) {
                if (!details[error]) {
                    errorNumb++;
                    setFieldErrors((prevState) => {
                        return { ...prevState, [error]: 'empty' };
                    });
                }
                if (
                    error === 'email' &&
                    details[error] &&
                    !IsValidEmail(details.email)
                ) {
                    errorNumb++;
                    setFieldErrors((prevState) => {
                        return { ...prevState, [error]: 'invalid' };
                    });
                }
            }

            if (errorNumb === 0) handleLoginSubmit();
        },
        [details, fieldErrors, handleLoginSubmit]
    );

    return (
        <form className="login-form" onSubmit={handleFormSubmit}>
            <CustomInput
                id="email"
                label="Email address"
                name="email"
                type="email"
                value={details.email}
                autoComplete="on"
                onChange={(e) => {
                    handleChange(e);
                    setFieldErrors((prevState) => {
                        return { ...prevState, email: '' };
                    });
                }}
                placeholder="Email address"
                error={fieldErrors.email}
                emptyMessage="Please fill in your email address"
                invalidMessage="Please enter a valid email address"
            />
            <div
                className={`input-container${
                    !fieldErrors.password ? '' : ' error'
                }`}
            >
                <div className="input-header">
                    <label className="input-label" htmlFor="password">
                        {t('Password')}
                    </label>
                    <Link
                        to={`${urlPrefix}/forgot-password`}
                        className="link"
                        onClick={linkAway}
                    >
                        {t('Forgot your password?')}
                    </Link>
                </div>

                <div className="input-wrapper">
                    <input
                        id="password"
                        autoComplete="off"
                        type={type}
                        name="password"
                        value={details.password}
                        onChange={(e) => {
                            handleChange(e);
                            setFieldErrors((prevState) => {
                                return {
                                    ...prevState,
                                    password: '',
                                };
                            });
                        }}
                        placeholder="Password"
                        className="input-field"
                    />
                    <button
                        className="input-btn"
                        type="button"
                        onClick={() =>
                            setType(type === 'password' ? 'text' : 'password')
                        }
                    >
                        <span className="sr-only">Change input type</span>
                        <Icon.Eye className="icon-small" />
                    </button>
                </div>
                {!fieldErrors.password ? null : (
                    <div className="input-error">
                        <span>Please fill in your password</span>
                    </div>
                )}
            </div>

            <div className="input-container">
                <label className="checkbox">
                    <input
                        type="checkbox"
                        name={'remember'}
                        value={details.remember}
                        onChange={handleCheckboxChange}
                    />
                    <span className="checkbox__checkmark" />
                    <span className="checkbox__label">{t('Remember me')}</span>
                </label>
            </div>

            <div className="login-form__footer">
                <button
                    className="signup__btn btn btn--blue"
                    disabled={loading}
                >
                    Log in
                    {loading ? (
                        <Loader
                            size={20}
                            bgColor={'var(--secondary90)'}
                            color={'var(--primary)'}
                            className="icon-right"
                        />
                    ) : null}
                </button>

                {!loginError ? null : (
                    <div className="input-error">
                        <span>{t(loginError)}</span>
                    </div>
                )}

                <div style={{ marginTop: '24px' }}>
                    {t("Don't have an account?")}{' '}
                    <button
                        onClick={() => register()}
                        style={{ fontWeight: 700, textDecoration: 'underline' }}
                        type="button"
                    >
                        {t('Register')}
                    </button>
                </div>
            </div>
        </form>
    );
}
