import { Icon } from '../../../common/Icon/Icon';
import React from 'react';
import './SuggestedLocationItem.css';

export default function SuggestedLocationItem({
    prediction,
    onLocationClick,
    structured_formatting,
}) {
    return (
        <button
            type="button"
            className="location-search__item"
            onClick={() => {
                onLocationClick(prediction);
            }}
        >
            {prediction.place_id ? (
                <Icon.Pointer className="icon-small" />
            ) : (
                <Icon.Hotel className="icon-small" />
            )}
            <span className="location-search__item-name">
                <span className="form-label">
                    {structured_formatting.main_text}
                </span>
                {structured_formatting.secondary_text}
            </span>
        </button>
    );
}
