import React from 'react';

const UPLOADS_URL = process.env.REACT_APP_UPLOADS_URL || '';

const Welcome = ({ userDetails }) => {
    return (
        <div className="content-container">
            <h1 className="profile__title h2">Welcome to the Club!</h1>
            {/* <div className="profile__img-container">
                // TODO get image from strapi
                <img
                    className="cover-img"
                    src={`${UPLOADS_URL}/uploads/large_Blog_Image_02_4f69efbbe8.jpg`}
                    alt="TODO add alt"
                />
            </div> */}
            <p className="profile__desc h4">
                An email has been sent to {userDetails.email} to confirm your account.
            </p>
        </div>
    );
};

export default Welcome;
