import './PreviewBar.css';
import { useNavigate, useLocation } from 'react-router-dom';



function PreviewBar(props) {
    const location = useLocation();
    const navigate = useNavigate();

    const cancelPreviewMode = (e) => {
        navigate({
            pathname: location.pathname.replace("preview/", "")
        });
      }

    return (
        <div className='preview-bar'>Preview View <button onClick={cancelPreviewMode}>(cancel)</button></div>
    )
}

export default PreviewBar;