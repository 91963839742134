import React from 'react';

import { format as formatDate, differenceInDays, parseISO } from 'date-fns';
import { enUS, de, nl } from 'date-fns/locale';
import { useLocaleContext } from '../../core/context/localeContext';

import './CheckInInfo.css';
import { Icon } from '../../common/Icon/Icon';
import { Pluralize } from '../../helpers';

const CheckInInfo = ({
    item,
    isMobile,
    address,
    occupancyString,
    meta,
    checkTitleFont,
}) => {
    const { locale, t } = useLocaleContext();
    const guestRating = item?.overallGuestRating;
    const guestRatingCount = item?.ratings?.guest?.count;

    let date_locale = enUS; //@mutates
    if (locale === 'de') date_locale = de
    if (locale === 'nl') date_locale = nl

    return (
        <div className="check-in-info">
            {(item || guestRating) && (
                <div className="check-in-info__overview-header">
                    {item && item.name && (
                        <h1 className="check-in-info__title h3">{item.name}</h1>
                    )}
                    {guestRating && (
                        <span className="check-in-info__rating rating">
                            <Icon.Smiley />
                            {guestRating}
                            {guestRatingCount && <span className="check-in-info__rating-count caption">({t('%{count} reviews', { count: guestRatingCount })})</span>}
                        </span>
                    )}
                </div>
            )}
            {occupancyString && (
                <div className="check-in-info__occupancy caption">
                    {occupancyString}
                </div>
            )}
            {isMobile ? address : null}
            {meta.checkin ? (
                <div className="check-in-info__check">
                    <div className="check-in-info__check-item">
                        <span
                            className={`check-in-info__check-title ${checkTitleFont}`}
                        >
                            {t('Check-in')}
                        </span>
                        <time
                            className="check-in-info__check-time caption"
                            dateTime={formatDate(parseISO(meta.checkin), 'd MMMM yyyy', {
                                locale: date_locale,
                            })}
                        >
                            {formatDate(parseISO(meta.checkin), 'E d MMMM yyyy', {
                                locale: date_locale,
                            })}
                        </time>
                    </div>
                    <div className="check-in-info__check-item">
                        <span
                            className={`check-in-info__check-title ${checkTitleFont}`}
                        >
                            {t('Check-out')}
                        </span>
                        <time
                            className="check-in-info__check-time caption"
                            dateTime={formatDate(parseISO(meta.checkout), 'd MMMM yyyy', {
                                locale: date_locale,
                            })}
                        >
                            {formatDate(parseISO(meta.checkout), 'E d MMMM yyyy', {
                                locale: date_locale,
                            })}{' '}
                            — {t('%{count} nights', { count: differenceInDays(parseISO(meta.checkout), parseISO(meta.checkin)) })}
                        </time>
                    </div>
                </div>
            ) : null}
        </div>
    );
};

export default CheckInInfo;
