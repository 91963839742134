import { Link } from 'react-router-dom';
import { useLocaleContext } from '../../../core/context/localeContext';

import './Card.css';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';

const UPLOADS_URL = process.env.REACT_APP_UPLOADS_URL || '';

const Card = ({ title, summary, image, to, highlight }) => {
    const { t } = useLocaleContext();

    const imageUrl = image
        ? image.formats && image.formats.large
            ? `${UPLOADS_URL}${image.formats.large.url}`
            : `${UPLOADS_URL}${image.url}`
        : '/../images/blog-image-default.png';

    return (
        <Link
            to={to}
            className={`card-highlight${highlight ? ' highlight' : ''}`}
            aria-label={title}
        >
            <div className="card-highlight__img-container">
                <img
                    className="card-highlight__img cover-img"
                    src={imageUrl}
                    alt={image && image.alternativeText}
                />
            </div>
            <div className="card-highlight__content">
                <h3 className="card-highlight__title h3">{title}</h3>
                <ReactMarkdown
                    className="card-highlight__desc text-container"
                    linkTarget="_blank"
                >
                    {summary}
                </ReactMarkdown>
                <span className="link">{t('Read more')}</span>
            </div>
        </Link>
    );
};

export default Card;
