import React from "react";

import chargerIcon from "../images/icons/chargerIcon.png"
import chargerIconSel from "../images/icons/chargerIconSel.png"
import propertyIcon from "../images/icons/propertyIcon.png"
import propertyIconSel from "../images/icons/propertyIconSel.png"





export default function Marker ({ position, map, type, radius, onClick, isSelected }) {
    // let gProperty = {
    //     url: propertyIcon,
    //     anchor: new window.google.maps.Point(15, 45),
    //     scaledSize: new window.google.maps.Size(20, 20),
    // }

    // let gPropertySel = {
    //     url: propertyIconSel,
    //     anchor: new window.google.maps.Point(15, 45),
    //     scaledSize: new window.google.maps.Size(20, 20),
    // }

    const [ marker, setMarker ] = React.useState();

    React.useEffect(() => {
        if (!marker) {
            let m = new window.google.maps.Marker();
            // console.log('marker created');
            if (radius) {
                m.circle = new window.google.maps.Circle({
                    strokeColor: "#2CC2FF",
                    strokeOpacity: 1,
                    strokeWeight: 0,
                    fillColor: "#2CC2FF",
                    fillOpacity: 0.4,
                    map,
                    center: position,
                    radius: radius,
                });
            }
            setMarker(m);
        }

        // remove marker from map on unmount
        return () => {
            if (marker) {
                // console.log('marker unloaded');
                if (marker.circle) {
                    marker.circle.setMap(null);
                    marker.circle = null
                }
                marker.setMap(null);
            }
        };
    }, [ marker, position, radius, map ]);

    React.useEffect(() => {
        let gCharger = {
            url: chargerIcon,
            anchor: new window.google.maps.Point(10, 10),
            scaledSize: new window.google.maps.Size(20, 20),
        }

        let gChargerSel = {
            url: chargerIconSel,
            anchor: new window.google.maps.Point(15, 15),
            scaledSize: new window.google.maps.Size(30, 30),
        }

        if (marker) {
            // console.log('marker updated');

            let icon;
            if (type === 'charger') {

                if (isSelected) {
                    icon = gChargerSel
                } else {
                    icon = gCharger
                }
            } else
                if (isSelected) {
                    icon = propertyIconSel
                } else {
                    icon = propertyIcon
                }

            marker.setOptions({ position, map, icon });

            [ "click", "idle" ].forEach((eventName) =>
                window.google.maps.event.clearListeners(marker, eventName)
            );
            if (onClick) {
                marker.addListener("click", onClick)
            }

            if (isSelected) {
                if (radius && !marker.circle) {
                    // console.log('created circle')
                    marker.circle = new window.google.maps.Circle({
                        strokeColor: "#2CC2FF",
                        strokeOpacity: 1,
                        strokeWeight: 0,
                        fillColor: "#2CC2FF",
                        fillOpacity: 0.35,
                        map,
                        center: position,
                        radius: radius,
                    });
                }
            } else if (marker.circle) {
                marker.circle.setMap(null)
                marker.circle = null;
            }
        }

    }, [ marker, position, map, isSelected, onClick, radius, type ]);

    return null;
};