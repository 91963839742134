import React from 'react';

import './ToggleButtonItem.css';

const ToggleButtonItem = React.forwardRef(({pressed, handleClick, value, children}, ref) => {
  return (
    <button ref={ref} className="toggle-button__item" type="button" aria-pressed={pressed} onClick={() => handleClick(value)}>{children}</button>
  )
});

export default ToggleButtonItem;