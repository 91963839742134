import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';

import axios from 'axios';
import { format as formatDate, parseISO } from 'date-fns';
import { enUS, de, nl } from 'date-fns/locale';

import { useLocaleContext } from './localeContext';
import { buildParams, formatHotelPath } from '../../helpers';
import useScreenSize from '../hooks/useScreenSize';
import { ThemeContext } from '../../providers/ThemeProvider';
import { useUserContext } from '../../core/context/userContext';

const SearchContext = React.createContext();

export function useSearchContext() {
    return useContext(SearchContext);
}

export function SearchProvider({ children }) {
    const didMountRef = useRef(false);
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);

    const { userDetails } = useUserContext();
    const { locale, urlPrefix, translateToLocale } = useLocaleContext();
    const [matches, setMatches] = useState([]);
    const [item, setItem] = useState(null);

    const [fromPop, setFromPop] = useState(false);
    const [fromMobileSearch, setFromMobileSearch] = useState(false);
    const [fromLocation, setFromLocation] = useState(false);
    const [datepickerOpen, setDatepickerOpen] = useState(false);
    const [findMobileOpen, setFindMobileOpen] = useState(false);
    const [destinationMobileOpen, setDestinationMobileOpen] = useState(false);
    const [dontSearch, setDontSearch] = useState(false);
    const [shouldPan, setShouldPan] = useState(false);
    const abortController = useRef(null);
    const { isMobile } = useScreenSize();
    const mobileOpenSearchBtnRef = useRef(null);
    const mobileDestinationBtnRef = useRef(null);
    const { page } = useContext(ThemeContext);

    useEffect(() => {
        if (page !== 'search') {
            setIsLoading(true);
            setMatches([]);
        }
    }, [page]);

    const openDestinationMobile = useCallback(
        (ref) => {
            mobileDestinationBtnRef.current = ref.current;
            setDestinationMobileOpen(true);
            window.history.pushState({}, null);
        },
        [mobileDestinationBtnRef]
    );

    const openMobileSearch = useCallback(
        (ref) => {
            mobileOpenSearchBtnRef.current = ref.current;
            setFindMobileOpen(true);
            window.history.pushState({}, null);
        },
        [mobileOpenSearchBtnRef]
    );

    let date_locale = enUS; // @mutates
    if (locale === 'de') date_locale = de;
    if (locale === 'nl') date_locale = nl;

    let queryParams = useMemo(
        () => ({
            dest: searchParams.get('dest'),
            checkin: searchParams.get('checkin'),
            checkout: searchParams.get('checkout'),
            lat: searchParams.get('lat'),
            lng: searchParams.get('lng'),
            lat0: searchParams.get('lat0'),
            lng0: searchParams.get('lng0'),
            lat1: searchParams.get('lat1'),
            lng1: searchParams.get('lng1'),
            adults: searchParams.get('adults'),
            child_age: searchParams.getAll('child_age'), // getAll!!!
            rooms: searchParams.get('rooms'),
            sid: searchParams.get('sid'),
            zoom: searchParams.get('zoom'),
            f_amenities: searchParams.getAll('f_amenities'),
            f_chargers: searchParams.get('f_chargers'),
        }),
        [searchParams]
    );

    let occupancy = useRef({
        adults: 2,
        child_age: [],
        rooms: 1,
    });

    // useEffect(() => {
    //     const cookie = getCookie('guests');

    //     if (cookie !== '') {
    //         const data = JSON.parse(cookie);

    //         occupancy.current = {
    //             adults: data?.adults || 2,
    //             child_age: data?.child_age || [],
    //             rooms: data?.rooms || 1,
    //         };
    //     }
    // }, [queryParams]);

    const [meta, setMeta] = useState({
        dest: queryParams.dest,
        checkin: queryParams.checkin,
        checkout: queryParams.checkout,
        lat: queryParams.lat,
        lng: queryParams.lng,
        lat0: queryParams.lat0,
        lng0: queryParams.lng0,
        lat1: queryParams.lat1,
        lng1: queryParams.lng1,
        adults: +queryParams.adults,
        child_age: queryParams.child_age,
        rooms: +queryParams.rooms,
        sid: queryParams.sid,
        zoom: +queryParams.zoom,
        f_amenities: queryParams.f_amenities || [],
        f_chargers: queryParams.f_chargers || null,
    });

    const [state, setState] = useState({
        dest: meta.dest || '',
        checkin: meta.checkin || '',
        checkout: meta.checkout || '',
        lat: +meta.lat || 0,
        lng: +meta.lng || 0,
        lat0: +meta.lat0 || 0,
        lng0: +meta.lng0 || 0,
        lat1: +meta.lat1 || 0,
        lng1: +meta.lng1 || 0,
        adults: +meta.adults || occupancy.current.adults,
        child_age: meta.child_age.length
            ? meta.child_age?.map((age) => +age)
            : occupancy.current.child_age,
        rooms: +meta.rooms || occupancy.current.rooms,
        sid: meta.sid || '',
        zoom: +meta.zoom || 13,
        f_amenities: meta.f_amenities,
        f_chargers: meta.f_chargers,
        location: meta.dest || '',
    });

    useEffect(() => {
        if (didMountRef.current) {
            setState((prevState) => ({
                ...prevState,
                dest: queryParams.dest || '',
                checkin: queryParams.checkin || '',
                checkout: queryParams.checkout || '',
                lat: +queryParams.lat || 0,
                lng: +queryParams.lng || 0,
                lat0: +queryParams.lat0 || 0,
                lng0: +queryParams.lng0 || 0,
                lat1: +queryParams.lat1 || 0,
                lng1: +queryParams.lng1 || 0,
                adults: +queryParams.adults || occupancy.current.adults,
                child_age: queryParams.child_age.length
                    ? queryParams.child_age?.map((age) => +age)
                    : occupancy.current.child_age,
                rooms: +queryParams.rooms || occupancy.current.rooms,
                sid: queryParams.sid || '',
                zoom: +queryParams.zoom || 13,
                f_amenities: queryParams.f_amenities || [],
                f_chargers: queryParams.f_chargers || null,
                location: queryParams.dest || '',
            }));
            setMeta((prevState) => ({
                ...prevState,
                dest: queryParams.dest || '',
                checkin: queryParams.checkin || '',
                checkout: queryParams.checkout || '',
                lat: +queryParams.lat || 0,
                lng: +queryParams.lng || 0,
                lat0: +queryParams.lat0 || 0,
                lng0: +queryParams.lng0 || 0,
                lat1: +queryParams.lat1 || 0,
                lng1: +queryParams.lng1 || 0,
                adults: +queryParams.adults || occupancy.current.adults,
                child_age: queryParams.child_age.length
                    ? queryParams.child_age?.map((age) => +age)
                    : occupancy.current.child_age,
                rooms: +queryParams.rooms || occupancy.current.rooms,
                sid: queryParams.sid || '',
                zoom: +queryParams.zoom || 13,
                f_amenities: queryParams.f_amenities || [],
                f_chargers: queryParams.f_chargers || null,
                location: queryParams.dest || '',
            }));
        }
        didMountRef.current = true;
    }, [queryParams, isMobile]);

    const formatCompactCheckInOutDate = (value) => {
        if (!value) return '';
        const date = parseISO(value);
        const result = formatDate(date, 'd MMM', {
            locale: date_locale,
        });
        return result;
    };

    const checkInStr = state.checkin
        ? formatCompactCheckInOutDate(state.checkin)
        : '';

    const checkOutStr = state.checkout
        ? formatCompactCheckInOutDate(state.checkout)
        : '';

    const fetchSuppliers = useCallback(
        async (newState, options) => {
            if (abortController.current && !options?.dontAbort)
                abortController.current.abort();
            try {
                abortController.current = new AbortController();
                const signal = abortController.current.signal;
                for (var key in newState) {
                    if (newState[key] === 0 || newState[key] === '0')
                        delete newState[key];
                }

                let isSubscribed = true;
                setIsLoading(true);

                let params = {
                    ...newState,
                    lang: locale,
                    m: isMobile,
                };

                // Don't send checkin checkout if they are not used
                if (params.checkin === '') delete params.checkin;
                if (params.checkout === '') delete params.checkout;

                let url = `${process.env.REACT_APP_SC_API_BASE}/suppliers/availability`;

                let headers = userDetails?.token
                    ? {
                          Authorization: `Bearer ${userDetails?.token}`,
                      }
                    : {};

                let result = await axios.get(url, {
                    params,
                    signal,
                    headers,
                });

                setIsLoading(false);

                const data = result?.data?.data;
                if (isSubscribed) {
                    const { lang, test_mode, metatags, ...params } = {
                        ...data?.meta,
                    };
                    setMatches(data?.items);
                    setMeta(data?.meta);
                    setFromPop(false);
                    setFromLocation(false);
                    if (options.shouldNavigate)
                        navigate(
                            {
                                pathname: `${urlPrefix}/search`,
                                search: `?${buildParams({
                                    ...params,
                                    zoom: newState.zoom,
                                })}`,
                            },
                            {
                                replace: options.replace,
                            }
                        );
                    if (options.scrollTop)
                        window.scrollTo({
                            top: 0,
                            behavior: 'smooth',
                        });
                }
                return data?.items;
            } catch (error) {
                setIsLoading(false);
                if (error.message !== 'canceled') {
                    setMatches([]);
                    setMeta(state);
                    setFromPop(false);
                    setFromLocation(false);
                }
                return null;
            }
        },
        // eslint-disable-next-line
        [locale, urlPrefix, isMobile, userDetails.token]
    );

    const fetchSupplier = useCallback(
        async (newState, options) => {
            setFromPop(false);
            if (abortController.current && options.shouldAbort)
                abortController.current.abort();
            try {
                abortController.current = new AbortController();
                const signal = abortController.current.signal;
                for (var key in newState) {
                    if (
                        newState[key] === 0 ||
                        newState[key] === '0' ||
                        newState[key] === ''
                    )
                        delete newState[key];
                }

                let isSubscribed = true;
                setIsLoading(true);

                const url = `${process.env.REACT_APP_SC_API_BASE}/supplier_detail`;

                let headers = userDetails?.token
                    ? {
                          Authorization: `Bearer ${userDetails?.token}`,
                      }
                    : {};

                let result = await axios.get(url, {
                    params: {
                        ...newState,
                        lang: locale,
                        m: isMobile,
                    },
                    signal,
                    headers,
                });

                setIsLoading(false);

                const data = result?.data?.data;
                if (isSubscribed) {
                    const { lang, test_mode, metatags, ...params } = {
                        ...data?.meta,
                    };
                    setItem(data?.item);
                    setMeta(data?.meta);
                    setMeta(state);
                    setFromMobileSearch(false);
                    if (options.shouldNavigate)
                        navigate({
                            pathname: `${urlPrefix}${formatHotelPath({
                                name: data?.item?.name,
                                city: data?.item?.address?.city,
                                country:
                                    data?.item?.address?.country_code,
                                locale,
                                translateToLocale,
                            })}`,
                            search: `?${buildParams(params)}`,
                        });
                }
                return data?.item;
            } catch (error) {
                setIsLoading(false);
                if (error.message !== 'canceled') {
                    setItem(null);
                    setFromMobileSearch(false);
                }
                return null;
            }
        },
        // eslint-disable-next-line
        [locale, urlPrefix, userDetails.token]
    );

    return (
        <SearchContext.Provider
            value={{
                state,
                setState,
                isLoading,
                setIsLoading,
                meta,
                setMeta,
                queryParams,
                checkInStr,
                checkOutStr,
                fetchSuppliers,
                fetchSupplier,
                matches,
                setMatches,
                item,
                setItem,
                abortController,
                fromPop,
                setFromPop,
                fromMobileSearch,
                setFromMobileSearch,
                fromLocation,
                setFromLocation,
                datepickerOpen,
                setDatepickerOpen,
                findMobileOpen,
                setFindMobileOpen,
                shouldPan,
                setShouldPan,
                dontSearch,
                setDontSearch,
                mobileOpenSearchBtnRef,
                mobileDestinationBtnRef,
                openMobileSearch,
                openDestinationMobile,
                destinationMobileOpen,
                setDestinationMobileOpen,
            }}
        >
            {children}
        </SearchContext.Provider>
    );
}
