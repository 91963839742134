import { useState, useEffect } from 'react';
import { createRoot } from 'react-dom/client';

const Marker = ({ map, position, content, hover, index, price, amount }) => {
    const [marker, setMarker] = useState();

    useEffect(() => {
        if (!marker) {
            let m = new window.google.maps.OverlayView();

            setMarker(m);
        }

        return () => {
            if (marker) {
                marker.setMap(null);
            }
        };
    }, [marker, position.lat, position.lng, map, price, amount]);

    useEffect(() => {
        if (marker) {
            marker.setMap(map);

            marker.onAdd = () => {
                var container = document.createElement('div');
                const pane = marker.getPanes().floatPane;
                pane.classList.add('marker-pane');

                createRoot(container).render(content);
                container.style.position = 'absolute';
                container.style.pointerEvents = 'none';
                container.classList.add('map-marker-container');
                marker.container = container;
                pane.appendChild(container);
            };

            marker.draw = () => {
                var pos = marker.getProjection().fromLatLngToDivPixel(position);
                marker.container.style.left = pos.x + 'px';
                marker.container.style.top = pos.y + 'px';
                marker.container.addEventListener('click', (e) => {
                    e.stopPropagation();
                });
            };

            marker.onRemove = () => {
                marker.container.parentNode.removeChild(marker.container);
                marker.container = null;
            };
        }
    // eslint-disable-next-line
    }, [marker, position.lat, position.lng, price, amount]);

    useEffect(() => {
        if (marker?.container) {
            if (hover) {
                marker.container.classList.add('selected');
            } else {
                marker.container.classList.remove('selected');
            }
        }
    }, [marker, hover, index]);

    return null;
};

export default Marker;
