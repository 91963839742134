import { useEffect, useRef, useContext } from 'react';
import { CSSTransition } from 'react-transition-group';

import useScreenSize from '../../core/hooks/useScreenSize';
import DesktopSearchbar from '../Search/DesktopSearchbar/DesktopSearchbar';

import { useSearchContext } from '../../core/context/searchContext';
import { ThemeContext } from '../../providers/ThemeProvider';
import MobileSearchButton from '../Search/MobileSearch/MobileSearchButton/MobileSearchButton';
import { Divider, POSITION } from '../../common/Divider/Divider';

import './ImageHero.css';

const UPLOADS_URL = process.env.REACT_APP_UPLOADS_URL || '';

export default function ImageHero({ data }) {
    const { findMobileOpen, setFindMobileOpen } = useSearchContext();
    const { moveSearch, searchFormRef } = useContext(ThemeContext);
    const { isMobile } = useScreenSize();
    const openBtnRef = useRef(null);
    const desktopSearchRef = useRef(null);
    const mobileSearchRef = useRef(null);
    const { setHeroColor, setHeroWithSearch } = useContext(ThemeContext);

    useEffect(() => {
        setHeroColor(data?.showBackgroundGraphic ? 'hero-dark' : 'hero-light');
        setHeroWithSearch(true);

        return () => {
            setHeroColor('');
        };
    }, [data?.showBackgroundGraphic, setHeroColor, setHeroWithSearch]);

    useEffect(() => {
        searchFormRef.current = isMobile
            ? mobileSearchRef.current
            : desktopSearchRef.current;
    }, [searchFormRef, desktopSearchRef, isMobile]);

    useEffect(() => {
        document.body.style.overflow =
            findMobileOpen && isMobile ? 'hidden' : '';

        return () => {
            document.body.style.overflow = '';
        };
    }, [findMobileOpen, isMobile]);

    useEffect(() => {
        if (findMobileOpen) {
            setFindMobileOpen(false);
            window.history.go(-1);
        }
        // eslint-disable-next-line
    }, [isMobile]);

    return (
        <>
            <section className="image-hero">
                <div className="wrap">
                    <div className="image-hero__container">
                        <div className="image-hero__content">
                            {data && data.title ? (
                                <h1 className="image-hero__title h1">
                                    {data.title}
                                </h1>
                            ) : null}
                            {data && data.subtitle ? (
                                <p className="image-hero__desc h3">
                                    {data.subtitle}
                                </p>
                            ) : null}
                            {isMobile ? (
                                <div
                                    className="image-hero__mobile-search"
                                    ref={mobileSearchRef}
                                >
                                    <CSSTransition
                                        in={!moveSearch}
                                        classNames="desktop-search-home"
                                        timeout={250}
                                        unmountOnExit
                                    >
                                        <MobileSearchButton ref={openBtnRef} />
                                    </CSSTransition>
                                </div>
                            ) : (
                                <div
                                    className="image-hero__desktop-search"
                                    ref={desktopSearchRef}
                                >
                                    <CSSTransition
                                        in={!moveSearch}
                                        classNames="desktop-search-home"
                                        timeout={250}
                                        unmountOnExit
                                    >
                                        <DesktopSearchbar isCompact />
                                    </CSSTransition>
                                </div>
                            )}
                        </div>
                        {!data.image.data ? null : (
                            <div className="image-hero__img-container">
                                <img
                                    className="cover-img"
                                    src={`${UPLOADS_URL}${data.image.data?.attributes.url}`}
                                    alt={
                                        data.image.data.attributes
                                            .alternativeText
                                    }
                                />
                            </div>
                        )}
                    </div>
                </div>
            </section>
            <Divider position={POSITION.lightDark} />
        </>
    );
}
