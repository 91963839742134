import React, { useCallback, useMemo, useState } from 'react';
import CustomInput from '../../../../common/CustomInput/CustomInput';
import CustomDropdown from '../../../../common/Dropdown/CustomDropdown';
import DetectClickOutside from '../../../../core/hooks/detectClickOutside.js';
import { Icon } from '../../../../common/Icon/Icon';
import NewTraveller from '../../NewTraveller/NewTraveller';
import MoodList from '../../../Mood/MoodList/MoodList';

const GroupForm = ({ userDetails, editGroup, setEditGroup }) => {
    const [name, setName] = useState(
        userDetails.groups?.find((group) => group.id === editGroup)?.name || ''
    );
    const [travellers, setTravellers] = useState(
        userDetails.groups?.find((group) => group.id === editGroup)
            ?.travellers || []
    );
    const [mood, setMood] = useState(
        userDetails.groups?.find((group) => group.id === editGroup)?.mood ||
            'surpriseme'
    );
    const [showTravellerForm, setShowTravellerForm] = useState(false);

    const handleSubmit = useCallback(
        (e) => {
            e.preventDefault();
            // TODO send a request to create a group
            console.log(name, travellers, mood);
            setEditGroup(null);
        },
        [mood, name, setEditGroup, travellers]
    );

    const handleChange = useCallback((e, value) => {
        return setTravellers((prevState) => [...prevState, value]);
    }, []);

    const handleCheckboxChange = useCallback(
        (evt) => {
            if (travellers.indexOf(+evt.target.name) === -1)
                return setTravellers((prevState) => [
                    ...prevState,
                    +evt.target.name,
                ]);

            const newTravellers = [...travellers].filter(
                (traveller) => traveller !== +evt.target.name
            );
            setTravellers(newTravellers);
        },
        [travellers]
    );

    const travellersList = useMemo(
        () =>
            userDetails.travellers
                ?.filter((traveller) => travellers.includes(traveller.id))
                .map((traveller) => (
                    <label
                        className={`profile__checkbox${
                            travellers.includes(traveller.id) ? ' checked' : ''
                        }`}
                        key={traveller.id}
                    >
                        <input
                            autoComplete="off"
                            type="checkbox"
                            name={traveller.id}
                            value={travellers.includes(traveller.id)}
                            checked={travellers.includes(traveller.id)}
                            onChange={handleCheckboxChange}
                            className="sr-only"
                        />
                        <span className="h5">
                            {traveller.name}
                            <Icon.Plus className="icon-small" />
                        </span>
                    </label>
                )),
        [handleCheckboxChange, travellers, userDetails.travellers]
    );

    const travellersDropdownList = useMemo(
        () =>
            userDetails.travellers
                ?.filter((traveller) => !travellers.includes(traveller.id))
                .map((traveller) => ({
                    value: traveller.id,
                    label: traveller.name,
                })),
        [travellers, userDetails.travellers]
    );

    return (
        <>
            <form
                className="profile__groups-form"
                onSubmit={handleSubmit}
                id="group-form"
            >
                <CustomInput
                    id="name"
                    label="Name your travel group"
                    name="name"
                    value={name}
                    onChange={(e) => {
                        setName(e.target.value);
                    }}
                    placeholder="Travel group name"
                />
                <div className="input-container input-container--travellers">
                    <label className="input-label">Add travellers</label>
                    {!travellersList?.length ? null : (
                        <div className="profile__checkbox-container">
                            {travellersList}
                        </div>
                    )}
                    <div className="profile__groups-controls">
                        {!travellersDropdownList?.length ? null : (
                            <CustomDropdown
                                handleChange={handleChange}
                                items={travellersDropdownList}
                                label="Add existing "
                            />
                        )}
                        <DetectClickOutside
                            onClick={() => {
                                if (showTravellerForm)
                                    setShowTravellerForm(false);
                            }}
                            detectTab={true}
                        >
                            <div className="relative">
                                <button
                                    className="btn btn--outline-dark"
                                    type="button"
                                    onClick={() =>
                                        setShowTravellerForm(
                                            (prevState) => !prevState
                                        )
                                    }
                                >
                                    Create new{' '}
                                    <Icon.Plus className="icon-small icon-right" />
                                </button>
                                {!showTravellerForm ? null : (
                                    <NewTraveller
                                        setShowTravellerForm={
                                            setShowTravellerForm
                                        }
                                    />
                                )}
                            </div>
                        </DetectClickOutside>
                    </div>
                </div>
                <div className="input-container">
                    <label className="input-label">Travel mood</label>
                    <MoodList
                        mood={mood}
                        onClick={(value) => {
                            setMood(value);
                        }}
                    />
                </div>
            </form>
            <div className="signup__footer">
                <button
                    className="link"
                    type="button"
                    onClick={() => {
                        setEditGroup(null);
                    }}
                >
                    Cancel
                </button>
                <button
                    className="btn btn--blue"
                    type="submit"
                    form="group-form"
                    disabled={name.trim() === ''}
                >
                    Save
                    <Icon.Checkmark className="icon-small icon-right" />
                </button>
            </div>
        </>
    );
};

export default GroupForm;
