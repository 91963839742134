import { useLayoutEffect, useContext, useEffect } from 'react';
import { ThemeContext } from '../../providers/ThemeProvider';
import { useParams } from 'react-router-dom';

const useLoader = (done) => {
    const { pageSlug } = useParams();
    const { setLoading } = useContext(ThemeContext);

    useLayoutEffect(() => {
        return () => {
            setLoading(true);
        };
    }, [setLoading, pageSlug]);

    useEffect(() => {
        if (done) setLoading(false);
    }, [done, setLoading]);
};

export default useLoader;
