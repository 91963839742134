import React, {
    useEffect,
    useCallback,
    useState,
    useRef,
    useMemo,
} from 'react';
import { useNavigate } from 'react-router-dom';

import SearchInput from '../../../Components/SearchInput/SearchInput';
import SuggestionsContainer from '../../../Components/SuggestionsContainer/SuggestionsContainer';

import DatePicker from './DatePicker/DatePicker';
import SuggestedRoomLayout from '../../../Components/SuggestionsContainer/SuggestedRoomLayout/SuggestedRoomLayout';
import { OccupancyString, buildParams } from '../../../helpers.js';

import { isAfter, parseISO } from 'date-fns';

import DetectClickOutside from '../../../core/hooks/detectClickOutside.js';
import { Icon } from '../../../common/Icon/Icon';
import { useLocaleContext } from '../../../core/context/localeContext';
import { useSearchContext } from '../../../core/context/searchContext';
import LocationPredictions from '../../LocationPredictions/LocationPredictions';
import { useModalContext } from '../../../core/context/modalContext';
// import MoodList, { moodList } from '../../../Components/Mood/MoodList/MoodList';

let geocoder;

const SHOW_DEBUG = process.env.REACT_APP_SHOW_DEBUG;
const APP_BUILD_ENV = process.env.REACT_APP_BUILD_ENV;

const validateInputFields = (
    placeId,
    startDate,
    endDate,
    ages,
    setError,
    t,
    supplierPage
) => {
    if (!placeId && !supplierPage) {
        setError({
            name: 'location',
            value: 'We need to know your destination',
        });
        return true;
    }

    if (startDate && !endDate) {
        setError({
            name: 'checkout',
            value: 'We need to know when you want to check-out',
        });
        return true;
    }

    if (!startDate && endDate) {
        setError({
            name: 'checkin',
            value: 'We need to know when you want to check-in',
        });
        return true;
    }

    if (startDate && startDate === endDate) {
        setError({
            name: 'checkout',
            value: 'Check-in must be before check-out',
        });
        return true;
    }

    if (
        startDate &&
        endDate &&
        isAfter(parseISO(startDate), parseISO(endDate))
    ) {
        setError({ name: 'error', value: 'Check-in must be before check-out' });
        return true;
    }
    const ageNotSelected = ages?.some((age) => age === -1);

    if (ageNotSelected) {
        setError({
            name: 'childrenAge',
            value: 'You must enter children ages',
        });
        return true;
    }

    setError({ name: '', value: '' });
    return false;
};

export default function DesktopSearchbar({
    isCompact,
    fieldApply,
    supplierPage,
    onMyLocation,
    datepickerIsModal,
}) {
    let google = window.google;

    let navigate = useNavigate();
    const { urlPrefix, t } = useLocaleContext();

    const [error, setError] = useState({ name: '', value: '' });
    const [name, setName] = useState('');
    const checkInInputRef = useRef(null);
    const checkInBtnRef = useRef(null);
    const guestBtnRef = useRef(null);
    const [showGuestSuggestion, setShowGuestSuggestion] = useState(false);
    const [predictions, setPredictions] = useState([]);
    const [locationFieldFocused, setIsLocationFieldFocused] = useState(false);
    const [formFocused, setFormFocused] = useState(false);
    const [pendingMyLocation, setPendingMyLocation] = useState(false);
    const searchInput = useRef(null);
    const guestsInput = useRef(null);
    const submitBtnRef = useRef(null);
    const { createModal } = useModalContext();
    const {
        element: DatePickerModal,
        open: openDatePickerModal,
        close: closeDatePickerModal,
    } = useMemo(() => createModal(), [createModal]);
    const {
        element: GuestModal,
        open: openGuestModal,
        close: closeGuestModal,
    } = useMemo(() => createModal(), [createModal]);

    const {
        state,
        setState,
        meta,
        setMeta,
        checkInStr,
        checkOutStr,
        fetchSupplier,
        setFromLocation,
        setShouldPan,
        datepickerOpen,
        setDatepickerOpen,
    } = useSearchContext();

    let guestPlaceholder = useMemo(
        () =>
            OccupancyString({
                adults: state.adults,
                children: state.child_age?.length,
                rooms: state.rooms,
                t,
            }),
        [state.adults, state.child_age?.length, state.rooms, t]
    );

    const handleGuest = () => {
        setShowGuestSuggestion(true);
    };

    useEffect(() => {
        geocoder = new google.maps.Geocoder();
    }, [google]);

    const handleLocationInput = (name, data) => {
        setName(name);
        setState((prevState) => ({
            ...prevState,
            location: data.value,
        }));
    };

    const handlePlaceId = async (prediction) => {
        // is it a google suggestion or our own?
        if (prediction.place_id) {
            // NOTE calls into google's geocoder
            let placeId = prediction.place_id;
            const result = await geocoder.geocode({ placeId });
            const place = result.results[0];

            if (place) {
                setState((prevState) => ({
                    ...prevState,
                    dest: prediction.description,
                    location: prediction.description,
                    lat: place.geometry.location.lat(),
                    lng: place.geometry.location.lng(),
                }));

                setIsLocationFieldFocused(false);
                setFormFocused(false);
                setFromLocation(true);
                if (submitBtnRef.current) submitBtnRef.current.focus();

                if (!fieldApply) {
                    setPredictions([]);
                } else {
                    setShouldPan(true);
                    const { sid, location, lat0, lng0, lat1, lng1, ...params } =
                        {
                            ...{
                                ...state,
                                dest: prediction.description,
                                lat: place.geometry.location.lat(),
                                lng: place.geometry.location.lng(),
                                zoom: 13,
                            },
                        };
                    setMeta(params);
                }
            }
        } else {
            // its our own suggestion
            setState((prevState) => ({
                ...prevState,
                dest: prediction.description,
                location: prediction.description,
                lat: prediction.latlng.lat,
                lng: prediction.latlng.lng,
            }));

            setIsLocationFieldFocused(false);
            setFormFocused(false);
            setFromLocation(true);
            if (submitBtnRef.current) submitBtnRef.current.focus();

            if (!fieldApply) {
                setPredictions([]);
            } else {
                setShouldPan(true);
                const { sid, location, lat0, lng0, lat1, lng1, ...params } = {
                    ...{
                        ...state,
                        dest: prediction.description,
                        lat: prediction.latlng.lat,
                        lng: prediction.latlng.lng,
                        zoom: 13,
                    },
                };
                setMeta(params);
            }
        }
    };

    const handleEnter = async (name, event) => {
        if (event.key.toLowerCase() === 'enter') {
            if (name === 'Location') {
                event.preventDefault();
                if (!predictions.length) return;
                await handlePlaceId(predictions[0]);
                setTimeout(() => {
                    if (submitBtnRef.current) submitBtnRef.current.click();
                }, 100);
            }
            event.preventDefault();
        }
    };

    const handleSubmit = useCallback(
        (e) => {
            e?.preventDefault();
            const error = validateInputFields(
                state.dest,
                datepickerIsModal ? state.checkin : false,
                datepickerIsModal ? state.checkout : false,
                state.child_age,
                setError,
                t,
                supplierPage
            );

            if (error) return;
            if (supplierPage) {
                const { dest, radius, location, ...params } = { ...state };

                navigate({
                    pathname: `${urlPrefix}/supplier`,
                    search: `?${buildParams(params)}`,
                });
            } else {
                const { sid, location, ...params } = { ...state };

                navigate({
                    pathname: `${urlPrefix}/search`,
                    search: `?${buildParams(params)}`,
                });
            }
        },
        [navigate, state, supplierPage, urlPrefix, t]
    );

    const handleFormOutsideClick = useCallback(
        (moodValue) => {
            if (formFocused) {
                setFormFocused(false);
            }
            if (fieldApply) {
                setTimeout(() => {
                    setState((prevState) => ({
                        ...prevState,
                        checkin: meta.checkin,
                        checkout: meta.checkout,
                        adults: meta.adults,
                        rooms: meta.rooms,
                        child_age: meta.child_age?.map((age) => +age),
                    }));
                }, 0);
            }
        },
        [
            fieldApply,
            formFocused,
            meta.adults,
            meta.checkin,
            meta.checkout,
            meta.child_age,
            meta.rooms,
            setState,
        ]
    );

    const handleLocationOutsideClick = useCallback(() => {
        setIsLocationFieldFocused(false);
        if (state.location) return;
        setState((prevState) => ({
            ...prevState,
            location: meta.location,
        }));
    }, [meta.location, setState, state.location]);

    const handleDatepickerOutsideClick = useCallback(() => {
        setDatepickerOpen(false);
    }, [datepickerOpen, fieldApply, meta.checkin, meta.checkout, setState]);

    // const handleMoodOutsideClick = useCallback(() => {
    //     setShowMoodSuggestion(false);
    // }, []);

    const handleGuestOutsideClick = useCallback(() => {
        setShowGuestSuggestion(false);
    }, []);

    const locationCompactLabel = useMemo(
        () => (
            <>
                <span className="input-component__label-placeholder">
                    {state.location}
                </span>
            </>
        ),
        [state.location]
    );

    const checkinCompactLabel = useMemo(
        () => (
            <>
                {!checkInStr && !checkOutStr ? (
                    <span className="input-component__label-placeholder input-component__label-placeholder--grey">
                        {t('Select date')}
                    </span>
                ) : (
                    <>
                        <span className="input-component__label-placeholder">
                            {`
                            ${checkInStr || 'Check-in'}${
                                checkInStr ? ' - ' : ', '
                            }
                        `}
                        </span>{' '}
                        <span className="input-component__label-placeholder">
                            {`
                            ${checkInStr ? checkOutStr || '?' : 'Check-out'}
                        `}
                        </span>
                    </>
                )}
            </>
        ),
        [checkInStr, checkOutStr]
    );

    // const moodCompactLabel = useMemo(
    //     () => (
    //         <>
    //             <span className="input-component__label-placeholder">
    //                 {t(`mood.${moodList.find((item) => item.value === mood).value}.title`)}
    //             </span>
    //         </>
    //     ),
    //     [mood, t]
    // );

    const guestCompactLabel = useMemo(
        () => (
            <>
                <span className="input-component__label-placeholder">
                    {OccupancyString({
                        adults: state.adults,
                        t,
                    })}
                    ,
                </span>{' '}
                {!state.child_age.length ? (
                    <>
                        {' '}
                        <span className="input-component__label-placeholder">
                            {OccupancyString({
                                rooms: state.rooms,
                                t,
                            })}
                        </span>
                    </>
                ) : (
                    <span className="input-component__label-placeholder">
                        {OccupancyString({
                            children: state.child_age.length,
                            t,
                        })}
                    </span>
                )}
            </>
        ),
        [state, t]
    );

    useEffect(() => {
        if (state.location && error?.name === 'location')
            setError({ name: '', value: '' });
        // eslint-disable-next-line
    }, [state.location]);

    useEffect(() => {
        if (state.checkin) setError({ name: '', value: '' });
    }, [state.checkin]);

    useEffect(() => {
        if (state.checkout) setError({ name: '', value: '' });
    }, [state.checkout]);

    useEffect(() => {
        if (state.child_age?.every((age) => age !== -1))
            setError({ name: '', value: '' });
    }, [state.child_age]);

    const getMyLocation = useCallback(async () => {
        setPendingMyLocation(true);
        setState((prevState) => ({
            ...prevState,
            location: t('Fetching location...'),
            lat: 0,
            lng: 0,
        }));
        navigator.geolocation.getCurrentPosition(
            async (position) => {
                if (SHOW_DEBUG) console.log('found location', position);
                setPendingMyLocation(false);
                setShouldPan(true);
                if (onMyLocation) {
                    onMyLocation({
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    });
                } else {
                    const result = await geocoder.geocode({
                        location: {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude,
                        },
                    });
                    setState((prevState) => ({
                        ...prevState,
                        location:
                            result?.results[1]?.formatted_address ||
                            t('Location on map'),
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                        dest:
                            result?.results[1]?.formatted_address ||
                            t('Location on map'),
                    }));
                }
                setIsLocationFieldFocused(false);
                setFormFocused(false);
            },
            (error) => {
                if (SHOW_DEBUG) console.log(error);
                setPendingMyLocation(false);
                setState((prevState) => ({
                    ...prevState,
                    location: '',
                    dest: '',
                    lat: 0,
                    lng: 0,
                }));
                switch (error.code) {
                    case error.PERMISSION_DENIED:
                        setError({
                            name: 'location',
                            value: 'Enable geolocation to use this feature.',
                        });
                        break;
                    case error.POSITION_UNAVAILABLE:
                        setError({
                            name: 'location',
                            value: 'Unable to fetch your location.',
                        });
                        break;
                    case error.TIMEOUT:
                        setError({
                            name: 'location',
                            value: 'Unable to fetch your location.',
                        });
                        break;
                    case error.UNKNOWN_ERROR:
                        setError({
                            name: 'location',
                            value: 'Unable to fetch your location.',
                        });
                        break;
                    default:
                        break;
                }

                setIsLocationFieldFocused(false);
                setFormFocused(false);
                if (!fieldApply) {
                    setPredictions([]);
                    // searchInput.current.focus();
                }
            }
        );
    }, [fieldApply, onMyLocation, setState, t]);

    return (
        <DetectClickOutside
            onClick={() => {
                if (supplierPage) return;
                handleFormOutsideClick();
            }}
            detectTab={true}
        >
            <div className="desktop-search-form-container">
                <form
                    className={`desktop-search-form${
                        isCompact ? ' compact' : ''
                    }${formFocused ? ' focused' : ''}`}
                    onSubmit={handleSubmit}
                    onClick={() => setFormFocused(true)}
                    onFocus={() => setFormFocused(true)}
                >
                    {supplierPage ? null : (
                        <div
                            className={`desktop-search__input${
                                error.name === 'location'
                                    ? ' error'
                                    : locationFieldFocused
                                    ? ' focused'
                                    : ''
                            }`}
                        >
                            <DetectClickOutside
                                onClick={handleLocationOutsideClick}
                                detectTab={true}
                            >
                                <div className="desktop-search-option-container location-input">
                                    <SearchInput
                                        innerRef={searchInput}
                                        value={state.location || ''}
                                        onFocus={() => {
                                            setIsLocationFieldFocused(true);
                                            searchInput.current.select();
                                        }}
                                        handleEnter={(e) =>
                                            handleEnter(name, e)
                                        }
                                        name="Location"
                                        label={t('Where?')}
                                        placeholder={t(
                                            'Enter your destination'
                                        )}
                                        handleInput={handleLocationInput}
                                        isCompact={isCompact}
                                        supplierPage={supplierPage}
                                        formFocused={formFocused}
                                        compactLabel={locationCompactLabel}
                                    />
                                    {locationFieldFocused ? (
                                        <SuggestionsContainer
                                            suggestionsContainerName="locationSuggestions"
                                            suggestionsContainerClass={
                                                'suggestions-visible'
                                            }
                                        >
                                            <LocationPredictions
                                                predictions={predictions}
                                                setPredictions={setPredictions}
                                                onLocationClick={handlePlaceId}
                                            />
                                            {'geolocation' in navigator ? (
                                                <button
                                                    className="location-search__item location-search__item--my-location"
                                                    onClick={getMyLocation}
                                                    type="button"
                                                >
                                                    <Icon.Pointer className="icon-small" />
                                                    <span className="location-search__item-name form-label">
                                                        {t('My location')}
                                                    </span>
                                                </button>
                                            ) : null}
                                        </SuggestionsContainer>
                                    ) : null}
                                </div>
                            </DetectClickOutside>
                        </div>
                    )}

                    {isCompact ? null : (
                        <>
                            {locationFieldFocused ||
                            datepickerOpen ||
                            supplierPage ? null : (
                                <div className="desktop-search__line" />
                            )}
                            <div
                                className={`desktop-search__input${`
                                                    ${
                                                        error.name ===
                                                            'checkin' ||
                                                        error.name ===
                                                            'checkout'
                                                            ? ' error'
                                                            : ''
                                                    }
                                                        ${
                                                            datepickerOpen
                                                                ? ' focused'
                                                                : ''
                                                        }
                                                `}`}
                            >
                                <DetectClickOutside
                                    onClick={handleDatepickerOutsideClick}
                                    detectTab={true}
                                >
                                    <div className="desktop-search-option-container">
                                        {supplierPage || datepickerIsModal ? (
                                            <>
                                                <button
                                                    className="desktop-search__field-btn"
                                                    id="when"
                                                    onClick={
                                                        openDatePickerModal
                                                    }
                                                    ref={checkInBtnRef}
                                                    type="button"
                                                >
                                                    <span>{t('When?')}</span>
                                                    <span className="desktop-search__field-value">
                                                        {!checkInStr ? (
                                                            <span>
                                                                {t('Check-in')}
                                                            </span>
                                                        ) : (
                                                            checkInStr
                                                        )}
                                                        {!checkInStr ||
                                                        !checkOutStr ? (
                                                            <span>, </span>
                                                        ) : (
                                                            <> - </>
                                                        )}
                                                        {!checkOutStr ? (
                                                            <span>
                                                                {t('Check-out')}
                                                            </span>
                                                        ) : (
                                                            checkOutStr
                                                        )}
                                                    </span>
                                                </button>
                                                <DatePickerModal
                                                    size={758}
                                                    title={t('When?')}
                                                    btnRef={checkInBtnRef}
                                                    closeCallback={
                                                        handleFormOutsideClick
                                                    }
                                                >
                                                    <div className="desktop-datepicker">
                                                        <DatePicker
                                                            handleDatepickerOutsideClick={
                                                                handleDatepickerOutsideClick
                                                            }
                                                            handleFormOutsideClick={
                                                                handleFormOutsideClick
                                                            }
                                                            checkInInputRef={
                                                                checkInInputRef
                                                            }
                                                            setFormFocused={
                                                                setFormFocused
                                                            }
                                                            error={error}
                                                            setError={setError}
                                                            fieldApply={
                                                                fieldApply
                                                            }
                                                            validateInputFields={
                                                                validateInputFields
                                                            }
                                                            supplierPage={
                                                                supplierPage
                                                            }
                                                            closeDatePickerModal={
                                                                closeDatePickerModal
                                                            }
                                                            datepickerIsModal={
                                                                datepickerIsModal
                                                            }
                                                        />
                                                    </div>
                                                </DatePickerModal>
                                            </>
                                        ) : (
                                            <>
                                                <div className="desktop-search-option when-input">
                                                    <SearchInput
                                                        value={`${
                                                            checkInStr
                                                                ? checkInStr +
                                                                  ' - '
                                                                : ''
                                                        }${
                                                            checkOutStr
                                                                ? checkInStr
                                                                    ? checkOutStr
                                                                    : '- ' +
                                                                      checkOutStr
                                                                : ''
                                                        }`}
                                                        onFocus={() =>
                                                            setDatepickerOpen(
                                                                true
                                                            )
                                                        }
                                                        readonly
                                                        innerRef={
                                                            checkInInputRef
                                                        }
                                                        name="when"
                                                        label={t('When?')}
                                                        isCompact={isCompact}
                                                        supplierPage={
                                                            supplierPage
                                                        }
                                                        formFocused={
                                                            formFocused
                                                        }
                                                        compactLabel={
                                                            checkinCompactLabel
                                                        }
                                                        placeholder={`${
                                                            checkInStr ||
                                                            t('Check-in')
                                                        }${
                                                            checkInStr
                                                                ? ' - '
                                                                : ', '
                                                        }${
                                                            checkInStr
                                                                ? checkOutStr ||
                                                                  '?'
                                                                : t('Check-out')
                                                        }`}
                                                    />
                                                </div>
                                                {datepickerOpen ? (
                                                    <SuggestionsContainer suggestionsContainerClass="suggestions-container--datepicker suggestions-visible">
                                                        <DatePicker
                                                            handleDatepickerOutsideClick={
                                                                handleDatepickerOutsideClick
                                                            }
                                                            handleFormOutsideClick={
                                                                handleFormOutsideClick
                                                            }
                                                            checkInInputRef={
                                                                checkInInputRef
                                                            }
                                                            setFormFocused={
                                                                setFormFocused
                                                            }
                                                            error={error}
                                                            setError={setError}
                                                            fieldApply={
                                                                fieldApply
                                                            }
                                                            validateInputFields={
                                                                validateInputFields
                                                            }
                                                            supplierPage={
                                                                supplierPage
                                                            }
                                                        />
                                                    </SuggestionsContainer>
                                                ) : null}
                                            </>
                                        )}
                                    </div>
                                </DetectClickOutside>
                            </div>
                            {/* {datepickerOpen || showMoodSuggestion ? null : (
                        <div className="desktop-search__line" />
                    )} */}

                            {/* <div
                        className={`desktop-search__input${
                            showMoodSuggestion ? ' focused' : ''
                        }`}
                    >
                        <DetectClickOutside
                            onClick={handleMoodOutsideClick}
                            detectTab={true}
                        >
                            <div className="desktop-search-option-container">
                                <div className="desktop-search-option">
                                    <SearchInput
                                        value={
                                            t(`mood.${moodList.find(
                                                (item) => item.value === mood
                                            ).value}.title`)
                                        }
                                        innerRef={moodInput}
                                        onFocus={() =>
                                            setShowMoodSuggestion(true)
                                        }
                                        readonly
                                        name="mood"
                                        label={t("What?")}
                                        isCompact={isCompact}
                                        supplierPage={supplierPage}
                                        formFocused={formFocused}
                                        compactLabel={moodCompactLabel}
                                    />
                                </div>
                                {showMoodSuggestion ? (
                                    <SuggestionsContainer suggestionsContainerClass="suggestions-container--mood suggestions-visible">
                                        <MoodList
                                            mood={mood}
                                            onClick={(value) => {
                                                setMood(value);
                                                handleMoodOutsideClick();
                                                handleFormOutsideClick(value);
                                            }}
                                        />
                                    </SuggestionsContainer>
                                ) : null}
                            </div>
                        </DetectClickOutside>
                    </div> */}

                            {datepickerOpen || showGuestSuggestion ? null : (
                                <div className="desktop-search__line" />
                            )}

                            <div
                                className={`desktop-search__input${
                                    error.name === 'childrenAge'
                                        ? ' error'
                                        : showGuestSuggestion
                                        ? ' focused'
                                        : ''
                                }`}
                            >
                                <DetectClickOutside
                                    onClick={handleGuestOutsideClick}
                                    detectTab={true}
                                >
                                    <div className="desktop-search-option-container">
                                        {supplierPage ? (
                                            <>
                                                <button
                                                    className="desktop-search__field-btn"
                                                    onClick={openGuestModal}
                                                    ref={guestBtnRef}
                                                    type="button"
                                                >
                                                    <span>{t('Who?')}</span>
                                                    <span className="desktop-search__field-value">
                                                        {guestPlaceholder}
                                                    </span>
                                                </button>
                                                <GuestModal
                                                    size={500}
                                                    title={t('Who?')}
                                                    btnRef={guestBtnRef}
                                                    closeCallback={
                                                        handleFormOutsideClick
                                                    }
                                                >
                                                    <SuggestedRoomLayout
                                                        handleFormOutsideClick={
                                                            handleFormOutsideClick
                                                        }
                                                        setFormFocused={
                                                            setFormFocused
                                                        }
                                                        setError={setError}
                                                        fieldApply={fieldApply}
                                                        validateInputFields={
                                                            validateInputFields
                                                        }
                                                        supplierPage={
                                                            supplierPage
                                                        }
                                                        handleGuestOutsideClick={
                                                            handleGuestOutsideClick
                                                        }
                                                        setShowGuestSuggestion={
                                                            setShowGuestSuggestion
                                                        }
                                                        closeGuestModal={
                                                            closeGuestModal
                                                        }
                                                    />
                                                </GuestModal>
                                            </>
                                        ) : (
                                            <>
                                                <div className="desktop-search-option guest-input">
                                                    <SearchInput
                                                        value={guestPlaceholder}
                                                        innerRef={guestsInput}
                                                        onFocus={handleGuest}
                                                        readonly
                                                        name="guest"
                                                        label={t('Who?')}
                                                        isCompact={isCompact}
                                                        supplierPage={
                                                            supplierPage
                                                        }
                                                        formFocused={
                                                            formFocused
                                                        }
                                                        compactLabel={
                                                            guestCompactLabel
                                                        }
                                                    />
                                                </div>
                                                {showGuestSuggestion ? (
                                                    <SuggestionsContainer
                                                        suggestionsContainerName="guestSuggestions"
                                                        suggestionsContainerClass="guest-suggestions-container suggestions-visible"
                                                    >
                                                        <SuggestedRoomLayout
                                                            handleFormOutsideClick={
                                                                handleFormOutsideClick
                                                            }
                                                            setFormFocused={
                                                                setFormFocused
                                                            }
                                                            setError={setError}
                                                            fieldApply={
                                                                fieldApply
                                                            }
                                                            validateInputFields={
                                                                validateInputFields
                                                            }
                                                            supplierPage={
                                                                supplierPage
                                                            }
                                                            handleGuestOutsideClick={
                                                                handleGuestOutsideClick
                                                            }
                                                            setShowGuestSuggestion={
                                                                setShowGuestSuggestion
                                                            }
                                                        />
                                                    </SuggestionsContainer>
                                                ) : null}
                                            </>
                                        )}
                                    </div>
                                </DetectClickOutside>
                            </div>
                        </>
                    )}

                    {fieldApply ? null : (
                        <button
                            className="desktop-search__btn btn btn--blue btn--small"
                            type="submit"
                            ref={submitBtnRef}
                        >
                            <Icon.Arrow className="icon-small" />
                            <span className="sr-only">Search</span>
                        </button>
                    )}
                </form>
                {error.name ? (
                    <div
                        className={`${
                            error.name ? 'visible' : 'not-visible'
                        } error-container`}
                        id="error-box"
                    >
                        <div className="error-item">
                            <Icon.Alert className="icon-small" />
                            <span>{t(error.value)}</span>
                        </div>
                    </div>
                ) : null}
            </div>
        </DetectClickOutside>
    );
}
