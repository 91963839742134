import React, { useCallback, useRef } from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Lazy } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/lazy';

import { Icon } from '../../common/Icon/Icon';

import './CardImageSlider.css';

const CardImageSlider = ({ images }) => {
    const swiperRef = useRef(null);

    const handlePrev = useCallback(
        (e) => {
            e.stopPropagation();
            if (!swiperRef.current) return;
            swiperRef.current.swiper.slidePrev();
        },
        [swiperRef]
    );

    const handleNext = useCallback(
        (e) => {
            e.stopPropagation();
            if (!swiperRef.current) return;
            swiperRef.current.swiper.slideNext();
        },
        [swiperRef]
    );

    if (!images || images.length === 0) return null;

    return (
        <div className="card-image-slider">
            {images.length > 1 ? (
                <>
                    <button
                        className="card-image-slider__arrow card-image-slider__arrow--prev slider__arrow slider__arrow--prev"
                        onClick={handlePrev}
                    >
                        <Icon.Arrow className="icon-small" />
                        <span className="sr-only">Previous slide</span>
                    </button>
                    <button
                        className="card-image-slider__arrow card-image-slider__arrow--next slider__arrow slider__arrow--next"
                        onClick={handleNext}
                    >
                        <Icon.Arrow className="icon-small" />
                        <span className="sr-only">Previous slide</span>
                    </button>
                    <Swiper
                        ref={swiperRef}
                        className="card-image-slider__slider"
                        modules={[Pagination, Lazy]}
                        pagination={{ clickable: true }}
                        lazy={{
                            enabled: true,
                            loadPrevNext: true,
                        }}
                        style={{
                            '--swiper-preloader-color': '#383128',
                            '--swiper-pagination-bullet-horizontal-gap': '2px',
                        }}
                    >
                        {images.map((image, i) => (
                            <SwiperSlide
                                key={i}
                                className="card-image-slider__slide"
                            >
                                <img
                                    className="card-image-slider__img swiper-lazy"
                                    data-src={`${
                                        image.links['1000px']
                                            ? image.links['1000px'].href
                                            : image.links['350px'].href
                                    }`}
                                    alt={image.caption}
                                    fetchpriority="high"
                                />
                                <div className="swiper-lazy-preloader"></div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </>
            ) : (
                <div className="card-image-slider__slide">
                    <img
                        className="card-image-slider__img"
                        src={`${
                            images[0].links['1000px']
                                ? images[0].links['1000px'].href
                                : images[0].links['350px'].href
                        }`}
                            alt={images[ 0 ].caption}
                            fetchpriority="high"
                    />
                </div>
            )}
        </div>
    );
};

export default CardImageSlider;
