import { useState } from 'react';
import { useLocaleContext } from '../../../../core/context/localeContext';
import { useSearchContext } from '../../../../core/context/searchContext';
import DatePickerRange from '../../../DatePickerRange/DatePickerRange';

const DatePicker = ({
    handleDatepickerOutsideClick,
    handleFormOutsideClick,
    checkInInputRef,
    setFormFocused,
    error,
    setError,
    fieldApply,
    validateInputFields,
    supplierPage,
    closeDatePickerModal,
    datepickerIsModal,
}) => {
    const { t } = useLocaleContext();
    const [selecting, setSelecting] = useState(0);
    const {
        state,
        setState,
        setMeta,
        fetchSupplier,
        setShouldPan,
        setDatepickerOpen,
    } = useSearchContext();

    const clearDates = () => {
        setError({ name: '', value: '' });
        const newState = {
            ...state,
            checkin: '',
            checkout: '',
        };
        setSelecting(0);
        setState(newState);
    };

    return (
        <>
            <DatePickerRange
                selecting={selecting}
                setSelecting={setSelecting}
            />
            {datepickerIsModal ? (
                <div className="suggestions-container__footer">
                    <button
                        className="link"
                        type="button"
                        onClick={() => {
                            clearDates();
                        }}
                    >
                        {t('Clear dates')}
                    </button>
                    <button
                        className="btn btn--blue btn--small"
                        type="button"
                        onClick={() => {
                            setDatepickerOpen(false);
                            setFormFocused(false);
                            if (closeDatePickerModal) closeDatePickerModal();
                        }}
                    >
                        {t('Apply changes')}
                    </button>
                </div>
            ) : !fieldApply ? (
                <div className="suggestions-container__footer">
                    <button
                        className="link"
                        type="button"
                        onClick={() => {
                            clearDates();
                        }}
                    >
                        {t('Clear dates')}
                    </button>
                </div>
            ) : (
                <div className="suggestions-container__footer">
                    <button
                        className="link"
                        type="button"
                        onClick={() => {
                            handleDatepickerOutsideClick();
                            handleFormOutsideClick();
                            setFormFocused(false);
                            if (closeDatePickerModal) closeDatePickerModal();
                        }}
                    >
                        {t('Cancel')}
                    </button>
                    <button
                        className="link"
                        type="button"
                        onClick={() => {
                            clearDates();
                        }}
                    >
                        {t('Clear dates')}
                    </button>
                    <button
                        className="btn btn--blue btn--small"
                        type="button"
                        onClick={() => {
                            setDatepickerOpen(false);
                            setFormFocused(false);
                            if (closeDatePickerModal) closeDatePickerModal();
                            const error = validateInputFields(
                                state.dest,
                                state.checkin,
                                state.checkout,
                                state.child_age,
                                setError,
                                t,
                                supplierPage
                            );
                            if (error) return;
                            if (supplierPage) {
                                fetchSupplier(state, {
                                    shouldNavigate: true,
                                    shouldAbort: true,
                                });
                            } else {
                                setShouldPan(true);
                                const {
                                    sid,
                                    location,
                                    lat0,
                                    lng0,
                                    lat1,
                                    lng1,
                                    ...params
                                } = state;
                                setMeta(params);
                            }
                        }}
                    >
                        {t('Apply changes')}
                    </button>
                </div>
            )}
        </>
    );
};

export default DatePicker;
