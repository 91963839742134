import { useMemo } from 'react';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import { Icon } from '../../common/Icon/Icon';
import { useSearchDataContext } from '../../core/context/searchDataContext';
import './SearchUSP.css';

const SearchUSP = () => {
    const { searchData } = useSearchDataContext();
    const data = useMemo(() => searchData?.uspBlock, [searchData]);

    if (!data) return null;

    return (
        <div className="search-usp">
            <ReactMarkdown className="search-usp__text text-container" linkTarget="_blank">
                {data.text}
            </ReactMarkdown>
            {!data.uspItems.length ? null : (
                <ul className="search-usp__list">
                    {data.uspItems.map((el) => {
                        const IconElement =
                            Icon[
                                el.uspIcon.charAt(0).toUpperCase() +
                                    el.uspIcon.slice(1)
                            ];
                        return (
                            <li key={el.id} className="search-usp__item h4">
                                <IconElement className="icon-small" />
                                {el.uspItem}
                            </li>
                        );
                    })}
                </ul>
            )}
        </div>
    );
};

export default SearchUSP;
