import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFade, Lazy } from 'swiper';
import { Icon } from '../../../common/Icon/Icon';
import { useLocaleContext } from '../../../core/context/localeContext';

import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/lazy';
import './GalleryImages.css';

const GalleryImages = ({ galleryParams, isMobile, images }) => {
    const { t } = useLocaleContext();
    const swiperRef = useRef(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const [activeIndex, setActiveIndex] = useState(
        +galleryParams.gallery_item - 1
    );

    useEffect(() => {
        if (galleryParams.gallery_item)
            setActiveIndex(+galleryParams.gallery_item - 1);
    }, [galleryParams.gallery_item]);

    const handlePrev = useCallback(() => {
        if (!swiperRef.current) return;
        swiperRef.current.swiper.slidePrev();
    }, [swiperRef]);

    const handleNext = useCallback(() => {
        if (!swiperRef.current) return;
        swiperRef.current.swiper.slideNext();
    }, [swiperRef]);

    const changeImage = useCallback(
        (i) => {
            if (activeIndex === i - 1) return;
            searchParams.set('gallery_item', i);
            setSearchParams(searchParams, { replace: true });
        },
        [activeIndex, searchParams, setSearchParams]
    );

    const closeImage = useCallback(
        (i) => {
            if (activeIndex === i - 1) return;
            searchParams.delete('gallery_item');
            setSearchParams(searchParams);
        },
        [activeIndex, searchParams, setSearchParams]
    );

    return (
        <div className="gallery-images">
            <div className="wrap">
                <div className="gallery-images__pagination h5">
                    {+activeIndex + 1}/{images.length}
                </div>
                <button
                    className="gallery-images__close gallery-images__btn"
                    onClick={closeImage}
                >
                    <Icon.Plus className="icon-small" />
                    <span className="sr-only">{t('Close image modal')}</span>
                </button>
                <div className="gallery-images__container">
                    {isMobile ? null : (
                        <div className="gallery-images__arrow-container">
                            {activeIndex < 1 ? null : (
                                <button
                                    className="gallery-images__arrow flip-x gallery-images__btn"
                                    type="button"
                                    onClick={handlePrev}
                                >
                                    <Icon.Arrow className="icon-small" />
                                    <span className="sr-only">
                                        {t('Previous image')}
                                    </span>
                                </button>
                            )}
                        </div>
                    )}
                    <Swiper
                        className="gallery-images__swiper"
                        ref={swiperRef}
                        effect={'fade'}
                        fadeEffect={{ crossFade: true }}
                        modules={[EffectFade, Lazy]}
                        initialSlide={activeIndex}
                        lazy={{
                            enabled: true,
                            loadPrevNext: true,
                        }}
                        onSlideChange={(e) => {
                            if (galleryParams.gallery_item)
                                changeImage(e.activeIndex + 1);
                        }}
                    >
                        {images.map((image, i) => (
                            <SwiperSlide key={i}>
                                <div className="gallery-images__img-container">
                                    <img
                                        className="gallery-images__img cover-img swiper-lazy"
                                        data-src={
                                            image.links['1000px']?.href ||
                                            image.links['350px']?.href
                                        }
                                        alt={image.caption}
                                    />
                                    <div className="swiper-lazy-preloader"></div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                    {isMobile ? null : (
                        <div className="gallery-images__arrow-container">
                            {activeIndex > images.length - 2 ? null : (
                                <button
                                    className="gallery-images__arrow gallery-images__btn"
                                    type="button"
                                    onClick={handleNext}
                                >
                                    <Icon.Arrow className="icon-small" />
                                    <span className="sr-only">
                                        {t('Next image')}
                                    </span>
                                </button>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default GalleryImages;
