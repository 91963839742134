import {
    React,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import reactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';

import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ThemeContext } from '../../providers/ThemeProvider';
import Logo from '../Logo';
import { Icon } from '../../common/Icon/Icon';

import { ToBool, formatHotelPath } from '../../helpers.js';

import './Navigation.css';

import { useLocaleContext } from '../../core/context/localeContext';
import { useSearchContext } from '../../core/context/searchContext';
import { useUserContext } from '../../core/context/userContext';

import CustomDropdown from '../../common/Dropdown/CustomDropdown';
import PreviewBar from '../PreviewBar/PreviewBar';
import ModalHOC from '../../common/ModalHOC/ModalHOC';
import MobileDestination from '../Search/MobileSearch/MobileDestination/MobileDestination';
import DesktopSearchbar from '../Search/DesktopSearchbar/DesktopSearchbar';

const REACT_APP_SHOW_USERS = ToBool(process.env.REACT_APP_SHOW_USERS);

function Navigation({ isMobile, navItems, showLangSelector }) {
    const { heroColor, page, isScrolled, moveSearch, heroWithSearch } =
        useContext(ThemeContext);
    const [mobileNavIsOpen, setMobileNavIsOpen] = useState(false);
    const {
        locale,
        preview,
        locales,
        urlPrefix,
        localizedSlugs,
        t,
        translateToLocale,
    } = useLocaleContext();
    const location = useLocation();
    const navigate = useNavigate();
    const {
        setFromPop,
        setDontSearch,
        destinationMobileOpen,
        mobileDestinationBtnRef,
        openDestinationMobile,
        item,
    } = useSearchContext();
    const openSearchBtnRef = useRef(null);
    const pagesWithoutSearchInNav = [
        'search',
        'supplier',
        'booking',
        'my-booking',
    ];
    const { userDetails, login, logout, register } = useUserContext();

    const openNav = useCallback(() => {
        setMobileNavIsOpen(true);
        setDontSearch(true);
        document.body.style.overflow = 'hidden';
        window.history.pushState({}, null);
    }, [setDontSearch]);

    const closeNav = useCallback(() => {
        setMobileNavIsOpen(false);
        setDontSearch(true);
        document.body.style.overflow = '';
        window.history.go(-1);
    }, [setDontSearch]);

    const openSearch = useCallback(() => {
        openDestinationMobile(openSearchBtnRef);
    }, [openDestinationMobile, openSearchBtnRef]);

    useEffect(() => {
        window.addEventListener('popstate', () => {
            setMobileNavIsOpen(false);
        });

        return () => {
            if (mobileNavIsOpen) {
                document.body.style.overflow = '';
                window.removeEventListener('popstate', () => {
                    setMobileNavIsOpen(false);
                });
            }
        };
    }, [mobileNavIsOpen, isMobile]);

    useEffect(() => {
        setMobileNavIsOpen(false);
    }, [isMobile]);

    const closeDestination = useCallback(() => {
        navigate(-1);
    }, [navigate]);

    let css = heroColor === 'hero-dark' ? ' sc-theme-dark' : '';
    css += isScrolled ? ' scrolled' : '';
    css += moveSearch ? ' move-search' : '';
    css += mobileNavIsOpen ? ' nav--open' : '';
    css += page ? ` nav--${page}` : '';

    const languageSwitcher = (
        <div className="nav__language-switcher">
            <CustomDropdown
                handleChange={(e, value) => {
                    setFromPop(true);
                    if (!localizedSlugs) {
                        navigate({
                            pathname:
                                page !== 'supplier'
                                    ? '/' +
                                      value +
                                      location.pathname.replace(
                                          `${locale}/`,
                                          ''
                                      )
                                    : `/${value}${formatHotelPath({
                                          name: item.name,
                                          city: item.address?.city,
                                          country: item.address?.country_code,
                                          locale: value,
                                          translateToLocale,
                                      })}`,
                            search: location.search,
                        });
                    } else {
                        const slug = localizedSlugs.filter(
                            (slug) => slug.locale === value
                        )[0]?.slug;
                        const route = localizedSlugs.filter(
                            (slug) => slug.locale === value
                        )[0]?.route;
                        const previewPrefix = preview ? '/preview' : '';

                        if (!slug) {
                            return navigate({
                                pathname: `${previewPrefix}/${value}/`,
                                search: location.search,
                            });
                        }
                        if (slug.startsWith('home-')) {
                            navigate({
                                pathname: `${previewPrefix}/${value}/`,
                                search: location.search,
                            });
                        } else {
                            navigate({
                                pathname: `${previewPrefix}/${value}/${route}/${slug}`,
                                search: location.search,
                            });
                        }
                    }
                }}
                items={
                    locales &&
                    locales.map((l) => {
                        return { label: l.text, value: l.code };
                    })
                }
                selected={locale}
                icon={<Icon.Globe className="icon-small" />}
                ariaLabel={t('Language switcher dropdown')}
            />
        </div>
    );

    const desktopDropdownNav = useMemo(
        () => (
            <CustomDropdown
                handleChange={(e, value) => {
                    navigate(`${urlPrefix}/${value}`);
                }}
                items={
                    navItems &&
                    navItems.map((item) => {
                        return {
                            label: item.title,
                            value: item.link,
                        };
                    })
                }
                selected={locale}
                icon={<Icon.Menu className="icon-small" />}
                ariaLabel={t('Navigation dropdown')}
                noArrow
            />
        ),
        [locale, navItems, navigate, t, urlPrefix]
    );

    const mobileSearchButton = useMemo(
        () => (
            <button
                className="nav__search-btn btn btn--blue"
                type="button"
                onClick={openSearch}
                ref={openSearchBtnRef}
            >
                {t('Start your search')}
                <Icon.Arrow className="icon-small icon-right" />
            </button>
        ),
        [openSearch, t]
    );

    const userDropdown = useMemo(() => {
        const loginItems = userDetails?.token
            ? [
                  {
                      label: 'Profile',
                      value: `profile`,
                  },
                  {
                      label: 'Log out',
                      value: 'logout',
                  },
              ]
            : [
                  {
                      label: 'Login',
                      value: 'login',
                  },
                  {
                      label: 'Register',
                      value: 'register',
                  },
              ];
        return (
            <div className="nav__user-dropdown">
                <CustomDropdown
                    handleChange={(e, value) => {
                        // log-out is simply removing token from storage,
                        // we can just call the logout function here
                        // @TODO IMPROVE if we are on a profile/user page, redirect to login
                        if (value === 'logout') {
                            logout();
                        } else if (value === 'login') {
                            login();
                        } else if (value === 'register') {
                            register();
                        } else {
                            navigate(`${urlPrefix}/${value}`);
                        }
                    }}
                    items={loginItems}
                    icon={
                        userDetails?.firstname &&
                        userDetails?.lastname ? null : (
                            <Icon.User className="icon-small" />
                        )
                    }
                    label={
                        !userDetails?.firstname || !userDetails?.lastname
                            ? null
                            : `${userDetails.firstname
                                  .charAt(0)
                                  .toUpperCase()}${userDetails.lastname
                                  .charAt(0)
                                  .toUpperCase()}`
                    }
                    ariaLabel={t('User Navigation dropdown')}
                    noArrow={true}
                    style={{
                        backgroundColor: userDetails?.token
                            ? 'var(--warning)'
                            : '',
                        borderColor: userDetails?.token ? 'var(--warning)' : '',
                    }}
                />
            </div>
        );
    }, [
        login,
        logout,
        navigate,
        register,
        t,
        urlPrefix,
        userDetails.firstname,
        userDetails.lastname,
        userDetails?.token,
    ]);

    return (
        <>
            <nav className={`nav${css}`}>
                {preview && <PreviewBar />}
                <div
                    className={`wrap${
                        location.pathname.includes('search')
                            ? ' wrap--full-width'
                            : ''
                    }`}
                >
                    {isMobile ? (
                        !isScrolled ||
                        pagesWithoutSearchInNav.includes(page) ? (
                            <>
                                <div className="nav__container">
                                    <div className="nav__logo-container">
                                        <Logo />
                                    </div>
                                    {showLangSelector &&
                                        locales?.length > 1 &&
                                        languageSwitcher}
                                    {REACT_APP_SHOW_USERS ? userDropdown : null}
                                    <button
                                        className="nav__open circle-btn"
                                        type="button"
                                        onClick={openNav}
                                    >
                                        <Icon.Menu className="icon-small" />
                                        <span className="sr-only">
                                            Open navigation
                                        </span>
                                    </button>
                                </div>
                                {mobileNavIsOpen ? (
                                    <div className="nav__mobile-container">
                                        <button
                                            className="nav__close circle-btn"
                                            type="button"
                                            onClick={closeNav}
                                        >
                                            <Icon.Plus className="icon-small" />
                                            <span className="sr-only">
                                                {t('close')}
                                            </span>
                                        </button>

                                        <ul className="nav__mobile-menu-list">
                                            {navItems &&
                                                navItems.map((item) => {
                                                    return (
                                                        <li
                                                            className="nav__mobile-menu-item h4"
                                                            key={item.id}
                                                        >
                                                            <Link
                                                                to={`${urlPrefix}/${item.link}`}
                                                                onClick={() =>
                                                                    setMobileNavIsOpen(
                                                                        (
                                                                            prev
                                                                        ) =>
                                                                            !prev
                                                                    )
                                                                }
                                                            >
                                                                {item.title}
                                                            </Link>
                                                        </li>
                                                    );
                                                })}
                                        </ul>
                                    </div>
                                ) : null}
                            </>
                        ) : (
                            <div className="nav__btn-container">
                                {heroWithSearch ? (
                                    <CSSTransition
                                        in={moveSearch}
                                        classNames="desktop-search"
                                        timeout={250}
                                        unmountOnExit
                                    >
                                        {mobileSearchButton}
                                    </CSSTransition>
                                ) : (
                                    mobileSearchButton
                                )}
                            </div>
                        )
                    ) : (
                        <div className="nav__container">
                            <div className="nav__logo-container">
                                <Logo />
                            </div>
                            <div className="nav__search-container">
                                {heroWithSearch === null ||
                                pagesWithoutSearchInNav.includes(
                                    page
                                ) ? null : heroWithSearch ? (
                                    <CSSTransition
                                        in={moveSearch}
                                        classNames="desktop-search"
                                        timeout={250}
                                        unmountOnExit
                                    >
                                        <DesktopSearchbar isCompact />
                                    </CSSTransition>
                                ) : (
                                    <DesktopSearchbar isCompact />
                                )}
                            </div>
                            <div className="nav__menu-container">
                                {showLangSelector &&
                                    locales?.length > 1 &&
                                    languageSwitcher}
                                {REACT_APP_SHOW_USERS ? userDropdown : null}
                                <div className="nav__navigation-dropdown">
                                    {desktopDropdownNav}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </nav>
            {reactDOM.createPortal(
                <ModalHOC
                    isOpen={destinationMobileOpen}
                    clear={page !== 'search'}
                    openBtnRef={mobileDestinationBtnRef}
                    closeModal={closeDestination}
                >
                    <MobileDestination
                        standalone={page !== 'search'}
                        closeDestination={closeDestination}
                    />
                </ModalHOC>,
                document.getElementById('modal')
            )}
        </>
    );
}

export default Navigation;
