import { useState } from 'react';
import axios from 'axios';
import { Icon } from '../../common/Icon/Icon';

import './NewsletterSignup.css';
import Loader from '../../common/Loader/Loader';

function NewsletterSignup(props) {
    const [submitted, setSubmitted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    function signup(e) {
        e.preventDefault();
        setIsLoading(true);

        axios
            .post(process.env.REACT_APP_NEWSLETTER_ENDPOINT, {
                email_address: e.target.email.value,
            })
            .then(function (response) {
                setSubmitted(true);
                setIsLoading(false);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    let classStr = props.theme === 'light' ? ' light' : '';

    return (
        <div className={`newsletter-signup${classStr}`}>
            <div className="wrap wrap--xs">
                <div className="newsletter-signup__container">
                    {!submitted && props.contactData ? (
                        <>
                            <h2 className="newsletter-signup__title form-label">
                                {props.contactData.title}
                            </h2>
                            <form
                                className="newsletter-signup__form"
                                onSubmit={signup}
                            >
                                <input
                                    name="email"
                                    type="email"
                                    placeholder={props.contactData.placeholder}
                                    className="newsletter-signup__email"
                                    required
                                />
                                <button
                                    disabled={isLoading}
                                    type="submit"
                                    className="newsletter-signup__btn btn btn--blue"
                                >
                                    {!isLoading ? (
                                        <Icon.Mail className="icon-small" />
                                    ) : (
                                        <Loader
                                            size={20}
                                            bgColor={'var(--secondary90)'}
                                            color={'var(--primary)'}
                                        />
                                    )}
                                    <span className="sr-only">
                                        {isLoading
                                            ? props.contactData.buttonWaitText
                                            : props.contactData.buttonText}
                                    </span>
                                </button>
                            </form>
                        </>
                    ) : (
                        <p className="newsletter-signup__message h3">
                            {props.contactData && props.contactData.successText}
                        </p>
                    )}
                </div>
            </div>
        </div>
    );
}

export default NewsletterSignup;
