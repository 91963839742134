import React, { useCallback, useEffect, useRef, useState } from 'react';
import axios from 'axios';

import { useLocaleContext } from '../../../core/context/localeContext';
import {
    DotStringToObject,
    DeepMerge,
    IsValidEmail,
    ToBool,
} from '../../../helpers.js';

import { COUNTRY_INFO_BY_INDEX } from '../../../core/hooks/constants.js';
import PhoneField from '../../PhoneField/PhoneField';

import styles from './UserDetails.module.css';
import {
    useUserContext,
    USER_MESSAGE,
} from '../../../core/context/userContext';
import CustomInput from '../../../common/CustomInput/CustomInput';
import { Icon } from '../../../common/Icon/Icon';

const REACT_APP_SHOW_USERS = ToBool(process.env.REACT_APP_SHOW_USERS);

const ACCOUNT_BTN = {
    login: 'login',
    register: 'register',
};

const INITIAL_DETAILS = {
    firstname: '',
    lastname: '',
    phone_country_code: '31',
    phone: '',
    email: '',
    special_requests: [],
    is_business_trip: false,
    company: {
        name: '',
        address: {
            line_1: '',
            postal_code: '',
            city: '',
            country_code: '',
        },
    },
    agree_to_terms_and_conditions: false,
}

export default function UserDetails({
    setDetails,
    newDetails,
    setNewDetails,
    changeDetails,
    setChangeDetails,
    handleCheckboxChange,
    isMobile,
    errors,
}) {
    const { t } = useLocaleContext();
    const { userDetails, login, register, message } = useUserContext();
    const [showAccountBtn, setShowAccountBtn] = useState(ACCOUNT_BTN.register);
    // const [loginMessage, setLoginMessage] = useState(null);
    const loginBtnRef = useRef(null);
    const registerBtnRef = useRef(null);

    const handleChange = useCallback(
        (evt) => {
            let name = evt.target.name;
            let value = evt.target.value;

            let property = DotStringToObject(name, value);

            let newUserDetails = DeepMerge(structuredClone(newDetails), property);

            setNewDetails(newUserDetails);
        },
        [newDetails, setNewDetails]
    );

    const phoneCountryOptions = COUNTRY_INFO_BY_INDEX.phone.map((e, i) => {
        return (
            <option key={i} value={e}>
                {COUNTRY_INFO_BY_INDEX.display[i]}
            </option>
        );
    });

    const checkEmail = async (email) => {
        try {
            let result = await axios.post(
                `${process.env.REACT_APP_SC_API_BASE}/check_email`,
                {
                    email,
                }
            );
            if (result.status === 200) {
                setShowAccountBtn(ACCOUNT_BTN.register);
            } else {
                console.error(result);
            }
        } catch (err) {
            if (err.response.data.status === 'error') {
                setShowAccountBtn(ACCOUNT_BTN.login);
            } else {
                console.error(err.response);
            }
        }
    };

    useEffect(() => {
        if (!userDetails.email) {
            setShowAccountBtn(ACCOUNT_BTN.register);
            setDetails(INITIAL_DETAILS);
            setNewDetails(INITIAL_DETAILS);
            return;
        }
        setShowAccountBtn(false);
        setDetails((prevState) => ({ ...prevState, ...userDetails }));
        setNewDetails((prevState) => ({ ...prevState, ...userDetails }));
    }, [setDetails, setNewDetails, userDetails]);

    const businessTripCheckbox = (
        <div className="input-container">
            <label className="toggle">
                <input
                    type="checkbox"
                    name={'is_business_trip'}
                    value={newDetails.is_business_trip}
                    onChange={handleCheckboxChange}
                />
                <span className="toggle__label">{t('Business trip')}</span>
                <span className="toggle__checkmark" />
            </label>
        </div>
    );

    const businessFields = (
        <>
            <CustomInput
                id="company-name-user"
                label={t('Company name')}
                name="company.name"
                type="text"
                value={newDetails.company.name}
                onChange={handleChange}
                placeholder={t('Company name')}
                error={errors.includes('company_name')}
            />
            <CustomInput
                id="company-country-code-user"
                label={t('Country')}
                name="company.address.country_code"
                type="text"
                value={newDetails.company.address.country_code}
                onChange={handleChange}
                placeholder={t('Country')}
                error={errors.includes('company_country_code')}
            />
            <CustomInput
                id="company-line-1-user"
                label={t('Company address')}
                name="company.address.line_1"
                type="text"
                value={newDetails.company.address.line_1}
                onChange={handleChange}
                placeholder={t('Street + house number')}
                error={errors.includes('company_line_1')}
            />

            <div
                style={{
                    display: 'grid',
                    gridTemplateColumns: '2fr 5fr',
                    gap: 10,
                    marginBottom: '-24px',
                    alignItems: 'start',
                }}
            >
                <CustomInput
                    id="company-postal-code-user"
                    label={t('Zipcode')}
                    name="company.address.postal_code"
                    type="text"
                    value={newDetails.company.address.postal_code}
                    onChange={handleChange}
                    placeholder={t('Postal code')}
                    error={errors.includes('company_postal_code')}
                />
                <CustomInput
                    id="company-city-user"
                    label={t('City')}
                    name="company.address.city"
                    type="text"
                    value={newDetails.company.address.city}
                    onChange={handleChange}
                    placeholder={t('City')}
                    error={errors.includes('company_city')}
                />
            </div>
        </>
    );

    return (
        <>
            <div className="booking__header">
                <h2 className="booking__subtitle h3">
                    {t('Your personal details')}
                </h2>
            </div>
            <div className="booking__element-container">
                {!userDetails?.token ? null : (
                    <>
                        <div className="booking__user">
                            <div className="booking__user-header">
                                <h3 className="h4">{`${userDetails.firstname} ${userDetails.lastname}`}</h3>
                                {!message ? null : message ===
                                  USER_MESSAGE.login ? (
                                    <div className="user-message success caption">
                                        <Icon.User className="icon-xs" />
                                        {t('Logged in as')}{' '}
                                        {userDetails.firstname}
                                    </div>
                                ) : message === USER_MESSAGE.register ? (
                                    <div className="user-message success caption">
                                        <Icon.Check className="icon-xs" />
                                        {t('Account succesfully created')}
                                    </div>
                                ) : null}
                            </div>
                            <div>{userDetails.email}</div>
                            {!userDetails.phone ? null : (
                                <div>
                                    +{userDetails.phone_country_code}{' '}
                                    {userDetails.phone}
                                </div>
                            )}
                            {!newDetails.is_business_trip ? null : !userDetails
                                  ?.company?.added ? (
                                <div>{t('Please add company details')}</div>
                            ) : (
                                <>
                                    <h3
                                        className="h4"
                                        style={{ marginTop: '8px' }}
                                    >
                                        Company
                                    </h3>
                                    <div>{userDetails?.company?.name}</div>
                                    <address style={{ fontStyle: 'normal' }}>
                                        <div>
                                            {
                                                userDetails?.company?.address
                                                    ?.country_code
                                            }
                                        </div>
                                        <div>
                                            {
                                                userDetails?.company?.address
                                                    ?.line_1
                                            }
                                        </div>
                                        <div>
                                            {
                                                userDetails?.company?.address
                                                    ?.postal_code
                                            }{' '}
                                            {
                                                userDetails?.company?.address
                                                    ?.city
                                            }
                                        </div>
                                    </address>
                                </>
                            )}
                        </div>
                        {businessTripCheckbox}
                        <div className="input-container">
                            <label className="checkbox">
                                <input
                                    type="checkbox"
                                    name={'change_details'}
                                    checked={changeDetails}
                                    onChange={(evt) =>
                                        setChangeDetails(evt.target.checked)
                                    }
                                />
                                <span className="checkbox__checkmark" />
                                <span className="checkbox__label">
                                    {t(
                                        'Enter different details for this booking'
                                    )}
                                </span>
                            </label>
                        </div>
                    </>
                )}
                {userDetails?.token && !changeDetails ? (
                    newDetails.is_business_trip && !userDetails?.company?.added ? (
                        businessFields
                    ) : null
                ) : (
                    <div className={styles['user-details']}>
                        <CustomInput
                            id="email-user"
                            label={t('E-mail address')}
                            name="email"
                            type="email"
                            value={newDetails.email}
                            onChange={handleChange}
                            placeholder={t('E-mail address')}
                            containerStyle={{ marginBottom: '16px' }}
                            autoComplete="on"
                            info={t(
                                'The confirmation email goes to this address'
                            )}
                            inputEvents={{
                                onBlur(e) {
                                    if (
                                        !IsValidEmail(e.target.value) ||
                                        userDetails.email
                                    )
                                        return;
                                    checkEmail(e.target.value);
                                },
                            }}
                            error={errors.includes('email')}
                        />
                        {REACT_APP_SHOW_USERS &&
                        showAccountBtn === ACCOUNT_BTN.login ? (
                            <div className="label">
                                <button
                                    className="label__btn"
                                    type="button"
                                    ref={loginBtnRef}
                                    onClick={() => login(newDetails.email)}
                                >
                                    <span className="caption">
                                        {t('Want to log in?')} -{' '}
                                        <span className="underline">
                                            {t('Log in')}
                                        </span>
                                    </span>
                                </button>
                            </div>
                        ) : REACT_APP_SHOW_USERS &&
                          showAccountBtn === ACCOUNT_BTN.register ? (
                            <div className="label">
                                <button
                                    className="label__btn"
                                    type="button"
                                    ref={registerBtnRef}
                                    onClick={() => register(newDetails.email)}
                                >
                                    <span className="caption">
                                        {t('Want to create an account?')} -{' '}
                                        <span className="underline">
                                            {t('Create account')}
                                        </span>
                                    </span>
                                </button>
                            </div>
                        ) : null}
                        <div
                            style={{
                                display: 'grid',
                                gridTemplateColumns: isMobile
                                    ? '1fr'
                                    : '1fr 1fr',
                                gap: isMobile ? '0' : '20px',
                                marginBottom: isMobile ? '24px' : '',
                                alignItems: 'start',
                            }}
                        >
                            <CustomInput
                                id="firstname-user"
                                label={t('First name')}
                                name="firstname"
                                type="text"
                                value={newDetails.firstname}
                                onChange={handleChange}
                                placeholder={t('First name')}
                                error={errors.includes('firstname')}
                            />
                            <CustomInput
                                id="lastname-user"
                                label={t('Last name')}
                                name="lastname"
                                type="text"
                                value={newDetails.lastname}
                                onChange={handleChange}
                                placeholder={t('Last name')}
                                error={errors.includes('lastname')}
                            />
                        </div>

                        <div
                            className={`input-container${
                                !errors.includes('phone') ? '' : ' error'
                            }`}
                        >
                            <label className="input-label">
                                {t('Phone number')}
                            </label>

                            <PhoneField
                                handleChange={handleChange}
                                countryOptions={phoneCountryOptions}
                                countryValue={newDetails.phone_country_code}
                                phoneValue={newDetails.phone}
                            />
                        </div>

                        {userDetails?.token ? null : businessTripCheckbox}

                        {newDetails.is_business_trip ? businessFields : null}
                    </div>
                )}
            </div>
        </>
    );
}
