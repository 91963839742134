import Card from "../Card/Card";
import { Link } from 'react-router-dom';

import './CardsBlock.css';
import { useLocaleContext } from "../../core/context/localeContext";
import { Icon } from "../../common/Icon/Icon";

function CardsBlock({title, blogs, button}) {
    const { urlPrefix } = useLocaleContext();

    const renderBlogCards = (blogs) => {
        if (blogs) {
            return (
                blogs.map((blog, index) => {
                    const { title, publishedAt, featuredImage, slug, subtitle } = blog.attributes;
                    return (
                        <Card
                            key={index}
                            title={title}
                            subtitle={subtitle}
                            date={new Date(publishedAt)}
                            image={featuredImage ? featuredImage.data.attributes : ""}
                            to={`${urlPrefix}/blog/` + slug}
                        />
                    )
                })
            )
        }
    }

    const renderCardsButton = (button) => {
        if (button) {
            const { title, link, style } = button;
            if (title && link) {
                return (
                    <Link
                        to={`${urlPrefix}/${link}`}
                        className={`cards-block__cta ${ style === 'Green Right Arrow' ? ' btn btn--green' : ' btn btn--outline-dark'}`}
                    >
                        {title}
                        <Icon.Arrow className="icon-small icon-right" />
                    </Link>
                )
            }
        }
    }

    return (
        <section className={`cards-block section${blogs?.length < 3 ? ' cards-block--center' : ''}`}>
            <div className="wrap wrap--md">
                <div className="cards-block__container">
                    {title && <h2 className="cards-block__title h2">{title}</h2>}
                    <div className='cards-block__cards'>
                        {renderBlogCards(blogs)}
                    </div>
                    {renderCardsButton(button)}
                </div>
            </div>
        </section>
    );
}

export default CardsBlock;