import { Link } from 'react-router-dom';
import { useLocaleContext } from '../../core/context/localeContext';

import './Discover.css';

function Discover({ slogan, usp_items_list, button }) {
    const { urlPrefix } = useLocaleContext();

    const renderUSPItems = (usp_items) => {
        if (usp_items) {
            return (
                <ul className="discover__list">
                    {usp_items.map((item, index) => (
                        <li className="discover__item h4" key={index}>
                            <img
                                src={'/images/icons/' + item.uspIcon + '.svg'}
                                className="discover__item-img"
                                alt=""
                            />
                            <span>{item.uspItem}</span>
                        </li>
                    ))}
                </ul>
            );
        }
    };

    const renderUSPButton = (button) => {
        if (button) {
            const { icon, text, link } = button;
            if (text && link) {
                return (
                    <div>
                        <Link
                            to={`${urlPrefix}/${link}`}
                            className="discover__cta btn btn--green"
                        >
                            {icon ? (
                                <img
                                    src={'/images/icons/' + icon + '.svg'}
                                    alt={icon + '-icon'}
                                />
                            ) : (
                                false
                            )}
                            {text}
                        </Link>
                    </div>
                );
            }
        }
    };

    return (
        <>
            <section className="discover">
                <div className="wrap wrap--sm">
                    {!slogan
                        ? null
                        : <h2 className="discover__title h2">{slogan}</h2>
                    }
                    {renderUSPItems(usp_items_list)}
                    {renderUSPButton(button)}
                </div>
            </section>
        </>
    );
}

export default Discover;
