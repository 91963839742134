import React, { useCallback, useRef } from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';

import { Icon } from '../../common/Icon/Icon';

import './HeroSlider.css';

const HeroSlider = ({ item, isMobile }) => {
    const swiperRef = useRef(null);
    const images = item?.images?.slice(0, 7) || [];

    const handlePrev = useCallback(() => {
        if (!swiperRef.current) return;
        swiperRef.current.swiper.slidePrev();
    }, [ swiperRef ]);

    const handleNext = useCallback(() => {
        if (!swiperRef.current) return;
        swiperRef.current.swiper.slideNext();
    }, [ swiperRef ]);

    if (images.length === 0) return null;

    return (
        <div className="hero-slider">
            {images.length > 1 ? (
                <>
                    {isMobile ? null : (
                        <>
                            <button
                                className="hero-slider__arrow hero-slider__arrow--prev slider__arrow slider__arrow--prev"
                                onClick={handlePrev}
                            >
                                <Icon.Arrow className="icon-small" />
                                <span className="sr-only">Previous slide</span>
                            </button>
                            <button
                                className="hero-slider__arrow hero-slider__arrow--next slider__arrow slider__arrow--next"
                                onClick={handleNext}
                            >
                                <Icon.Arrow className="icon-small" />
                                <span className="sr-only">Next slide</span>
                            </button>
                        </>
                    )}
                    <Swiper
                        ref={swiperRef}
                        className="hero-slider__slider"
                        modules={[ Pagination ]}
                        pagination={{ clickable: true }}
                    >
                        {images
                            .filter(image => image.links && image.links[ '1000px' ])
                            .map((image, i) => (
                                <SwiperSlide key={i} className="hero-slider__slide">
                                    <img
                                        className="hero-slider__img"
                                        src={image.links[ '1000px' ].href}
                                        alt={image.caption}
                                    />
                                </SwiperSlide>
                            ))}
                    </Swiper>
                    {isMobile ? null : (
                        <div className="hero-slider__number cta">
                            <Icon.Images className="icon-small" />+
                            {images.filter(image => image.links && image.links[ '1000px' ]).length - 1}
                        </div>
                    )}
                </>
            ) : (
                    <img
                        className="hero-slider__img"
                        src={images[ 0 ].links[ '1000px' ].href}
                        alt={images[ 0 ].caption}
                    />
                )}
        </div>
    );
};

export default HeroSlider;
