import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Icon } from '../Icon/Icon';
import { hasRoom } from '../../helpers';

import './CustomDropdown.css';

const CustomDropdown = ({
    selected,
    items,
    handleChange,
    style,
    greyoutFirst,
    icon,
    label,
    ariaLabel,
    noArrow,
    prefix,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const btnRef = useRef(null);
    const menuRef = useRef(null);
    const itemsRef = useRef([]);
    const currentFocusIndex = useRef(0);

    const closeDropdownListener = useCallback(
        (e) => {
            if (btnRef.current !== e.target) {
                setIsOpen(false);
                document.removeEventListener('click', closeDropdownListener);
            }
        },
        [btnRef]
    );

    const closeDropdown = useCallback(() => {
        setIsOpen(false);
        if (btnRef.current) btnRef.current.focus();
        document.removeEventListener('click', closeDropdownListener);
    }, [closeDropdownListener]);

    const moveFocus = (index) => {
        currentFocusIndex.current = index;
        itemsRef.current[index]?.focus();
    };

    useEffect(() => {
        if (isOpen) moveFocus(0);
    }, [isOpen]);

    const handleClick = useCallback(() => {
        if (isOpen) {
            closeDropdown();
        } else {
            setIsOpen((prevState) => !prevState);
        }
        document.addEventListener('click', closeDropdownListener);
    }, [closeDropdown, closeDropdownListener, isOpen]);

    const handleBtnKeyDown = useCallback(
        (e) => {
            if (e.keyCode === 9 || (e.shiftKey && e.keyCode === 9)) {
                closeDropdown();
            }
        },
        [closeDropdown]
    );

    const handleItemKeyDown = useCallback(
        (e, value) => {
            if (e.keyCode === 13 || e.keyCode === 32) {
                e.preventDefault();
                if (handleChange) handleChange(e, value);
                closeDropdown();
            }

            if (e.keyCode === 38) {
                e.preventDefault();
                if (currentFocusIndex.current > 0)
                    moveFocus(currentFocusIndex.current - 1);
                else moveFocus(itemsRef.current.length - 1);
            }

            if (e.keyCode === 40) {
                e.preventDefault();
                if (currentFocusIndex.current < itemsRef.current.length - 1)
                    moveFocus(currentFocusIndex.current + 1);
                else moveFocus(0);
            }

            if (e.keyCode === 9 || (e.shiftKey && e.keyCode === 9)) {
                closeDropdown();
            }
        },
        [currentFocusIndex, closeDropdown, handleChange]
    );

    if (!items) return null;

    return (
        <div className="custom-dropdown">
            <button
                className={`custom-dropdown__btn${isOpen ? ' open' : ''}`}
                ref={btnRef}
                tabIndex={0}
                type="button"
                aria-haspopup="true"
                aria-expanded={isOpen}
                onClick={handleClick}
                onKeyDown={handleBtnKeyDown}
                style={{
                    ...style,
                    color: greyoutFirst
                        ? items.map((item) => item.value).indexOf(selected) ===
                          0
                            ? 'var(--primary80)'
                            : 'var(--primary)'
                        : '',
                }}
                aria-label={ariaLabel}
            >
                {icon}
                {label ? (
                    <span className="custom-dropdown__value">
                        <span className="custom-dropdown__label caption">
                            {label}
                        </span>
                        {
                            items.filter((item) => item.value === selected)[0]
                                ?.label
                        }
                    </span>
                ) : (
                    <>
                        {!prefix ? null : (
                            <span className="custom-dropdown__prefix">
                                {prefix}
                            </span>
                        )}
                        {
                            items.filter((item) => item.value === selected)[0]
                                ?.label
                        }
                    </>
                )}
                {noArrow ? null : (
                    <Icon.Chevron className="custom-dropdown__chevron icon-small" />
                )}
            </button>
            <ul
                ref={menuRef}
                className={`custom-dropdown__menu${
                    isOpen ? ' custom-dropdown__menu--visible' : ''
                }`}
                role="menu"
                style={
                    hasRoom(btnRef.current, menuRef.current)
                        ? { left: 0 }
                        : { right: 0 }
                }
            >
                {items.map((item, i) => {
                    return (
                        <li
                            className={`custom-dropdown__item${
                                item.value === selected
                                    ? ' custom-dropdown__item--active'
                                    : ''
                            }`}
                            ref={(ref) => {
                                itemsRef.current[i] = ref;
                            }}
                            key={i}
                            tabIndex="-1"
                            role="menuitem"
                            onClick={
                                handleChange
                                    ? (e) => {
                                          handleChange(e, item.value);
                                          setTimeout(() => {
                                              if (btnRef.current)
                                                  btnRef.current.focus();
                                          }, 0);
                                      }
                                    : null
                            }
                            onKeyDown={(e) => handleItemKeyDown(e, item.value)}
                        >
                            {item.label}
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default CustomDropdown;
