import { React, useState, useEffect, useCallback } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { buildParams, ToBool } from '../helpers.js';

import axios from 'axios';
import CustomSelect from '../common/CustomSelect/CustomSelect.js';
import CustomToggle from '../common/CustomToggle/CustomToggle.js';
import CustomRangeSlider from '../common/CustomRangeSlider/CustomRangeSlider.js';
import CustomInput from '../common/CustomInput/CustomInput.js';

import DetectClickOutside from '../core/hooks/detectClickOutside.js';
import SuggestionsContainer from '../Components/SuggestionsContainer/SuggestionsContainer';

import SuggestedLocationItem from '../Components/SuggestionsContainer/SuggestedLocationItem/SuggestedLocationItem';
import { Icon } from '../common/Icon/Icon.js';

const DEFAULT_NEARBY_CHARGER_DISTANCE = 250;
const MAX_RANGE_OPTION = 2000;
const RANGE_STEP_SIZE = 10;
const MAX_RATING = 10;
const RATING_STEP_SIZE = 0.1;

let predictionOrderNr = 0;
let autoCompleteService;
let geocoder;

export default function AdminSearchFilter ({ user }) {
    let navigate = useNavigate();

    const [ searchParams ] = useSearchParams();

    let queryParams = {
        sid: searchParams.get('sid'),
        max_distance: Number(searchParams.get('max_distance')),
        use_amenity: ToBool(searchParams.get('use_amenity')),
        has_amenity: ToBool(searchParams.get('has_amenity')),
        use_ecomov: ToBool(searchParams.get('use_ecomov')),
        hide_confirmed: ToBool(searchParams.get('hide_confirmed')),
        min_rating: Number(searchParams.get('min_rating')),
        country_id: searchParams.get('country_id') || '129',
        province_id: searchParams.get('province_id') || '6035375',
        lat: searchParams.get('lat'),
        lng: searchParams.get('lng'),
        only_empty_email: ToBool(searchParams.get('only_empty_email')),
        min_rating: Number(searchParams.get('min_rating')),
    };

    // const [ useEcoMov, setUseEcoMov ] = useState(queryParams.use_ecomov);
    // const [ amenities, setAmenities ] = useState(queryParams.has_amenity);
    // const [ useAmenities, setUseAmenities ] = useState(queryParams.use_amenity);
    const [ maxDistance, setMaxDistance ] = useState(
        queryParams.max_distance || DEFAULT_NEARBY_CHARGER_DISTANCE
    );
    const [ countries, setCountries ] = useState([]);
    const [ provinces, setProvinces ] = useState([]);
    const [ countryId, setCountryId ] = useState(queryParams.country_id);
    const [ provinceId, setProvinceId ] = useState(queryParams.province_id);
    const [ lat, setLat ] = useState(queryParams.lat);
    const [ lng, setLng ] = useState(queryParams.lng);

    const [ sid, setSid ] = useState(queryParams.sid || '');
    const [ hideConfirmed, setHideConfirmed ] = useState(queryParams.hide_confirmed);

    const [ propertyName, setPropertyName ] = useState('');
    const [ suggestions, setSuggestions ] = useState([]);

    const [ minRating, setMinRating ] = useState(queryParams.min_rating || 8);

    const [ showOnlyEmptyEmail, setShowOnlyEmptyEmail ] = useState(
        queryParams.only_empty_email
    );

    // Init: Fetch countries
    useEffect(() => {
        let isSubscribed = true;

        const fetchInfo = async (latlng) => {
            let result = await axios.get(
                `${process.env.REACT_APP_SC_API_BASE}/admin/countries`,
                {
                    headers: {
                        Authorization: `Bearer ${user.token}`,
                    },
                }
            );

            // console.log(result);

            if (isSubscribed) {
                let data = result.data;
                setCountries(data);
            }
        };

        fetchInfo().catch(console.error);
    }, []);

    const fetchProvinces = async (cid) => {
        let result = await axios.get(
            `${process.env.REACT_APP_SC_API_BASE}/admin/provinces`,
            {
                params: {
                    country_id: cid,
                },
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            },
        );

        return result;
    };

    // Fetch provinces
    useEffect(() => {
        let isSubscribed = true;
        setProvinces([]);

        const fetchInfo = async (latlng) => {
            let result = await fetchProvinces(countryId);

            if (isSubscribed) {
                let data = result.data;
                setProvinces(data);
            }
        };

        fetchInfo().catch(console.error);
    }, [ countryId ]);

    // set default province id
    useEffect(() => {
        if (provinces.length) {
            let p = provinces.find((e) => e.id === queryParams.province_id);
            if (p) {
                setProvinceId(p.id);
            } else {
                setProvinceId(provinces[ 0 ].id);
            }
        }
    }, [ provinces, queryParams.province_id ]);

    // init google
    useEffect(() => {
        geocoder = new window.google.maps.Geocoder();
        autoCompleteService = new window.google.maps.places.AutocompleteService();
        predictionOrderNr = 0;
    }, [ window.google ]);

    const changeDistance = (event) => {
        setMaxDistance(event.target.value);
    };
    const changeMinRating = (event) => {
        setMinRating(event.target.value);
    };
    // const changeUseEcoMov = (event) => {
    //     setUseEcoMov(event.target.checked);
    // };
    const changeHideConfirmed = (event) => {
        setHideConfirmed(event.target.checked);
    };

    // const changeAmenities = (event) => {
    //     setAmenities(event.target.checked);
    // };
    // const changeUseAmenities = (event) => {
    //     setUseAmenities(event.target.checked);
    // };

    const changeShowOnlyEmptyEmail = (event) => {
        setShowOnlyEmptyEmail(event.target.checked);
    };

    const setFilter = () => {
        let searchParams = buildParams({
            max_distance: maxDistance,
            // use_amenity: useAmenities,
            // has_amenity: amenities,
            // use_ecomov: useEcoMov,
            country_id: countryId,
            province_id: provinceId,
            hide_confirmed: hideConfirmed,
            min_rating: minRating,
            only_empty_email: showOnlyEmptyEmail,
            min_rating: minRating,
            sid: sid,
            lat: lat,
            lng: lng,
        });

        navigate({
            pathname: '/admin/map',
            search: searchParams,
            replace: true,
        });
    };

    let optionsCountry = countries.map((e) => {
        return (
            <option key={e.id} value={e.id}>
                {e.name}
            </option>
        );
    });

    let optionsProvinces = [ <option key={-1} value={''}>
        -- ALL --
    </option> ].concat(provinces.map((e) => {
        return (
            <option key={e.id} value={e.id}>
                {e.name}
            </option>
        )
    }));

    const handleChangeCountry = (evt) => {
        setCountryId(evt.target.value);
    };

    const handleChangeProvince = (evt) => {
        setProvinceId(evt.target.value);
    };

    const handleChangeSid = (evt) => {
        setSid(evt.target.value);
    };

    const handleChangePropertyName = (evt) => {
        setPropertyName(evt.target.value);
        setLat('')
        setLng('')
    };

    useEffect(() => {
        let isSubscribed = true;

        const fetchInfo = async () => {
            predictionOrderNr++;

            // NOTE(gb) we use a closure to keep track of the order here because Google does not return the callbacks in order.
            // NOTE(gb) we dont use expedia region text search because google search has fuzzy search capabilities which is very useful.
            let formattedSuggestions = [];

            if (propertyName) {
                await (async (orderNr) => {

                    let url = `${process.env.REACT_APP_SC_API_BASE}/admin/search`;
                    let result = await axios.post(url,
                        {
                            text: propertyName,
                        },
                        {
                            headers: {
                                Authorization: `Bearer ${user.token}`,
                            },
                        });

                    if (result.status === 200) {
                        isSubscribed = false;
                        if (orderNr === predictionOrderNr) {
                            let data = result.data;
                            let formatted = data.map((e) => {
                                return {
                                    description: e.name,
                                    place_id: '',
                                    sid: e._id,
                                    structured_formatting: {
                                        main_text: e.name,
                                        secondary_text: `${e.address.city}, ${e.address.country_code}`,
                                    },
                                };
                            });

                            formattedSuggestions = formattedSuggestions.concat(formatted)

                            setSuggestions(formattedSuggestions)
                            return formattedSuggestions;
                        }
                    }



                })(predictionOrderNr);
                // @TODO FIXME when we get to here, the predictionOrder can have changed again

            }


        };

        fetchInfo().catch(console.error);
    }, [ propertyName ]);

    const handleLocationOutsideClick = useCallback(() => {
        setSuggestions([]);
    }, []);

    const handleSuggestionClick = useCallback((suggestion) => {
        setPropertyName(suggestion.description);
        if (suggestion.sid) {
            setSid(suggestion.sid);
        } else if (suggestion.region_id) {
            console.log(suggestion)
        } else {
            setSid('');
            handlePlaceId(suggestion, suggestion.description);
        }
        setSuggestions([]);
    }, []);

    const handlePlaceId = async (prediction, description) => {

        // NOTE calls into google's geocoder
        let placeId = prediction.place_id;
        const result = await geocoder.geocode({ placeId });
        const place = result.results[ 0 ];

        console.log({ place })
        if (place) {
            const bounds = place.geometry.bounds;
            let centerLatLng = bounds.getCenter().lat();
            let latspan = bounds.toSpan().lat();
            let lngspan = bounds.toSpan().lng();

            let latlng = {
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng(),
            }

            setLat(latlng.lat);
            setLng(latlng.lng);

        }


    };

    return (
        <div className="admin-map__filters-container">
            <h2 className="admin-map__filters-title h3">Filter</h2>

            <CustomInput
                id="sid"
                label="Supplier ID"
                name="sid"
                value={sid}
                onChange={handleChangeSid}
            />

            <div className={``}>
                <DetectClickOutside
                    onClick={handleLocationOutsideClick}
                    detectTab={true}
                >
                    <div className="desktop-search-option-container location-input">
                        <CustomInput
                            id="property_name"
                            label="Search property"
                            name="property_name"
                            value={propertyName}
                            onChange={handleChangePropertyName}
                        />
                        {suggestions.length ? (
                            <SuggestionsContainer
                                suggestionsContainerName="locationSuggestions"
                                suggestionsContainerClass={
                                    'suggestions-visible'
                                }
                            >
                                <div
                                    className="location-search-result-container"
                                    style={{ color: 'var(--primary)' }}
                                >
                                    {suggestions?.map((suggestion, index) => (
                                        <SuggestedLocationItem
                                            key={index}
                                            description={suggestion.description}
                                            structured_formatting={
                                                suggestion.structured_formatting
                                            }
                                            prediction={suggestion}
                                            index={index}
                                            onLocationClick={
                                                handleSuggestionClick
                                            }
                                        />
                                    ))}
                                </div>
                            </SuggestionsContainer>
                        ) : null}
                    </div>
                </DetectClickOutside>
            </div>

            <button
                className="btn btn--grey"
                type="button"
                onClick={() => {
                    setSid('');
                    setPropertyName('');
                }}
            >
                Clear search
            </button>

            <CustomSelect
                id="country"
                label="Country"
                name="country"
                value={countryId}
                onChange={handleChangeCountry}
                options={optionsCountry}
            />
            <CustomSelect
                id="state"
                label="State/Province"
                name="state"
                value={provinceId}
                onChange={handleChangeProvince}
                options={optionsProvinces}
            />
            {/* <CustomToggle
                label="Check against EcoMovement data"
                checked={useEcoMov}
                onChange={changeUseEcoMov}
            />
            {useEcoMov && (
                <>
                    <CustomRangeSlider
                        label="Max. distance to EcoMov location"
                        value={maxDistance}
                        onChange={changeDistance}
                        max={MAX_RANGE_OPTION}
                        step={RANGE_STEP_SIZE}
                        min={0}
                    />
                </>
            )} */}
            {/* <CustomToggle
                label="Filter on Expedia amenity checkbox"
                checked={useAmenities}
                onChange={changeUseAmenities}
            /> */}
            {/* {useAmenities && (
                <CustomToggle
                    label="Has checked the charger amenity"
                    checked={amenities}
                    onChange={changeAmenities}
                />
            )} */}
            <CustomToggle
                label="Hide confirmed"
                checked={hideConfirmed}
                onChange={changeHideConfirmed}
            />

            {true && (
                <>
                    <CustomRangeSlider
                        label="Choose minimum property rating"
                        value={minRating}
                        onChange={changeMinRating}
                        max={MAX_RATING}
                        step={RATING_STEP_SIZE}
                        min={0}
                    />
                    <CustomToggle
                        label="Only show properties with no email address"
                        checked={showOnlyEmptyEmail}
                        onChange={changeShowOnlyEmptyEmail}
                    />
                </>
            )}


            <button className="btn btn--blue" type="button" onClick={setFilter}>
                Set filter
            </button>
        </div>
    );
}
