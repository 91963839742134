import { useCallback, useMemo, useRef } from 'react';

import { useLocaleContext } from '../../core/context/localeContext';
import { useModalContext } from '../../core/context/modalContext.js';

import { Icon } from '../../common/Icon/Icon.js';
import ChargerDetails from '../ChargerDetails/ChargerDetails.js';

import './ChargerInfoCard.css';

export default function ChargerInfoCard(props) {
    const { t } = useLocaleContext();
    const btnRef = useRef(null);
    const { createModal } = useModalContext();
    const { element: ChargerModal, open: openModal } = useMemo(
        () => createModal(),
        [createModal]
    );

    let powers = props.item.powers;
    let str = `${(powers[0] / 1000).toFixed(1)} kW`;
    if (powers.length > 1) {
        str += ` - ${(powers[powers.length - 1] / 1000).toFixed(1)} kW`;
    }

    // either we passed a custom action, or this opens its modal
    let clickAction = props.onClick || openModal;

    const handleKeyDown = useCallback(
        (e) => {
            if (e.keyCode === 13 || e.keyCode === 32) {
                e.preventDefault();
                clickAction();
            }
        },
        [clickAction]
    );

    const cardProps = useMemo(
        () =>
            !clickAction
                ? null
                : {
                      onClick: clickAction,
                      role: 'button',
                      tabIndex: 0,
                      onKeyDown: handleKeyDown,
                      ref: btnRef,
                  },
        [clickAction, handleKeyDown]
    );

    return (
        <>
            <div
                className={`info-card${
                    props.color ? ` info-card--${props.color}` : ''
                }${props.onMap ? ` info-card--map` : ''}${
                    props.fullWidth ? ' info-card--full-width' : ''
                }${clickAction ? ' info-card--clickable' : ''}`}
                {...cardProps}
            >
                <div className="info-card__header">
                    <h3 className="info-card__title h5">
                        {props.item.owner.name}
                    </h3>
                </div>
                {!props.atProperty ? (
                    <address className="info-card__address">
                        {props.item.address}
                    </address>
                ) : null}
                <div className="info-card__chargers">
                    {t('%{count} chargers', { count: props.item.evses.length })}
                </div>
                <div className="info-card__footer">
                    <ul className="info-card__details">
                        <li className="info-card__detail">
                            <Icon.Bolt />
                            <span className="caption">{str}</span>
                        </li>
                        {!props.atProperty ? (
                            <li className="info-card__detail">
                                <Icon.Distance />
                                <span className="caption">
                                    {Math.round(props.item.distance)}m
                                </span>
                            </li>
                        ) : null}
                    </ul>
                    {clickAction ? (
                        <span className="info-card__btn btn btn--outline-dark">
                            {t('Read more')}
                        </span>
                    ) : null}
                </div>
            </div>
            <ChargerModal
                size={800}
                title={t('Charger information')}
                btnRef={btnRef}
            >
                <ChargerDetails {...props} />
            </ChargerModal>
        </>
    );
}
