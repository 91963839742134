import { useContext, createContext, useEffect, useState } from 'react';
import axios from 'axios';
import qs from 'qs';
import { useLocaleContext } from './localeContext';
import { Outlet } from 'react-router-dom';

const SearchDataContext = createContext();

export function useSearchDataContext() {
    return useContext(SearchDataContext);
}

export function SearchDataProvider() {
    const [searchData, setSearchData] = useState();
    const { locale } = useLocaleContext();

    useEffect(() => {
        if (!locale) return;

        const getSearchData = async () => {
            const query = qs.stringify({
                locale: locale,
                populate: {
                    uspBlock: {
                        populate: '*'
                    }
                },
            });
            await axios
                .get(
                    `${process.env.REACT_APP_API_URL}/api/search?${query}`,
                    {
                        headers: {
                            Authorization: process.env.REACT_APP_API_KEY,
                        },
                    }
                )
                .then((res) => {
                    setSearchData(res.data.data.attributes);
                })
                .catch(() => {
                    setSearchData(null);
                });
        };

        if (locale) getSearchData();
    }, [locale]);

    return (
        <SearchDataContext.Provider
            value={{ searchData }}
        >
            <Outlet />
        </SearchDataContext.Provider>
    );
}
