import {
    useContext,
    createContext,
    useCallback,
    useState,
    useEffect,
    useMemo,
    useLayoutEffect,
} from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import LoginForm from '../../Components/Registration/Login/LoginForm';
import RegistrationForm from '../../Components/Registration/Registration/RegistrationForm';
import RemoveAccount from '../../Components/Profile/RemoveAccount/RemoveAccount';

import { useModalContext } from '../../core/context/modalContext.js';
import { useLocaleContext } from './localeContext';
import { Icon } from '../../common/Icon/Icon';

const UserContext = createContext();

export function useUserContext() {
    return useContext(UserContext);
}

export const USER_MESSAGE = {
    login: 'login',
    register: 'register',
};

export function UserContextProvider({ children }) {
    const navigate = useNavigate();
    let sessionToken = sessionStorage.getItem('authenticationToken');
    if (sessionToken === 'undefined') sessionToken = null;
    const { t } = useLocaleContext();
    const authenticationToken = JSON.parse(
        localStorage.getItem('authenticationToken') || sessionToken
    );
    const [message, setMessage] = useState(null);
    const [authToken, setAuthToken] = useState(authenticationToken);
    const [userDetails, setUserDetails] = useState({
        token: authToken,
    });
    const [loading, setLoading] = useState(false);
    const [modalDetails, setModalDetails] = useState({
        title: '',
        body: '',
    });
    const { createModal } = useModalContext();
    const {
        element: UserModal,
        open: openModal,
        close: closeModal,
    } = useMemo(() => createModal(), [createModal]);

    const setSessionStorageToken = useCallback((token) => {
        sessionStorage.setItem('authenticationToken', JSON.stringify(token));
        setAuthToken(token);
    }, []);

    const setLocalSessionToken = useCallback((token) => {
        localStorage.setItem('authenticationToken', JSON.stringify(token));
        setAuthToken(token);
    }, []);

    const removeAuthToken = useCallback(() => {
        sessionStorage.removeItem('authenticationToken');
        localStorage.removeItem('authenticationToken');
        setUserDetails({});
    } ,[]);

    const logout = useCallback(async () => {
        if (authToken) {
            removeAuthToken();
            setModalDetails({
                title: t('Log-out'),
                body: (
                    <div style={{ textAlign: 'center' }}>
                        {t('You are now logged out.')}
                    </div>
                ),
            });
        }
        openModal();
    }, [authToken, openModal, removeAuthToken, t]);

    const handleLoginResult = useCallback((result, doRemember) => {
        if (result.status === 200) {
            let token = result.data.token;
            let prev_login = result.data.prev_login;

            if (doRemember) {
                setLocalSessionToken(token);
            } else {
                setSessionStorageToken(token);
            }

            setUserDetails({ token, prev_login });
            setMessage(USER_MESSAGE.login);
            setModalDetails({
                title: t('Log in'),
                body: (
                    <div style={{ textAlign: 'center' }}>
                        {t('You are now logged in.')}
                    </div>
                ),
            });
        }
    }, [setLocalSessionToken, setSessionStorageToken, t]);

    const handleRegistrationResult = useCallback((result) => {
        if (result.status === 201) {
            setUserDetails({
                ...userDetails,
                email: result.data.email,
                status: result.data.status,
            });
            setMessage(USER_MESSAGE.register);
            setModalDetails({
                title: t('Create account'),
                body: (
                    <div>
                        <div className="info info--green" style={{ marginBottom: '16px'}}>
                            <Icon.Check className="icon-small icon-left"/>
                            {t('Check your e-mail to confirm your registration')}
                        </div>
                        <div>{t('An email has been sent to %{email} to confirm your account', {email: result.data.email})}</div>
                    </div>
                ),
            });
        } else {
            console.error(result);
        }
    }, [closeModal, navigate, userDetails]);

    const login = useCallback((email) => {
        setModalDetails({
            title: t('Log in'),
            body: (
                <LoginForm
                    email={email}
                    handleResult={handleLoginResult}
                    linkAway={closeModal}
                    register={register}
                />
            ),
        });
        openModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [closeModal, handleLoginResult, openModal]);

    const register = useCallback((email) => {
        setModalDetails({
            title: t('Create account'),
            body: (
                <RegistrationForm
                    email={email}
                    handleResult={handleRegistrationResult}
                    linkAway={closeModal}
                    login={login}
                />
            ),
        });
        openModal();
    }, [closeModal, handleRegistrationResult, login, openModal]);

    const removeUser = useCallback(() => {
        setModalDetails({
            title: t('Remove my profile'),
            body: <RemoveAccount closeModal={closeModal} />,
        });
        openModal();
    }, [closeModal, openModal, t]);

    // Grab user data when we have the token
    useLayoutEffect(() => {
        console.log('looking for token...');

        if (authToken) {
            console.log('getting user...');
            setLoading(true);

            let token = authToken;

            const getUser = async (userData) => {
                let result = await axios.get(
                    `${process.env.REACT_APP_SC_API_BASE}/user`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );

                setLoading(false);

                if (result.status === 200) {
                    let responseUserDetails = result.data;
                    const userCompany = responseUserDetails.company
                        ? {
                              ...responseUserDetails.company,
                              added: true,
                          }
                        : {
                              added: false,
                              name: '',
                              address: {
                                  country_code: '',
                                  line_1: '',
                                  postal_code: '',
                                  city: '',
                              },
                          };

                    setUserDetails({
                        ...userDetails,
                        ...responseUserDetails,
                        company: { ...userCompany },
                    });
                } else {
                    console.error(
                        'Error retrieving user, invalidating token',
                        result
                    );
                    removeAuthToken();
                }
            };

            getUser().catch((err, res) => {
                setLoading(false);

                console.error(
                    'Error retrieving user, invalidating token',
                    err.response
                );

                setModalDetails({
                    title: 'User error',
                    body: (
                        <div style={{ textAlign: 'center' }}>
                            {t('Error retrieving user. You are logged out.')}
                        </div>
                    ),
                });
                openModal();

                removeAuthToken();
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authToken]);

    useEffect(() => {
        const handleStorageUpdate = (e) => {
            const { key, newValue } = e;

            if (key !== 'authenticationToken') return;

            if (newValue) {
                setLocalSessionToken(newValue.replaceAll('"', ''));
            } else {
                removeAuthToken();
            }
        };

        window.addEventListener('storage', handleStorageUpdate);
        return () => {
            window.removeEventListener('storage', handleStorageUpdate);
        };
    }, [removeAuthToken, setLocalSessionToken]);

    return (
        <UserContext.Provider
            value={{
                userDetails,
                setUserDetails,
                logout,
                login,
                register,
                loading,
                setSessionStorageToken,
                setLocalSessionToken,
                message,
                removeUser,
            }}
        >
            {children}

            <UserModal title={modalDetails.title} size={560} showCloseButton>
                {modalDetails.body}
            </UserModal>
        </UserContext.Provider>
    );
}
