import React from 'react';

import { differenceInDays, format as formatDate, parseISO } from 'date-fns';
import { enUS, de, nl } from 'date-fns/locale';

import dompurify from 'dompurify';
import './PropertyInfo.css';

import { formatCancelPenalties, OccupancyString } from '../../helpers.js';
import Question from '../Question/Question';
import { useLocaleContext } from '../../core/context/localeContext';
import RoomInfo from '../RoomInfo/RoomInfo';
import CardImageSlider from '../CardImageSlider/CardImageSlider';
import { PAGE_STATUS } from '../Booking/Booking';
import SearchUSP from '../SearchUSP/SearchUSP';
import useScreenSize from '../../core/hooks/useScreenSize';

const sanitizer = dompurify.sanitize;

const PropertyInfo = ({
    item,
    bookingInfo,
    meta,
    hideRoomInfo,
    pageStatus,
}) => {
    const { t, locale } = useLocaleContext();
    const { isMobile } = useScreenSize();
    let chosenRate = bookingInfo?.chosenRate;

    let cancellationPolicy = chosenRate
        ? formatCancelPenalties(chosenRate, t)
        : undefined;

    // @Mutates
    let checkinInstructions = [];
    if (item?.checkin) {
        let importantInfoArr = [];
        let checkInOutArr = [];

        if (cancellationPolicy) {
            importantInfoArr.push(
                <div
                    className="property-info__cancellation-policy property-info__important-block"
                    key={importantInfoArr.length}
                >
                    <h4 className="h4">{t('Cancellation policy')}</h4>
                    <div>{cancellationPolicy.header}</div>
                    <div>{cancellationPolicy.body}</div>
                </div>
            );
        }

        if (item.checkin['24_hour']) {
            checkInOutArr.push(
                <div
                    className="property-info__important-block"
                    key={checkInOutArr.length}
                >
                    {item.checkin['24_hour']}
                </div>
            );
        }

        if (item.checkin || item.checkout) {
            checkInOutArr.push(
                <h4 className="h4" key={checkInOutArr.length}>
                    {t('Check-in information')}
                </h4>
            );
        }

        if (item.checkin) {
            // @Mutates
            let checkinStrArr = [];

            if (item.checkin.begin_time) {
                checkinStrArr.push(item.checkin.begin_time);
            }
            if (item.checkin.end_time) {
                checkinStrArr.push(item.checkin.end_time);
            }

            checkInOutArr.push(
                <div key={checkInOutArr.length}>
                    {t('Check-in time: %{time}', {
                        time: checkinStrArr.join(' - '),
                    })}
                </div>
            );
        }

        if (item.checkout) {
            let checkoutStrArr = [];
            if (item.checkout.time) {
                checkoutStrArr.push(item.checkout.time);
            }

            checkInOutArr.push(
                <div key={checkInOutArr.length}>
                    {t('Check-out time: %{time}', {
                        time: checkoutStrArr.join(' - '),
                    })}
                </div>
            );
        }

        if (item.checkin.min_age) {
            checkInOutArr.push(
                <div key={checkInOutArr.length}>
                    {t('Minimum age to check-in: %{age}', {
                        age: item.checkin.min_age,
                    })}
                </div>
            );
        }

        importantInfoArr.push(
            <div
                className="property-info__important-block"
                key={importantInfoArr.length}
            >
                {checkInOutArr}
            </div>
        );

        if (item.checkin.instructions) {
            importantInfoArr.push(
                <div
                    className="property-info__important-block"
                    key={importantInfoArr.length}
                >
                    <h4 className="h4">{t('Instructions')}</h4>
                    <div
                        className="property-info__instructions"
                        dangerouslySetInnerHTML={{
                            __html: sanitizer(item.checkin.instructions),
                        }}
                    />
                </div>
            );
        }
        if (item.checkin.special_instructions) {
            importantInfoArr.push(
                <div
                    className="property-info__important-block"
                    key={importantInfoArr.length}
                >
                    <h4 className="h4">{t('Special Instructions')}</h4>
                    <div
                        className="property-info__special-instructions"
                        dangerouslySetInnerHTML={{
                            __html: sanitizer(
                                item.checkin.special_instructions
                            ),
                        }}
                    />
                </div>
            );
        }

        checkinInstructions.push(
            <Question
                question={t('Important information')}
                answer={importantInfoArr}
                open
                key={checkinInstructions.length}
            />
        );

        if (item.fees) {
            let feesStrArr = [];

            if (item.fees.mandatory) {
                feesStrArr.push(
                    <div
                        className="property-info__fees property-info__fees-mandatory"
                        key={feesStrArr.length}
                    >
                        <div
                            dangerouslySetInnerHTML={{
                                __html: sanitizer(item.fees.mandatory),
                            }}
                        />
                    </div>
                );
            }

            if (item.fees.optional) {
                feesStrArr.push(
                    <div
                        className="property-info__fees property-info__fees-optional"
                        key={feesStrArr.length}
                    >
                        <div
                            dangerouslySetInnerHTML={{
                                __html: sanitizer(item.fees.optional),
                            }}
                        />
                    </div>
                );
            }

            checkinInstructions.push(
                <Question
                    question={t('Fees')}
                    answer={feesStrArr}
                    key={checkinInstructions.length}
                />
            );
        }

        if (item.policies) {
            let policiesStrArr = []; //@Mutates

            if (item.policies.know_before_you_go) {
                policiesStrArr.push(
                    <div
                        className="property-info__policies"
                        key={policiesStrArr.length}
                    >
                        <div
                            dangerouslySetInnerHTML={{
                                __html: sanitizer(
                                    item.policies.know_before_you_go
                                ),
                            }}
                        />
                    </div>
                );
            }

            checkinInstructions.push(
                <Question
                    question={t('Policies')}
                    answer={policiesStrArr}
                    key={checkinInstructions.length}
                />
            );
        }
    }

    const googleLink = encodeURI(
        `${item?.name},${item?.address?.line_1},${item?.address?.postal_code},${item?.address?.city},${item?.address?.country_code}`
    );

    const propertyLoader = (
        <div className="loading-property">
            <div className="loading-property__img block-loader"></div>
            <div className="loading-property__hotel block-loader"></div>
            <div className="loading-property__title block-loader"></div>
            <div className="loading-property__checkin block-loader"></div>
            <div className="loading-property__title block-loader"></div>
            <div className="loading-property__pricing block-loader"></div>
            <div className="loading-property__info block-loader"></div>
        </div>
    );

    let date_locale = enUS; //@mutates
    if (locale === 'de') date_locale = de;
    if (locale === 'nl') date_locale = nl;

    let numNights;

    if (meta.checkout && meta.checkin) {
        numNights = differenceInDays(
            parseISO(meta.checkout),
            parseISO(meta.checkin)
        );
    }

    let occupancyString = OccupancyString({
        adults: meta.adults,
        children: meta.child_age?.length,
        nights: numNights,
        t,
    });

    return pageStatus === PAGE_STATUS.error ? null : pageStatus ===
      PAGE_STATUS.loading ? (
        propertyLoader
    ) : (
        <div className="property-info">
            {!hideRoomInfo ? (
                <div className="property-info__container">
                    {!item.images.length ? null : (
                        <div className="property-info__img-container">
                            <CardImageSlider
                                images={item.images.slice(0, 10)}
                            />
                        </div>
                    )}

                    <h3 className="property-info__title h3">{item.name}</h3>
                    <a
                        className="property-info__address"
                        href={`https://www.google.com/maps/dir/?api=1&destination=${googleLink}`}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {item.address.line_1}, {item.address.postal_code}{' '}
                        {item.address.city}
                    </a>

                    <div className="property-info__check-in-out">
                        <div className="property-info__check-item">
                            <span className="h4">{t('Check-in')}</span>
                            {formatDate(parseISO(meta.checkin), 'd MMMM yyyy', {
                                locale: date_locale,
                            })}
                        </div>
                        <div className="property-info__check-item">
                            <span className="h4">{t('Check-out')}</span>
                            {formatDate(
                                parseISO(meta.checkout),
                                'd MMMM yyyy',
                                {
                                    locale: date_locale,
                                }
                            )}
                        </div>
                    </div>

                    <RoomInfo
                        meta={meta}
                        roomData={bookingInfo.rooms[0].extra}
                        bookingInfo={bookingInfo}
                        occupancyString={occupancyString}
                        numNights={numNights}
                        item={item}
                    />
                </div>
            ) : null}

            {!isMobile ? null : <SearchUSP />}

            <div className="property-info__check-in-instructions">
                {checkinInstructions}
                <Question
                    question={t('Disclaimers')}
                    answer={
                        <ul className="property-info__list">
                            {t('pricing_disclaimers').map((e, i) => (
                                <li className="property-info__item" key={i}>
                                    {e}
                                </li>
                            ))}
                            <li className="property-info__item">
                                {t('bed_type_disclaimer')}
                            </li>
                        </ul>
                    }
                />
            </div>
        </div>
    );
};

export default PropertyInfo;
