import React, { useCallback, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';

import { Icon } from '../../../common/Icon/Icon';
import { useLocaleContext } from '../../../core/context/localeContext';
import { useUserContext } from '../../../core/context/userContext';

const BASE_URL = process.env.REACT_APP_BASE_URL || 'https://www.sleepercharger.com';

const Preferences = () => {
    const { locale, t } = useLocaleContext();
    const { userDetails, setUserDetails } = useUserContext();
    const [preferences, setPreferences] = useState({
        long_distance: userDetails?.preferences?.long_distance || '',
        scenic_routes: userDetails?.preferences?.scenic_routes || '',
    });
    let navigate = useNavigate();

    const handleSubmit = useCallback(
        (e) => {
            e.preventDefault();
            // TODO send driving preferences to BE
            console.log(preferences);
            navigate('../profile');
        },
        [navigate, preferences]
    );

    const handleRadioChange = useCallback((e) => {
        setPreferences((prevState) => {
            return {
                ...prevState,
                [e.target.name]: e.target.value,
            };
        });
    }, []);

    return (
        <>
            <Helmet>
                <title>{`${t('Add driving preferences')} | SleeperCharger`}</title>
                <meta
                    name="description"
                    content={`${t('Add driving preferences')} | SleeperCharger`}
                />
                <link
                    rel="canonical"
                    href={`${BASE_URL}${
                        locale !== 'en' ? `/${locale}` : ''
                    }/profile/preferences`}
                />
            </Helmet>
            <section className="groups">
                <div className="wrap wrap--sm">
                    <div className="content-container">
                        <div className="profile__header">
                            <h1 className="profile__title h2">
                                {t('Add driving preferences')}
                            </h1>
                            <p className="profile__desc h4">
                                Get better offers based on how you like to drive
                            </p>
                        </div>
                        <form
                            className="profile__preferences-form"
                            onSubmit={handleSubmit}
                            id="preferences-form"
                        >
                            <div className="input-container">
                                <span
                                    className="input-label"
                                    id="long-distance-label"
                                >
                                    Do you enjoy driving long distances?
                                </span>
                                <div
                                    className="radio-container"
                                    role="radiogroup"
                                    aria-labelledby="long-distance-label"
                                >
                                    <label
                                        className={`radio-label${
                                            preferences.long_distance === 'yes'
                                                ? ' selected'
                                                : ''
                                        }`}
                                    >
                                        <input
                                            type="radio"
                                            name="long_distance"
                                            value="yes"
                                            onChange={(e) => {
                                                handleRadioChange(e);
                                            }}
                                            className="input-radio sr-only"
                                        />
                                        <span>Yes</span>
                                    </label>
                                    <label
                                        className={`radio-label${
                                            preferences.long_distance === 'no'
                                                ? ' selected'
                                                : ''
                                        }`}
                                    >
                                        <input
                                            type="radio"
                                            name="long_distance"
                                            value="no"
                                            onChange={(e) => {
                                                handleRadioChange(e);
                                            }}
                                            className="input-radio sr-only"
                                        />
                                        <span>No</span>
                                    </label>
                                </div>
                            </div>
                            <div className="input-container">
                                <span
                                    className="input-label"
                                    id="scenic-routes-label"
                                >
                                    Do you enjoy driving scenic routes?
                                </span>
                                <div
                                    className="radio-container"
                                    role="radiogroup"
                                    aria-labelledby="scenic-routes-label"
                                >
                                    <label
                                        className={`radio-label${
                                            preferences.scenic_routes === 'yes'
                                                ? ' selected'
                                                : ''
                                        }`}
                                    >
                                        <input
                                            type="radio"
                                            name="scenic_routes"
                                            value="yes"
                                            onChange={(e) => {
                                                handleRadioChange(e);
                                            }}
                                            className="input-radio sr-only"
                                        />
                                        <span>Yes</span>
                                    </label>
                                    <label
                                        className={`radio-label${
                                            preferences.scenic_routes === 'no'
                                                ? ' selected'
                                                : ''
                                        }`}
                                    >
                                        <input
                                            type="radio"
                                            name="scenic_routes"
                                            value="no"
                                            onChange={(e) => {
                                                handleRadioChange(e);
                                            }}
                                            className="input-radio sr-only"
                                        />
                                        <span>No</span>
                                    </label>
                                </div>
                            </div>
                        </form>
                        <div className="signup__footer">
                            <Link className="link" to="../profile">
                                Cancel
                            </Link>
                            <button
                                className="btn btn--blue"
                                type="submit"
                                form="preferences-form"
                            >
                                Save
                                <Icon.Checkmark className="icon-small icon-right" />
                            </button>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Preferences;
