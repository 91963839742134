import React from 'react';

const CustomToggle = ({
    id,
    label,
    checked,
    onChange,
    name,
    error,
    emptyMessage,
    invalidMessage,
}) => {
    return (
        <>
            <label className="toggle">
                <input
                    type="checkbox"
                    name={name}
                    checked={checked}
                    id={id}
                    onChange={onChange}
                />
                <span className="input-label">{label}</span>
                <span className="toggle__checkmark" />
            </label>
            {!error ? null : (
                <div className="input-error">
                    <span>
                        {error === 'empty'
                            ? emptyMessage
                            : error === 'invalid'
                            ? invalidMessage
                            : ''}
                    </span>
                </div>
            )}
        </>
    );
};

export default CustomToggle;
