import React, { useEffect, useRef, useState } from 'react';
import { useLocaleContext } from '../../core/context/localeContext';

import { Icon } from '../../common/Icon/Icon';

import './PhoneField.css';

const PhoneField = ({
    handleChange,
    countryOptions,
    countryValue,
    phoneValue,
}) => {
    const [showCode, setShowCode] = useState(false);
    const phoneRef = useRef(null);
    const countryRef = useRef(null);
    const codeRef = useRef([]);

    const { t } = useLocaleContext();

    useEffect(() => {
        const phone = phoneRef.current;
        const country = countryRef.current;
        const code = codeRef.current;

        if (!code.length || !phone || !country) return;

        code[1].style.display = showCode || phoneValue ? 'block' : 'none';

        country.style.paddingRight = code[0].offsetWidth + 36 + 'px';
        phone.style.paddingLeft =
            showCode || phoneValue ? code[1].offsetWidth + 19 + 'px' : '16px';
    }, [showCode, phoneValue, countryValue]);

    const allowOnlyDigits = (e) => {
        const allowedKeys = ['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Delete'];
        if (!allowedKeys.includes(e.key) && (e.key < '0' || e.key > '9')) {
          e.preventDefault();
        }
      };

    return (
        <div className="phone-field">
            <div className="phone-field__country-container">
                <select
                    className="phone-field__country"
                    ref={countryRef}
                    autoComplete="off"
                    type="phone_country_code"
                    name="phone_country_code"
                    value={countryValue}
                    onChange={handleChange}
                >
                    {countryOptions}
                </select>
                <span
                    className="phone-field__code"
                    ref={(ref) => {
                        codeRef.current[0] = ref;
                    }}
                >
                    (+{countryValue})
                </span>
                <Icon.TriangleSolid />
            </div>

            <div className="phone-field__phone-container">
                <span
                    className="phone-field__code"
                    ref={(ref) => {
                        codeRef.current[1] = ref;
                    }}
                >
                    +{countryValue}
                </span>
                <input
                    className="phone-field__phone"
                    ref={phoneRef}
                    autoComplete="off"
                    type="phone"
                    name="phone"
                    value={phoneValue}
                    onChange={handleChange}
                    placeholder={t("Enter phone number")}
                    pattern="\d*"
                    onFocus={() => setShowCode(true)}
                    onBlur={() => setShowCode(false)}
                    onKeyDown={allowOnlyDigits}
                />
            </div>
        </div>
    );
};

export default PhoneField;
