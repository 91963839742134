import React, { useContext } from 'react';

import dark from '../images/divider-hero-dark.svg';
import light from '../images/divider-hero-light.svg';
import { ThemeContext } from '../providers/ThemeProvider';

function DividerHero() {
    const { heroColor } = useContext(ThemeContext);

    return (
        <div
            className={`page-visual page-visual--hero${heroColor === 'hero-light' ? ' page-visual--right' : ''}`}
            style={{
                backgroundImage: `url(${heroColor === 'hero-dark' ? dark : heroColor === 'hero-light' ? light : ''})`,
            }}
        />
    );
}

export default DividerHero;
