import React, {useEffect} from "react";
import ReactMarkdown from 'react-markdown';
import { useLocaleContext } from '../../core/context/localeContext.js';

import './ContactForm.css';

const ContactForm = (props) => {
    const { locale } = useLocaleContext();

    useEffect(() => {
        const script = document.createElement('script');
        script.src = "//js-eu1.hsforms.net/forms/v2.js";
        document.body.appendChild(script)
        const listener=() => {
                if (window.hbspt){
                    window.hbspt.forms.create({
                        portalId: props.portalId,
                        formId: props.formId,
                        target: '#hubspotForm',
                        locale: locale
                    })
                }
            }
            script.addEventListener('load', listener)

        return () => {
            script.removeEventListener('load',listener)
        };
    }, [props.portalId, props.formId, locale])

    return (
        <section className="contact-form section">
            <div className="contact-form__container">
                <ReactMarkdown className="contact-form__desc text-container" linkTarget="_blank">
                    {props.text}
                </ReactMarkdown>
                <div className="contact-form__form-container">
                    <h2 className="contact-form__title h2">{ props.title }</h2>
                    <div id="hubspotForm"></div>
                </div>
            </div>
        </section>
    );

}

export default ContactForm;