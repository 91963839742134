import ReactMarkdown from 'react-markdown';
import { Link } from 'react-router-dom';
import { Icon } from '../../common/Icon/Icon';
import { useLocaleContext } from '../../core/context/localeContext';
import Question from '../Question/Question';

import './FAQBlock.css';

function FAQBlock({ title, button, faqs, reverse, isMobile, pageBuilder }) {
    const { urlPrefix } = useLocaleContext();

    const renderFAQButton = (button) => {
        if (button) {
            const { text, link, title } = button;
            if ((text || title) && link) {
                return (
                    <Link
                        to={`${urlPrefix}/${link}`}
                        className="faq-block__link btn btn--outline-dark"
                    >
                        {text || title}
                        <Icon.Arrow className="icon-small icon-right"/>
                    </Link>
                );
            }
        }
    };

    const renderFAQs = (faqs) => {
        if (faqs) {
            return faqs.map((faq, index) => {
                return (
                    <Question
                        key={index}
                        question={faq.question}
                        answer={faq.answer}
                    />
                );
            });
        }
    };

    return (
        <section className="faq-block section">
            <div
                className={`faq-block__container${
                    reverse ? ' reverse' : ''
                }`}
            >
                <div className="faq-block__content">
                    {!pageBuilder ? (
                        <h2 className="faq-block__title h2">{title}</h2>
                    ) : (
                        <ReactMarkdown className="faq-block__title text-container" linkTarget="_blank">
                            {title}
                        </ReactMarkdown>
                    )}

                    {!isMobile ? renderFAQButton(button) : null}
                </div>

                <div className="faq-block__questions">
                    {renderFAQs(faqs)}
                </div>
                {isMobile ? renderFAQButton(button) : null}
            </div>
        </section>
    );
}

export default FAQBlock;
