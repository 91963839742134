import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useLocaleContext } from '../../core/context/localeContext';
import { useSearchContext } from '../../core/context/searchContext';
import { MODAL_TRANSITION } from '../../common/ModalHOC/ModalHOC';
import { Icon } from '../../common/Icon/Icon';
import { buildParams } from '../../helpers';

import './LocationSearchMobile.css';
import LocationPredictions from '../LocationPredictions/LocationPredictions';

const SHOW_DEBUG = process.env.REACT_APP_SHOW_DEBUG;
let geocoder;

const LocationSearch = ({ setErrors, standalone }) => {
    let google = window.google;
    const { state, setState, setFromLocation, setDestinationMobileOpen } =
        useSearchContext();
    const navigate = useNavigate();
    const handleClear = useCallback(() => {
        setState((prevState) => ({
            ...prevState,
            dest: '',
            location: '',
            lat: 0,
            lng: 0,
        }));
        if (locationInputRef.current) locationInputRef.current.focus();
    }, [setState]);
    const handleOnKeyUp = (e) => {
        if (e.key === 'Enter') handleSubmit();
    };
    const { urlPrefix, t } = useLocaleContext();
    const [predictions, setPredictions] = useState([]);
    const [pendingMyLocation, setPendingMyLocation] = useState(false);
    const locationInputRef = useRef(null);

    useEffect(() => {
        geocoder = new google.maps.Geocoder();
        // predictionOrderNr = 0;
    }, [google]);

    useEffect(() => {
        if (state.location) setErrors([1]);
    }, [setErrors, state.location]);

    useEffect(() => {
        if (!locationInputRef.current) return;

        setTimeout(() => {
            locationInputRef.current.focus();
        }, MODAL_TRANSITION + 30);
    }, [locationInputRef]);

    const clearStyles = () => {
        document.body.style.inset = '';
        document.body.style.overflow = '';
        document.body.style.insetInlineEnd = '';
        document.body.style.position = '';
        document.querySelector('.nav').style.width = '';
        window.scrollTo(0, 0);
    };

    const getMyLocation = useCallback(async () => {
        setPendingMyLocation(true);
        setState((prevState) => ({
            ...prevState,
            location: t('Fetching location...'),
            lat: 0,
            lng: 0,
        }));
        navigator.geolocation.getCurrentPosition(
            async (position) => {
                setFromLocation(true);
                setPendingMyLocation(false);
                const result = await geocoder.geocode({
                    location: {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    },
                });
                setState((prevState) => ({
                    ...prevState,
                    location: result?.results[1]?.formatted_address || t('Location on map'),
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                    dest: result?.results[1]?.formatted_address || t('Location on map'),
                    zoom: 13,
                }));
                if (!standalone) {
                    navigate(-1);
                    return;
                }
                const { sid, location, ...params } = { ...state };
                const newParams = {
                    ...params,
                    dest: result?.results[1]?.formatted_address || t('Location on map'),
                    location: result?.results[1]?.formatted_address || t('Location on map'),
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                    zoom: 13,
                };
                clearStyles();
                setDestinationMobileOpen(false);
                navigate(
                    {
                        pathname: `${urlPrefix}/search`,
                        search: `?${buildParams(newParams)}`,
                    },
                    {
                        replace: true,
                    }
                );
            },
            (error) => {
                if (SHOW_DEBUG) console.log(error);
                setState((prevState) => ({
                    ...prevState,
                    location: '',
                    dest: '',
                    lat: 0,
                    lng: 0,
                }));
                setPendingMyLocation(false);
                switch (error.code) {
                    case error.PERMISSION_DENIED:
                        setErrors([
                            {
                                name: 'location',
                                value: 'Enable geolocation to use this feature.',
                            },
                        ]);
                        break;
                    case error.POSITION_UNAVAILABLE:
                        setErrors([
                            {
                                name: 'location',
                                value: 'Unable to fetch your location.',
                            },
                        ]);
                        break;
                    case error.TIMEOUT:
                        setErrors([
                            {
                                name: 'location',
                                value: 'Unable to fetch your location.',
                            },
                        ]);
                        break;
                    case error.UNKNOWN_ERROR:
                        setErrors([
                            {
                                name: 'location',
                                value: 'Unable to fetch your location.',
                            },
                        ]);
                        break;
                    default:
                        break;
                }
            }
        );
    }, [setErrors, setFromLocation, setState, t]);

    const handlePlaceId = async (prediction) => {
        if (prediction.place_id) {
            let result = await geocoder.geocode({
                placeId: prediction.place_id,
            });
            let place = result.results[0];

            if (place) {
                setFromLocation(true);
                setState((prevState) => ({
                    ...prevState,
                    dest: prediction.description,
                    location: prediction.description,
                    lat: place.geometry.location.lat(),
                    lng: place.geometry.location.lng(),
                    zoom: 13,
                }));
                if (!standalone) {
                    navigate(-1);
                    return;
                }
                const { sid, location, ...params } = { ...state };
                const newParams = {
                    ...params,
                    dest: prediction.description,
                    location: prediction.description,
                    lat: place.geometry.location.lat(),
                    lng: place.geometry.location.lng(),
                    zoom: 13,
                };
                clearStyles();
                setDestinationMobileOpen(false);
                navigate(
                    {
                        pathname: `${urlPrefix}/search`,
                        search: `?${buildParams(newParams)}`,
                    },
                    {
                        replace: true,
                    }
                );
            }
        } else {
            // its our own suggestion
            setState((prevState) => ({
                ...prevState,
                dest: prediction.description,
                location: prediction.description,
                lat: prediction.latlng.lat,
                lng: prediction.latlng.lng,
            }));

            setFromLocation(true);

            if (!standalone) {
                navigate(-1);
                return;
            }
            const { sid, location, ...params } = { ...state };
            const newParams = {
                ...params,
                dest: prediction.description,
                location: prediction.description,
                lat: prediction.latlng.lat,
                lng: prediction.latlng.lng,
                zoom: 13,
            };
            clearStyles();
            setDestinationMobileOpen(false);
            navigate(
                {
                    pathname: `${urlPrefix}/search`,
                    search: `?${buildParams(newParams)}`,
                },
                {
                    replace: true,
                }
            );
        }
    };

    const handleSubmit = async () => {
        handlePlaceId(predictions[0]);
    };

    return (
        <>
            <div className="location-search__input-container">
                <Icon.Search className="icon-small" />
                <input
                    type="text"
                    value={state.location}
                    onChange={(e) =>
                        setState((prevState) => ({
                            ...prevState,
                            location: e.target.value,
                        }))
                    }
                    onKeyUp={handleOnKeyUp}
                    placeholder={t('Search for a location')}
                    className="location-search__input-field"
                    ref={locationInputRef}
                />
                {state.location && (
                    <button
                        type="button"
                        onClick={handleClear}
                        className="location-search__clear"
                    >
                        <span className="sr-only">{t('Clear search')}</span>
                        <Icon.Plus className="icon-small" />
                    </button>
                )}
            </div>

            <div className="location-search__result-container">
                <LocationPredictions
                    predictions={predictions}
                    setPredictions={setPredictions}
                    onLocationClick={handlePlaceId}
                />
                {predictions.length ? null : 'geolocation' in navigator ? (
                    <button
                        className="location-search__item"
                        onClick={getMyLocation}
                        type="button"
                    >
                        <Icon.Pointer className="icon-small" />
                        <span className="location-search__item-name form-label">
                            {t('My location')}
                        </span>
                    </button>
                ) : null}
            </div>
        </>
    );
};

export default LocationSearch;
