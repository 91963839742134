import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import NewsletterSignup from '../NewsletterSignup/NewsletterSignup';
import { useLocaleContext } from '../../core/context/localeContext';
import { getCountryName } from '../../helpers';

import './Footer.css';

function Footer({ companyDetails, footerLinks, contactBlock, legalLinks }) {
    const { urlPrefix, locales, locale, t } = useLocaleContext();

    const [siteLinks, setSiteLinks] = useState([]);
    const [langluageLinks, setLangluageLinks] = useState([]);
    const [bottomLinks, setBottomLinks] = useState([]);

    useEffect(() => {
        setLangluageLinks(
            locales.map((l) => {
                const lang = getCountryName(l.code, locale, 'language');
                return {
                    link: `/${l.code}`, //language links always link to homepage
                    title: lang,
                };
            })
        );
        setSiteLinks(
            footerLinks.map((l) => {
                return {
                    link: `${urlPrefix}/${l.link}`, //These need urlPrefix
                    title: l.title,
                };
            })
        );
        setBottomLinks(
            legalLinks.map((l) => {
                return {
                    link: `${urlPrefix}/${l.link}`, //These need urlPrefix
                    title: l.title,
                };
            })
        );
    }, [locales, footerLinks, urlPrefix, locale, legalLinks]);

    return (
        <footer className="footer">
            <div className="wrap wrap--md">
                <div className="footer__container">
                    <div className="footer__col">
                        <h2 className="footer__label form-label">
                            {t('Navigate to')}
                        </h2>
                        <nav className="footer__nav">
                            <ul className="footer__list">
                                {siteLinks.map((link, i) => {
                                    return (
                                        <li className="footer__item" key={i}>
                                            <Link
                                                to={link.link}
                                                className="footer__link"
                                            >
                                                {link.title}
                                            </Link>
                                        </li>
                                    );
                                })}
                            </ul>
                        </nav>
                    </div>
                    <div className="footer__col">
                        <h2 className="footer__label form-label">
                            {t('Languages')}
                        </h2>
                        <nav className="footer__nav">
                            <ul className="footer__list">
                                {langluageLinks.map((link, i) => {
                                    return (
                                        <li className="footer__item" key={i}>
                                            <Link
                                                to={link.link}
                                                className="footer__link"
                                            >
                                                {link.title}
                                            </Link>
                                        </li>
                                    );
                                })}
                            </ul>
                        </nav>
                    </div>
                    <div className="footer__col">
                        <NewsletterSignup contactData={contactBlock} />
                    </div>
                </div>
                <div className="footer__bottom">
                    <div className="footer__legal">{companyDetails}</div>
                    <nav className="footer__bottom-nav">
                        <ul className="footer__bottom-list">
                            {bottomLinks.map((link, i) => {
                                return (
                                    <li className="footer__bottom-item" key={i}>
                                        <Link
                                            to={link.link}
                                            className="footer__bottom-link"
                                        >
                                            {link.title}
                                        </Link>
                                    </li>
                                );
                            })}
                        </ul>
                    </nav>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
