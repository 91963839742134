import { useState, useEffect, useContext } from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import qs from 'qs';

import { useOutletContext } from 'react-router-dom';
import BlogHero from './components/BlogHero/BlogHero.js';
import PageBuilder from '../../Components/PageBuilder';
import DraftTag from '../../Components/DraftTag/DraftTag.js';
import { useLocaleContext } from '../../core/context/localeContext.js';
import PageNotFound from '../../Components/PageNotFound';
import { ThemeContext } from '../../providers/ThemeProvider.js';
import useLoader from '../../core/hooks/useLoader.js';

const BASE_URL =
    process.env.REACT_APP_BASE_URL || 'https://www.sleepercharger.com';

const builderPopulate = {
    populate: {
        blogs: {
            populate: '*',
        },
        images: {
            populate: '*',
        },
        image: {
            populate: '*',
        },
        tips: {
            populate: '*',
        },
        uspItems: {
            populate: '*',
        },
        themes: {
            populate: '*',
        },
        category: {
            populate: '*',
        },
        button: {
            populate: '*',
        },
        properties: {
            populate: '*',
        },
        faqList: {
            populate: '*',
        },
        pt_themes: {
            populate: '*',
        },
    },
};

function BlogSingle(props) {
    const [blog, setBlog] = useState();
    const { locale, preview, setLocalizedSlugs } = useLocaleContext();
    const { setHeroWithSearch } = useContext(ThemeContext);
    useLoader(blog);

    useEffect(() => {
        setHeroWithSearch(false);
    }, [setHeroWithSearch]);

    const [pageID] = useOutletContext();

    useEffect(() => {
        const getPage = async () => {
            const query = qs.stringify(
                {
                    locale: locale,
                    filters: {
                        slug: `${pageID}`,
                    },
                    populate: {
                        featuredImage: {
                            populate: '*',
                        },
                        journeySummary: {
                            populate: '*',
                        },
                        localizations: {
                            populate: '.',
                        },
                        pageMeta: {
                            populate: '*',
                        },
                        builder: builderPopulate,
                    },
                    publicationState: preview ? 'preview' : 'live',
                },
                {
                    encodeValuesOnly: true, // prettify URL
                }
            );
            await axios
                .get(`${process.env.REACT_APP_API_URL}/api/blogs?${query}`, {
                    headers: {
                        Authorization: process.env.REACT_APP_API_KEY,
                    },
                })
                .then((res) => {
                    let blogData = res.data.data;
                    setBlog(blogData);
                })
                .catch((err) => {
                    setBlog(null);
                });
        };

        if (locale && pageID) getPage();
    }, [pageID, preview]);

    useEffect(() => {
        setLocalizedSlugs([]);
        if (!blog || !blog.length > 0) return;

        const localeSlugs = blog[0].attributes.localizations.data.map(
            (localization) => ({
                slug: localization.attributes.slug,
                locale: localization.attributes.locale,
                route: 'blog',
            })
        );

        setLocalizedSlugs(localeSlugs);

        return () => {
            setLocalizedSlugs(null);
        };
    }, [blog, setLocalizedSlugs]);

    let content = <></>;

    if (blog) {
        if (blog.length > 0) {
            const blogData = blog[0].attributes;
            //Prefer large image if available, else use default image
            blogData.image_src = blogData.featuredImage.data
                ? blogData.featuredImage.data.attributes.formats.large
                    ? blogData.featuredImage.data.attributes.formats.large.url
                    : blogData.featuredImage.data.attributes.url
                : null;
            content = (
                <>
                    <Helmet>
                        <title>
                            {blogData?.pageMeta?.title || blogData?.title} |
                            SleeperCharger
                        </title>
                        <meta
                            name="description"
                            content={
                                blogData?.pageMeta?.description ||
                                'Hotel with guaranteed electric vehicle chargers. Book now only at SleeperCharger.'
                            }
                        />
                        <link
                            rel="canonical"
                            href={
                                blogData?.pageMeta?.canonical ||
                                `${BASE_URL}${
                                    locale !== 'en' ? `/${locale}` : ''
                                }/blog/${pageID}`
                            }
                        />
                    </Helmet>
                    <article>
                        <>
                            <div className="wrap wrap--md">
                                {!blogData.publishedAt && (
                                    <DraftTag type="page" />
                                )}
                                <BlogHero
                                    title={blogData.title}
                                    date={blogData.createdAt}
                                    summary={blogData.summary}
                                    image_src={blogData.image_src}
                                    journeySummary={blogData.journeySummary}
                                    isMobile={props.isMobile}
                                />
                            </div>
                            {!blogData.builder.length ? null : (
                                <PageBuilder blocks={blogData.builder} />
                            )}
                        </>
                    </article>
                </>
            );
        } else {
            content = <PageNotFound />;
        }
    }

    return <>{content}</>;
}

export default BlogSingle;
