import { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import ReactMarkdown from 'react-markdown';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Icon } from '../../common/Icon/Icon';
import { useLocaleContext } from '../../core/context/localeContext';
import { useSearchContext } from '../../core/context/searchContext';
import useScreenSize from '../../core/hooks/useScreenSize';
import { formatHotelPath, getCountryName } from '../../helpers';

import './PropertyShowcase.css';

let geocoder;

const PropertyShowcase = ({ data }) => {
    const [isLocked, setIsLocked] = useState(false);
    const { urlPrefix, locale, translateToLocale, t } = useLocaleContext();
    const [propertyData, setPropertyData] = useState([]);
    const { fetchSupplier, fetchSuppliers, setMatches, setIsLoading } =
        useSearchContext();
    const { isMobile } = useScreenSize();
    const locationFetchInitiated = useRef(false);
    const idFetchInitiated = useRef(false);
    const [loading, setLoading] = useState({
        byId: true,
        byLocation: true,
    });

    useEffect(() => {
        geocoder = new window.google.maps.Geocoder();

        return () => {
            setMatches([]);
            setIsLoading(true);
        };
    }, [setMatches, setIsLoading]);

    const getLocation = useCallback(async () => {
        const results = await geocoder.geocode({
            address: data.location,
        });

        const center = results.results[0]?.geometry.bounds.getCenter();
        const southWest = results.results[0]?.geometry.bounds.getSouthWest();
        const northEast = results.results[0]?.geometry.bounds.getNorthEast();
        const lat = center.lat();
        const lng = center.lng();
        const lat0 = southWest.lat();
        const lng0 = southWest.lng();
        const lat1 = northEast.lat();
        const lng1 = northEast.lng();

        return {
            lat,
            lng,
            lat0,
            lng0,
            lat1,
            lng1,
        };
    }, [data.location]);

    const getLocationProperties = useCallback(async () => {
        locationFetchInitiated.current = true;
        const { lat, lng, lat0, lng0, lat1, lng1 } = await getLocation();

        const suppliers = await fetchSuppliers(
            {
                dest: data.location,
                lat,
                lng,
                lat0,
                lng0,
                lat1,
                lng1,
                rooms: 1,
                adults: 2,
                f_themes: data.pt_themes.data.map(
                    (el) => el.attributes.themeId
                ),
                limit: data.amountToShow || 3,
            },
            { shouldNavigate: false, dontAbort: true }
        );

        return suppliers;
    }, [data, fetchSuppliers, getLocation]);

    useEffect(() => {
        (async () => {
            if (!geocoder || !data.location || locationFetchInitiated.current)
                return;
            const locationProperties = await getLocationProperties();
            if (locationProperties) {
                setPropertyData((prevState) => [
                    ...prevState,
                    ...locationProperties.map((el) => ({ value: el })),
                ]);
            }
            setLoading((prevState) => ({
                ...prevState,
                byLocation: false,
            }));
        })();
    }, [data, getLocationProperties]);

    const handleLock = useCallback(() => {
        setIsLocked(true);
    }, []);

    const handleUnlock = useCallback(() => {
        setIsLocked(false);
    }, []);

    useEffect(() => {
        (async () => {
            if (idFetchInitiated.current) return;
            idFetchInitiated.current = true;
            const propertiesData = await Promise.allSettled(
                data.properties.map((property) => {
                    const propertyData = fetchSupplier(
                        {
                            sid: property.propertyId,
                        },
                        { shouldNavigate: false, shouldAbort: false }
                    );
                    return propertyData;
                })
            );
            setPropertyData((prevState) => [...prevState, ...propertiesData]);
            setLoading((prevState) => ({
                ...prevState,
                byId: false,
            }));
        })();
    }, [data.properties, fetchSupplier]);

    const expectedPropertyNumb = useMemo(
        () =>
            (data.location && (data.amountToShow || 3)) +
            data.properties.length,
        [data.amountToShow, data.location, data.properties.length]
    );

    const renderCards = useMemo(() => {
        return propertyData
            .filter((item) => item.value && item.value.name)
            .map((item) => item.value)
            .map((property, i) =>
                property ? (
                    <SwiperSlide className="property-showcase__item" key={i}>
                        <Link
                            className="property-showcase__card"
                            to={`${urlPrefix}${formatHotelPath({
                                name: property.name,
                                city: property.address?.city,
                                country: property.address?.country_code,
                                locale,
                                translateToLocale,
                            })}?sid=${property.sid || property._id}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <div className="property-showcase__card-img-container">
                                <img
                                    className="property-showcase__card-img cover-img"
                                    src={
                                        property.hero?.links['1000px'].href ||
                                        property.images?.find(
                                            (img) => img.hero_image
                                        ).links['1000px']?.href
                                    }
                                    alt={
                                        property.hero?.caption ||
                                        property.images?.find(
                                            (img) => img.hero_image
                                        ).caption
                                    }
                                />
                            </div>
                            <div className="property-showcase__card-content">
                                <h3 className="property-showcase__card-title h3">
                                    {property.name}
                                </h3>
                                <address className="property-showcase__card-address caption">
                                    {property.address.city},{' '}
                                    {getCountryName(
                                        property.address.country_code,
                                        locale,
                                        'region'
                                    )}
                                </address>
                                <span className="property-showcase__card-link btn btn--outline btn--xs">
                                    {t('Book now')}
                                    <Icon.Arrow className="icon-small icon-right" />
                                </span>
                            </div>
                        </Link>
                    </SwiperSlide>
                ) : null
            );
    }, [propertyData, urlPrefix, locale, translateToLocale, t]);

    const renderPlaceholders = useMemo(() => {
        return loading.byId || loading.byLocation
            ? [...Array(expectedPropertyNumb - renderCards.length)].map(
                  (el, i) => (
                      <SwiperSlide
                          className="property-showcase__item property-showcase__item--placeholder"
                          key={i}
                      >
                          <div className="block-loader" />
                      </SwiperSlide>
                  )
              )
            : [];
    }, [
        expectedPropertyNumb,
        loading.byId,
        loading.byLocation,
        renderCards.length,
    ]);

    return (
        <section className="property-showcase section">
            <div className="wrap wrap--md">
                {!data.text ? null : (
                    <ReactMarkdown className="property-showcase__title text-container" linkTarget="_blank">
                        {data.text}
                    </ReactMarkdown>
                )}
                <div
                    className={`property-showcase__container${
                        isLocked ||
                        (!isMobile &&
                            renderCards?.length + renderPlaceholders?.length <
                                5)
                            ? ' locked'
                            : ''
                    }`}
                >
                    {isMobile ? (
                        <Swiper
                            onLock={handleLock}
                            onUnlock={handleUnlock}
                            className="property-showcase__slider"
                            slidesPerView={'auto'}
                            init={false}
                        >
                            {renderCards}
                            {renderPlaceholders}
                        </Swiper>
                    ) : (
                        <>
                            {renderCards}
                            {renderPlaceholders}
                        </>
                    )}
                </div>
            </div>
        </section>
    );
};

export default PropertyShowcase;
