import React, { useState } from 'react';
import { useCallback } from 'react';
import CustomInput from '../../../common/CustomInput/CustomInput';
import { Icon } from '../../../common/Icon/Icon';

const NewTraveller = ({
    setShowTravellerForm,
    setEditTraveller,
    inline,
    traveller,
}) => {
    const [name, setName] = useState(traveller?.name || '');
    const [isMinor, setIsMinor] = useState(traveller?.minor || false);

    const handleAdd = useCallback(() => {
        // TODO send a request to add a traveller
        console.log(isMinor, name);
        if (setShowTravellerForm) setShowTravellerForm(false);
    }, [isMinor, name, setShowTravellerForm]);

    const handleEdit = useCallback(() => {
        // TODO send a request to edit a traveller
        console.log(isMinor, name);
        if (setEditTraveller) setEditTraveller(null);
    }, [isMinor, name, setEditTraveller]);

    return (
        <div className="profile__travellers">
            <div className="profile__travellers-container">
                <CustomInput
                    id="name"
                    label="Name"
                    name="firstname"
                    value={name}
                    onChange={(e) => {
                        setName(e.target.value);
                    }}
                    placeholder="Name"
                />
                <div className="input-container">
                    <label className="toggle">
                        <input
                            type="checkbox"
                            name={'is_business_trip'}
                            value={isMinor}
                            onChange={() =>
                                setIsMinor((prevState) => !prevState)
                            }
                            checked={isMinor}
                        />
                        <span className="toggle__label">{'Age < 18'}</span>
                        <span className="toggle__checkmark" />
                    </label>
                </div>
            </div>
            {!inline ? (
                <div className="profile__travellers-footer">
                    <button
                        className="link"
                        type="button"
                        onClick={() => setShowTravellerForm(false)}
                    >
                        Cancel
                    </button>
                    <button
                        className="btn btn--blue"
                        type="button"
                        onClick={handleAdd}
                        disabled={name.trim() === ''}
                    >
                        Add traveller
                        <Icon.Plus className="icon-small icon-right" />
                    </button>
                </div>
            ) : (
                <button
                    className="btn btn--blue"
                    disabled={name.trim() === ''}
                    onClick={handleEdit}
                >
                    Save
                </button>
            )}
        </div>
    );
};

export default NewTraveller;
