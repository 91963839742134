import { useMemo, useRef } from 'react';

import { useLocaleContext } from '../../core/context/localeContext';
import { useModalContext } from '../../core/context/modalContext.js';

import { Icon } from '../../common/Icon/Icon.js';
import ChargerDetails from '../ChargerDetails/ChargerDetails.js';

import './AdminChargerInfoCard.css';

export default function AdminChargerInfoCard(props) {
    const { t } = useLocaleContext();
    const btnRef = useRef(null);
    const { createModal } = useModalContext();
    const { element: ChargerModal, open: openModal } = useMemo(
        () => createModal(),
        [createModal]
    );

    // either we passed a custom action, or this opens its modal
    let clickAction = props.onClick || openModal;

    return (
        <>
            <div
                className={`admin-info-card${
                    props.selected ? ` admin-info-card--selected` : ''
                }${
                    props.isHot ? ' hover' : ''
                }`}
            >
                <button
                    className="admin-info-card__modal-btn"
                    type="button"
                    onClick={openModal}
                >
                    <span className="admin-info-card__title h4">
                        {props.item.owner.name}
                    </span>
                    {false ? (
                        <span className="admin-info-card__access_type caption">
                            {t('%{type} charger', {
                                type: props.item.access_type,
                            })}
                        </span>
                    ) : null}
                    {!props.atProperty ? (
                        <address className="admin-info-card__address caption">
                            {props.item.address}
                        </address>
                    ) : null}
                </button>
                <button
                    className="admin-info-card__select-btn"
                    type="button"
                    onClick={clickAction}
                >
                    <span className="sr-only">
                        Confirm charger {props.item.owner.name}
                    </span>
                    {props.selected ? (
                        <Icon.Checkmark className="icon-small" />
                    ) : (
                        <Icon.Plus className="icon-small" />
                    )}
                </button>
            </div>
            <ChargerModal
                size={800}
                title={t('Charger information')}
                btnRef={btnRef}
            >
                <ChargerDetails {...props} />
            </ChargerModal>
        </>
    );
}
