import { React, useEffect, useState, useRef, useMemo } from 'react';
import { createCustomEqual } from 'fast-equals';

import Recommendation from '../Recommendation/Recommendation.js';
import CustomMarker from './CustomMarker.js';
// import GoogleMapPolygon from './GoogleMapPolygon.js';
import DetectClickOutside from '../../core/hooks/detectClickOutside.js';
import { useSearchContext } from '../../core/context/searchContext.js';
import { useModalContext } from '../../core/context/modalContext.js';
import ChargerDetails from '../ChargerDetails/ChargerDetails.js';
import { useLocaleContext } from '../../core/context/localeContext.js';
import { Icon } from '../../common/Icon/Icon.js';
import useScreenSize from '../../core/hooks/useScreenSize.js';

// const CHARGERS_NEARBY_MAX_DISTANCE = 200;
const SHOW_DEBUG = process.env.REACT_APP_SHOW_DEBUG;

export default function SimpleGoogleMap(props) {
    const {
        abortController,
        meta,
        fromPop,
        setFromLocation,
        setState,
        state,
        shouldPan,
        setShouldPan,
        dontSearch,
    } = useSearchContext();
    const ref = useRef(null);
    const [map, setMap] = useState(null);
    const { t } = useLocaleContext();
    const { isMobile } = useScreenSize();
    const [polygons, setPolygons] = useState([]);
    const [mapMarkers, setMapMarkers] = useState([]);
    const [selectedMarker, setSelectedMarker] = useState(0);
    const [hoveredMarker, setHoveredMarker] = useState(0);
    const [showMarkers, setShowMarkers] = useState(false);
    const searchTimeout = useRef(null);
    const panTimeout = useRef(null);
    const [initial, setInitial] = useState(true);
    const { createModal } = useModalContext();
    const { element: ChargerModal, open: openModal } = useMemo(
        () => createModal(),
        [createModal]
    );

    const google = window.google;

    const options = useMemo(
        () => ({
            disableDefaultUI: true,
            maxZoom: 19,
            gestureHandling: isMobile ? 'cooperative' : 'greedy',
            styles: [
                {
                    featureType: 'all',
                    elementType: 'geometry.fill',
                    stylers: [
                        {
                            color: '#d1f3dc',
                        },
                    ],
                },
                {
                    featureType: 'all',
                    elementType: 'labels.text',
                    stylers: [
                        {
                            color: '#665e54',
                        },
                    ],
                },
                {
                    featureType: 'all',
                    elementType: 'labels.text.stroke',
                    stylers: [
                        {
                            visibility: 'off',
                        },
                    ],
                },
                {
                    featureType: 'administrative.locality',
                    elementType: 'geometry.fill',
                    stylers: [
                        {
                            color: '#a74444',
                        },
                    ],
                },
                {
                    featureType: 'landscape.man_made',
                    elementType: 'geometry.fill',
                    stylers: [
                        {
                            color: '#f5f1ea',
                        },
                    ],
                },
                {
                    featureType: 'poi',
                    elementType: 'labels.icon',
                    stylers: [
                        {
                            visibility: 'off',
                        },
                    ],
                },
                {
                    featureType: 'road',
                    elementType: 'all',
                    stylers: [
                        {
                            saturation: '-71',
                        },
                        {
                            gamma: '0.84',
                        },
                        {
                            lightness: '37',
                        },
                    ],
                },
                {
                    featureType: 'road',
                    elementType: 'geometry.fill',
                    stylers: [
                        {
                            color: '#ffffff',
                        },
                        {
                            visibility: 'on',
                        },
                        {
                            lightness: '48',
                        },
                        {
                            saturation: '-100',
                        },
                    ],
                },
                {
                    featureType: 'road',
                    elementType: 'geometry.stroke',
                    stylers: [
                        {
                            saturation: '-42',
                        },
                        {
                            color: '#efe3cc',
                        },
                        {
                            visibility: 'on',
                        },
                    ],
                },
                {
                    featureType: 'road',
                    elementType: 'labels',
                    stylers: [
                        {
                            visibility: 'simplified',
                        },
                        {
                            gamma: '1.76',
                        },
                        {
                            lightness: '-18',
                        },
                        {
                            saturation: '18',
                        },
                    ],
                },
                {
                    featureType: 'road',
                    elementType: 'labels.text',
                    stylers: [
                        {
                            lightness: '23',
                        },
                        {
                            saturation: '-62',
                        },
                    ],
                },
                {
                    featureType: 'road',
                    elementType: 'labels.text.fill',
                    stylers: [
                        {
                            visibility: 'on',
                        },
                    ],
                },
                {
                    featureType: 'road',
                    elementType: 'labels.icon',
                    stylers: [
                        {
                            visibility: 'off',
                        },
                    ],
                },
                {
                    featureType: 'road.highway.controlled_access',
                    elementType: 'geometry.fill',
                    stylers: [
                        {
                            visibility: 'on',
                        },
                        {
                            color: '#94bec9',
                        },
                    ],
                },
                {
                    featureType: 'road.highway.controlled_access',
                    elementType: 'geometry.stroke',
                    stylers: [
                        {
                            visibility: 'off',
                        },
                    ],
                },
                {
                    featureType: 'transit',
                    elementType: 'labels.icon',
                    stylers: [
                        {
                            visibility: 'off',
                        },
                    ],
                },
                {
                    featureType: 'water',
                    elementType: 'all',
                    stylers: [
                        {
                            color: '#bfeff6',
                        },
                    ],
                },
            ],
        }),
        [props.center, props.zoom, isMobile]
    );

    // because React does not do deep comparisons, a custom hook is used
    // see discussion in https://github.com/googlemaps/js-samples/issues/946
    useDeepCompareEffectForMaps(() => {
        if (map) {
            map.setOptions({
                ...options,
                minZoom: !props.smallMap ? 3 : 16,
                restriction: {
                    latLngBounds: !props.smallMap
                        ? {
                              east: 184,
                              north: 85,
                              south: -60,
                              west: -175,
                          }
                        : {
                              east: props.center.lng + 0.008,
                              north: props.center.lat + 0.005,
                              south: props.center.lat - 0.005,
                              west: props.center.lng - 0.008,
                          },
                },
            });
        }
    }, [map]);

    useEffect(() => {
        if (map) {
            if (initial) {
                map.setZoom(props.zoom);
            }
            if (shouldPan && !dontSearch) {
                const zoom = map.getZoom();
                if (zoom !== props.zoom) map.setZoom(props.zoom);
            }
        }
    }, [props.zoom, map, initial, shouldPan, dontSearch]);

    useEffect(() => {
        if (map) {
            if (initial) {
                setTimeout(() => {
                    map.panTo(props.center);
                }, 0);
                return;
            }
            if (shouldPan && !dontSearch) {
                setTimeout(() => {
                    map.panTo(props.center);
                }, 0);
            }
        }
    }, [dontSearch, initial, map, props.center, shouldPan]);

    useEffect(() => {
        // Create map when it's not there
        if (ref.current && !map) {
            setMap(new window.google.maps.Map(ref.current, options));
        }
    }, [ref, map, options]);

    // useEffect(() => {
    //     if (map) {
    //         let center = props.center;

    //         function getCirclePoints(center, radius, numPoints, clockwise) {
    //             var points = [];
    //             if (google.maps.geometry) {
    //                 for (var i = 0; i < numPoints; ++i) {
    //                     var angle = (i * 360) / numPoints;
    //                     if (!clockwise) {
    //                         angle = 360 - angle;
    //                     }

    //                     var p = google.maps.geometry.spherical.computeOffset(
    //                         center,
    //                         radius,
    //                         angle
    //                     );
    //                     points.push(p);
    //                 }

    //                 if (points.length > 1) {
    //                     points.push(points[0]);
    //                 }
    //             }
    //             return points;
    //         }

    // if (props.showRadius === 'donut') {
    // setPolygons([
    // <GoogleMapPolygon
    //     key={0}
    //     paths={[
    //         getCirclePoints(
    //             center,
    //             Number(props.radius),
    //             128,
    //             true
    //         ),
    //         getCirclePoints(
    //             center,
    //             Number(props.radius) / 2,
    //             128,
    //             false
    //         ),
    //     ]}
    //     strokeColor={'#06DBFF'}
    //     strokeOpacity={1}
    //     strokeWeight={0}
    //     fillColor={'#06DBFF'}
    //     fillOpacity={0.2}
    //     map={map}
    //     center={center}
    // />,
    // <GoogleMapPolygon
    //     key={1}
    //     paths={[
    //         [
    //             { lat: -90, lng: -180 },
    //             { lat: 90, lng: -180 },
    //             { lat: 90, lng: 180 },
    //             { lat: -90, lng: 180 },
    //             { lat: -90, lng: 0 },
    //         ],
    //         getCirclePoints(
    //             center,
    //             Number(props.radius),
    //             128,
    //             false
    //         ),
    //     ]}
    //     strokeColor={'#383128'}
    //     strokeOpacity={1}
    //     strokeWeight={0}
    //     fillColor={'#383128'}
    //     fillOpacity={0.2}
    //     map={map}
    //     center={center}
    // />,
    // ]);

    // setMapMarkers([
    // center point
    // <SimpleMarker
    //     key={0}
    //     position={center}
    //     map={map}
    //     icon={{
    //         url: '/assets/icons/map/focus-point.svg',
    //     }}
    //     zIndex={0}
    // />,
    // range label 1
    // <SimpleMarker
    //     key={1}
    //     position={{
    //         lng: center.lng,
    //         lat: center.lat + Number(props.radius) / 111200,
    //     }}
    //     map={map}
    //     icon={{
    //         url: 'none',
    //         origin: new google.maps.Point(0, 0),
    //         anchor: new google.maps.Point(0, 0),
    //         size: new google.maps.Size(0, 0, 'px', 'px'),
    //     }}
    //     label={{
    //         text:
    //             Number(props.radius) / 1000 >= 1
    //                 ? `${parseFloat(
    //                     (Number(props.radius) / 1000).toFixed(
    //                         1
    //                     )
    //                 )}km`
    //                 : `${Number(props.radius).toFixed(0)}m`,
    //         className: 'range-label',
    //         color: '#383128',
    //         fontSize: '12px',
    //         fontFamily: 'ClashDisplay-Semibold',
    //     }}
    //     zIndex={0}
    // />,
    // range label 2
    // <SimpleMarker
    //     key={2}
    //     position={{
    //         lng: props.center.lng,
    //         lat:
    //             props.center.lat +
    //             Number(props.radius) / 222200,
    //     }}
    //     map={map}
    //     icon={{
    //         url: 'none',
    //         origin: new google.maps.Point(0, 0),
    //         anchor: new google.maps.Point(0, 0),
    //         size: new google.maps.Size(0, 0, 'px', 'px'),
    //     }}
    //     clickable={false}
    //     label={{
    //         text:
    //             Number(props.radius) / 2000 >= 1
    //                 ? `${parseFloat(
    //                     (Number(props.radius) / 2000).toFixed(
    //                         1
    //                     )
    //                 )}km`
    //                 : `${(Number(props.radius) / 2).toFixed(
    //                     0
    //                 )}m`,
    //         className: 'range-label',
    //         color: '#383128',
    //         fontSize: '12px',
    //         fontFamily: 'ClashDisplay-Semibold',
    //     }}
    //     zIndex={0}
    // />,
    // ]);
    // }

    // @TODO rewrite to new react child way
    //         if (props.showRadius === 'circle') {
    //             new google.maps.Circle({
    //                 strokeColor: '#06DBFF',
    //                 strokeOpacity: 1,
    //                 strokeWeight: 0,
    //                 fillColor: '#06DBFF',
    //                 fillOpacity: 0.25,
    //                 map,
    //                 center: center,
    //                 radius: CHARGERS_NEARBY_MAX_DISTANCE,
    //             });
    //             new google.maps.Circle({
    //                 strokeColor: '#06DBFF',
    //                 strokeOpacity: 1,
    //                 strokeWeight: 0,
    //                 fillColor: '#06DBFF',
    //                 fillOpacity: 0.25,
    //                 map,
    //                 center: center,
    //                 radius: CHARGERS_NEARBY_MAX_DISTANCE * 0.66,
    //             });
    //         }
    //     }
    // }, [
    //     google.maps.Circle,
    //     google.maps.Point,
    //     google.maps.Size,
    //     google.maps.geometry,
    //     map,
    //     props.center,
    //     props.radius,
    //     props.showRadius,
    // ]);

    useEffect(() => {
        if (map) {
            ['idle', 'drag', 'dragstart', 'click', 'bounds_changed'].forEach(
                (eventName) =>
                    window.google.maps.event.clearListeners(map, eventName)
            );

            if (SHOW_DEBUG) {
                map.addListener('click', (evt) => {
                    console.log({
                        lng: evt.latLng.lng(),
                        lat: evt.latLng.lat(),
                    });
                });
            }

            map.addListener('idle', () => {
                const zoom = map.getZoom();
                const center = map.getCenter();
                if (!center) return;
                const centerLatLng = {
                    lat: center.lat(),
                    lng: center.lng(),
                };

                if (panTimeout.current) window.clearTimeout(panTimeout.current);
                panTimeout.current = window.setTimeout(() => {
                    setShouldPan(false);
                }, 300);

                let mapBounds = map.getBounds();
                let jsonBounds = mapBounds.toJSON();
                const bounds = {
                    lat0: jsonBounds.south,
                    lng0: jsonBounds.west,
                    lat1: jsonBounds.north,
                    lng1: jsonBounds.east,
                };
                if (searchTimeout.current)
                    window.clearTimeout(searchTimeout.current);
                setInitial(false);
                searchTimeout.current = window.setTimeout(() => {
                    if (!props.smallMap) {
                        props.handleIdle(centerLatLng, bounds, zoom, initial);
                    }
                }, 300);
            });

            map.addListener('bounds_changed', () => {
                if (!shouldPan && !initial) {
                    setState((prevState) => ({
                        ...prevState,
                        dest: t('Location on map'),
                    }));
                }
                if (abortController?.current && !fromPop)
                    abortController.current.abort();
            });
            map.addListener('drag', () => {
                if (abortController?.current && !fromPop)
                    abortController.current.abort();
            });
        }
        // eslint-disable-next-line
    }, [
        abortController,
        map,
        props.center,
        props.setZoom,
        props.setCenter,
        props.onDragEnd,
        props.zoom,
        fromPop,
        state,
        shouldPan,
    ]);

    let markers = useMemo(
        () =>
            props.matches.map((e, i) => {
                if (props.smallMap) {
                    // NOTE(gb) props.matches types seem to differ for small Map?
                    if (e.type === 'charger') {
                        let chargerMarkers = [
                            // charger icon
                            <CustomMarker
                                key={0}
                                map={map}
                                position={e.latlng}
                                content={
                                    <button
                                        type="button"
                                        className="small-map-marker-charger"
                                        onClick={() => {
                                            setSelectedMarker(e);
                                            openModal();
                                        }}
                                        onMouseOver={() => {
                                            setHoveredMarker(e);
                                        }}
                                        onMouseOut={() => setHoveredMarker(0)}
                                    >
                                        <span className="sr-only">
                                            {e.name}
                                        </span>
                                    </button>
                                }
                                hover={
                                    hoveredMarker._id &&
                                    hoveredMarker._id === e._id
                                }
                            />,
                            // label
                            <CustomMarker
                                key={1}
                                map={map}
                                position={e.latlng}
                                content={
                                    <button
                                        type="button"
                                        className="small-map-marker-charger hidden"
                                        onClick={() => {
                                            setSelectedMarker(e);
                                            openModal();
                                        }}
                                        onMouseOver={() => {
                                            setHoveredMarker(e);
                                        }}
                                        onMouseOut={() => setHoveredMarker(0)}
                                    >
                                        <span className="map-marker-property__chargers map-marker-property__chargers--blue">
                                            {e.evses.length}
                                        </span>
                                        <span className="sr-only">
                                            {e.name}
                                        </span>
                                    </button>
                                }
                                hover={
                                    hoveredMarker._id &&
                                    hoveredMarker._id === e._id
                                }
                            />,
                        ];

                        return chargerMarkers;
                    } else {
                        // property marker
                        let propertyMarker = (
                            <CustomMarker
                                key={i}
                                map={map}
                                position={e.latlng}
                                content={
                                    <div className="small-map-marker-property" />
                                }
                            />
                        );

                        return propertyMarker;
                    }
                } else {
                    const confirmedChargers = e.confirmed_chargers?.reduce(
                        (accumulator, charger) =>
                            accumulator + charger.evses.length,
                        0
                    );
                    const chargersNearby = e.chargers?.reduce(
                        (accumulator, charger) =>
                            accumulator + charger.evses.length,
                        0
                    );
                    const amount = showMarkers
                        ? confirmedChargers
                        : confirmedChargers + chargersNearby;

                    if (!e.totalPriceInc) {
                        // Property Marker
                        let propertyMarker = (
                            <CustomMarker
                                key={e._id}
                                map={map}
                                position={e.latlng}
                                price={e.totalPriceInc}
                                content={
                                    <button
                                        type="button"
                                        className="map-marker-property"
                                        onClick={
                                            !props.disableClick
                                                ? () => setSelectedMarker(e)
                                                : undefined
                                        }
                                        onMouseOver={() =>
                                            props.onMouseOverMarker(e._id)
                                        }
                                        onMouseOut={() =>
                                            props.onMouseOverMarker('')
                                        }
                                    >
                                        <span className="sr-only">
                                            {e.name}
                                        </span>
                                    </button>
                                }
                                hover={
                                    (selectedMarker._id &&
                                        selectedMarker._id === e._id) ||
                                    props.hotItemId.toString() ===
                                        e._id.toString()
                                }
                            />
                        );

                        let propertyMarkerLabel =
                            amount <= 0 ? null : (
                                <CustomMarker
                                    key={i}
                                    map={map}
                                    position={e.latlng}
                                    price={e.totalPriceInc}
                                    amount={amount}
                                    content={
                                        <button
                                            type="button"
                                            className="map-marker-property hidden"
                                            tabIndex={-1}
                                            onClick={
                                                !props.disableClick
                                                    ? () => setSelectedMarker(e)
                                                    : undefined
                                            }
                                            onMouseOver={() =>
                                                props.onMouseOverMarker(e._id)
                                            }
                                            onMouseOut={() =>
                                                props.onMouseOverMarker('')
                                            }
                                        >
                                            <span className="map-marker-property__chargers">
                                                {amount}
                                            </span>
                                            <span className="sr-only">
                                                {e.name}
                                            </span>
                                        </button>
                                    }
                                    hover={
                                        (selectedMarker._id &&
                                            selectedMarker._id === e._id) ||
                                        props.hotItemId.toString() ===
                                            e._id.toString()
                                    }
                                />
                            );

                        return [propertyMarker, propertyMarkerLabel];
                    } else {
                        const propertyMarker = (
                            <CustomMarker
                                key={e._id}
                                map={map}
                                position={e.latlng}
                                price={e.totalPriceInc}
                                content={
                                    <button
                                        type="button"
                                        className="map-marker-price"
                                        onClick={
                                            !props.disableClick
                                                ? () => setSelectedMarker(e)
                                                : undefined
                                        }
                                        onMouseOver={() =>
                                            props.onMouseOverMarker(e._id)
                                        }
                                        onMouseOut={() =>
                                            props.onMouseOverMarker('')
                                        }
                                    >
                                        {`€ ${Number(
                                            e.totalPriceInc / e.numNights
                                        ).toFixed(0)}`}
                                        <span className="sr-only">
                                            {e.name}
                                        </span>
                                    </button>
                                }
                                hover={
                                    (selectedMarker._id &&
                                        selectedMarker._id === e._id) ||
                                    props.hotItemId.toString() ===
                                        e._id.toString()
                                }
                            />
                        );

                        const propertyMarkerLabel =
                            amount <= 0 ? null : (
                                <CustomMarker
                                    key={i}
                                    map={map}
                                    position={e.latlng}
                                    price={e.totalPriceInc}
                                    amount={amount}
                                    content={
                                        <button
                                            type="button"
                                            className="map-marker-price hidden"
                                            tabIndex={-1}
                                            onClick={
                                                !props.disableClick
                                                    ? () => setSelectedMarker(e)
                                                    : undefined
                                            }
                                            onMouseOver={() =>
                                                props.onMouseOverMarker(e._id)
                                            }
                                            onMouseOut={() =>
                                                props.onMouseOverMarker('')
                                            }
                                        >
                                            {`€ ${Number(
                                                e.totalPriceInc
                                            ).toFixed(0)}`}
                                            <span className="map-marker-price__chargers">
                                                {amount}
                                            </span>
                                        </button>
                                    }
                                    hover={
                                        (selectedMarker._id &&
                                            selectedMarker._id === e._id) ||
                                        props.hotItemId.toString() ===
                                            e._id.toString()
                                    }
                                />
                            );

                        return [propertyMarker, propertyMarkerLabel];
                    }
                }
            }),
        [
            map,
            openModal,
            props,
            selectedMarker._id,
            hoveredMarker._id,
            showMarkers,
        ]
    );

    const uniqueChargers = useMemo(() => {
        if (props.smallMap) return [];
        const uniqueChargersArray = [];

        props.matches.forEach((match) => {
            match.chargers.forEach((charger) => {
                if (
                    uniqueChargersArray.some((item) => item._id === charger._id)
                )
                    return;
                uniqueChargersArray.push(charger);
            });
        });

        return uniqueChargersArray;
    }, [props.matches, props.smallMap]);

    const chargerMarkers = useMemo(
        () =>
            uniqueChargers.map((charger, i) => {
                if (props.smallMap) return null;

                return (
                    <CustomMarker
                        key={i}
                        map={map}
                        position={{
                            lat: charger.loc.coordinates[1],
                            lng: charger.loc.coordinates[0],
                        }}
                        content={
                            <button
                                type="button"
                                className="map-marker-charger"
                                onClick={() => {
                                    setSelectedMarker(charger);
                                    openModal();
                                }}
                                onMouseOver={() => {
                                    setHoveredMarker(charger);
                                }}
                                onMouseOut={() => setHoveredMarker(0)}
                            >
                                <span className="map-marker-charger-label">
                                    {charger.evses.length}
                                </span>
                                <span className="sr-only">{charger.name}</span>
                            </button>
                        }
                        hover={
                            hoveredMarker._id &&
                            hoveredMarker._id === charger._id
                        }
                    />
                );
            }),
        [hoveredMarker._id, map, openModal, props.smallMap, uniqueChargers]
    );

    const chargerSwither = useMemo(
        () => (
            <div className="search-map__charger-toggle">
                <label className="toggle">
                    <input
                        type="checkbox"
                        name={'with_cancellation'}
                        checked={showMarkers}
                        onChange={(e) => setShowMarkers(e.target.checked)}
                    />
                    <span className="caption">
                        {t('Show charger locations')}
                    </span>
                    <span className="toggle__checkmark" />
                </label>
            </div>
        ),
        [showMarkers, t]
    );

    return (
        <>
            {props.smallMap ? null : (
                <div className="search__range">
                    {!isMobile ? null : chargerSwither}
                    <div className="search__map-btn-container">
                        <button
                            className={`search__map-btn search__map-btn--minus${
                                meta.zoom <= 3 ? ' disabled' : ''
                            }`}
                            onClick={() => {
                                setFromLocation(true);
                                map.setZoom(map.getZoom() - 1);
                            }}
                        >
                            <Icon.Minus className="icon-small" />
                            <span className="sr-only">{t('zoom out')}</span>
                        </button>
                        <button
                            className={`search__map-btn search__map-btn--plus${
                                meta.zoom >= 19 ? ' disabled' : ''
                            }`}
                            onClick={() => {
                                setFromLocation(true);
                                map.setZoom(map.getZoom() + 1);
                            }}
                        >
                            <Icon.Plus className="icon-small" />
                            <span className="sr-only">{t('zoom in')}</span>
                        </button>
                    </div>
                </div>
            )}
            <div className="map" style={props.containerStyle}>
                <div
                    ref={ref}
                    id="map"
                    style={props.mapStyle}
                    tabIndex={0}
                    aria-label="map"
                ></div>
                {polygons}
                {mapMarkers}
                {markers}
                {showMarkers && chargerMarkers}
            </div>
            {props.smallMap || isMobile ? null : chargerSwither}

            {/* Show selected item info cards */}
            {selectedMarker
                ? !selectedMarker.distance && (
                      <div className="recommendation--map-container">
                          <DetectClickOutside
                              onClick={() => setSelectedMarker(0)}
                              detectTab={true}
                          >
                              <Recommendation
                                  item={selectedMarker}
                                  onMap
                                  onClick={() => props.onClick(selectedMarker)}
                                  shouldFocus
                              />
                          </DetectClickOutside>
                      </div>
                  )
                : null}

            {/* {selectedMarker
                ? selectedMarker.distance && (
                      <div className="recommendation--map-container">
                          <ChargerInfoCard onMap item={selectedMarker} />
                      </div>
                  )
                : null} */}

            <ChargerModal
                size={800}
                title={t('Charger information')}
                btnRef={ref}
            >
                <ChargerDetails item={selectedMarker} />
            </ChargerModal>
        </>
    );
}

const deepCompareEqualsForMaps = createCustomEqual((deepEqual) => (a, b) => {
    // if (
    //   isLatLngLiteral(a) ||
    //   a instanceof window.google.maps.LatLng ||
    //   isLatLngLiteral(b) ||
    //   b instanceof window.google.maps.LatLng
    // )
    return new window.google.maps.LatLng(a).equals(
        new window.google.maps.LatLng(b)
    );
    // TODO extend to other types
    // use fast-equals for other objects
    // return deepEqual(a, b);
});

function useDeepCompareMemoize(value) {
    const ref = useRef();

    if (!deepCompareEqualsForMaps(value, ref.current)) {
        ref.current = value;
    }
    return ref.current;
}

function useDeepCompareEffectForMaps(callback, dependencies) {
    useEffect(callback, [...dependencies.map(useDeepCompareMemoize), callback]);
}
