import { React, useMemo, useCallback, useRef } from 'react';

import { differenceInDays, parseISO, format as formatDate } from 'date-fns';
import RoomDetails from '../RoomDetails/RoomDetails';
import {
    DeepEqual,
    buildParams,
    ObjectKeysToArray,
    formatCancelPenalties,
    OccupancyString,
} from '../../helpers.js';
import RateOption from '../RateOption/RateOption.js';
import CardImageSlider from '../CardImageSlider/CardImageSlider.js';
import { useSearchContext } from '../../core/context/searchContext.js';
import './RoomRecommendation.css';

import { Icon } from '../../common/Icon/Icon.js';
import { useModalContext } from '../../core/context/modalContext.js';
import { useLocaleContext } from '../../core/context/localeContext.js';
import useScreenSize from '../../core/hooks/useScreenSize';

export default function RoomRecommendation ({
    item,
    roomFilters,
    queryParams,
    info,
}) {
    const { urlPrefix, t } = useLocaleContext();
    const { setDatepickerOpen, setFindMobileOpen, meta } = useSearchContext();
    const { isMobile } = useScreenSize();
    const { createModal } = useModalContext();
    const { element: RoomModal, open: openModal } = useMemo(
        () => createModal(),
        [ createModal ]
    );
    const btnRef = useRef(null);

    const checkAvailabilityDesktop = useCallback(() => {
        const input = document.querySelector('#when');
        if (!input) return;
        setDatepickerOpen(true);
        input.click();
    }, [ setDatepickerOpen ]);

    const checkAvailabilityMobile = useCallback(() => {
        setFindMobileOpen(true);
        window.history.pushState({}, null);
    }, [ setFindMobileOpen ]);

    let distinctCancelPolicies = []; // @Mutates
    let distinctAmenities = [];

    let numNights;

    if (meta.checkout && meta.checkin) {
        numNights = differenceInDays(
            parseISO(meta.checkout),
            parseISO(meta.checkin)
        );
    }

    let occupancyString = OccupancyString({
        adults: meta.adults,
        children: meta.child_age?.length,
        rooms: meta.rooms,
        nights: numNights,
        t,
    });

    if (item.rates) {
        for (let i = 0; i < item.rates.length; ++i) {
            let rate = item.rates[ i ];

            if (!rate.cancel_penalties || !rate.refundable) {
                // the room is non-refundable
                distinctCancelPolicies.push('');
                break;
            }

            for (let j = 0; j < rate.cancel_penalties.length; ++j) {
                let penalty = rate.cancel_penalties[ j ];

                // @Todo(gb) improvement: we need to add a time-span to allow cancel start times to differ because they are based on request time, and they can differ by seconds. This way it can look like 2 different cancellation policies.
                let p = {
                    ...penalty,
                    start: formatDate(parseISO(penalty.start), 'd MM yyyy'),
                };

                let found = false;
                for (let k = 0; k < distinctCancelPolicies.length; k++) {
                    let dinstinctPolicy = distinctCancelPolicies[ k ];

                    let e2 = {
                        ...dinstinctPolicy,
                        start: formatDate(
                            parseISO(dinstinctPolicy.start),
                            'd MM yyyy'
                        ),
                    };
                    let isSame = DeepEqual(e2, p);
                    if (isSame) {
                        found = true;
                        break;
                    }
                }
                if (!found) distinctCancelPolicies.push(penalty);
            }

            // @TODO do better
            let amenityObj = rate.propertyRateInfo
                ? rate.propertyRateInfo.amenities
                : {};
            let amenityArray = amenityObj ? ObjectKeysToArray(amenityObj) : [];

            for (let j = 0; j < amenityArray.length; ++j) {
                let amenity = amenityArray[ j ];

                let found = false;
                for (let k = 0; k < distinctAmenities.length; ++k) {
                    let isSame = DeepEqual(distinctAmenities[ k ], amenity);
                    if (isSame) {
                        found = true;
                        break;
                    }
                }
                if (!found) distinctAmenities.push(amenity);
            }
        }
    }

    const roomsToShow = useMemo(
        () =>
            item.rates
                ? item.rates.filter((rate) => {
                    if (roomFilters[ 'with_cancellation' ]) {
                        if (roomFilters[ 'with_breakfast' ]) {
                            return (
                                rate.refundable &&
                                Object.values(rate.amenities).some(
                                    (amenitie) =>
                                        amenitie.name === 'Free breakfast'
                                )
                            );
                        }
                        return rate.refundable;
                    } else if (roomFilters[ 'with_breakfast' ]) {
                        return Object.values(rate.amenities).some(
                            (amenitie) => amenitie.name === 'Free breakfast'
                        );
                    } else {
                        return true;
                    }
                })
                : null,
        [ item, roomFilters ]
    );

    const groupedRooms = useMemo(
        () => {
            let result = roomsToShow
                ? roomsToShow.reduce((group, rate) => {
                    let idArr = [];
                    if (rate.amenities) {
                        idArr = Object.values(rate.amenities)
                            .sort((amenity) => amenity.id)
                            .map((amenity) => amenity.id);
                    }

                    if (rate.promotions) {
                        if (rate.promotions.value_adds) {
                            let promos = Object.values(rate.promotions.value_adds).map(e => e.id)
                            idArr = idArr.concat(promos)
                        } else if (rate.promotions.deal) {
                            // this only has a deal, but not a special promotion
                            // idArr.push(rate.promotions.deal.id)
                        } else {
                            console.log("UNHANDLED", rate.promotions)
                            // @TODO undhandled promotion
                        }
                    }
                    const amenityStr = idArr.join(',');
                    group[ amenityStr ] = group[ amenityStr ] || [];
                    group[ amenityStr ].push(rate);
                    return group;
                }, {})
                : {};

            if (Object.values(result).length) {
                let n = Object.values(result).sort((a, b) => {
                    return Number(a[ 0 ].totalPrice) - Number(b[ 0 ].totalPrice)
                })
                return n

            }

            return result;
        },
        [ roomsToShow ]
    );

    const infoElement = useCallback(
        (showArea) => (
            <>
                {(item.name || item.room_name) && (
                    <>
                        <h3 className="room-option__title h3">
                            {item.name || item.room_name}
                        </h3>
                        {showArea ? (
                            <div className="room-option__occupancy caption">
                                {' '}
                                ({`${item.area}m`}
                                <sup>2</sup>)
                            </div>
                        ) : null}
                    </>
                )}
            </>
        ),
        [ item ]
    );

    return (
        <div className="room-card">
            <div className={`room-option${item.rates ? ' room-option__with-rates' : ''}`}>
                <CardImageSlider images={item.images.slice(0, 10)} />
                <div className="room-option__body">
                    <div className="room-option__header">
                        <h3 className="room-option__title h4">
                            {item.room_name || item.name}
                        </h3>

                        <button
                            className="rate-option__modal-btn link"
                            type="button"
                            onClick={openModal}
                            ref={btnRef}
                        >
                            {t('Details')}
                        </button>

                        {/* {infoElement(false)} */}
                    </div>
                    {!roomsToShow ? null : (
                        <div className="room-option__occupancy caption">
                            {occupancyString}
                        </div>
                    )}
                    {item.rates ? null : (
                        <div className="room-option__btn-container room-option__btn-container--column">
                            <button
                                className="btn btn--small btn--blue"
                                type="button"
                                onClick={
                                    isMobile
                                        ? checkAvailabilityMobile
                                        : checkAvailabilityDesktop
                                }
                                ref={btnRef}
                            >
                                {t('Check availability')}
                            </button>
                        </div>
                    )}
                </div>
            </div>
            {!groupedRooms
                ? null
                : Object.values(groupedRooms)

                    .map((group, i) => {

                        let amenityArray = group[ 0 ].amenities
                            ? ObjectKeysToArray(group[ 0 ].amenities)
                            : [];
                        let amenities = amenityArray.map((e, i) => {
                            let name = e.name;
                            if (name.substr(0, 5).toLowerCase() === 'free ') {
                                name = name.substr(5);
                                // capitalize first letter
                                name = name.charAt(0).toUpperCase() + name.slice(1);
                            }
                            return (
                                <li
                                    className="rate-option__amenity-item caption"
                                    key={e.id}
                                >
                                    <Icon.Check className="icon-small" />
                                    {name}
                                </li>
                            );
                        });

                        const promotion = (group[ 0 ].promotions?.value_adds) ?
                            Object.values(group[ 0 ].promotions.value_adds).map((promo, i) => {

                                let description = promo.description

                                if (description.substr(0, 5).toLowerCase() === 'free ') {
                                    description = description.substr(5);
                                    // capitalize first letter
                                    description = description.charAt(0).toUpperCase() + description.slice(1);
                                }

                                return <li key={i} className="rate-option__amenity-item caption">
                                    <Icon.Check className="icon-small" />
                                    {description}
                                </li>
                            }) : undefined


                        const bedTypes = !Array.isArray(group)
                            ? ''
                            : group[ 0 ]?.bed_groups
                                ?.map((group) => group.description)
                                .join(', ');

                        return (
                            <div className="rate-card" key={`group-${i}`}>
                                {(!amenities.length && !bedTypes.length) ||
                                    !group[ 0 ].totalPrice ? null : (
                                    <>
                                        <ul className="rate-option__amenity-list">
                                            {amenities}
                                            {promotion}
                                            <li key={i} className="rate-option__amenity-item caption">
                                                <Icon.Bed className="icon-small" />
                                                {bedTypes}
                                            </li>
                                        </ul>
                                    </>
                                )}
                                {group
                                    .map((rate, i) => (
                                        <RateOption
                                            roomId={item.id}
                                            queryParams={queryParams}
                                            rate={rate}
                                            room={item}
                                            info={info}
                                            key={rate.id}
                                        />
                                    ))}
                            </div>
                        );
                    })}
            <RoomModal btnRef={btnRef}>
                <RoomDetails
                    room={item}
                    info={infoElement}
                    amenities={item.amenities}
                />
            </RoomModal>
        </div>
    );
}
