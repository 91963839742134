import React, { useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import axios from 'axios';

import { useLocaleContext } from '../../core/context/localeContext';
import ForgotPasswordForm from '../../Components/Registration/Login/ForgotPasswordForm';
import { Icon } from '../../common/Icon/Icon';
import Loader from '../../common/Loader/Loader';

const BASE_URL =
    process.env.REACT_APP_BASE_URL || 'https://www.sleepercharger.com';

export default function ResetPassword() {
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const { t, locale, urlPrefix } = useLocaleContext();
    const [result, setResult] = useState({
        code: 'idle',
        message: '',
    });

    const handleResult = useCallback((result) => {
        setResult({
            code: result.data.statusCode,
            message: result.data.message,
        });
    }, []);

    const resendMail = () => {
        if (!email) return;
        const func = async () => {
            setLoading(true);
            const payload = {
                email,
            };
            let result = await axios.post(
                `${process.env.REACT_APP_SC_API_BASE}/user/send-reset-password-link`,
                payload
            );

            setLoading(false);
            handleResult(result);
        };

        func().catch((err) => {
            setLoading(false);
            console.error({ err });
            handleResult(err.response);
        });
    };

    const element = useMemo(() => {
        if (result.code === 'idle') {
            return (
                <ForgotPasswordForm
                    handleResult={handleResult}
                    setSentEmail={setEmail}
                />
            );
        } else if (result.code === 200) {
            return (
                <div className="wrap wrap--md">
                    <div className="signup__header">
                        <h1 className="signup__title h2">
                            {t('Check your mail')}
                        </h1>
                        <p className="signup__desc h4">
                            {t(
                                'We have sent a password reset link to your email'
                            )}
                        </p>
                    </div>
                    <p>
                        {t(
                            'Did not receive the email? Check your spam filter or'
                        )}
                    </p>
                    <button className="link" type="button" onClick={resendMail}>
                        {t('Resend link')}{' '}
                        {loading ? (
                            <Loader
                                size={16}
                                bgColor={'white'}
                                color={'var(--primary)'}
                                className="icon-right"
                            />
                        ) : null}
                    </button>
                </div>
            );
        } else {
            return (
                <>
                    <div className="wrap wrap--md">
                        <div className="profile__message profile__message__warning">
                            {t(result.message)}
                        </div>
                    </div>
                    <ForgotPasswordForm
                        handleResult={handleResult}
                        setSentEmail={setEmail}
                    />
                </>
            );
        }
    }, [result, loading]);

    return (
        <section className="signup">
            <div className="wrap wrap--sm">
                <Link className="signup__back link" to={`${urlPrefix}/profile`}>
                    <Icon.Arrow className="icon-small icon-left flip-x" />
                    {t('Back to profile')}
                </Link>
                <div className="content-container">
                    <Helmet>
                        <title>{`${t(
                            'Reset Password'
                        )} | SleeperCharger`}</title>
                        <meta
                            name="description"
                            content={`${t('Reset Password')} | SleeperCharger`}
                        />
                        <link
                            rel="canonical"
                            href={`${BASE_URL}${
                                locale !== 'en' ? `/${locale}` : ''
                            }/forgot-password`}
                        />
                    </Helmet>
                    {element}
                </div>
            </div>
        </section>
    );
}
