import React, { useState } from 'react';
import { Icon } from '../Icon/Icon';

const CustomInput = ({
    type,
    id,
    label,
    value,
    onChange,
    placeholder,
    name,
    error,
    emptyMessage,
    invalidMessage,
    autoComplete,
    containerStyle,
    info,
    inputEvents,
    link,
}) => {
    const [currentType, setCurrentType] = useState(type);

    return (
        <div
            className={`input-container${!error ? '' : ' error'}`}
            style={{ ...containerStyle }}
        >
            {!label && !link ? null : (
                <div className="input-header">
                    {!label ? null : (
                        <label className="input-label" htmlFor={id}>
                            {label}
                        </label>
                    )}
                    {link}
                </div>
            )}
            <div className="input-wrapper">
                <input
                    id={id}
                    autoComplete={autoComplete || 'off'}
                    type={currentType || 'text'}
                    name={name}
                    value={value}
                    onChange={onChange}
                    placeholder={placeholder}
                    className="input-field"
                    {...inputEvents}
                />
                {type !== 'password' ? null : (
                    <button
                        className="input-btn"
                        type="button"
                        onClick={() =>
                            setCurrentType(
                                currentType === 'password' ? 'text' : 'password'
                            )
                        }
                    >
                        <span className="sr-only">Change input type</span>
                        {currentType === 'password' ? (
                            <Icon.Eye className="icon-small" />
                        ) : (
                            <Icon.Eyehidden className="icon-small" />
                        )}
                    </button>
                )}
                {!info ? null : (
                    <div className="input-info caption">{info}</div>
                )}
            </div>
            {!error ? null : (
                <div className="input-error">
                    <span>
                        {error === 'empty'
                            ? emptyMessage
                            : error === 'invalid'
                            ? invalidMessage
                            : ''}
                    </span>
                </div>
            )}
        </div>
    );
};

export default CustomInput;
