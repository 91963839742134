import { useUserContext } from '../../../core/context/userContext';
import { useLocaleContext } from '../../../core/context/localeContext';
import { ToBool } from '../../../helpers';

const DiscountDialog = ({ setOpenState }) => {
    const { userDetails, login, register } = useUserContext();
    const isLoggedIn = ToBool(userDetails?.token);
    const { t } = useLocaleContext();

    return isLoggedIn ? (
        <div>
            {t("You get discount because you're a member of SleeperCharger!")}
        </div>
    ) : (
        <>
            <div>{t('Get discount rates with a SleeperCharger account')}</div>
            <button
                className="btn btn--blue"
                onClick={() => {
                    setOpenState(false);
                    register();
                }}
            >
                {t('Join')}
            </button>

            <button
                className="btn btn--outline-dark"
                onClick={() => {
                    setOpenState(false);
                    login();
                }}
            >
                {t('Sign in')}
            </button>
        </>
    );
};

export default DiscountDialog;
