import { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import qs from 'qs';

import { Link } from 'react-router-dom';
import { Icon } from '../../common/Icon/Icon';
import { useLocaleContext } from '../../core/context/localeContext';
import ReactMarkdown from 'react-markdown';

import logo from '../../images/carbon overcompensation.svg';

import './CarbonOvercompensation.css';

const UPLOADS_URL = process.env.REACT_APP_UPLOADS_URL || '';
let cache = {}; //@mutable

const CarbonOvercompensation = () => {
    const [cocContent, setCocContent] = useState();
    const { locale, urlPrefix } = useLocaleContext();

    useEffect(() => {
        if (!locale) return;
        if (!cache || !cache[locale] || !cache[locale].cocContent) {
            (async () => {
                const query = qs.stringify({
                    locale: locale,
                    populate: ["logo", "button"]
                }, {
                    encodeValuesOnly: true, // prettify URL
                });

                await axios.get(
                    `${process.env.REACT_APP_API_URL}/api/carbon-over-compensation?${query}`,
                    {
                        headers: {
                            Authorization: process.env.REACT_APP_API_KEY,
                        },
                    }
                ).then(res => {
                    if (!cache[locale]){
                        cache[locale] = {};    
                    }
                    cache[locale].cocContent = {
                        title: res.data.data.attributes.title,
                        logoUrl: `${UPLOADS_URL}${res.data.data.attributes.logo.data.attributes.url}`,
                        content: res.data.data.attributes.content,
                        button: res.data.data.attributes.button
                    }
                    setCocContent(cache[locale].cocContent);
                }).catch(err => {
                    // console.error('err', err);
                    setCocContent(oldContent);
                });
            })();
        } else {
            setCocContent(cache[locale].cocContent);
        }
    }, [locale]);


    const oldContent = { //for strapi backwards compatibility
        title: "Your booking is carbon overcompensated",
        content: `Your travel is better than carbon neutral…\nTravel with SleeperCharge is carbon negative.\n\nWhat do we mean, carbon negative? Whilst EV's don't directly emit CO2 or other noxious substances, not all electricity is green. And not all hotels and accommodation are CO2 neutral. At SleeperCharger we include 400 kg of carbon offsetting in every accommodation booking. This is enough to actually reduce CO2 by more than your travel produces. By miles. Over compensate with us.\n\n### But how?\nPlanting trees just takes too long. The polar bears will be long dead and the tornados wild, long before planting a few trees helps the climate. It's far better not to chop trees down and burn them in the first place. So we give clean cook stoves to the 3 billion people that cook on open fires every day. This immediately reduces a lot of CO2 and other noxious gas emissions, plus saves huge numbers of trees. And let's consider the health benefits of not breathing this smoke in, where 4 million people per year die from these emissions in developing countries. Yes 4 million.\n\n### How do we calculate this?\nThe short answer is, it's easier and cheaper to over compensate than to calculate to the tiniest details. But the basic calculation works like this-  \nEVs are calculated at producing circa 60 gram CO2 per kilometer.  \nWe work on an average of 1000 km's round trip.  \nThis produces 60 kg of CO2 equivalent.  \nFor 1 euro per booking, you offset 400 kg of CO2 equivalent.  \nAt SleeperCharger your travel is totally over compensated.  \n\nMake a positive impact on other people and for the planet.`,
        logoUrl: logo,
        button: {
            title: "Read the full story",
            link: "/blog/600-carbon-neutral",
            type: "Icon.Arrow"
        }
    }


    return (cocContent &&
        <div className="coc">
            <div className="coc__header">
                <h2 className="h2">{cocContent.title}</h2>
                <img className="coc_img" src={cocContent.logoUrl} alt="carbon overcompensation" />
            </div>
            <ReactMarkdown
                className="coc__content text-container"
                linkTarget="_blank"
            >
                {cocContent.content}
            </ReactMarkdown>
            <Link className="coc__cta btn btn--outline-dark" to={`${urlPrefix}${cocContent.button && cocContent.button.link
                }`}>
                {cocContent.button && cocContent.button.title}
                <Icon.Arrow className="icon-small icon-right" />
            </Link>
        </div>
    );

};

export default CarbonOvercompensation;
