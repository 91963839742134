import React, { useMemo, useState } from 'react';

import { parseISO, format as formatDate } from 'date-fns';

import { useLocaleContext } from '../../../../core/context/localeContext';
import { useSearchContext } from '../../../../core/context/searchContext';
import { FIELDS } from '../MobileSearch';
import DatePickerRange from '../../../DatePickerRange/DatePickerRange';

const MobileDatePicker = ({ activeField, openDate }) => {
    const { t } = useLocaleContext();
    const { state } = useSearchContext();
    const [selecting, setSelecting] = useState(0);

    const parsedCheckin = useMemo(
        () => (state.checkin ? parseISO(state.checkin) : null),
        [state.checkin]
    );
    const parsedCheckout = useMemo(
        () => (state.checkout ? parseISO(state.checkout) : null),
        [state.checkout]
    );
    return activeField !== FIELDS.date ? (
        <button className="mobile-search__btn" type="button" onClick={openDate}>
            <span className="mobile-search__btn-label form-label">
                {t('When?')}
            </span>
            {parsedCheckin || parsedCheckout ? (
                <span className="mobile-search__btn-value">
                    {parsedCheckin &&
                    parsedCheckout &&
                    formatDate(parsedCheckin, 'MMM') ===
                        formatDate(parsedCheckout, 'MMM') ? (
                        <>
                            {formatDate(parsedCheckin, 'dd')}
                            {' - '}
                            {formatDate(parsedCheckout, 'dd MMMM yyyy')}
                        </>
                    ) : (
                        <>
                            {parsedCheckin
                                ? formatDate(parsedCheckin, 'dd MMM')
                                : '?'}
                            {' - '}
                            {parsedCheckout
                                ? formatDate(parsedCheckout, 'dd MMM yyyy')
                                : '?'}
                        </>
                    )}
                </span>
            ) : (
                <span className="mobile-search__btn-value mobile-search__btn-value--placeholder">
                    {t('Enter dates')}
                </span>
            )}
        </button>
    ) : (
        <div className="mobile-search__btn active">
            <span className="mobile-search__btn-label form-label">
                {t('When?')}
            </span>
            <DatePickerRange
                selecting={selecting}
                setSelecting={setSelecting}
            />
        </div>
    );
};

export default MobileDatePicker;
