import React, { useCallback, useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';

import CustomInput from '../../../common/CustomInput/CustomInput';
import { Icon } from '../../../common/Icon/Icon';
import Loader from '../../../common/Loader/Loader';
import { useLocaleContext } from '../../../core/context/localeContext';
import { COUNTRY_INFO_BY_INDEX } from '../../../core/hooks/constants';
import {
    DeepMerge,
    DotStringToObject,
    IsValidEmail,
} from '../../../helpers';
import PhoneField from '../../PhoneField/PhoneField';
import useScreenSize from '../../../core/hooks/useScreenSize';

import './RegistrationForm.css';

export default function RegistrationForm({
    handleResult,
    linkAway,
    email,
    login,
}) {
    const navigate = useNavigate();
    const { urlPrefix, t } = useLocaleContext();
    const { isMobile } = useScreenSize();
    const [loading, setLoading] = useState(false);
    const [details, setDetails] = useState({
        firstname: '',
        lastname: '',
        phone_country_code: '31',
        phone: '',
        email: email || '',
        password: '',
    });
    const [errors, setErrors] = useState({
        email: '',
        password: '',
        firstname: '',
        lastname: '',
        phone: '',
    });
    const [registerError, setRegisterError] = useState('');
    const [type, setType] = useState('password');

    const handleRegistrationSubmit = useCallback(
        (userData) => {
            setLoading(true);
            const tryRegister = async (userData) => {
                let result = await axios.post(
                    `${process.env.REACT_APP_SC_API_BASE}/user/register`,
                    {
                        ...userData,
                    }
                );

                setLoading(false);
                setRegisterError('');
                handleResult(result);
            };

            tryRegister(userData).catch((err) => {
                setLoading(false);
                console.error(err);
                setRegisterError(err.response.data.message);
            });
        },
        [navigate]
    );

    const handleFormSubmit = useCallback(
        (e) => {
            e.preventDefault();
            let errorNum = 0;

            for (const error in errors) {
                if (!details[error]) {
                    errorNum++;
                    setErrors((prevState) => {
                        return { ...prevState, [error]: 'empty' };
                    });
                }
                if (
                    error === 'email' &&
                    details[error] &&
                    !IsValidEmail(details.email)
                ) {
                    errorNum++;
                    setErrors((prevState) => {
                        return { ...prevState, [error]: 'invalid' };
                    });
                }
                if (
                    error === 'password' &&
                    details[error] &&
                    details[error].length < 8
                ) {
                    errorNum++;
                    setErrors((prevState) => {
                        return { ...prevState, [error]: 'invalid' };
                    });
                }
            }

            if (errorNum === 0) handleRegistrationSubmit(details);
        },
        [details, errors, handleRegistrationSubmit]
    );

    const phoneCountryOptions = COUNTRY_INFO_BY_INDEX.phone.map((e, i) => {
        return (
            <option key={i} value={e}>
                {COUNTRY_INFO_BY_INDEX.display[i]}
            </option>
        );
    });

    const handleChange = useCallback(
        (evt) => {
            let name = evt.target.name;
            let value = evt.target.value;

            let property = DotStringToObject(name, value);

            let newDetails = DeepMerge({ ...details }, property);

            setDetails(newDetails);
        },
        [details, setDetails]
    );

    return (
        <form className="registration-form" onSubmit={handleFormSubmit}>
            <CustomInput
                id="email"
                label="Email address"
                name="email"
                value={details.email}
                onChange={(e) => {
                    handleChange(e);
                    setErrors((prevState) => {
                        return { ...prevState, email: '' };
                    });
                }}
                autoComplete="on"
                placeholder="Email address"
                error={errors.email}
                emptyMessage="Please fill in your email address"
                invalidMessage="Please enter a valid email address"
            />

            <div
                className={`input-container${!errors.password ? '' : ' error'}`}
            >
                <label className="input-label" htmlFor="password">
                    Password
                </label>
                <div className="input-wrapper">
                    <input
                        id="password"
                        autoComplete="off"
                        type={type}
                        name="password"
                        value={details.password}
                        onChange={(e) => {
                            handleChange(e);
                            setErrors((prevState) => {
                                return {
                                    ...prevState,
                                    password: '',
                                };
                            });
                        }}
                        placeholder="Password"
                        className="input-field"
                    />
                    <button
                        className="input-btn"
                        type="button"
                        onClick={() =>
                            setType(type === 'password' ? 'text' : 'password')
                        }
                    >
                        <span className="sr-only">Change input type</span>
                        <Icon.Eye className="icon-small" />
                    </button>
                </div>
                <p className="input-message caption">
                    Password should contain minimally 8 characters, etc.
                </p>
                {!errors.password ? null : (
                    <div className="input-error">
                        <span>
                            {errors.password === 'empty'
                                ? 'Please fill in your password'
                                : errors.password === 'invalid'
                                ? 'Password should contain minimally 8 characters'
                                : ''}
                        </span>
                    </div>
                )}
            </div>

            <div
                style={{
                    display: 'grid',
                    gridTemplateColumns: isMobile ? '1fr' : '1fr 1fr',
                    gap: isMobile ? '0' : '16px',
                }}
            >
                <CustomInput
                    id="firstname"
                    label="First name"
                    name="firstname"
                    value={details.firstname}
                    onChange={(e) => {
                        handleChange(e);
                        setErrors((prevState) => {
                            return {
                                ...prevState,
                                firstname: '',
                            };
                        });
                    }}
                    placeholder="First name"
                    error={errors.firstname}
                    emptyMessage="Please fill in your first name"
                />
                <CustomInput
                    id="lastname"
                    label="Last name"
                    name="lastname"
                    value={details.lastname}
                    onChange={(e) => {
                        handleChange(e);
                        setErrors((prevState) => {
                            return {
                                ...prevState,
                                lastname: '',
                            };
                        });
                    }}
                    placeholder="Last name"
                    error={errors.lastname}
                    emptyMessage="Please fill in your last name"
                />
            </div>

            <div className={`input-container${!errors.phone ? '' : ' error'}`}>
                <label className="input-label">Phone number</label>

                <PhoneField
                    handleChange={(e) => {
                        handleChange(e);
                        setErrors((prevState) => {
                            return { ...prevState, phone: '' };
                        });
                    }}
                    countryOptions={phoneCountryOptions}
                    countryValue={details.phone_country_code}
                    phoneValue={details.phone}
                />
                {!errors.phone ? null : (
                    <div className="input-error">
                        Please fill in your phone number
                    </div>
                )}
            </div>

            <p className="signup__terms">
                By pressing ‘Agree and create account’ below, you agree to our{' '}
                <Link to={`${urlPrefix}/terms-and-conditions`} target="_blank">
                    {'terms & conditions'}
                </Link>{' '}
            </p>

            <div className="registration-form__footer">
                <button
                    className="signup__btn btn btn--blue"
                    disabled={loading}
                    type="submit"
                >
                    Agree and create account
                    {loading ? (
                        <Loader
                            size={20}
                            bgColor={'var(--tertiary)'}
                            color={'var(--primary)'}
                            className="icon-right"
                        />
                    ) : (
                        <Icon.Arrow className="icon-small icon-right" />
                    )}
                </button>

                {!registerError ? null : (
                    <div className="input-error">
                        <span>{t(registerError)}</span>
                    </div>
                )}

                <div style={{ marginTop: '24px' }}>
                    {t('Already have an account?')}{' '}
                    <button
                        onClick={() => login()}
                        style={{ fontWeight: 700, textDecoration: 'underline' }}
                        type="button"
                    >
                        {t('Log in')}
                    </button>
                </div>
            </div>
        </form>
    );
}
