import React, { useContext, useState } from 'react';
import translate from './translate.js';

const LocaleContext = React.createContext();

export function useLocaleContext() {
    return useContext(LocaleContext);
}

export function LocaleProvider({ children }) {
    const [locale, setLocale] = useState();
    const [preview, setPreview] = useState(false);
    const [locales, setLocales] = useState([]);
    const [urlPrefix, setUrlPrefix] = useState('');
    const [localizedSlugs, setLocalizedSlugs] = useState(null);

    const t = translate(locale);
    const translateToLocale = (locale) => translate(locale);

    return (
        <LocaleContext.Provider
            value={{
                locale,
                setLocale,
                preview,
                setPreview,
                locales,
                setLocales,
                urlPrefix,
                setUrlPrefix,
                localizedSlugs,
                setLocalizedSlugs,
                t,
                translateToLocale,
            }}
        >
            {children}
        </LocaleContext.Provider>
    );
}
