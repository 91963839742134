import React, { useCallback, useContext, useState } from 'react';
import Modal from '../../common/Modal/Modal';
import { v4 as uuid } from 'uuid';

const ModalContext = React.createContext();

export function useModalContext() {
    return useContext(ModalContext);
}

export function ModalProvider({ children }) {
    const [controls, setControls] = useState({});

    const isOpen = useCallback((key) => controls[key], [controls]);

    const getControl = useCallback(
        (key) => ({
            open: () => {
                setControls((values) => ({ ...values, [key]: true }));
                // window.history.pushState({}, null);
            },
            close: () => setControls((values) => ({ ...values, [key]: false })),
        }),
        []
    );

    const createModal = useCallback(
        (showCloseButton = true) => {
            const key = uuid();
            return {
                element: ({ children, size, title, btnRef, closeCallback }) => (
                    <Modal
                        id={key}
                        title={title}
                        showCloseButton={showCloseButton}
                        size={size}
                        btnRef={btnRef}
                        closeCallback={closeCallback}
                    >
                        {children}
                    </Modal>
                ),
                ...getControl(key),
            };
        },
        [getControl]
    );

    return (
        <ModalContext.Provider value={{ createModal, getControl, isOpen }}>
            {children}
        </ModalContext.Provider>
    );
}
