import ReactMarkdown from 'react-markdown';

function TextBlock(props) {
    return (
        <ReactMarkdown
            linkTarget="_blank"
            className={`text-container page-block${
                props.className ? ' ' + props.className : ''
            }${props.fullWidth ? ' page-block--full' : ''}`}
        >
            {props.content}
        </ReactMarkdown>
    );
}

export default TextBlock;
