import { React, useEffect, useRef, useState } from 'react';

import { RangeArray } from '../../../helpers.js';
import CustomDropdown from '../../../common/Dropdown/CustomDropdown';
import GroupedButtons from '../../../common/GroupedButtons/GroupedButtons.js';
import { useLocaleContext } from '../../../core/context/localeContext';

import './SuggestedRoomLayout.css';
import { useSearchContext } from '../../../core/context/searchContext.js';

export default function SuggestedRoomLayout({
    mobile,
    handleFormOutsideClick,
    setFormFocused,
    setError,
    fieldApply,
    validateInputFields,
    supplierPage,
    handleGuestOutsideClick,
    setShowGuestSuggestion,
    closeGuestModal,
}) {
    const { state, setState, setMeta, fetchSupplier, setShouldPan } =
        useSearchContext();
    const { t } = useLocaleContext();

    let [numAdults, setNumAdults] = useState(state.adults);
    let [childAges, setChildAges] = useState(state.child_age);
    let [numRooms, setNumRooms] = useState(state.rooms);
    const firstRender = useRef(true);

    const handleChange = (i, value) => {
        setAge(i, value);
    };

    const setNumChildren = (i, val) => {
        let ages = [...childAges].slice(0, i);

        if (i > ages.length) {
            ages.push(-1);
        }
        setChildAges(ages);
    };

    const setAge = (i, val) => {
        let ages = [...childAges];
        ages[i] = val;
        setChildAges(ages);
    };

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }

        setState((prevState) => ({
            ...prevState,
            adults: numAdults,
            child_age: childAges,
            rooms: numRooms,
        }));

        // setCookie(
        //     'guests',
        //     JSON.stringify({
        //         adults: numAdults,
        //         child_age: childAges,
        //         rooms: numRooms,
        //     }),
        //     90
        // );
    }, [numAdults, childAges, numRooms, setState]);

    const returnDropdownOption = (option) => {
        if (option === -1) {
            return t('Age');
        } else if (option === 0) {
            return t('Under 1');
        } else return option;
    };

    const options = RangeArray(-1, 17).map((e) => {
        return { label: returnDropdownOption(e), value: e };
    });

    let childrenDiv = childAges.map((e, i) => {
        return (
            <div
                className="guest-result__item guest-result__item--child"
                key={i}
            >
                <div className="guest-result__left guest-result__left--child">
                    <div className="guest-result__title cta">
                        {mobile
                            ? t('Age child %{num}', { num: i + 1 })
                            : t('Child %{num}', { num: i + 1 })}
                    </div>
                    {!mobile && (
                        <div className="guest-result__subtitle caption">
                            {t('What age?')}
                        </div>
                    )}
                </div>

                <CustomDropdown
                    items={options}
                    selected={childAges[i]}
                    greyoutFirst
                    handleChange={(e, value) => {
                        handleChange(i, value);
                    }}
                />
            </div>
        );
    });

    return (
        <>
            <div className="guest-result">
                <div className="guest-result__item">
                    <div className="guest-result__left">
                        <div className="guest-result__title cta">
                            {t('Adults')}
                        </div>
                        {!mobile && (
                            <div className="guest-result__subtitle caption">
                                {t('18 or older')}
                            </div>
                        )}
                    </div>

                    <GroupedButtons
                        min={1}
                        num={numAdults}
                        setNum={setNumAdults}
                        label="Number of adults"
                        singularLabel="an adult"
                        pluralLabel="adults"
                    />
                </div>

                <div className="guest-result__item">
                    <div className="guest-result__left">
                        <div className="guest-result__title cta">
                            {t('Children')}
                        </div>
                        {!mobile && (
                            <div className="guest-result__subtitle caption">
                                {t('Age 0 - 17')}
                            </div>
                        )}
                    </div>

                    <GroupedButtons
                        min={0}
                        num={childAges.length}
                        setNum={setNumChildren}
                        label={t('Number of children')}
                        singularLabel="a child"
                        pluralLabel="children"
                    />
                </div>

                {childAges.length ? childrenDiv : null}

                <div className="guest-result__item">
                    <div className="guest-result__left">
                        <div className="guest-result__title cta">
                            {mobile ? t('Number of rooms') : t('Rooms')}
                        </div>
                        {!mobile && (
                            <div className="guest-result__subtitle caption">
                                {t('How many')}
                            </div>
                        )}
                    </div>

                    <GroupedButtons
                        min={1}
                        num={numRooms}
                        setNum={setNumRooms}
                        label={t('Number of rooms')}
                        singularLabel="a room"
                        pluralLabel="rooms"
                    />
                </div>
            </div>
            {!fieldApply ? null : (
                <div className="suggestions-container__footer">
                    <button
                        className="link"
                        type="button"
                        onClick={() => {
                            handleFormOutsideClick();
                            handleGuestOutsideClick();
                            setFormFocused(false);
                            if (closeGuestModal) closeGuestModal();
                        }}
                    >
                        {t('Cancel')}
                    </button>
                    <button
                        className="btn btn--blue btn--small"
                        type="button"
                        onClick={() => {
                            setShowGuestSuggestion(false);
                            setFormFocused(false);
                            if (closeGuestModal) closeGuestModal();
                            const error = validateInputFields(
                                state.dest,
                                state.checkin,
                                state.checkout,
                                state.child_age,
                                setError,
                                t,
                                supplierPage
                            );
                            if (error) return;
                            if (supplierPage) {
                                fetchSupplier(state, {
                                    shouldNavigate: true,
                                    shouldAbort: true,
                                });
                            } else {
                                setShouldPan(true);
                                const {
                                    sid,
                                    location,
                                    lat0,
                                    lng0,
                                    lat1,
                                    lng1,
                                    ...params
                                } = state;
                                setMeta(params);
                            }
                        }}
                    >
                        {t('Apply changes')}
                    </button>
                </div>
            )}
        </>
    );
}
