import React, { useState, useEffect, useMemo, useContext } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import { ThemeContext } from '../../providers/ThemeProvider';
import { useLocaleContext } from '../../core/context/localeContext';
import useLoader from '../../core/hooks/useLoader';
import { getCountryName } from '../../helpers';

import './Countries.css';

const BASE_URL =
    process.env.REACT_APP_BASE_URL || 'https://www.sleepercharger.com';

const Countries = () => {
    const [data, setData] = useState(null);
    const { locale, t } = useLocaleContext();
    const { setHeroWithSearch } = useContext(ThemeContext);

    useEffect(() => {
        setHeroWithSearch(false);
    }, [setHeroWithSearch]);

    useEffect(() => {
        import('./data.json').then((data) => setData(data.default));
    }, []);

    const europeData = useMemo(() => {
        if (!data) return {};
        return data.filter((el) => el.continent.name === 'Europe')[0];
    }, [data]);

    useLoader(europeData);

    return (
        <>
            <Helmet>
                <title>{t('Country')} | SleeperCharger</title>
                <meta
                    name="description"
                    content="Hotel with guaranteed electric vehicle chargers. Book now only at SleeperCharger."
                />
                <link
                    rel="canonical"
                    href={`${BASE_URL}${
                        locale !== 'en' ? `/${locale}` : ''
                    }/countries`}
                />
            </Helmet>
            <section className="countries">
                <div className="wrap wrap--md">
                    <h1 className="countries__title h2">
                        {t('Most popular countries/cities')}
                    </h1>
                    <ul className="countries__container">
                        {!europeData.continent
                            ? null
                            : europeData.continent.countries.map(
                                  (country) => (
                                      <li
                                          className="countries__item h4"
                                          key={country.id}
                                      >
                                          <Link
                                              className="countries__link"
                                              to={`${
                                                  locale !== 'en'
                                                      ? `/${locale}`
                                                      : ''
                                              }/search?dest=${getCountryName(
                                                  country.country_code,
                                                  locale,
                                                  'region'
                                              ).replace('&', 'and')}&lat=${
                                                  country.coordinates
                                                      .center_latitude
                                              }&lng=${
                                                  country.coordinates
                                                      .center_longitude
                                              }&zoom=7`}
                                          >
                                              {getCountryName(
                                                  country.country_code,
                                                  locale,
                                                  'region'
                                              ).replace('&', 'and')}
                                          </Link>
                                          <ul className="countries__list">
                                              {country.cities?.map((city) => (
                                                  <li key={city._id}>
                                                      <Link
                                                          className="countries__link"
                                                          to={`${
                                                              locale !== 'en'
                                                                  ? `/${locale}`
                                                                  : ''
                                                          }/search?dest=${
                                                              city.name
                                                          }&lat=${
                                                              city.coordinates
                                                                  .center_latitude
                                                          }&lng=${
                                                              city.coordinates
                                                                  .center_longitude
                                                          }&zoom=13`}
                                                      >
                                                          {city.name}
                                                      </Link>
                                                  </li>
                                              ))}
                                          </ul>
                                      </li>
                                  )
                              )}
                    </ul>
                </div>
            </section>
        </>
    );
};

export default Countries;
