import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import axios from 'axios';

import { useLocaleContext } from '../../../core/context/localeContext';
import { useUserContext } from '../../../core/context/userContext';
import CustomInput from '../../../common/CustomInput/CustomInput';
import { Icon } from '../../../common/Icon/Icon';

import './ChangePassword.css';
import Loader from '../../../common/Loader/Loader';

const BASE_URL =
    process.env.REACT_APP_BASE_URL || 'https://www.sleepercharger.com';

const ChangePassword = () => {
    const { locale, t, urlPrefix } = useLocaleContext();
    const [loading, setLoading] = useState(false);
    const { userDetails, setUserDetails, logout } = useUserContext();
    const [details, setDetails] = useState({
        old: '',
        new: '',
    });
    const [fieldErrors, setFieldErrors] = useState({
        old: '',
        new: '',
    });
    const [error, setError] = useState('');
    const [message, setMessage] = useState('');

    const handleChange = (evt) => {
        let name = evt.target.name;
        let value = evt.target.value;

        setDetails((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleResult = (result) => {
        if (result.status === 200) {
            let token = result.data.token;
            setUserDetails({ ...userDetails, token });
            setMessage(t('Password successfully changed'));
            setTimeout(() => {
                logout();
            }, 2000);
        } else {
            setError(result?.data?.message);
            console.log('Password not changed');
        }
    };

    const handleLoginSubmit = () => {
        setLoading(true);
        setError('');
        setMessage('');
        const tryChangePassword = async () => {
            let result = await axios.post(
                `${process.env.REACT_APP_SC_API_BASE}/user/change-password`,
                {
                    ...details,
                },
                {
                    headers: {
                        Authorization: `Bearer ${userDetails.token}`,
                    },
                }
            );

            setLoading(false);

            handleResult(result);
        };

        tryChangePassword().catch((err) => {
            setLoading(false);
            handleResult(err.response);
        });
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        let errorNumb = 0;

        for (const error in fieldErrors) {
            if (!details[error]) {
                errorNumb++;
                setFieldErrors((prevState) => {
                    return { ...prevState, [error]: 'empty' };
                });
            }
        }

        if (errorNumb === 0) handleLoginSubmit();
    };

    const forgotPasswordLink = (
        <Link to={`${urlPrefix}/forgot-password`} className="link">
            {t('Forgot your password?')}
        </Link>
    );

    return (
        <>
            <Helmet>
                <title>{`${t('Change your password')} | SleeperCharger`}</title>
                <meta
                    name="description"
                    content={`${t('Change your password')} | SleeperCharger`}
                />
                <link
                    rel="canonical"
                    href={`${BASE_URL}${
                        locale !== 'en' ? `/${locale}` : ''
                    }/change-password`}
                />
            </Helmet>
            <section className="change-password signup">
                <div className="wrap wrap--sm">
                    <Link
                        className="change-password__back link"
                        to={`${urlPrefix}/profile`}
                    >
                        <Icon.Arrow className="icon-small icon-left flip-x" />
                        {t('Back to profile')}
                    </Link>
                    {!message ? null : (
                        <div className="change-password__message info info--green">
                            <Icon.Check className="icon-small icon-left" />
                            {t(message)}
                        </div>
                    )}
                    {!error ? null : (
                        <div className="change-password__message info info--red">
                            <Icon.Alert className="icon-small icon-left" />
                            {t(error)}
                        </div>
                    )}
                    <div className="content-container">
                        <div className="signup__header">
                            <h1 className="signup__title h2">
                                {t('Change your password')}
                            </h1>
                        </div>
                        <form onSubmit={handleFormSubmit}>
                            <CustomInput
                                id="old-password"
                                label="Old password"
                                name="old"
                                type="password"
                                value={details.old}
                                autoComplete="off"
                                onChange={(e) => {
                                    handleChange(e);
                                    setFieldErrors((prevState) => {
                                        return { ...prevState, old: '' };
                                    });
                                }}
                                placeholder="Password"
                                error={fieldErrors.old}
                                emptyMessage="Please fill in your old password"
                                invalidMessage="Please enter a valid old password"
                                link={forgotPasswordLink}
                            />
                            <CustomInput
                                id="new-password"
                                label="New password"
                                name="new"
                                type="password"
                                value={details.new}
                                autoComplete="off"
                                onChange={(e) => {
                                    handleChange(e);
                                    setFieldErrors((prevState) => {
                                        return { ...prevState, new: '' };
                                    });
                                }}
                                placeholder="Password"
                                error={fieldErrors.new}
                                emptyMessage="Please fill in your new password"
                                invalidMessage="Please enter a valid new password"
                                info="Password should contain minimally 8 characters, etc."
                            />
                            <button
                                className="change-password__btn btn btn--blue"
                                type="submit"
                            >
                                {t('Save')}
                                {loading ? (
                                    <Loader
                                        size={20}
                                        bgColor={'var(--secondary90)'}
                                        color={'var(--primary)'}
                                        className="icon-right"
                                    />
                                ) : null}
                            </button>
                        </form>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ChangePassword;
