import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import qs from 'qs';

import Hero from '../Components/Hero/Hero.js';
import { useLocaleContext } from '../core/context/localeContext';
import PageBuilder from '../Components/PageBuilder';
import useLoader from '../core/hooks/useLoader.js';
const BASE_URL =
    process.env.REACT_APP_BASE_URL || 'https://www.sleepercharger.com';

function Faq(props) {
    const [faqs, setFaq] = useState();
    const { locale, urlPrefix } = useLocaleContext();
    const navigate = useNavigate();
    useLoader(faqs);

    useEffect(() => {
        const getFaqItems = async () => {
            const query = qs.stringify(
                {
                    locale: locale,
                    populate: {
                        hero: {
                            populate: ['image', 'imageMobile'],
                        },
                        questions: {
                            populate: '*',
                        },
                        questions2: {
                            populate: '*',
                        },
                        builder: {
                            populate: '*',
                        },
                        pageMeta: {
                            populate: '*',
                        },
                    },
                },
                {
                    encodeValuesOnly: true, // prettify URL
                }
            );
            await axios
                .get(`${process.env.REACT_APP_API_URL}/api/faq?${query}`, {
                    headers: {
                        Authorization: process.env.REACT_APP_API_KEY,
                    },
                })
                .then((res) => {
                    setFaq(res.data.data.attributes);
                })
                .catch(() => {
                    setFaq(null);
                    navigate(`${urlPrefix}/`, { replace: true });
                });
        };

        if (locale) getFaqItems();
    }, [locale, navigate, urlPrefix]);

    return (
        <>
            {faqs && (
                <>
                    <Helmet>
                        <title>
                            {faqs?.pageMeta?.title || 'FAQ'} | SleeperCharger
                        </title>
                        <meta
                            name="description"
                            content={
                                faqs?.pageMeta?.description ||
                                'Hotel with guaranteed electric vehicle chargers. Book now only at SleeperCharger.'
                            }
                        />
                        <link
                            rel="canonical"
                            href={
                                faqs?.pageMeta?.canonical ||
                                `${BASE_URL}${
                                    locale !== 'en' ? `/${locale}` : ''
                                }/faq`
                            }
                        />
                    </Helmet>
                    {faqs.hero && (
                        <Hero data={faqs.hero} isMobile={props.isMobile} />
                    )}
                    {faqs.builder.length && (
                        <PageBuilder blocks={faqs.builder} />
                    )}
                </>
            )}
        </>
    );
}

export default Faq;
