import { ReactComponent as Airco } from './icons/airco.svg';
import { ReactComponent as Alert } from './icons/alert.svg';
import { ReactComponent as Arrow } from './icons/arrow.svg';
import { ReactComponent as Bar } from './icons/bar.svg';
import { ReactComponent as Bed } from './icons/bed.svg';
import { ReactComponent as Bin } from './icons/bin.svg';
import { ReactComponent as Bolt } from './icons/bolt.svg';
import { ReactComponent as Calendar } from './icons/calendar.svg';
import { ReactComponent as Camera } from './icons/camera.svg';
import { ReactComponent as Car } from './icons/car.svg';
import { ReactComponent as Castle } from './icons/castle.svg';
import { ReactComponent as Check } from './icons/check.svg';
import { ReactComponent as Checkmark } from './icons/checkmark.svg';
import { ReactComponent as Chevron } from './icons/chevron.svg';
import { ReactComponent as Child } from './icons/child.svg';
import { ReactComponent as City } from './icons/city.svg';
import { ReactComponent as Clock } from './icons/clock.svg';
import { ReactComponent as Couple } from './icons/couple.svg';
import { ReactComponent as Diamond } from './icons/diamond.svg';
import { ReactComponent as Distance } from './icons/distance.svg';
import { ReactComponent as Download } from './icons/download.svg';
import { ReactComponent as Edit } from './icons/edit.svg';
import { ReactComponent as Eye } from './icons/eye.svg';
import { ReactComponent as Eyehidden } from './icons/eyehidden.svg';
import { ReactComponent as Family } from './icons/family.svg';
import { ReactComponent as Filter } from './icons/filter.svg';
import { ReactComponent as Food } from './icons/food.svg';
import { ReactComponent as Friends } from './icons/friends.svg';
import { ReactComponent as Globe } from './icons/globe.svg';
import { ReactComponent as Gym } from './icons/gym.svg';
import { ReactComponent as Hand } from './icons/hand.svg';
import { ReactComponent as Heart } from './icons/heart.svg';
import { ReactComponent as Hotel } from './icons/hotel.svg';
import { ReactComponent as House } from './icons/house.svg';
import { ReactComponent as Images } from './icons/images.svg';
import { ReactComponent as Info } from './icons/info.svg';
import { ReactComponent as InfoCircle } from './icons/info-circle.svg';
import { ReactComponent as Leaf } from './icons/leaf.svg';
import { ReactComponent as List } from './icons/list.svg';
import { ReactComponent as Lock } from './icons/lock.svg';
import { ReactComponent as Magic } from './icons/magic.svg';
import { ReactComponent as Mail } from './icons/mail.svg';
import { ReactComponent as Map } from './icons/map.svg';
import { ReactComponent as Menu } from './icons/menu.svg';
import { ReactComponent as Minus } from './icons/minus.svg';
import { ReactComponent as Moon } from './icons/moon.svg';
import { ReactComponent as Parasol } from './icons/parasol.svg';
import { ReactComponent as Payment } from './icons/payment.svg';
import { ReactComponent as Percentage } from './icons/percentage.svg';
import { ReactComponent as Phone } from './icons/phone.svg';
import { ReactComponent as Plug } from './icons/plug.svg';
import { ReactComponent as Plus } from './icons/plus.svg';
import { ReactComponent as Pointer } from './icons/pointer.svg';
import { ReactComponent as Resort } from './icons/resort.svg';
import { ReactComponent as Search } from './icons/search.svg';
import { ReactComponent as Services } from './icons/services.svg';
import { ReactComponent as Share } from './icons/share.svg';
import { ReactComponent as Sign } from './icons/sign.svg';
import { ReactComponent as Size } from './icons/size.svg';
import { ReactComponent as Ski } from './icons/ski.svg';
import { ReactComponent as Sleep } from './icons/sleep.svg';
import { ReactComponent as Smiley } from './icons/smiley.svg';
import { ReactComponent as Star } from './icons/star.svg';
import { ReactComponent as StarHalf } from './icons/star-half.svg';
import { ReactComponent as StarHollow } from './icons/star-hollow.svg';
import { ReactComponent as Sun } from './icons/sun.svg';
import { ReactComponent as Swim } from './icons/swim.svg';
import { ReactComponent as Tent } from './icons/tent.svg';
import { ReactComponent as Threestars } from './icons/threestars.svg';
import { ReactComponent as Tree } from './icons/tree.svg';
import { ReactComponent as Twostars } from './icons/twostars.svg';
import { ReactComponent as User } from './icons/user.svg';
import { ReactComponent as Villa } from './icons/villa.svg';
import { ReactComponent as Wellness } from './icons/wellness.svg';
import { ReactComponent as Wifi } from './icons/wifi.svg';

import { ReactComponent as BedSolid } from './icons/solid/bed.svg';
import { ReactComponent as BoltSolid } from './icons/solid/bolt.svg';
import { ReactComponent as FamilySolid } from './icons/solid/family.svg';
import { ReactComponent as HotelSolid } from './icons/solid/hotel.svg';
import { ReactComponent as SizeSolid } from './icons/solid/size.svg';
import { ReactComponent as TriangleSolid } from './icons/solid/triangle.svg';

import { ReactComponent as Chademo } from './icons/connectors/CHADEMO.svg';
import { ReactComponent as DomesticA } from './icons/connectors/DOMESTIC_A.svg';
import { ReactComponent as DomesticB } from './icons/connectors/DOMESTIC_B.svg';
import { ReactComponent as DomesticC } from './icons/connectors/DOMESTIC_C.svg';
import { ReactComponent as DomesticD } from './icons/connectors/DOMESTIC_D.svg';
import { ReactComponent as DomesticE } from './icons/connectors/DOMESTIC_E.svg';
import { ReactComponent as DomesticF } from './icons/connectors/DOMESTIC_F.svg';
import { ReactComponent as DomesticG } from './icons/connectors/DOMESTIC_G.svg';
import { ReactComponent as DomesticH } from './icons/connectors/DOMESTIC_H.svg';
import { ReactComponent as DomesticI } from './icons/connectors/DOMESTIC_I.svg';
import { ReactComponent as DomesticJ } from './icons/connectors/DOMESTIC_J.svg';
import { ReactComponent as DomesticK } from './icons/connectors/DOMESTIC_K.svg';
import { ReactComponent as DomesticL } from './icons/connectors/DOMESTIC_L.svg';
import { ReactComponent as DomesticX } from './icons/connectors/DOMESTIC_X.svg';
import { ReactComponent as DomesticY } from './icons/connectors/DOMESTIC_Y.svg';
import { ReactComponent as DomesticZ } from './icons/connectors/DOMESTIC_Z.svg';
import { ReactComponent as IEC_60309_2_single_16 } from './icons/connectors/IEC_60309_2_single_16.svg';
import { ReactComponent as IEC_60309_2_three_16 } from './icons/connectors/IEC_60309_2_three_16.svg';
import { ReactComponent as IEC_60309_2_three_32 } from './icons/connectors/IEC_60309_2_three_32.svg';
import { ReactComponent as IEC_60309_2_three_64 } from './icons/connectors/IEC_60309_2_three_64.svg';
import { ReactComponent as IEC_62196_T1 } from './icons/connectors/IEC_62196_T1.svg';
import { ReactComponent as IEC_62196_T1_COMBO } from './icons/connectors/IEC_62196_T1_COMBO.svg';
import { ReactComponent as IEC_62196_T2_COMBO } from './icons/connectors/IEC_62196_T2_COMBO.svg';
import { ReactComponent as IEC_62196_T2 } from './icons/connectors/IEC_62196_T2.svg';
import { ReactComponent as IEC_62196_T3A } from './icons/connectors/IEC_62196_T3A.svg';
import { ReactComponent as IEC_62196_T3C } from './icons/connectors/IEC_62196_T3C.svg';
import { ReactComponent as TESLA_R } from './icons/connectors/TESLA_R.svg';
import { ReactComponent as TESLA_S } from './icons/connectors/TESLA_S.svg';

export const Icon = {
    Airco,
    Alert,
    Arrow,
    Bar,
    Bed,
    Bin,
    Bolt,
    Calendar,
    Camera,
    Car,
    Castle,
    Check,
    Checkmark,
    Chevron,
    Child,
    City,
    Clock,
    Couple,
    Diamond,
    Distance,
    Download,
    Edit,
    Eye,
    Eyehidden,
    Family,
    Filter,
    Food,
    Friends,
    Globe,
    Gym,
    Hand,
    Heart,
    Hotel,
    House,
    Images,
    Info,
    InfoCircle,
    Leaf,
    List,
    Lock,
    Magic,
    Mail,
    Map,
    Menu,
    Minus,
    Moon,
    Parasol,
    Payment,
    Percentage,
    Phone,
    Plug,
    Plus,
    Pointer,
    Resort,
    Search,
    Services,
    Share,
    Sign,
    Size,
    Ski,
    Sleep,
    Smiley,
    Star,
    StarHalf,
    StarHollow,
    Sun,
    Swim,
    Tent,
    Threestars,
    Tree,
    Twostars,
    User,
    Villa,
    Wellness,
    Wifi,
    BedSolid,
    BoltSolid,
    FamilySolid,
    HotelSolid,
    SizeSolid,
    TriangleSolid,
    Chademo,
    DomesticA,
    DomesticB,
    DomesticC,
    DomesticD,
    DomesticE,
    DomesticF,
    DomesticG,
    DomesticH,
    DomesticI,
    DomesticJ,
    DomesticK,
    DomesticL,
    DomesticX,
    DomesticY,
    DomesticZ,
    IEC_60309_2_single_16,
    IEC_60309_2_three_16,
    IEC_60309_2_three_32,
    IEC_60309_2_three_64,
    IEC_62196_T1,
    IEC_62196_T1_COMBO,
    IEC_62196_T2,
    IEC_62196_T2_COMBO,
    IEC_62196_T3A,
    IEC_62196_T3C,
    TESLA_R,
    TESLA_S,
};
