import React, { useState, useEffect, useMemo, useContext } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { Helmet } from 'react-helmet';

import Map from '../SimpleGoogleMap/SimpleGoogleMap.js';

import {
    format as formatDate,
    addMinutes,
    isAfter,
    differenceInDays,
    parseISO,
} from 'date-fns';

import { OccupancyString, ToBool } from '../../helpers.js';

import useScreenSize from '../../core/hooks/useScreenSize.js';

import './MyBooking.css';
import { Icon } from '../../common/Icon/Icon.js';
import CheckInInfo from '../CheckInInfo/CheckInInfo.js';
import Amenities from '../Amenities/Amenities.js';
import ChargerInfoCards from '../ChargerInfoCards/ChargerInfoCards.js';
import RoomInfo from '../RoomInfo/RoomInfo.js';
import PropertyInfo from '../PropertyInfo/PropertyInfo.js';
import { useLocaleContext } from '../../core/context/localeContext.js';
import PageLoader from '../../common/PageLoader/PageLoader.js';
import { ThemeContext } from '../../providers/ThemeProvider.js';
import { useUserContext } from '../../core/context/userContext.js';
import SearchUSP from '../SearchUSP/SearchUSP.js';
import FindBookingForm from '../FindBookingForm/FindBookingForm.js';
import Gallery from '../Gallery/Gallery.js';

const REACT_APP_SHOW_USERS = ToBool(process.env.REACT_APP_SHOW_USERS);

// const checkIsExpired = (val) => {
//     console.log('checkIsExpired', val)
//     return isAfter(new Date(), val);
// };

export default function MyBooking(props) {
    const [searchParams] = useSearchParams();
    const [bookingInfo, setBookingInfo] = useState(0);
    const [meta, setMeta] = useState(0);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState([]);
    const [mapZoom, setMapZoom] = useState(16);
    const { locale, urlPrefix, t } = useLocaleContext();
    const { setPage } = useContext(ThemeContext);
    const { userDetails } = useUserContext();
    const bookingId = searchParams.get('booking_id');
    const hash = searchParams.get('h');
    const [fromGallery, setFromGallery] = useState(false);

    // const [ isExpired, setIsExpired ] = useState(false);

    const { isMobile } = useScreenSize();

    const galleryParams = useMemo(
        () => ({
            gallery: searchParams.get('gallery'),
            gallery_item: searchParams.get('gallery_item'),
        }),
        [searchParams]
    );

    useEffect(() => {
        let isSubscribed = true;
        let tempErrors = []; //@Mutates
        let getBooking;
        if (!hash || galleryParams.gallery || fromGallery) return;
        getBooking = async () => {
            let params = {
                lang: locale,
            };
            setLoading(true);
            let result = await axios.get(
                `${process.env.REACT_APP_SC_API_BASE}/booking?h=${hash}`,
                {
                    params,
                }
            );

            if (isSubscribed) {
                if (result?.data) {
                    setBookingInfo(result.data.data?.item);
                    setMeta(result.data.data?.meta);
                    if (result.data.error) setErrors([result.data.error]);
                }
            }
            setLoading(false);
        };

        getBooking().catch((e) => {
            console.error(e);
            if (e.response) {
                tempErrors.push(e.response.data.message);
                setErrors(tempErrors);
                setLoading(false);
            }
        });
    }, [locale, hash, searchParams, userDetails.token]);

    let item = useMemo(() => {
        return bookingInfo ? bookingInfo.supplierInfo : null;
    }, [bookingInfo]);

    let chargerMarkers = useMemo(() => {
        return item
            ? item.chargers_nearby.map((e) => {
                  return {
                      ...e,
                      latlng: {
                          lat: e.loc.coordinates[1],
                          lng: e.loc.coordinates[0],
                      },
                      type: 'charger',
                  };
              })
            : [];
    }, [item]);

    let supplierLatlng = useMemo(() => {
        return {
            lat: item ? item.loc.coordinates[1] : 0,
            lng: item ? item.loc.coordinates[0] : 0,
        };
    }, [item]);

    let markers = useMemo(
        () =>
            [
                {
                    latlng: supplierLatlng,
                    name: item ? item.name : '',
                },
            ].concat(chargerMarkers),
        [chargerMarkers, item, supplierLatlng]
    );

    useEffect(() => {
        setPage('my-booking');

        return () => {
            setPage('');
        };
    }, []);

    if (errors.length) {
        return (
            <section className="my-booking">
                <div className="wrap">
                    {errors.map((e, i) => {
                        return (
                            <div className="h4" key={i}>
                                Error: {e}
                            </div>
                        );
                    })}
                </div>
            </section>
        );
    }

    // @TODO Temporary, move calculations and conclusions to back-end
    const reservedUntil = addMinutes(new Date(bookingInfo?.found?.created), 10);
    // const reservedUntil = addMinutes(new Date(), 10);
    const isExpired = isAfter(new Date(), reservedUntil);
    // let timeDiff = differenceInSeconds(reservedUntil, new Date())
    const canceled = bookingInfo?.found?.status === 'canceled';

    // clearTimeout(timeOut)
    // timeOut = setTimeout(() => {
    //     setIsExpired(true);
    // }, timeDiff);

    let numNights;

    if (meta?.checkout && meta?.checkin) {
        numNights = differenceInDays(
            parseISO(meta.checkout),
            parseISO(meta.checkin)
        );
    }

    let occupancyString = OccupancyString({
        adults: meta?.adults,
        children: meta?.child_age?.length,
        nights: numNights,
        t,
    });

    const googleLink = encodeURI(
        `${item?.name},${item?.address.line_1},${item?.address.postal_code},${item?.address.city},${item?.address.country_code}`
    );

    return (
        <section className="my-booking">
            <Helmet>
                <title>
                    {meta?.metatags?.title || 'My Booking | SleeperCharger'}
                </title>
                <meta
                    name="description"
                    content={
                        meta?.metatags?.description ||
                        'My booking at SleeperCharger'
                    }
                />
            </Helmet>
            {!hash ? (
                <div className="wrap wrap--sm">
                    <div className="content-container">
                        <div className="signup__header">
                            <h1 className="signup__title h2">
                                {t('Looking for a booking?')}
                            </h1>
                            <p className="signup__desc h4">
                                {t('Fill out the form below')}
                            </p>
                        </div>
                        <FindBookingForm bookingId={bookingId} />
                    </div>
                </div>
            ) : null}
            {loading ? <PageLoader /> : null}
            <div className="wrap wrap--md">
                {loading ? (
                    <div className="my-booking__loader">
                        <div className="block-loader"></div>
                        <div className="block-loader"></div>
                    </div>
                ) : !bookingInfo || !meta ? null : bookingInfo.found.status ===
                  'completed' ? (
                    <>
                        <div className="my-booking__header">
                            <h1 className="my-booking__title h2">
                                {t('Your booking')}
                            </h1>
                            {/* ToDo correct label */}
                            <div className="my-booking__label">
                                <Icon.Check className="icon-small" />
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: t(
                                            "Your booking with reservation number %{booking_id} is succesfully paid. We've sent a confirmation to %{email}",
                                            {
                                                booking_id: `<strong>${meta.booking_id}</strong>`,
                                                email: bookingInfo.found
                                                    ?.user_details?.email,
                                            }
                                        ),
                                    }}
                                />
                            </div>
                        </div>
                        <div className="my-booking__container my-booking__container__completed">
                            <div className="my-booking__summary">
                                <h2 className="my-booking__subtitle h3">
                                    {t('Payment summary')}
                                </h2>
                                <div className="my-booking__info">
                                    <h2 className="room-info__title h4">
                                        {bookingInfo.supplierInfo.name}
                                    </h2>
                                    <a
                                        className="room-info__address link"
                                        href={`https://www.google.com/maps/dir/?api=1&destination=${googleLink}`}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {item.address.line_1},{' '}
                                        {item.address.postal_code}{' '}
                                        {item.address.city}
                                    </a>
                                    <RoomInfo
                                        meta={meta}
                                        roomData={bookingInfo.rooms[0].extra}
                                        bookingInfo={bookingInfo}
                                        occupancyString={occupancyString}
                                        numNights={numNights}
                                        item={item}
                                    />
                                </div>
                                <h2 className="my-booking__subtitle h3">
                                    {t('Booker information')}
                                </h2>
                                <div className="my-booking__info">
                                    <div className="my-booking__row">
                                        <div className="my-booking__column">
                                            <h3 className="my-booking__name h4">
                                                {
                                                    bookingInfo.found
                                                        .user_details.firstname
                                                }{' '}
                                                {
                                                    bookingInfo.found
                                                        .user_details.lastname
                                                }
                                            </h3>
                                            <div>
                                                {
                                                    bookingInfo.found
                                                        .user_details.email
                                                }{' '}
                                                •{' '}
                                                <span className="my-booking__phone">
                                                    {
                                                        bookingInfo.found
                                                            .user_details
                                                            .phone_country_code
                                                    }{' '}
                                                    {
                                                        bookingInfo.found
                                                            .user_details.phone
                                                    }
                                                </span>
                                            </div>
                                        </div>
                                        {REACT_APP_SHOW_USERS &&
                                        userDetails.email !==
                                            bookingInfo.found.user_details
                                                .email ? (
                                            <Link
                                                className="btn btn--blue"
                                                to={`${urlPrefix}/register`}
                                            >
                                                {t('Create account')}
                                            </Link>
                                        ) : null}
                                    </div>
                                    {!bookingInfo.found.user_details
                                        .company ? null : (
                                        <div className="my-booking__row my-booking__row--border">
                                            <div className="my-booking__column">
                                                <h3 className="my-booking__name h4">
                                                    {
                                                        bookingInfo.found
                                                            .user_details
                                                            .company.name
                                                    }
                                                </h3>
                                                <div>
                                                    <span className="my-booking__address">
                                                        {
                                                            bookingInfo.found
                                                                .user_details
                                                                .company.address
                                                                .line_1
                                                        }
                                                        {', '}
                                                        {
                                                            bookingInfo.found
                                                                .user_details
                                                                .company.address
                                                                .postal_code
                                                        }{' '}
                                                        {
                                                            bookingInfo.found
                                                                .user_details
                                                                .company.address
                                                                .city
                                                        }
                                                    </span>{' '}
                                                    •{' '}
                                                    {
                                                        bookingInfo.found
                                                            .user_details
                                                            .company.address
                                                            .country_code
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <CheckInInfo
                                    isMobile={isMobile}
                                    meta={meta}
                                    checkTitleFont="h3"
                                />
                                <Gallery
                                    item={item}
                                    galleryParams={galleryParams}
                                    isMobile={isMobile}
                                    setFromGallery={setFromGallery}
                                />

                                {!isMobile && (
                                    <>
                                        <Amenities
                                            amenities={Object.values(
                                                item.amenities
                                            )}
                                        />

                                        <h2 className="my-booking__subtitle h3">
                                            {t('Additional hotel information')}
                                        </h2>

                                        <div className="policies">
                                            <PropertyInfo
                                                isMobile={isMobile}
                                                item={item}
                                                hideRoomInfo
                                                bookingInfo={bookingInfo}
                                                pricing={
                                                    bookingInfo.roomsRawPricing
                                                }
                                                meta={meta}
                                                roomData={
                                                    bookingInfo.rooms[0].extra
                                                }
                                            />
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className="my-booking__map">
                                <div className="my-booking__map-container">
                                    <h2 className="my-booking__subtitle h3">
                                        {t('Chargers')}
                                    </h2>
                                    <Map
                                        google={props.google}
                                        matches={markers}
                                        center={supplierLatlng}
                                        showRadius="donut"
                                        radius={200}
                                        disableClick={true}
                                        smallMap={true}
                                        confirmedChargers={
                                            item.confirmed_chargers
                                        }
                                        zoom={mapZoom}
                                        setZoom={setMapZoom}
                                        mapStyle={{
                                            height: isMobile ? 480 : 402,
                                            borderRadius: '24px',
                                            overflow: 'hidden',
                                        }}
                                    />
                                    <ChargerInfoCards
                                        item={item}
                                        checkin={parseISO(meta.checkin)}
                                    />
                                </div>
                                {isMobile && (
                                    <>
                                        <Amenities
                                            amenities={Object.values(
                                                item.amenities
                                            )}
                                        />

                                        <h2 className="my-booking__subtitle h3">
                                            {t('Additional hotel information')}
                                        </h2>

                                        <div className="policies">
                                            <PropertyInfo
                                                isMobile={isMobile}
                                                item={item}
                                                hideRoomInfo
                                                bookingInfo={bookingInfo}
                                                pricing={
                                                    bookingInfo.roomsRawPricing
                                                }
                                                meta={meta}
                                                roomData={
                                                    bookingInfo.rooms[0].extra
                                                }
                                            />
                                        </div>
                                    </>
                                )}
                                <div className="my-booking__support">
                                    <h2 className="my-booking__subtitle h3">
                                        {t('Need help?')}
                                    </h2>
                                    <Link
                                        to="/contact"
                                        className="my-booking__contact btn btn--outline-dark"
                                    >
                                        {t('Contact customer support')}{' '}
                                        <Icon.Mail className="icon-small icon-right" />
                                    </Link>
                                </div>
                                {isMobile ? null : <SearchUSP />}
                            </div>
                        </div>
                    </>
                ) : (
                    <div className="my-booking__container">
                        <div className="my-booking__payment">
                            {canceled ? (
                                <div
                                    className="info info--red"
                                    style={{ marginBottom: '20px' }}
                                >
                                    <Icon.Alert className="icon-small icon-left" />
                                    {t('This booking has been cancelled')}
                                </div>
                            ) : isExpired ? (
                                <>
                                    <h1 className="my-booking__title h2">
                                        {t('Payment')}
                                    </h1>
                                    <div className="my-booking__payment-card">
                                        <h2 className="my-booking__payment-subtitle h3">
                                            {t('Time period has passed')}.
                                        </h2>
                                        <p className="my-booking__payment-desc">
                                            {t('booking_period_passed_message')}
                                        </p>
                                    </div>
                                    <Link
                                        className="link"
                                        to={`${urlPrefix || '/'}`}
                                    >
                                        <Icon.Search className="icon-small icon-left" />
                                        {t('Search again')}
                                    </Link>
                                </>
                            ) : (
                                <>
                                    <h1 className="my-booking__title h2">
                                        {t('Payment')}
                                    </h1>
                                    <div className="my-booking__payment-card">
                                        {/* ToDo get real time */}
                                        <h2 className="my-booking__payment-subtitle h3">
                                            {t('Reserved until %{date}', {
                                                date: formatDate(
                                                    reservedUntil,
                                                    'HH:mm'
                                                ),
                                            })}
                                        </h2>
                                        <p className="my-booking__payment-desc">
                                            {t('reservation_pending_message', {
                                                booking_id: meta.booking_id,
                                            })}
                                        </p>
                                        <div className="my-booking__payment-link-container">
                                            <a
                                                className="my-booking__payment-link btn btn--blue"
                                                variant="contained"
                                                href={`${process.env.REACT_APP_SC_API_BASE}/pay_booking?booking_id=${meta.booking_id}&lang=${locale}`}
                                            >
                                                {t('Pay')}
                                            </a>
                                        </div>
                                    </div>
                                </>
                            )}
                            {isMobile ? null : <SearchUSP />}
                        </div>
                        <div className="my-booking__property">
                            <PropertyInfo
                                isMobile={isMobile}
                                item={item}
                                bookingInfo={bookingInfo}
                                pricing={bookingInfo.roomsRawPricing}
                                meta={meta}
                                roomData={bookingInfo.rooms[0].extra}
                            />
                        </div>
                    </div>
                )}
            </div>
        </section>
    );
}
