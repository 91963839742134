import ImageBlock from './ImageBlock/ImageBlock.js';
import QuoteBlock from './QuoteBlock/QuoteBlock.js';
import Title from './Title.js';
import ImageSlider from './ImageSlider/ImageSlider.js';
import useScreenSize from '../core/hooks/useScreenSize.js';
import ContentBlock from './ContentBlock/ContentBlock.js';
import ContactForm from './ContactForm/ContactForm';
import CardsBlock from './CardsBlock/CardsBlock.js';
import CategoryIndex from '../Pages/blog/components/CategoryIndex/CategoryIndex.js';
import FAQBlock from './FAQBlock/FAQBlock.js';
import TipsCarousel from './TipsCarousel/TipsCarousel.js';
import SearchBlock from './SearchBlock/SearchBlock';
import PropertyShowcase from './PropertyShowcase/PropertyShowcase.js';
import DiscoverImage from './DiscoverImage/DiscoverImage.js';
import CardsBlockHighlight from './CardsBlockHighlight/CardsBlockHighlight.js';
import Discover from './Discover/Discover.js';

function PageBuilder(props) {
    const { isMobile } = useScreenSize();

    return (
        <div className="page">
            <div className="wrap wrap--md">
                <div className="page__container">
                    {props.blocks.map((block, index) => {
                        if (block.__component === 'content-blocks.textblock') {
                            return <ContentBlock key={index} block={block} />;
                        }

                        if (block.__component === 'content-blocks.blog-list') {
                            return block.displayStyle !== 'Feature First' ? (
                                <CardsBlock
                                    key={index}
                                    title={block.title}
                                    blogs={block.blogs.data}
                                    button={block.button}
                                />
                            ) : (
                                <CardsBlockHighlight
                                    key={index}
                                    title={block.title}
                                    blogs={block.blogs.data}
                                    button={block.button}
                                />
                            );
                        }

                        if (
                            block.__component ===
                            'content-blocks.filtered-blog-list'
                        ) {
                            return (
                                <CategoryIndex
                                    key={index}
                                    categoryOld={block.themeCategory}
                                    categories={block.category?.data}
                                    blogType={block.blogType}
                                    themes={block.themes?.data}
                                    text={block.text}
                                    showAllBtn={block.showFilterAllButton !== false}   //backwards compatibility: when null, do show the filter button.
                                />
                            );
                        }

                        if (block.__component === 'content-blocks.faq-block') {
                            return (
                                <FAQBlock
                                    key={index}
                                    title={block.text}
                                    faqs={block.faqList}
                                    button={block.button}
                                    reverse={block.alignment === 'left'}
                                    pageBuilder={true}
                                    isMobile={isMobile}
                                />
                            );
                        }

                        if (
                            block.__component === 'content-blocks.tip-carousel'
                        ) {
                            return (
                                <TipsCarousel
                                    key={index}
                                    data={block}
                                    isMobile={isMobile}
                                />
                            );
                        }

                        if (block.__component === 'media.large-image') {
                            return (
                                <ImageBlock
                                    key={index}
                                    data={block.image.data}
                                />
                            );
                        }

                        if (block.__component === 'media.image-caroussel') {
                            return (
                                <ImageSlider
                                    key={index}
                                    images={block.images.data}
                                    isMobile={isMobile}
                                />
                            );
                        }

                        if (block.__component === 'textfields.quote') {
                            return (
                                <QuoteBlock key={index} quote={block.text} />
                            );
                        }

                        if (block.__component === 'textfields.title') {
                            return <Title key={index} title={block.text} />;
                        }

                        // What to best name the component?
                        if (block.__component === 'forms.hubspot-form') {
                            return (
                                <ContactForm
                                    key={index}
                                    title={block.title}
                                    text={block.text}
                                    portalId={block.portalId}
                                    formId={block.formId}
                                />
                            );
                        }

                        if (block.__component === 'content-blocks.search-bar') {
                            return (
                                <SearchBlock key={index} data={block}/>
                            );
                        }

                        if (block.__component === 'content-blocks.property-showcase') {
                            return (
                                <PropertyShowcase key={index} data={block}/>
                            );
                        }

                        if (block.__component === 'content-blocks.usp-block') {
                            return (
                                block.image.data ? <DiscoverImage key={index} data={block}/> : <Discover slogan={block.text} key={index} usp_items_list={block.uspItems}/>
                            );
                        }

                        return null;
                    })}
                </div>
            </div>
        </div>
    );
}

export default PageBuilder;
