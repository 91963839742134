import React from 'react';
import ChargerConnectorInfo from './ChargerConnectorInfo.js'

export default function ChargerEVSEInfo ({ pricing, distinctConnectors, chargerInfo }) {
    return (
        <div className="charger-evse">
            <ChargerConnectorInfo connector={distinctConnectors} pricing={pricing} chargerInfo={chargerInfo}/>
        </div>
    );
};


