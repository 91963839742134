import React, { useState, useEffect, useRef, } from "react";
import { createCustomEqual } from "fast-equals";

export default function Map ({ onClick, onIdle, onBoundsChanged, onDragEnd, children, style, ...options }) {
    const ref = useRef(null);
    const [ map, setMap ] = useState();

    useEffect(() => {
        if (ref.current && !map) {
            setMap(new window.google.maps.Map(ref.current, {}));
        }
    }, [ ref, map ]);

    // because React does not do deep comparisons, a custom hook is used
    // see discussion in https://github.com/googlemaps/js-samples/issues/946
    useDeepCompareEffectForMaps(() => {
        if (map) {
            map.setOptions(options);
        }
    }, [ map, options ]);

    useEffect(() => {
        if (map) {
            [ "click", "idle", 'bounds_changed', 'dragend' ].forEach((eventName) =>
                window.google.maps.event.clearListeners(map, eventName)
            );
            if (onClick) {
                map.addListener("click", onClick);
            }

            if (onIdle) {
                map.addListener("idle", () => onIdle(map));
            }

            if (onBoundsChanged) {
                map.addListener("bounds_changed", () => onBoundsChanged(map));
            }

            if (onDragEnd) {
                map.addListener("dragend", () => onDragEnd(map));
            }
        }
    }, [ map, onClick, onIdle ]);

    return (
        <>
            
            <div ref={ref} style={style} />
            
            {React.Children.map(children, (child) => {
                // set the map prop on the child component
                return React.cloneElement(child, { map });
            })}
        </>
    );

};

const deepCompareEqualsForMaps = createCustomEqual((deepEqual) => (a, b) => {
    // if (
    //   isLatLngLiteral(a) ||
    //   a instanceof window.google.maps.LatLng ||
    //   isLatLngLiteral(b) ||
    //   b instanceof window.google.maps.LatLng
    // )
    return new window.google.maps.LatLng(a).equals(new window.google.maps.LatLng(b));
    // TODO extend to other types
    // use fast-equals for other objects
    // return deepEqual(a, b);
});

function useDeepCompareMemoize (value) {
    const ref = useRef();

    if (!deepCompareEqualsForMaps(value, ref.current)) {
        ref.current = value;
    }
    return ref.current;
}

function useDeepCompareEffectForMaps (callback, dependencies) {
    useEffect(callback, [ ...dependencies.map(useDeepCompareMemoize), callback ]);
}
