import React, { useCallback, useMemo, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSearchParams, useParams, } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import axios from 'axios';
import NewPasswordForm from '../../Components/Registration/Registration/NewPasswordForm';
import ForgotPasswordForm from '../../Components/Registration/Login/ForgotPasswordForm';

import { Icon } from '../../common/Icon/Icon';
import Loader from '../../common/Loader/Loader';
import { useLocaleContext } from '../../core/context/localeContext';
import { useUserContext } from '../../core/context/userContext';

const State = {
    VERIFYING: 0,
    VALID_LINK: 1,
    ERROR: 2
}

const BASE_URL = process.env.REACT_APP_BASE_URL || 'https://www.sleepercharger.com';

export default function NewPassword () {
    const navigate = useNavigate();
    const [ searchParams ] = useSearchParams();
    const { hash } = useParams();

    const { userDetails, setUserDetails, setLocalSessionToken, setSessionStorageToken } = useUserContext();

    const [ type, setType ] = useState('password');
    const [ loading, setLoading ] = useState(false);
    const { locale, t } = useLocaleContext();
    const [ password, setPassword ] = useState('');
    const [ result, setResult ] = useState(State.VERIFYING);
    const [ errors, setErrors ] = useState({
        password: '',
    });

    // Check if the link is valid before doing anything
    useEffect(() => {
        const func = async () => {
            setLoading(true);

            let result = await axios.get(
                `${process.env.REACT_APP_SC_API_BASE}/user/reset-password/${hash}`
            );

            setLoading(false);

            setResult(State.VALID_LINK)

        };

        func().catch((err) => {
            setLoading(false);

            if (err.response.status === 400) {
                console.log(err.response.data.message)
                setResult(State.ERROR)
            } else {
                console.error(err)
            }
        });
    }, [ searchParams ])

    // Send the new password
    const handleNewPasswordResult = useCallback((result) => {
        if (result.status === 200) {
            console.log(result)
            let token = result.data.token;
            setSessionStorageToken(token)
            setUserDetails({ token })
            navigate('../profile');
        }
    }, [])

    const handleResetPasswordResult = useCallback((result) => {
        console.log(result)
    })

    // @TODO show a useful message and redirect to somewhere useful
    const element = useMemo(() => {
        if (result === State.VERIFYING) {
            return null;
        } else if (result === State.VALID_LINK) {

            return (
                <NewPasswordForm handleResult={handleNewPasswordResult} />
            );
        } else {
            return (
                <>
                    <div className="wrap wrap--md">
                        <div className="profile__message profile__message__warning">
                            This password reset link is incorrect. Please request a new password reset link.
                        </div>
                    </div>
                    <ForgotPasswordForm handleResult={handleResetPasswordResult} />
                </>
            );
        }
    }, [ result ])

    return (
        <>
            <Helmet>
                <title>{`${t('Create new password')} | SleeperCharger`}</title>
                <meta
                    name="description"
                    content={`${t('Create new password')} | SleeperCharger`}
                />
                <link
                    rel="canonical"
                    href={`${BASE_URL}${locale !== 'en' ? `/${locale}` : ''
                        }/new-password`}
                />
            </Helmet>
            <section className="signup">
                <div className="wrap wrap--sm">
                    <div className="content-container">
                        {element}
                    </div>
                </div>
            </section>
        </>
    )
}
