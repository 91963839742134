import React from 'react';
import useScreenSize from '../core/hooks/useScreenSize.js';

import './PageLayout.css';
import useLoader from '../core/hooks/useLoader.js';

export default function PageLayout (props) {
    const { isMobile } = useScreenSize();
    useLoader();

    // urlPrefix logic has moved to App.js

    return (
        <div className="page-layout">
            {props.page ? React.cloneElement(props.page, {
                isMobile: isMobile,
            }) : null}
        </div>
    );
}
