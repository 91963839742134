import { useState, useEffect } from 'react';
import axios from 'axios';
import qs from 'qs';

import Card from '../../../../Components/Card/Card';
import './CategoryIndex.css';
import { Icon } from '../../../../common/Icon/Icon';
import { useLocaleContext } from '../../../../core/context/localeContext';
import ReactMarkdown from 'react-markdown';

function CategoryIndex(props) {
    const [filter, setFilter] = useState(null);
    const [themes, setThemes] = useState([]);
    const [blogs, setBlogs] = useState([]);
    const [numbShow, setNumbShow] = useState(6);
    const { locale, preview, urlPrefix, t } = useLocaleContext();

    const filteredBlogs = blogs.filter(
        (blog) => {
            return blog.attributes.themes.data.filter(
                (data) => data.attributes.slug === filter
            ).length > 0;
        }
    );

    const filterClicked = (e) => {
        let slug = e.currentTarget.getAttribute('data-slug');
        setFilter(slug);
    };

    useEffect(() => {
        let filters = {};
        filters.themes = {};
        if (!props.categoryOld) {
            filters.themes = {
                $and: []
            }
            if (props.themes.length > 0) {
                filters.themes.$and.push(
                    {
                        id: {
                            $in: props.themes.map(t => t.id),
                        }
                    });
            }
            if (props.categories.length > 0) {
                filters.themes.$and.push(
                    {
                        theme_category: {
                            id: {
                                $in: props.categories.map(c => c.id),
                            }
                        }
                    });
            }
        } else {
            filters.themes = {
                category: {
                    $contains: (props.categoryOld && props.categoryOld !== "_All_") ? `${props.categoryOld}` : "",
                }
            }
        }
        filters.blogType = {
            $contains: (props.blogType && props.blogType !== "_All_") ? `${props.blogType}` : ""
        }
        const getBlogs = async () => {
            const query = qs.stringify(
                {
                    locale: locale,
                    populate: {
                        featuredImage: {
                            populate: '*',
                        },
                        themes: {
                            populate: '*',
                        },
                    },
                    filters: filters,
                    publicationState: preview ? "preview" : "live"
                },
                {
                    encodeValuesOnly: true, // prettify URL
                }
            );
            await axios
                .get(`${process.env.REACT_APP_API_URL}/api/blogs?${query}`, {
                    headers: {
                        Authorization: process.env.REACT_APP_API_KEY,
                    },
                })
                .then((res) => {
                    let allThemes = res.data.data.map(b => b.attributes.themes.data).flat();
                    let uniq = allThemes
                        .sort(function (a, b) {
                            return a.id - b.id;
                        })
                        .reduce(function (a, b) {
                            if (a.slice(-1)[0]?.id !== b.id) a.push(b); // slice(-1)[0] means last item in array without removing it (like .pop())
                            return a;
                        }, []); // this empty array becomes the starting value for a

                    setThemes(uniq); 
                    setBlogs(res.data.data);
                })
                .catch(() => {
                    setBlogs(null);
                });
        };

        if (locale) {
            getBlogs();
        }
    }, [props.categoryOld, props.categories, props.themes, locale, preview]);

    useEffect(() => {
        if (!props.showAllBtn && themes.length >= 1) {
            setFilter(themes[0].attributes.slug);
        }
    }, [themes, blogs]);

    const listPosts = filter
        ? filteredBlogs.map((blog, i) => {
            return i < numbShow ? (
                <Card
                    key={blog.id}
                    title={blog.attributes.title}
                    subtitle={blog.attributes.subtitle}
                    date={blog.attributes.updatedAt}
                    image={
                        blog.attributes.featuredImage.data &&
                        blog.attributes.featuredImage.data.attributes
                    }
                    to={`${urlPrefix}/blog/${blog.attributes.slug}`}
                    draft={!blog.attributes.publishedAt}  //When null, article is draft version
                />
            ) : null;
        })
        : blogs.map((blog, i) => {
            return i < numbShow ? (
                <Card
                    key={blog.id}
                    title={blog.attributes.title}
                    subtitle={blog.attributes.subtitle}
                    date={blog.attributes.updatedAt}
                    image={
                        blog.attributes.featuredImage.data &&
                        blog.attributes.featuredImage.data.attributes
                    }
                    to={`${urlPrefix}/blog/${blog.attributes.slug}`}
                    draft={!blog.attributes.publishedAt}  //When null, article is draft version
                />
            ) : null;
        });

    if (blogs.length === 0) return null;
    return (
        <section className="category section">
            <div className="wrap">
                <div className="category__container">
                    <ReactMarkdown className="category__title text-container" linkTarget="_blank">{props.text}</ReactMarkdown>
                    <ul className="category__nav">
                        {props.showAllBtn &&
                            <li key="0">
                                <button
                                    onClick={filterClicked}
                                    data-slug={undefined}
                                    className={`category__btn h4${filter === null ? ' active' : ''
                                        }`}
                                >
                                    {t('All')}
                                </button>
                            </li>
                        }
                        {themes.map((theme, i) => {
                            return (
                                <li key={theme.id.toString()}>
                                    <button
                                        key={theme.attributes.slug}
                                        onClick={filterClicked}
                                        data-slug={theme.attributes.slug}
                                        className={`category__btn h4${theme.attributes.slug ===
                                            filter
                                            ? ' active'
                                            : ''
                                            }`}
                                    >
                                        {theme.attributes.title}
                                    </button>
                                </li>
                            );
                        })}
                    </ul>
                    <div className="category__blog-container">
                        {blogs && listPosts}
                    </div>
                    {numbShow < listPosts.length ? (
                        <button
                            className="category__more btn btn--green"
                            onClick={() =>
                                setNumbShow((prevState) => prevState + 6)
                            }
                            type="button"
                        >
                            <Icon.Sleep className="icon-small icon-left" />
                            Load more
                        </button>
                    ) : null}
                </div>
            </div>
        </section>
    );
}

export default CategoryIndex;
