import { useState, useEffect, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';

import useScreenSize from '../../core/hooks/useScreenSize.js';
import AdminSearchFilter from '../AdminSearchFilter.js';
import AdminSupplierInfo from './AdminSupplierInfo.js';
import GoogleMap from '../GoogleMap.js';
import Marker from '../Marker.js';
import Login from '../Login.js';
import { Icon } from '../../common/Icon/Icon.js';
import Loader from '../../common/Loader/Loader.js';

import { GeoJsonToLatLng, ToBool, IsValidEmail } from '../../helpers.js';

import './AdminMap.css';
import ToggleButton from '../../common/ToggleButton/ToggleButton.js';
import ToggleButtonItem from '../../common/ToggleButton/ToggleButtonItem/ToggleButtonItem.js';

const CHARGER_DISTANCE_RADIUS = 250;
const REGION_ZOOM_LEVEL = 9;
const PROPERTY_ZOOM_LEVEL = 16;
const DEFAULT_LATLNG = {
    lat: 52,
    lng: 5,
};
export const PROPERTY_FILTER = {
    all: 'all',
    unconfirmed: 'unconfirmed',
    confirmed: 'confirmed',
};

export default function AdminMap (props) {
    // const [ map, setMap ] = useState();
    const [ searchParams ] = useSearchParams();
    const { isMobile } = useScreenSize();

    const [ latspan, setLatspan ] = useState(0);
    const [ lngspan, setLngspan ] = useState(0);

    let queryParams = {
        sid: searchParams.get('sid'),
        max_distance: Number(searchParams.get('max_distance')),
        use_amenity: ToBool(searchParams.get('use_amenity')),
        has_amenity: ToBool(searchParams.get('has_amenity')),
        use_ecomov: ToBool(searchParams.get('use_ecomov')),
        hide_confirmed: ToBool(searchParams.get('hide_confirmed')),
        all_ratings: ToBool(searchParams.get('all_ratings')),
        country_id: searchParams.get('country_id'),
        province_id: searchParams.get('province_id'),
        lat: searchParams.get('lat'),
        lng: searchParams.get('lng'),
        only_empty_email: ToBool(searchParams.get('only_empty_email')),
        min_rating: Number(searchParams.get('min_rating')),
        prev_cursor: searchParams.get('prev_cursor'),
        next_cursor: searchParams.get('next_cursor'),
        latspan,
        lngspan,
    };
    const authenticationToken = sessionStorage.getItem('authenticationToken');
    const [ isLoading, setIsLoading ] = useState(false);
    const [ items, setItems ] = useState([]);
    const [ selectedChargers, setSelectedChargers ] = useState([]);
    const [ selectedId, setSelectedId ] = useState(0);
    const [ mapZoom, setMapZoom ] = useState(REGION_ZOOM_LEVEL);
    const [ mapCenter, setMapCenter ] = useState(DEFAULT_LATLNG);
    const [ regionCenter, setRegionCenter ] = useState(DEFAULT_LATLNG);
    const [ note, setNote ] = useState('');
    const [ email, setEmail ] = useState('');
    const [ user, setUser ] = useState(0);
    const [ propertyFilter, setPropertyFilter ] = useState(PROPERTY_FILTER.all);
    const [ groupedItems, setGroupedItems ] = useState({
        all: [],
        unconfirmed: [],
        confirmed: [],
    });
    const [ showNote, setShowNote ] = useState(false);
    const [ hotItemId, setHotItemId ] = useState(0);

    const [ pager, setPager ] = useState({ next: '', prev: '', total: 0 })

    useEffect(() => {
        // if (authenticationToken) Get user data
    }, [authenticationToken]);

    useEffect(() => {
        setShowNote(false);
        setNote('');
    }, [ selectedId ]);

    useEffect(() => {
        note ? setShowNote(true) : setShowNote(false);
    }, [ note ]);

    // let radius = queryParams.max_distance;
    // let matches = [];
    // let disableClick = false;
    // let showRadius = queryParams.use_ecomov === "true"

    const updateSupplier = async ({ unset = false }) => {
        setIsLoading(true);

        if (!selectedId) return;

        if (email && !IsValidEmail(email)) {
            return alert('Invalud email entered.');
        }

        let payload = { //@mutates
            email: email,
            charger_ids: selectedChargers.map(e => e._id),
            note: note,
        };

        if (unset) {
            payload.unset = true;
        }

        let token = user.token;

        let result = await axios.patch(
            `${process.env.REACT_APP_SC_API_BASE}/admin/supplier/${selectedId}`,
            payload,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        setIsLoading(false);

        if (result.status === 200) {
            // @TODO error handling
            let newItems = items.map((e) => {
                if (e._id === selectedId) {
                    e.meta = { ...result.data.value }
                }
                return e;
            });

            setItems(newItems);
            if (!unset) goNext();
        } else {
            console.error(result);
        }
    };

    const handleChargerCardClick = (charger) => {
        let sel = [ ...selectedChargers ]; // @Mutates

        let existingIndex = sel.findIndex((e) => e._id === charger._id);
        if (existingIndex < 0) {
            sel.push(charger);
        } else {
            sel.splice(existingIndex, 1);
        }

        setSelectedChargers(sel);
    };

    const handleChargerHover = useCallback((charger) => {
        setHotItemId(charger._id);
    }, []);

    const handleChargerHoverClear = useCallback(() => {
        setHotItemId(0);
    }, []);

    const fetchInfo = async ({ prev_cursor, next_cursor } = {}) => {

        setIsLoading(true);

        let params = { // @mutates
            max_distance: queryParams.max_distance, // 100m
            use_amenity: queryParams.use_amenity,
            has_amenity: queryParams.has_amenity,
            region_id: queryParams.province_id || queryParams.country_id,
            use_ecomov: queryParams.use_ecomov,
            hide_confirmed: queryParams.hide_confirmed,
            all_ratings: queryParams.all_ratings,
            sid: queryParams.sid,
            lat: queryParams.lat,
            lng: queryParams.lng,
            latspan: queryParams.latspan,
            lngspan: queryParams.lngspan,
            only_empty_email: queryParams.only_empty_email,
            min_rating: queryParams.min_rating,
            prev_cursor,
            next_cursor
        }

        let result = await axios.get(
            `${process.env.REACT_APP_SC_API_BASE}/admin/getInfo`,
            {
                params: params,
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            }
        );

        setIsLoading(false);


        let data = result.data;
        setItems(data.matches);
        // setMapZoom(REGION_ZOOM_LEVEL);
        setMapCenter(data.centerLatLng);
        setRegionCenter(data.centerLatLng);
        setSelectedId(queryParams.sid);
        setPager({
            prev: data.pager?.prev,
            next: data.pager?.next,
            total: data.pager?.total,
        })

    };

    // Init: Fetch items
    useEffect(() => {
        let isSubscribed = true;

        fetchInfo().catch(console.error);
    }, [
        searchParams,
        queryParams.has_amenity,
        queryParams.hide_confirmed,
        queryParams.all_ratings,
        queryParams.max_distance,
        queryParams.province_id,
        queryParams.sid,
        queryParams.use_amenity,
        queryParams.use_ecomov,
        queryParams.only_empty_email,
        queryParams.min_rating,
        queryParams.prev_cursor,
        queryParams.next_cursor,
        user,
    ]);

    const getMapBounds = (val) => {
        console.log('getMapBounds', val);
    };

    // When setting selected
    useEffect(() => {
        const updateSelected = (val) => {
            if (!val || !items.length) return;

            let selChargers = [];
            let selectedItem = items.find((e) => e._id === val);

            if (!selectedItem) {
                return;
            }

            setMapCenter(GeoJsonToLatLng(selectedItem.loc));
            setMapZoom(PROPERTY_ZOOM_LEVEL);

            if (selectedItem.chargers && selectedItem.meta && selectedItem.meta.ids) {
                selectedItem.meta.ids.forEach((id) => {
                    let foundCharger = selectedItem.chargers.find(
                        (c) => c._id === id
                    );
                    if (foundCharger) {
                        selChargers.push(foundCharger);
                    }
                });
            }

            setSelectedChargers(selChargers);

            if (selectedItem.meta) {
                setNote(selectedItem.meta.note || '');
                setEmail(selectedItem.meta.email || '');
            } else {
                setNote('');
                setEmail('');
            }
        };

        // zoom in on selected property
        updateSelected(selectedId);
    }, [ selectedId, items ]);

    let selectedItem = items.find((e) => e._id === selectedId);

    const goPrev = async () => {
        setSelectedChargers([]);
        setEmail('');
        setShowNote(false);

        let findIndex = groupedItems[ propertyFilter ].findIndex(
            (e) => e._id === selectedId
        );

        if (findIndex === 0) {
            await fetchInfo({ prev_cursor: pager.prev})
        } else {
            let nextIndex = findIndex - 1;
            if (nextIndex < 0) nextIndex = groupedItems[ propertyFilter ].length - 1;
            setSelectedId(groupedItems[ propertyFilter ][ nextIndex ]._id);
        }

    };

    const goNext = async () => {

        setSelectedChargers([]);
        setEmail('');
        setShowNote(false);

        let itemsInGroup = groupedItems[ propertyFilter ]

        let findIndex = itemsInGroup.findIndex(
            (e) => e._id === selectedId
        );

        if (findIndex === itemsInGroup.length - 1) {
            await fetchInfo({ next_cursor: pager.next })
        } else {
            let nextIndex = (findIndex + 1) % itemsInGroup.length;
            setSelectedId(itemsInGroup[ nextIndex ]._id);
        }
    };

    let csvUrl = `${process.env.REACT_APP_SC_API_BASE
        }/admin/getcsv?${searchParams.toString()}`;
    let flatcsvUrl = `${process.env.REACT_APP_SC_API_BASE
        }/admin/getcsv?flatten=true&${searchParams.toString()}`;
    let flatAllcsvUrl = `${process.env.REACT_APP_SC_API_BASE
        }/admin/get_all_csv?flatten=true&${searchParams.toString()}`;

    const tryLogin = async (payload) => {
        setIsLoading(true);

        let result = await axios.post(
            `${process.env.REACT_APP_SC_API_BASE}/login2`,
            payload
        );

        setIsLoading(false);

        if (result.status === 200) {
            let user = result.data.user;
            setUser(user);
            sessionStorage.setItem('authenticationToken', JSON.stringify(user.token));
        }
    };

    const getPlaceDetails = async (sid) => {
        setIsLoading(true);

        let result = await axios.get(
            `${process.env.REACT_APP_SC_API_BASE}/admin/get_place_details`,
            {
                params: {
                    sid
                },
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            }
        );

        setIsLoading(false);

        if (result.status === 200) {
            // @TODO error handling
            let newItems = items.map((e) => {
                if (e._id === selectedId) {
                    e.meta = { ...result.data }
                }
                return e;
            });

            setItems(newItems);

        } else {
            console.error(result);
        }
    };

    const handlePropertyFilter = useCallback(
        (e) => {
            setPropertyFilter(e);
            setSelectedId(0);
            setMapZoom(REGION_ZOOM_LEVEL);
            setMapCenter(regionCenter);
        },
        [ regionCenter ]
    );

    useEffect(() => {
        const unconfirmed = items.filter(
            (item) => {
                return !(item.meta && item.meta.ids)
            }
        );

        const confirmed = items.filter((item) => item.meta && item.meta.ids);

        setGroupedItems({
            all: items,
            unconfirmed: unconfirmed,
            confirmed: confirmed,
        });

    }, [ items ]);

    useEffect(() => {
        if (groupedItems[ propertyFilter ] && groupedItems[ propertyFilter ].length && !selectedId) {
            setSelectedId(groupedItems[ propertyFilter ][ 0 ]._id)
        }
    }, [ groupedItems ])

    if (!user)
        return (
            <div>
                <Login tryLogin={tryLogin} />
            </div>
        );


    return (
        <div className="admin-map">
            <div className="wrap">
                <div className="h4">Logged in as: {user.username}</div>
                <div className="admin-map__container">
                    <aside className="admin-map__filters">
                        <AdminSearchFilter user={user} />
                        <div className="admin-map__filters-footer">
                            {items.length} matches.
                            <a
                                className=""
                                href={flatcsvUrl}
                                target="_blank"
                                rel="noreferrer"
                                title="Flat CSV"
                            >

                                <Icon.Download className="icon-small icon-right" />
                            </a>
                            <a
                                className=""
                                href={csvUrl}
                                target="_blank"
                                rel="noreferrer"
                                title="Specified CSV"
                            >

                                <Icon.Download className="icon-small icon-right" />
                            </a>
                            <a
                                className=""
                                href={flatAllcsvUrl}
                                target="_blank"
                                rel="noreferrer"
                                title="Flat CSV All Provinces"
                            >

                                <Icon.Download className="icon-small icon-right" />
                            </a>
                        </div>
                    </aside>
                    <div className="admin-map__content">
                        <ToggleButton
                            label="Results view"
                            onChange={handlePropertyFilter}
                        >
                            <ToggleButtonItem value={PROPERTY_FILTER.all}>
                                {isMobile ? 'All' : 'All properties'}
                                <span className="admin-map__toggle-numb admin-map__toggle-numb--grey">
                                    {groupedItems.all.length}/{pager.total}
                                </span>
                            </ToggleButtonItem>
                            <ToggleButtonItem
                                value={PROPERTY_FILTER.unconfirmed}
                            >
                                {isMobile ? 'Todo' : 'Unconfirmed'}
                                <span className="admin-map__toggle-numb admin-map__toggle-numb--red">
                                    {groupedItems.unconfirmed.length}
                                </span>
                            </ToggleButtonItem>
                            <ToggleButtonItem value={PROPERTY_FILTER.confirmed}>
                                {isMobile ? 'Done' : 'Confirmed'}
                                <span className="admin-map__toggle-numb admin-map__toggle-numb--green">
                                    {groupedItems.confirmed.length}
                                </span>
                            </ToggleButtonItem>
                        </ToggleButton>

                        {isLoading ? (
                            <Loader size={32} className="admin-map__loader" />
                        ) : null}

                        <div className="admin-map__map-container">
                            <div className="search__map-btn-container">
                                <button
                                    className={`search__map-btn search__map-btn--minus`}
                                    onClick={() => {
                                        setMapZoom(mapZoom - 1);
                                    }}
                                >
                                    <Icon.Minus className="icon-small" />
                                </button>
                                <button
                                    className={`search__map-btn search__map-btn--plus`}
                                    onClick={() => {
                                        setMapZoom(mapZoom + 1);
                                    }}
                                >
                                    <Icon.Plus className="icon-small" />
                                </button>
                            </div>

                            <GoogleMap
                                onDragEnd={(map) => {
                                    const mapBounds = map.getBounds();
                                    const mapSpan = mapBounds.toSpan();
                                    const center = mapBounds.getCenter();
                                    const centerLatLng = {
                                        lat: center.lat(),
                                        lng: center.lng(),
                                    };
                                    const latspan = mapSpan.lat();
                                    const lngspan = mapSpan.lng();
                                    setLatspan(latspan);
                                    setLngspan(lngspan);

                                    setMapCenter(centerLatLng);
                                }}
                                zoom={mapZoom}
                                center={mapCenter}
                                style={{
                                    width:
                                        selectedItem && !isMobile
                                            ? 'calc(100% - 250px)'
                                            : '100%',
                                    height: isMobile ? '400px' : '796px',
                                    borderRadius: '24px',
                                }}
                                options={{
                                    disableDefaultUI: true,
                                    mapTypeId: 'hybrid',
                                    zoomControlOptions: {
                                        position:
                                            window.google.maps.ControlPosition
                                                .LEFT_BOTTOM,
                                    },
                                }}
                                onIdle={(map) => {
                                    const mapBounds = map.getBounds();
                                    const mapSpan = mapBounds.toSpan();

                                    const latspan = mapSpan.lat();
                                    const lngspan = mapSpan.lng();
                                    setLatspan(latspan);
                                    setLngspan(lngspan);
                                }}
                            >
                                {groupedItems[ propertyFilter ].map((e, i) => {
                                    let itemChargers = e.chargers || [];
                                    let itemIsSelected = selectedId === e._id;

                                    let chargerMarkers = itemChargers.map(
                                        (charger, i) => {
                                            return (
                                                <Marker
                                                    key={charger._id}
                                                    type="charger"
                                                    isSelected={selectedChargers.some(
                                                        (selC) =>
                                                            selC._id ===
                                                            charger._id
                                                    )}
                                                    // supplierSelected={
                                                    //     selectedId
                                                    // }
                                                    onClick={() =>
                                                        handleChargerCardClick(
                                                            charger
                                                        )
                                                    }
                                                    // onMouseEnter={() =>
                                                    //     handleChargerHover(
                                                    //         charger
                                                    //     )
                                                    // }
                                                    // onMouseLeave={
                                                    //     handleChargerHoverClear
                                                    // }
                                                    position={GeoJsonToLatLng(
                                                        charger.loc
                                                    )}
                                                />
                                            );
                                        }
                                    );

                                    let propertyMarker = (
                                        <Marker
                                            key={e._id}
                                            radius={
                                               CHARGER_DISTANCE_RADIUS
                                            }
                                            isSelected={itemIsSelected}
                                            onClick={() => {
                                                setSelectedId(e._id);
                                            }}
                                            position={GeoJsonToLatLng(e.loc)}
                                        />
                                    );

                                    return chargerMarkers.concat(
                                        propertyMarker
                                    );
                                })}
                            </GoogleMap>
                        </div>
                        {selectedItem ? (
                            <div className="admin-map__card">
                                <AdminSupplierInfo
                                    selectedItem={selectedItem}
                                    handleChargerCardClick={
                                        handleChargerCardClick
                                    }
                                    selectedChargers={selectedChargers}
                                    note={note}
                                    setNote={setNote}
                                    email={email}
                                    setEmail={setEmail}
                                    updateSupplier={updateSupplier}
                                    goPrev={goPrev}
                                    goNext={goNext}
                                    // pager={pager}
                                    groupedItems={groupedItems}
                                    propertyFilter={propertyFilter}
                                    showNote={showNote}
                                    setShowNote={setShowNote}
                                    hotItemId={hotItemId}
                                    getPlaceDetails={getPlaceDetails}
                                />
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    );
}
