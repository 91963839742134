import React, { useEffect, useRef, useCallback } from 'react';
import reactDOM from 'react-dom';
import { useModalContext } from '../../core/context/modalContext';
import useScreenSize from '../../core/hooks/useScreenSize';
import { Icon } from '../Icon/Icon';
import ModalHOC from '../ModalHOC/ModalHOC';
import './Modal.css';

const Modal = ({ children, id, size, title, btnRef, closeCallback }) => {
    const { isOpen, getControl } = useModalContext();
    const { close } = getControl(id);
    const closeBtn = useRef(null);
    const isOpenID = isOpen(id);
    const { isMobile } = useScreenSize();
    const modalRef = useRef(null);

    const handleClose = useCallback(() => {
        close();
    }, []);

    useEffect(() => {
        if (!isOpenID) return;
        window.addEventListener('popstate', handleClose);
        return () => {
            if (isOpenID) {
                handleClose();
            }
            window.removeEventListener('popstate', handleClose);
        };
        // eslint-disable-next-line
    }, [isOpenID]);

    return reactDOM.createPortal(
        <ModalHOC
            isOpen={isOpenID}
            clear={true}
            closeModal={() => {
                handleClose()
                if (closeCallback) closeCallback();
            }}
            openBtnRef={btnRef}
        >
            <div
                ref={modalRef}
                className={`modal__wrapper${isMobile ? ' is-mobile' : ''}`}
                onMouseDown={() => {
                    if (closeCallback) closeCallback();
                    close();
                }}
            >
                <div className="modal__overflow">
                    <div
                        className="modal__container"
                        onMouseDown={(e) => e.stopPropagation()}
                        style={{ maxWidth: !isMobile ? size : '' }}
                    >
                        <div className="modal__top-line">
                            {!title ? null : (
                                <div className="modal__title h4">{title}</div>
                            )}
                            <button
                                type="button"
                                className="modal__close-btn"
                                onClick={() => {
                                    if (closeCallback) closeCallback();
                                    close();
                                }}
                                ref={closeBtn}
                            >
                                <Icon.Plus />
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>
                        <div className="modal__content">{children}</div>
                    </div>
                </div>
            </div>
        </ModalHOC>,
        document.getElementById('modal')
    );
};

export default Modal;
