import React, {
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';
import { useNavigate } from 'react-router-dom';

import { Icon } from '../../../common/Icon/Icon';
import { useLocaleContext } from '../../../core/context/localeContext';
import { useSearchContext } from '../../../core/context/searchContext';
import { ThemeContext } from '../../../providers/ThemeProvider';
import MobileOccupancy from './MobileOccupancy/MobileOccupancy';
import MobileDatePicker from './MobileDatePicker/MobileDatePicker';

import './MobileSearch.css';

export const FIELDS = {
    destination: 'destination',
    date: 'date',
    occupancy: 'occupancy',
};

const MobileSearch = ({ closeMobileSearch }) => {
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const { page } = useContext(ThemeContext);
    const { t } = useLocaleContext();
    const {
        state,
        setState,
        setFindMobileOpen,
        openDestinationMobile,
        destinationMobileOpen,
        setDontSearch,
        setMeta,
        setFromMobileSearch,
    } = useSearchContext();
    const openDestinationBtnRef = useRef(null);
    const [activeField, setActiveField] = useState(FIELDS.destination);

    useEffect(() => {
        setError(null);
    }, [state.child_age, state.location]);

    useEffect(() => {
        if (destinationMobileOpen || page === 'supplier') return;
        if (!state.location) {
            setError({
                name: 'location',
                value: 'We need to know your destination',
            });
        }
    }, [destinationMobileOpen, page, state.location]);

    useEffect(() => {
        setDontSearch(true);
        const handlePopState = () => {
            if (destinationMobileOpen) return;
            setFindMobileOpen(false);
        };

        window.addEventListener('popstate', handlePopState);
        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, [destinationMobileOpen, setFindMobileOpen, setDontSearch]);

    const openDestination = useCallback(() => {
        setActiveField(FIELDS.destination);
        openDestinationMobile(openDestinationBtnRef);
    }, [openDestinationMobile]);

    const openDate = useCallback(() => {
        setActiveField(FIELDS.date);
    }, []);

    const openOccupancy = useCallback(() => {
        setActiveField(FIELDS.occupancy);
    }, []);

    const clearDates = useCallback(() => {
        const newState = {
            ...state,
            checkin: '',
            checkout: '',
        };
        setState(newState);
    }, [setState, state]);

    const validateInputs = useCallback(() => {
        if (!state.location && page !== 'supplier') {
            setError({
                name: 'location',
                value: 'We need to know your destination',
            });
            return 1;
        }
        if (state.child_age.some((age) => age === -1)) {
            setError({
                name: 'childrenAge',
                value: 'You must enter children ages',
            });
            return 1;
        }
        setError(null);
    }, [page, state.child_age, state.location]);

    const search = useCallback(() => {
        const error = validateInputs();
        if (error) return;
        navigate(-1);
        setMeta(state);
        setDontSearch(false);
        setFromMobileSearch(true);
    }, [
        validateInputs,
        navigate,
        setMeta,
        state,
        setDontSearch,
        setFromMobileSearch,
    ]);

    return (
        <div className="mobile-search">
            <div className="mobile-search__header">
                <button
                    className="mobile-search__close"
                    type="button"
                    onClick={closeMobileSearch}
                >
                    <Icon.Plus className="icon-small r-45" />
                    <span className="sr-only">{t('close')}</span>
                </button>
            </div>
            <div className="mobile-search__body">
                <div className="mobile-search__content">
                    {page === 'supplier' ? null : (
                        <button
                            className={`mobile-search__btn${
                                activeField === FIELDS.destination
                                    ? ' active'
                                    : ''
                            }`}
                            type="button"
                            onClick={openDestination}
                            ref={openDestinationBtnRef}
                        >
                            <span
                                className={`mobile-search__btn-label form-label${
                                    error?.name === 'location' ? ' error' : ''
                                }`}
                            >
                                {t('Where?')}
                            </span>
                            <span className="mobile-search__btn-value mobile-search__btn-value--input">
                                {error?.name === 'location' ? error?.value : state.location}
                            </span>
                        </button>
                    )}
                    <MobileDatePicker
                        activeField={activeField}
                        openDate={openDate}
                    />
                    <MobileOccupancy
                        activeField={activeField}
                        openOccupancy={openOccupancy}
                        error={error}
                    />
                </div>
            </div>
            <div className="mobile-search__footer">
                {activeField === FIELDS.date ? (
                    <>
                        <button className="link" onClick={clearDates}>
                            {t('Clear dates')}
                        </button>
                        <button
                            className="btn btn--blue btn--small"
                            onClick={openOccupancy}
                        >
                            <span className="sr-only">{t('Next step')}</span>
                            <Icon.Arrow className="icon-small" />
                        </button>
                    </>
                ) : (
                    <button
                        className="btn btn--blue"
                        type="button"
                        onClick={search}
                    >
                        {t('Search')}
                        <Icon.Search className="icon-small icon-right" />
                    </button>
                )}
            </div>
        </div>
    );
};

export default MobileSearch;
