import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { differenceInDays, parseISO } from 'date-fns';
import { useModalContext } from '../../core/context/modalContext';

import {
    buildParams,
    ObjectKeysToArray,
    formatCancelPenalties,
    OccupancyString,
    isEmpty,
    ToBool,
} from '../../helpers.js';

import { useLocaleContext } from '../../core/context/localeContext.js';
import { useSearchContext } from '../../core/context/searchContext.js';
import { Icon } from '../../common/Icon/Icon.js';
import useScreenSize from '../../core/hooks/useScreenSize.js';

import './RateOption.css';
import DialogLabel from '../../common/DialogLabel/DialogLabel.js';
import { useUserContext } from '../../core/context/userContext.js';
import DiscountDialog from '../../common/DialogLabel/DiscountDialog/DiscountDialog.js';

const SHOW_USERS = ToBool(process.env.REACT_APP_SHOW_USERS)
const SHOW_DEBUG = ToBool(process.env.REACT_APP_SHOW_DEBUG)

const BookButton = ({ onClick, label }) => {
    return (
        <button className="btn btn--blue" onClick={onClick} type="button">
            {label}
        </button>
    );
};

export default function RateOption(props) {
    let navigate = useNavigate();
    let item = props.rate;

    const { urlPrefix, t } = useLocaleContext();
    const btnRef = useRef(null);
    const [searchParams] = useSearchParams();
    const [selectedBedGroup, setSelectedBedGroup] = useState({
        id: item.bed_groups[0]?.id,
    });
    const { setDatepickerOpen, setFindMobileOpen, meta } = useSearchContext();
    const { isMobile } = useScreenSize();
    const [showDiscountDialog, setShowDiscountDialog] = useState(false);
    const { userDetails } = useUserContext();
    const isLoggedIn = !isEmpty(userDetails?.token);

    const { createModal } = useModalContext();
    const { element: RoomModal, open: openModal } = useMemo(
        () => createModal(),
        [createModal]
    );

    let numNights;

    if (meta.checkout && meta.checkin) {
        numNights = differenceInDays(
            parseISO(meta.checkout),
            parseISO(meta.checkin)
        );
    }

    let occupancyString = OccupancyString({
        adults: meta.adults,
        children: meta.child_age?.length,
        rooms: meta.rooms,
        nights: numNights,
        t,
    });

    let cancelPolicy = formatCancelPenalties(props.rate, t);

    const doPriceCheck = useCallback(
        async ({ sid, rate_id, bgid, token }) => {
            const { radius, ...params } = { ...props.queryParams };
            let queryParams = buildParams({
                ...params,
                rate_id,
                bgid,
                room_id: props.roomId,
                token,
            });

            navigate({
                pathname: `${urlPrefix}/booking`,
                search: `?${queryParams}`,
            });
        },
        [navigate, props.queryParams, props.roomId, urlPrefix]
    );

    //TODO don't get this from url params
    let sid = searchParams.get('sid');

    const infoElement = useCallback(
        (showArea) => (
            <>
                {props.room.name && (
                    <>
                        <h3 className="room-option__title h3">
                            {props.room.name}
                        </h3>
                        {showArea ? (
                            <div className="room-option__occupancy caption">
                                {' '}
                                ({`${props.room.area}m`}
                                <sup>2</sup>)
                            </div>
                        ) : null}
                    </>
                )}
            </>
        ),
        [
            item.totalPrice,
            props.room.area,
            props.room.name,
            props.room.room_name,
        ]
    );

    const bookBtnElement = useCallback(
        (label) => (
            <BookButton
                label={label}
                onClick={() => {
                    let bed_group = item.bed_groups.find(
                        (e) => e.id === selectedBedGroup.id
                    );
                    doPriceCheck({
                        sid,
                        bgid: bed_group.id,
                        rate_id: item.id,
                        token: bed_group.token,
                    });
                }}
            />
        ),
        [doPriceCheck, item.bed_groups, item.id, selectedBedGroup.id, sid]
    );

    return (
        <div className="rate-option">
            {SHOW_DEBUG ? <div>{`id=${item.id}`}</div> : null}

            {props.room.sales_channel === 'mobile_web' ? (
                <div
                    style={{
                        marginTop: '8px',
                        padding: '4px 8px',
                        borderRadius: '16px',
                        background: 'var(--warning)',
                        alignSelf: 'flex-start',
                    }}
                >
                    mobile_rate
                </div>
            ) : null}

            <div className="rate-option__content">
                <div className="rate-option__body">
                    {item.totalPrice ? (
                        <>
                            <div className="room-option__policy caption">
                                {cancelPolicy.header}
                            </div>
                            <div className="room-option__price">
                                {item.member_deal_available && SHOW_USERS ? (
                                    <div className="room-option__discount-dialog">
                                        <DialogLabel
                                            text={t('Get discount')}
                                            icon={
                                                <Icon.Percentage className="icon-xs" />
                                            }
                                            openState={showDiscountDialog}
                                            setOpenState={setShowDiscountDialog}
                                        >
                                            <DiscountDialog
                                                setOpenState={
                                                    setShowDiscountDialog
                                                }
                                            />
                                        </DialogLabel>
                                    </div>
                                ) : null}
                                {item.strikethroughPrice && SHOW_USERS ? (
                                    <DialogLabel
                                        text={`- ${item.discountPercentage} %`}
                                        icon={''}
                                        openState={showDiscountDialog}
                                        setOpenState={setShowDiscountDialog}
                                    >
                                        <DiscountDialog
                                            setOpenState={setShowDiscountDialog}
                                        />
                                    </DialogLabel>
                                ) : null}
                                {item.strikethroughPrice ? (
                                    <div className="rate-option__price__strikethrough">
                                        <span className="h4">{`€ ${item.strikethroughPrice}`}</span>
                                    </div>
                                ) : null}
                                <div className="rate-option__price">
                                    <span className="form-label">{`€ ${
                                        item.totalPrice
                                    } ${t('total')}`}</span>
                                </div>
                            </div>
                        </>
                    ) : null}
                </div>

                {item.totalPrice ? (
                    <>
                        <div className="rate-option__footer">
                            {bookBtnElement(t('Reserve'))}
                        </div>
                    </>
                ) : null}
            </div>
        </div>
    );
}
