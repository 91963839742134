import React, { createContext, useEffect, useRef, useState } from 'react';

export const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
    const [heroColor, setHeroColor] = useState('');
    const [pageColor, setPageColor] = useState('');
    const [page, setPage] = useState('');
    const [isScrolled, setIsScrolled] = useState(false);
    const [moveSearch, setMoveSearch] = useState(false);
    const [heroWithSearch, setHeroWithSearch] = useState(null);
    const [loading, setLoading] = useState(true);
    const searchFormRef = useRef();
    const [isPrinting, setIsPrinting] = useState(false);

    useEffect(() => {
        const printMq =
            typeof window !== 'undefined' &&
            window.matchMedia &&
            window.matchMedia('print');
        const mqEvent = (mqList) => setIsPrinting(!!mqList.matches);

        printMq.addListener(mqEvent);
        return () => printMq.removeListener(mqEvent);
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 5000);
        return () => {
            clearTimeout(timer);
        };
    }, [loading]);

    useEffect(() => {
        const handleScroll = () => {
            if (document.body.style.position === 'fixed') return;
            setIsScrolled(
                (document.documentElement.scrollTop ||
                    document.body.scrollTop) > 30
                    ? true
                    : false
            );
            const rect = searchFormRef?.current?.getBoundingClientRect();
            if (!rect) return;
            const scrollTop =
                window.pageYOffset || document.documentElement.scrollTop;
            const searchFormPosition = rect.top + scrollTop;
            setMoveSearch(
                (document.documentElement.scrollTop ||
                    document.body.scrollTop) >
                    (searchFormPosition + 20 || 200) && heroWithSearch
                    ? true
                    : false
            );
        };

        setTimeout(() => {
            handleScroll();
        }, 0);

        document.addEventListener('scroll', handleScroll);

        return () => {
            document.removeEventListener('scroll', handleScroll);
        };
    }, [heroWithSearch, page]);

    return (
        <ThemeContext.Provider
            value={{
                heroColor,
                setHeroColor,
                pageColor,
                setPageColor,
                heroWithSearch,
                setHeroWithSearch,
                page,
                setPage,
                isScrolled,
                moveSearch,
                searchFormRef,
                loading,
                setLoading,
                isPrinting,
            }}
        >
            {children}
        </ThemeContext.Provider>
    );
};
