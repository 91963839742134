import React, { useCallback, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import CustomInput from '../../../common/CustomInput/CustomInput';
import { Helmet } from 'react-helmet';

import { Icon } from '../../../common/Icon/Icon';
import Loader from '../../../common/Loader/Loader';
import { useLocaleContext } from '../../../core/context/localeContext';
import { useUserContext } from '../../../core/context/userContext';
import { IsValidEmail } from '../../../helpers';

const BASE_URL = process.env.REACT_APP_BASE_URL || 'https://www.sleepercharger.com';

export default function Logout () {
    const navigate = useNavigate();

    const [ loading, setLoading ] = useState(false);
    const [ email, setEmail ] = useState('')
    const { userDetails, logout } = useUserContext();
    const { locale } = useLocaleContext();

    useEffect(() => {
        logout();
    }, [])

    if (userDetails.token) {
        return 'Not logged out yet.'
    }

    return (
        <>
            <Helmet>
                <title>{`Logout | SleeperCharger`}</title>
                <meta
                    name="description"
                    content="`Logout | SleeperCharger"
                />
                <link
                    rel="canonical"
                    href={`${BASE_URL}${locale !== 'en' ? `/${locale}` : ''
                        }/logout`}
                />
            </Helmet>
            <section className="signup">
                <div className="wrap wrap--md">

                    <div className="profile__message">You are logged out succesfully.</div>

                </div>
            </section>
        </>

    );
};
