import lightDarkColor from '../../images/light-to-dark-color.svg';
import lightDarkColorWinter from '../../images/light-to-dark-color-winter.svg';
import darkLightColor from '../../images/dark-to-light-color.svg';
import darkLightColorWinter from '../../images/dark-to-light-color-winter.svg';
import lightDark from '../../images/light-to-dark-default.svg';
import darkLight from '../../images/dark-to-light-default.svg';

import useScreenSize from '../../core/hooks/useScreenSize';
import { useHeaderFooterDataContext } from '../../core/context/headerFooterDataContext';

const POSITION = {
    lightDark: 'light-dark',
    lightDarkColor: 'light-dark-color',
    darkLight: 'dark-light',
    darkLightColor: 'dark-light-color',
};

function Divider({ position, side }) {
    const { season } = useHeaderFooterDataContext();
    const { isMobile } = useScreenSize();

    const visual = {
        Summer: {
            [POSITION.lightDarkColor]: { url: lightDarkColor, height: 294 },
            [POSITION.darkLightColor]: { url: darkLightColor, height: 294 },
            [POSITION.darkLight]: { url: darkLight, height: 214 },
            [POSITION.lightDark]: { url: lightDark, height: 168 },
        },
        'Winter Snow': {
            [POSITION.lightDarkColor]: { url: lightDarkColorWinter, height: 294 },
            [POSITION.darkLightColor]: { url: darkLightColorWinter, height: 294 },
            [POSITION.darkLight]: { url: darkLight, height: 214 },
            [POSITION.lightDark]: { url: lightDark, height: 168 },
        },
    };

    return !season ? null : (
        <div
            className={`page-visual${
                side === 'right' ? ' page-visual--right' : ''
            }`}
            style={{
                backgroundImage: `url(${visual[season][position].url})`,
                paddingTop: !isMobile
                    ? `${(visual[season][position].height * 100) / 1440}%`
                    : `${visual[season][position].height}px`,
            }}
        />
    );
}

export { Divider, POSITION };
