import React, { useCallback, useRef } from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';

import { Icon } from '../../common/Icon/Icon';

import './ImageSlider.css';

const UPLOADS_URL = process.env.REACT_APP_UPLOADS_URL || '';

const ImageSlider = ({ images, isMobile }) => {
    const swiperRef = useRef(null);

    const handlePrev = useCallback(() => {
        if (!swiperRef.current) return;
        swiperRef.current.swiper.slidePrev();
    }, [ swiperRef ]);

    const handleNext = useCallback(() => {
        if (!swiperRef.current) return;
        swiperRef.current.swiper.slideNext();
    }, [ swiperRef ]);

    if (!images || images.length === 0) return null;

    return (
        <div className="image-slider section">
            {images.length > 1 ? (
                <>
                    {isMobile ? null : (
                        <>
                            <button
                                className="image-slider__arrow image-slider__arrow--prev slider__arrow slider__arrow--prev"
                                onClick={handlePrev}
                            >
                                <Icon.Arrow className="icon-small" />
                                <span className="sr-only">Previous slide</span>
                            </button>
                            <button
                                className="image-slider__arrow image-slider__arrow--next slider__arrow slider__arrow--next"
                                onClick={handleNext}
                            >
                                <Icon.Arrow className="icon-small" />
                                <span className="sr-only">Previous slide</span>
                            </button>
                        </>
                    )}
                    <Swiper
                        ref={swiperRef}
                        className="image-slider__slider"
                        modules={[ Pagination ]}
                        pagination={{ clickable: true }}
                    >
                        {images
                            .map((image, i) => (
                                <SwiperSlide key={i} className="image-slider__slide">
                                    <img
                                        className="image-slider__img"
                                        src={`${UPLOADS_URL}${image.attributes.url}`}
                                        alt={image.attributes.alternativeText}
                                    />
                                </SwiperSlide>
                            ))}
                    </Swiper>
                    {isMobile ? null : (
                        <div className="image-slider__number cta">
                            <Icon.Images className="icon-small" />+
                            {images.length - 1}
                        </div>
                    )}
                </>
            ) : (
                    <img
                        className="image-slider__img"
                        src={`${UPLOADS_URL}${images[ 0 ].attributes.url}`}
                        alt={images[ 0 ].attributes.alternativeText}
                    />
                )}
        </div>
    );
};

export default ImageSlider;
