import TextBlock from '../TextBlock';
import './ContentBlock.css';

const UPLOADS_URL = process.env.REACT_APP_UPLOADS_URL || '';

const ContentBlock = ({block}) => {
    let classStr = '';
    if (block.alignment === 'right') classStr += ' right';
    if (!block.stylingType && block.showAsTip) classStr += ' tip';
    if (block.stylingType === 'Tip Block') classStr += ' tip';
    if (block.stylingType === 'Tip Block With Frame') classStr += ' frame';

    return (
        <div className={`content-block section${classStr}`}>
            <div className="content-block__content">
                <TextBlock content={block.text} fullWidth={true} />
            </div>
            {!block.image.data ? null :
            <div className="content-block__media">
                <div className="content-block__img-container">
                    <img className="content-block__img cover-img" src={`${UPLOADS_URL}${block.image.data.attributes.url}`} alt={block.image.data.attributes.alternativeText} />
                </div>
            </div>
            }
        </div>
    );
}

export default ContentBlock;