import { useMemo } from 'react';
import { Link } from 'react-router-dom';

import Card from './Card/Card';
import { useLocaleContext } from '../../core/context/localeContext';
import { Icon } from '../../common/Icon/Icon';
import { format, parseISO } from 'date-fns';

import './CardsBlockHighlight.css';

function CardsBlockHighlight({ title, blogs, button }) {
    const { urlPrefix } = useLocaleContext();
    const sortedBlogs = useMemo(
        () =>
            blogs?.sort(
                (a, b) =>
                    format(parseISO(b?.attributes?.createdAt), 'T') -
                    format(parseISO(a?.attributes?.createdAt), 'T')
            ) || [],
        [blogs]
    );

    const renderHighlightedCard = useMemo(() => {
        if (sortedBlogs.length) {
            const { title, featuredImage, slug, summary } = sortedBlogs[0].attributes;
            return (
                <Card
                    title={title}
                    summary={summary}
                    image={featuredImage ? featuredImage.data.attributes : ''}
                    to={`${urlPrefix}/blog/` + slug}
                    highlight={true}
                />
            );
        }
    }, [sortedBlogs, urlPrefix]);

    const renderBlogCards = useMemo(() => {
        if (sortedBlogs.length) {
            return sortedBlogs.slice(1).map((blog, index) => {
                const { title, featuredImage, slug, summary } = blog.attributes;
                return (
                    <Card
                        key={index}
                        title={title}
                        summary={summary}
                        image={
                            featuredImage ? featuredImage.data.attributes : ''
                        }
                        to={`${urlPrefix}/blog/` + slug}
                    />
                );
            });
        }
    }, [sortedBlogs, urlPrefix]);

    const renderCardsButton = useMemo(() => {
        if (button) {
            const { title, link, style } = button;
            if (title && link) {
                return (
                    <Link
                        to={`${urlPrefix}/${link}`}
                        className={`cards-block-highlight__cta ${
                            style === 'Green Right Arrow'
                                ? ' btn btn--green'
                                : ' link'
                        }`}
                    >
                        {title}
                        <Icon.Arrow className="icon-small icon-right" />
                    </Link>
                );
            }
        }
    }, [button, urlPrefix]);

    return (
        <section className="cards-block-highlight section">
            <div className="wrap wrap--md">
                {title && (
                    <h2 className="cards-block-highlight__title h2">{title}</h2>
                )}
                <div className="cards-block-highlight__container">
                    {renderHighlightedCard}
                    <div className="cards-block-highlight__cards">
                        {renderBlogCards}
                        {renderCardsButton}
                    </div>
                </div>
            </div>
        </section>
    );
}

export default CardsBlockHighlight;
