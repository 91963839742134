import React, { useCallback, useEffect, useRef } from 'react';

const CustomRangeSlider = ({
    id,
    label,
    value,
    onChange,
    name,
    min,
    max,
    step,
}) => {
    const inputRef = useRef(null);
    const tooltipRef = useRef(null);

    const handleTooltip = useCallback(
        (value) => {
            if (!inputRef.current || !tooltipRef.current) return;
            const newVal = Number(((value - min) * 100) / (max - min));

            tooltipRef.current.innerHTML = value;
            tooltipRef.current.style.left = `calc(${newVal}% + (${
                8 - newVal * 0.15
            }px))`;
        },
        [max, min]
    );

    useEffect(() => {
        handleTooltip(value);
    }, [handleTooltip, value]);

    return (
        <div className="input-container input-container--range">
            <label className="input-label" htmlFor={id}>
                {label}
            </label>
            <input
                ref={inputRef}
                type="range"
                className="range-field"
                autoComplete="off"
                id={id}
                name={name}
                value={value}
                onChange={(e) => {
                    onChange(e);
                }}
                min={min}
                max={max}
                step={step}
            />
            <span className="input-tooltip" ref={tooltipRef} />
        </div>
    );
};

export default CustomRangeSlider;
