import React, { useMemo, useRef, useState } from 'react';

import './RoomInfo.css';
import { Icon } from '../../common/Icon/Icon';
import PriceBreakdownRoom from '../PriceBreakdownRoom/PriceBreakdownRoom';

import { isEmpty, ObjectKeysToArray, RoomOccupancyString } from '../../helpers.js';
import DOMPurify from 'dompurify';
import { useModalContext } from '../../core/context/modalContext';
import { useLocaleContext } from '../../core/context/localeContext';
import CarbonOvercompensation from '../CarbonOvercompensation/CarbonOvercompensation';
import DialogLabel from '../../common/DialogLabel/DialogLabel';
import { useUserContext } from '../../core/context/userContext';
import DiscountDialog from '../../common/DialogLabel/DiscountDialog/DiscountDialog';

const sanitizer = DOMPurify.sanitize;
const HIDE_CARBON_OVERCOMPENSATION = true;
const REACT_APP_SHOW_USERS = process.env.REACT_APP_SHOW_USERS;

export default function RoomInfo({
    bookingInfo,
    meta,
    roomData,
    occupancyString,
    item,
}) {
    const { t } = useLocaleContext();
    const [showPriceBreakdown, setShowPriceBreakdown] = useState(false);
    const btnRef = useRef(null);
    const [showDiscountDialog, setShowDiscountDialog] = useState(false);
    const { userDetails } = useUserContext();
    const { createModal } = useModalContext();
    const isLoggedIn = !isEmpty(userDetails?.token);
    const { element: CoCModal, open: openModal } = useMemo(
        () => createModal(),
        [createModal]
    );

    let roomsInfo = bookingInfo.rooms.map((room, i) => {
        let roomOccString = RoomOccupancyString(
            room.occupancy.obj.adults,
            room.occupancy.obj.childAges,
            t
        );

        return (
            <div className="room-info__row room-info__row--border" key={i}>
                <div className="price-breakdown-container">
                    <h5>Room {i + 1}:</h5>
                    <div style={{ marginBottom: 10 }}>
                        <div>{roomData.name}</div>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: sanitizer(roomOccString),
                            }}
                        />
                    </div>
                    <PriceBreakdownRoom
                        room={room}
                        roomData={roomData}
                        pricing={bookingInfo.roomsRawPricing[i]}
                    />
                </div>
            </div>
        );
    });

    let carbonCompensationFee = Number(bookingInfo.carbon_compensation_fee);

    const amenities = useMemo(() => {
        let amenityArray = bookingInfo?.chosenRate?.amenities
            ? ObjectKeysToArray(bookingInfo.chosenRate.amenities)
            : [];

        return amenityArray.map((e, i) => {
            let name = e.name;
            if (name.substr(0, 5).toLowerCase() === 'free ') {
                name = name.substr(5);
                // capitalize first letter
                name = name.charAt(0).toUpperCase() + name.slice(1);
            }
            return (
                <li className="room-info__amenity-item caption" key={e.id}>
                    <Icon.Check className="icon-small" />
                    {name}
                </li>
            );
        });
    }, [bookingInfo]);

    return (
        <>
            {/* ToDo fix for multiple rooms */}
            <div className="room-info">
                <div className="room-info__row">
                    <div className="h4">
                        {bookingInfo.rooms.length}&times;{' '}
                        {bookingInfo.rooms[0].extra.name}
                    </div>
                    {/* <div>€{bookingInfo.roomsRawPricing[0].totals.inclusive.billable_currency.value}</div> */}
                    {!amenities.length ? null : (
                        <ul className="room-info__amenity-list">{amenities}</ul>
                    )}
                </div>
                <ul className="room-info__list">
                    <li className="room-info__item">{occupancyString}</li>
                    {bookingInfo?.chosenRate?.bed_groups &&
                        (Object.values(bookingInfo.chosenRate.bed_groups)
                            .length > 1 ? null : (
                            <li className="room-info__item">
                                {
                                    Object.values(
                                        bookingInfo.chosenRate.bed_groups
                                    )[0].description
                                }
                            </li>
                        ))}
                    {/* ToDo correct refund data */}
                    {!bookingInfo.paymentInfo?.isCancelable ? null : (
                        <li className="room-info__item">
                            Fully refundable before Date
                        </li>
                    )}
                    {!meta.booking_id ? null : (
                        <li className="room-info__item">
                            {t('Reservation number: %{number}', {
                                number: meta.booking_id,
                            })}
                        </li>
                    )}
                </ul>
                <button
                    className="room-info__more link"
                    type="button"
                    onClick={() =>
                        setShowPriceBreakdown((prevState) => !prevState)
                    }
                >
                    {showPriceBreakdown
                        ? t('Hide price breakdown')
                        : t('Show price breakdown')}
                    <Icon.Chevron
                        className="icon-small icon-right"
                        style={{
                            transform: showPriceBreakdown ? 'scaleY(-1)' : '',
                        }}
                    />
                </button>
                {showPriceBreakdown ? roomsInfo : null}
                {HIDE_CARBON_OVERCOMPENSATION ? null : (
                    <div className="room-info__row room-info__row--border">
                        <div className="room-info__coc">
                            <button
                                className="room-info__coc-btn"
                                onClick={openModal}
                                ref={btnRef}
                            >
                                <span>{t('carbon overcompensated')}</span>
                                <Icon.InfoCircle className="icon-small" />
                            </button>
                            <div className="room-info__coc-price">
                                € {Number(carbonCompensationFee).toFixed(2)}
                            </div>
                        </div>
                    </div>
                )}
                <div className="room-info__row room-info__row--border">
                    <span className="room-info__label h4">
                        {t('Total price')}
                    </span>
                    <div className="room-info__price-container">
                        {!bookingInfo.strikethroughPrice ||
                        !REACT_APP_SHOW_USERS ? null : (
                            <div className="room-info__discount">
                                <DialogLabel
                                    text={`- ${bookingInfo.discountPercentage} % off`}
                                    icon={null}
                                    openState={showDiscountDialog}
                                    setOpenState={setShowDiscountDialog}
                                >
                                    <DiscountDialog
                                        setOpenState={setShowDiscountDialog}
                                    />
                                </DialogLabel>
                                <div className="room-info__discounted-price form-label">
                                    {`€ ${bookingInfo.strikethroughPrice}`}
                                </div>
                            </div>
                        )}
                        {!bookingInfo.member_deal_available ||
                        !REACT_APP_SHOW_USERS ? null : (
                            <div className="room-info__discount">
                                <DialogLabel
                                    text={t('Get discount')}
                                    icon={
                                        <Icon.Percentage className="icon-xs" />
                                    }
                                    openState={showDiscountDialog}
                                    setOpenState={setShowDiscountDialog}
                                >
                                    <DiscountDialog
                                        setOpenState={setShowDiscountDialog}
                                    />
                                </DialogLabel>
                            </div>
                        )}
                        <span className="room-info__price h3">
                            €{' '}
                            {Number(bookingInfo.total_inclusive_value).toFixed(
                                2
                            )}
                        </span>
                    </div>
                </div>
                {bookingInfo.roomsRawPricing[0].totals.property_fees && (
                    <div className="room-info__row">
                        <div>
                            {t('Property Fee')} ({t('due at property')})
                        </div>
                        <div>
                            €{' '}
                            {(+bookingInfo.roomsRawPricing[0].totals
                                .property_fees.billable_currency.value).toFixed(
                                2
                            )}
                        </div>
                    </div>
                )}
            </div>
            <CoCModal size={800} btnRef={btnRef}>
                <CarbonOvercompensation />
            </CoCModal>
        </>
    );
}
