import React, { useState } from 'react';

import { format as formatDate, addDays } from 'date-fns';
import enLocale from 'date-fns/locale/en-US';

import './PriceBreakdownRoom.css';
import { Icon } from '../../common/Icon/Icon';
import { useLocaleContext } from '../../core/context/localeContext';

export default function PriceBreakdownRoom ({ room, roomData, pricing }) {

    const [ showNightlyBreakDown, setShowNightlyBreakDown ] = useState(false);
    const { t } = useLocaleContext();
    let e = pricing;

    let checkin = new Date(room.checkin);

    let taxesAndFees = 0;
    // let salesTax = 0;
    // let propertyFee = 0;
    // let sleeperChargerFee = 0;

    // let mandatoryFee = e.fees.mandatory_fee ? e.fees.mandatory_fee.billable_currency : 0;
    // let resortFee = e.fees.resort_fee ? e.fees.resort_fee.billable_currency : 0;
    // let mandatoryTax = e.fees.mandatory_tax ? e.fees.mandatory_tax.billable_currency : 0;

    let nightlyBreakDown = e.nightly.map((nightly, nightIndex) => {
        let baseRate = nightly.find((p) => p.type === 'base_rate');

        nightly.forEach((p) => {
            if (p.type === 'tax_and_service_fee') {
                taxesAndFees += Number(p.value);
            } else if (p.type === 'recovery_charges_and_fees') {
                taxesAndFees += Number(p.value);
            }
            // else if (p.type === 'sales_tax') {
            //     salesTax += Number(p.value);
            // } else if (p.type === 'property_fee') {
            //     propertyFee += Number(p.value);
            // }
        });

        return (
            <li className="price-breakdown__item" key={nightIndex}>
                <span className="price-breakdown__date">
                    {' '}
                    {formatDate(addDays(checkin, nightIndex), 'E d MMMM yyyy', {
                        locale: enLocale,
                    })}
                </span>
                <div className="price-breakdown__price">
                    {baseRate.currency}{' '}
                    {baseRate.value}
                </div>
            </li>
        );
    });


    return (
        <div className="price-breakdown">
            <ul className="price-breakdown__list">
                <li className="price-breakdown__item price-breakdown__item--dropdown">
                    <button
                        className="price-breakdown__btn"
                        onClick={() =>
                            setShowNightlyBreakDown((prevState) => !prevState)
                        }
                    >
                        <span>
                            {t('Room rate')}
                            <Icon.TriangleSolid
                                style={{
                                    transform: showNightlyBreakDown
                                        ? 'scaleY(1)'
                                        : 'scaleY(-1)',
                                }}
                            />
                        </span>
                        <div className="price-breakdown__price">
                            €{' '}
                            {e.totals.exclusive.billable_currency.value}
                        </div>
                    </button>
                    {showNightlyBreakDown ? <ul>{nightlyBreakDown}</ul> : null}
                </li>
                <li className="price-breakdown__item">
                    {t('Taxes and fees')}:
                    <div className="price-breakdown__price">
                        € {taxesAndFees.toFixed(2)}
                    </div>
                </li>
                <li className="price-breakdown__item">
                    {t('Room total')}:
                    <div className="price-breakdown__price">
                        €{' '}
                        {e.totals.inclusive.billable_currency.value}
                    </div>
                </li>
            </ul>
        </div>
    );
}
