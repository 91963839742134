import { Link } from 'react-router-dom';
import { useLocaleContext } from '../../core/context/localeContext';
import { Icon } from '../../common/Icon/Icon';

export default function BookingItem({ item }) {
    const { urlPrefix, t } = useLocaleContext();
    const supplier = item.supplier;

    if (!supplier || !item) return null;

    return (
        <Link
            className="booking-item"
            to={`${urlPrefix}/my-booking?h=${item.bookingHash}`}
        >
            {!item.image?.href ? null : (
                <div className="booking-item__img-container">
                    <img
                        className="booking-item__img cover-img"
                        src={item.image.href}
                        alt={item.image?.caption || ''}
                    />
                </div>
            )}
            <div className="booking-item__content">
                <div className="booking-item__body">
                    <h2 className="booking-item__title h4">{supplier.name}</h2>
                    <div>
                        {item.date}
                        {' · '}
                        {supplier.address?.city},{' '}
                        {supplier.address?.country_code}
                        {' · '}
                        {item.booking_id}
                    </div>
                </div>
                <div className="booking-item__price h4">
                    € {item.total_inclusive_value}
                </div>
                {item.status === 'canceled' ? <div className="booking-item__cancelled">
                    <Icon.Alert className='icon-small'/>
                    {t('Cancelled')}
                </div> : null}
            </div>
        </Link>
    );
}
