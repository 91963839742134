import { useContext, createContext, useEffect, useState } from 'react';
import axios from 'axios';
import qs from 'qs';
import { useLocaleContext } from './localeContext';

const HeaderFooterDataContext = createContext();

export function useHeaderFooterDataContext() {
    return useContext(HeaderFooterDataContext);
}

export function HeaderFooterDataProvider({ children }) {
    const [headerFooterData, setHeaderFooterData] = useState();
    const { locale } = useLocaleContext();
    const [season, setSeason] = useState(null);
    const [defaultSeason, setDefaultSeason] = useState(null);

    useEffect(() => {
        if (!locale) return;

        const getHeaderFooterData = async () => {
            const query = qs.stringify({
                locale: locale,
                populate: '*',
            });
            await axios
                .get(
                    `${process.env.REACT_APP_API_URL}/api/header-and-footer?${query}`,
                    {
                        headers: {
                            Authorization: process.env.REACT_APP_API_KEY,
                        },
                    }
                )
                .then((res) => {
                    setHeaderFooterData(res.data.data.attributes);
                    setSeason(res.data.data.attributes.theme);
                    setDefaultSeason(res.data.data.attributes.theme);
                })
                .catch(() => {
                    setHeaderFooterData(null);
                });
        };

        if (locale) getHeaderFooterData();
    }, [locale]);

    return (
        <HeaderFooterDataContext.Provider
            value={{ headerFooterData, season, setSeason, defaultSeason }}
        >
            {children}
        </HeaderFooterDataContext.Provider>
    );
}
