import { useState, useEffect, useContext } from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import qs from 'qs';

import { useOutletContext, useNavigate } from 'react-router-dom';
import Hero from '../Components/Hero/Hero.js';
import { useLocaleContext } from '../core/context/localeContext';
import PageBuilder from '../Components/PageBuilder';
import PageNotFound from '../Components/PageNotFound';
import DraftTag from '../Components/DraftTag/DraftTag';
import HomeParallaxContainer from '../common/HomeParallaxContainer/HomeParallaxContainer.js';
import HomeHero from '../Components/HomeHero/HomeHero.js';
import { Divider, POSITION } from '../common/Divider/Divider.js';
import { useHeaderFooterDataContext } from '../core/context/headerFooterDataContext.js';
import ImageHero from '../Components/ImageHero/ImageHero.js';
import useLoader from '../core/hooks/useLoader.js';
import { ThemeContext } from '../providers/ThemeProvider.js';

const BASE_URL =
    process.env.REACT_APP_BASE_URL || 'https://www.sleepercharger.com';

const builderPopulate = {
    populate: {
        blogs: {
            populate: '*',
        },
        images: {
            populate: '*',
        },
        image: {
            populate: '*',
        },
        tips: {
            populate: '*',
        },
        uspItems: {
            populate: '*',
        },
        themes: {
            populate: '*',
        },
        category: {
            populate: '*',
        },
        button: {
            populate: '*',
        },
        properties: {
            populate: '*',
        },
        faqList: {
            populate: '*',
        },
        pt_themes: {
            populate: '*',
        },
    },
};

function Page(props) {
    const { setPage: setPageType } = useContext(ThemeContext);
    const [page, setPage] = useState();
    const { locale, preview, setLocalizedSlugs } = useLocaleContext();
    const { setSeason, defaultSeason } = useHeaderFooterDataContext();
    const navigate = useNavigate();
    const [pageID] = useOutletContext();
    useLoader(page);

    useEffect(() => {
        const getPage = async () => {
            const query = qs.stringify(
                {
                    locale: locale,
                    filters: {
                        slug: `${pageID || `home-${locale}`}`,
                    },
                    populate: {
                        hero: {
                            populate: '*',
                        },
                        localizations: {
                            populate: '.',
                        },
                        pageMeta: {
                            populate: '*',
                        },
                        builder: builderPopulate,
                        builder2: builderPopulate,
                        builder3: builderPopulate,
                        builder4: builderPopulate,
                        builder5: builderPopulate,
                        builder6: builderPopulate,
                    },
                    publicationState: preview ? 'preview' : 'live',
                },
                {
                    encodeValuesOnly: true, // prettify URL
                }
            );
            await axios
                .get(`${process.env.REACT_APP_API_URL}/api/pages?${query}`, {
                    headers: {
                        Authorization: process.env.REACT_APP_API_KEY,
                    },
                })
                .then((res) => {
                    setPage(res.data.data);
                })
                .catch(() => {
                    setPage(null);
                    navigate('/404', { replace: true });
                });
        };

        if (locale) getPage();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locale, preview, pageID]);

    useEffect(() => {
        if (!page) return;
        if (!defaultSeason) return;
        if (!page[0]?.attributes.hero?.overrideGlobalTheme) {
            setSeason(defaultSeason);
        } else if (page[0]?.attributes.hero?.theme) {
            setSeason(page[0]?.attributes.hero?.theme);
        }
    }, [page, setSeason, defaultSeason]);

    useEffect(() => {
        setLocalizedSlugs([]);
        if (!page || page.length === 0) return;

        const localeSlugs = page[0]?.attributes.localizations.data.map(
            (localization) => ({
                slug: localization.attributes.slug,
                locale: localization.attributes.locale,
                route: 'p',
            })
        );

        setLocalizedSlugs(localeSlugs);

        return () => {
            setLocalizedSlugs(null);
        };
    }, [page, setLocalizedSlugs]);

    useEffect(() => {
        setPageType(pageID ? 'page' : 'home');

        return () => {
            setPageType('');
        };
    }, [pageID, setPageType]);

    let content = <></>;

    if (page) {
        if (page.length > 0) {
            const pageData = page[0]?.attributes;
            content = (
                <>
                    <Helmet>
                        <title>
                            {!pageID
                                ? `SleeperCharger | ${
                                      pageData?.pageMeta?.title ||
                                      pageData.hero?.title ||
                                      ''
                                  }`
                                : `${
                                      pageData?.pageMeta?.title ||
                                      pageData.hero?.title ||
                                      ''
                                  } | SleeperCharger`}
                        </title>
                        <meta
                            name="description"
                            content={
                                pageData?.pageMeta?.description ||
                                'Hotel with guaranteed electric vehicle chargers. Book now only at SleeperCharger.'
                            }
                        />
                        <link
                            rel="canonical"
                            href={
                                pageData?.pageMeta?.canonical ||
                                `${BASE_URL}${
                                    locale !== 'en' ? `/${locale}` : ''
                                }${
                                    pageID && !pageID.startsWith('home-')
                                        ? `/p/${pageID}`
                                        : '/'
                                }`
                            }
                        />
                    </Helmet>
                    {!pageData.publishedAt && <DraftTag type="page" />}
                    <div>
                        {pageData.hero &&
                            (pageData.hero.showSearchBar ? (
                                pageData.hero.background === 'Parallax' ? (
                                    <HomeParallaxContainer
                                        isOnTopOfPage={true}
                                        theme={pageData.hero.theme}
                                    >
                                        <HomeHero data={pageData.hero} />
                                    </HomeParallaxContainer>
                                ) : (
                                    <ImageHero data={pageData.hero} />
                                )
                            ) : (
                                <Hero
                                    data={pageData.hero}
                                    isMobile={props.isMobile}
                                />
                            ))}
                        {pageData.builder.length > 0 &&
                        pageData.builder2?.length > 0 ? (
                            <>
                                <div className="dark-section">
                                    <PageBuilder blocks={pageData.builder} />
                                </div>
                                <Divider
                                    position={
                                        pageData.hero.background === 'Parallax'
                                            ? POSITION.darkLightColor
                                            : POSITION.darkLight
                                    }
                                    side="right"
                                    theme={pageData.hero.theme}
                                />
                            </>
                        ) : (
                            <PageBuilder blocks={pageData.builder} />
                        )}
                        {pageData.builder2?.length > 0 && (
                            <PageBuilder blocks={pageData.builder2} />
                        )}
                        {pageData.builder3?.length > 0 && (
                            <>
                                <Divider
                                    position={POSITION.lightDark}
                                    side="left"
                                    theme={pageData.hero.theme}
                                />
                                <div className="dark-section">
                                    <PageBuilder blocks={pageData.builder3} />
                                </div>
                                <Divider
                                    position={POSITION.darkLight}
                                    side="right"
                                    theme={pageData.hero.theme}
                                />
                            </>
                        )}
                        {pageData.builder4?.length > 0 && (
                            <PageBuilder blocks={pageData.builder4} />
                        )}
                        {pageData.builder5?.length > 0 && (
                            <>
                                <Divider
                                    position={POSITION.lightDark}
                                    side="left"
                                    theme={pageData.hero.theme}
                                />
                                <div className="dark-section">
                                    <PageBuilder blocks={pageData.builder5} />
                                </div>
                                <Divider
                                    position={POSITION.darkLight}
                                    side="right"
                                    theme={pageData.hero.theme}
                                />
                            </>
                        )}
                        {pageData.builder6?.length > 0 && (
                            <PageBuilder blocks={pageData.builder6} />
                        )}
                    </div>
                </>
            );
        } else {
            content = <PageNotFound />;
        }
    }

    return <>{content}</>;
}

export default Page;
