import { useEffect, useRef, useContext } from 'react';

import useScreenSize from '../../core/hooks/useScreenSize';
import DesktopSearchbar from '../Search/DesktopSearchbar/DesktopSearchbar';

import './HomeHero.css';
import '../Find.css';
import { useSearchContext } from '../../core/context/searchContext';
import { ThemeContext } from '../../providers/ThemeProvider';
import MobileSearchButton from '../Search/MobileSearch/MobileSearchButton/MobileSearchButton';

const IS_COMPACT = false;

export default function HomeHero({ data }) {
    const { findMobileOpen, setFindMobileOpen } = useSearchContext();
    const { searchFormRef } = useContext(ThemeContext);
    const { isMobile } = useScreenSize();
    const openBtnRef = useRef(null);
    const desktopSearchRef = useRef(null);
    const mobileSearchRef = useRef(null);
    const { setHeroColor, setPageColor, setHeroWithSearch } =
        useContext(ThemeContext);

    useEffect(() => {
        setHeroColor(data?.showBackgroundGraphic ? 'hero-dark' : 'hero-light');
        setPageColor(
            !data?.showBackgroundGraphic && data?.background !== 'Parallax'
                ? 'background-color'
                : ''
        );
        if (data) {
            setHeroWithSearch(data?.showSearchBar ? true : false);
        } else {
            setHeroWithSearch(true);
        }

        return () => {
            setHeroColor('');
            setPageColor('');
        };
    }, [
        data,
        data?.background,
        data?.showBackgroundGraphic,
        data?.showSearchBar,
        setHeroColor,
        setHeroWithSearch,
        setPageColor,
    ]);

    useEffect(() => {
        searchFormRef.current = isMobile
            ? mobileSearchRef.current
            : desktopSearchRef.current;
    }, [searchFormRef, desktopSearchRef, isMobile]);

    useEffect(() => {
        document.body.style.overflow =
            findMobileOpen && isMobile ? 'hidden' : '';

        return () => {
            document.body.style.overflow = '';
        };
    }, [findMobileOpen, isMobile]);

    useEffect(() => {
        if (findMobileOpen) {
            setFindMobileOpen(false);
            window.history.go(-1);
        }
        // eslint-disable-next-line
    }, [isMobile]);

    return (
        <section className="home-hero">
            <div className="wrap">
                {isMobile ? (
                    <div className="home-hero__container">
                        <div className="home-hero__content">
                            {data && data.title ? (
                                <h1 className="home-hero__title h1">
                                    {data.title}
                                </h1>
                            ) : null}
                            {data && data.subtitle ? (
                                <p className="home-hero__desc h4">
                                    {data.subtitle}
                                </p>
                            ) : null}
                            <div
                                className="home-hero__btn-container"
                                ref={mobileSearchRef}
                            >
                                <MobileSearchButton ref={openBtnRef} />
                            </div>
                        </div>
                    </div>
                ) : (
                    <section className="desktop-search">
                        <div className="app-body-title">
                            {data && data.title ? (
                                <h1 className="desktop-search__title h1">
                                    {data.title}
                                </h1>
                            ) : null}
                            {data && data.subtitle ? (
                                <p className="desktop-search__desc h3">
                                    {data.subtitle}
                                </p>
                            ) : null}
                        </div>
                        <div
                            className={`desktop-search__container${IS_COMPACT ? ' compact' : ''}`}
                            ref={desktopSearchRef}
                        >
                            <DesktopSearchbar isCompact={IS_COMPACT} datepickerIsModal/>
                        </div>
                    </section>
                )}
            </div>
        </section>
    );
}
