import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import { Icon } from '../../common/Icon/Icon';
import { useLocaleContext } from '../../core/context/localeContext';

import 'swiper/css';
import 'swiper/css/pagination';
import './Gallery.css';
import ModalHOC from '../../common/ModalHOC/ModalHOC';
import GalleryModal from './GalleryModal/GalleryModal';
import GalleryImages from './GalleryImages/GalleryImages';

const Gallery = ({ item, isMobile, galleryParams, setFromGallery }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [images, setImages] = useState(null);
    const { t } = useLocaleContext();
    const swiperRef = useRef(null);
    const openBtnRef = useRef(null);
    const galleryItemBtnRef = useRef(null);

    useEffect(() => {
        let filteredImages = item.images?.filter(
            (image) => image.links && image.links['1000px']
        );
        if (!filteredImages?.length)
            filteredImages = item.images?.filter(
                (image) => image.links && image.links['350px']
            );

        setImages(filteredImages);
    }, [item.images]);

    const openGallery = useCallback(() => {
        if (galleryParams.gallery) return;
        searchParams.set('gallery', '1');
        setSearchParams(searchParams);
    }, [galleryParams.gallery, searchParams, setSearchParams]);

    const handlePrev = useCallback(() => {
        if (!swiperRef.current) return;
        swiperRef.current.swiper.slidePrev();
    }, [swiperRef]);

    const handleNext = useCallback(() => {
        if (!swiperRef.current) return;
        swiperRef.current.swiper.slideNext();
    }, [swiperRef]);

    const closeGalleryModal = useCallback(() => {
        searchParams.delete('gallery');
        setSearchParams(searchParams);
    }, [searchParams, setSearchParams]);

    const closeGalleryImages = useCallback(() => {
        searchParams.delete('gallery_item');
        setSearchParams(searchParams);
    }, [searchParams, setSearchParams]);

    if (!images) return null;

    return (
        <>
            <div className="gallery">
                {images?.length < 2 ? null : (
                    <>
                        <button
                            className="gallery__arrow gallery__arrow--prev slider__arrow slider__arrow--prev"
                            onClick={handlePrev}
                        >
                            <Icon.Arrow className="icon-small" />
                            <span className="sr-only">Previous slide</span>
                        </button>
                        <button
                            className="gallery__arrow gallery__arrow--next slider__arrow slider__arrow--next"
                            onClick={handleNext}
                        >
                            <Icon.Arrow className="icon-small" />
                            <span className="sr-only">Next slide</span>
                        </button>
                        {isMobile ? null : (
                            <button
                                className="gallery__open btn btn--outline-dark"
                                onClick={openGallery}
                                ref={openBtnRef}
                            >
                                <Icon.Images className="icon-small icon-left" />
                                {t('Show all')}
                            </button>
                        )}
                    </>
                )}
                <Swiper
                    className="gallery__swiper"
                    ref={swiperRef}
                    modules={[Pagination]}
                    pagination={
                        isMobile
                            ? {
                                  type: 'fraction',
                              }
                            : {
                                  type: 'bullets',
                                  dynamicBullets: true,
                              }
                    }
                >
                    {images.map((image, i) => (
                        <SwiperSlide key={i}>
                            <button
                                className="gallery__swiper-btn"
                                type="button"
                                onClick={openGallery}
                                tabIndex={-1}
                            >
                                <span className="gallery__img-container-mobile">
                                    <img
                                        className="cover-img"
                                        src={
                                            image.links['1000px']?.href ||
                                            image.links['350px']?.href
                                        }
                                        alt={image.caption}
                                    />
                                </span>
                            </button>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
            <ModalHOC
                isOpen={!!galleryParams.gallery}
                callback={() => setFromGallery(true)}
                clear={true}
                slideIn={true}
                openBtnRef={openBtnRef}
                closeModal={closeGalleryModal}
            >
                <GalleryModal
                    galleryParams={galleryParams}
                    galleryItemBtnRef={galleryItemBtnRef}
                    images={images}
                    closeGalleryModal={closeGalleryModal}
                />
            </ModalHOC>
            <ModalHOC
                isOpen={!!galleryParams.gallery && !!galleryParams.gallery_item}
                clear={false}
                slideIn={true}
                openBtnRef={galleryItemBtnRef}
                closeModal={closeGalleryImages}
            >
                <GalleryImages
                    galleryParams={galleryParams}
                    isMobile={isMobile}
                    images={images}
                />
            </ModalHOC>
        </>
    );
};

export default Gallery;
