import { useMemo } from 'react';
import { Route, Routes } from 'react-router-dom';

import { useUserContext } from './core/context/userContext';
import { ToBool } from './helpers';
import App from './App';
import AdminBookings from './Components/Admin/AdminBookings';
import AdminDashboard from './Components/Admin/AdminDashboard';
import AdminMap from './Components/AdminMap/AdminMap';
import Booking from './Components/Booking/Booking';
import Login from './Components/Login';
import MyBooking from './Components/MyBooking/MyBooking';
import PageNotFound from './Components/PageNotFound';
import AllBookings from './Components/Profile/AllBookings/AllBookings';
import ChangePassword from './Components/Profile/ChangePassword/ChangePassword';
import Groups from './Components/Profile/Groups/Groups';
import Preferences from './Components/Profile/Preferences/Preferences';
import ProtectedRoute from './Components/ProtectedRoute';
import Logout from './Components/Registration/Login/Logout';
import Registration from './Components/Registration/Registration/Registration';
import UserVerify from './Components/Registration/Registration/UserVerify';
import Search from './Components/Search/Search';
import Supplier from './Components/Supplier/Supplier';
import AboutUs from './Pages/AboutUs';
import AdminLayout from './Pages/AdminLayout';
import BlogIndex from './Pages/blog/Index';
import BlogSingle from './Pages/blog/Single';
import ContactPage from './Pages/ContactPage';
import Faq from './Pages/Faq';
import Legal from './Pages/Legal/Legal';
import Page from './Pages/Page';
import PageLayout from './Pages/PageLayout';
import ForgotPassword from './Pages/Profile/ForgotPassword';
import NewPassword from './Pages/Profile/NewPassword';
import RemoveAccount from './Components/Profile/RemoveAccount/RemoveAccount';
import Profile from './Pages/Profile/Profile';
import UserDetails from './Components/Profile/UserDetails/UserDetails';
import LoaderLayout from './Pages/LoaderLayout';
import Countries from './Pages/Countries/Countries';
import { SearchDataProvider } from './core/context/searchDataContext';

const REACT_APP_SHOW_USERS = ToBool(process.env.REACT_APP_SHOW_USERS);

const AppRoutes = () => {
    const { userDetails } = useUserContext();

    const InnerRoutes = useMemo(
        () => (
            <Route>
                <Route index element={<PageLayout page={<Page />} />} />
                <Route
                    path="about-us"
                    element={<PageLayout page={<AboutUs />} />}
                />
                <Route
                    path="blog"
                    element={<PageLayout page={<BlogIndex />} />}
                />
                <Route
                    path="blog/:pageSlug"
                    element={<PageLayout page={<BlogSingle />} />}
                />
                <Route path="p" element={<PageLayout page={<Page />} />} />
                {/* This is new way to route to dynamic pages */}
                <Route
                    path="p/:pageSlug"
                    element={<PageLayout page={<Page />} />}
                />
                <Route path="faq" element={<PageLayout page={<Faq />} />} />
                <Route
                    path="contact"
                    element={<PageLayout page={<ContactPage />} />}
                />
                <Route
                    exact
                    path="terms-and-conditions"
                    element={
                        <PageLayout
                            page={<Legal pageId="terms-and-conditions" />}
                        />
                    }
                />
                <Route
                    exact
                    path="privacy-policy"
                    element={
                        <PageLayout page={<Legal pageId="privacy-policy" />} />
                    }
                />
                <Route
                    path="countries"
                    element={<PageLayout page={<Countries />} />}
                />

                <Route element={<LoaderLayout />}>
                    <Route element={<SearchDataProvider />}>
                        <Route
                            path="hotel/"
                            element={<PageLayout page={<Supplier />} />}
                        >
                            <Route
                                path=":slug"
                                element={<PageLayout page={<Supplier />} />}
                            />
                        </Route>

                        <Route
                            path="booking/"
                            element={<PageLayout page={<Booking />} />}
                        />
                        <Route
                            path="search/"
                            element={<PageLayout page={<Search />} />}
                        />
                        <Route
                            path="my-reservation/"
                            element={<PageLayout page={<MyBooking />} />}
                        />
                        <Route
                            path="my-booking/"
                            element={<PageLayout page={<MyBooking />} />}
                        />
                    </Route>
                </Route>

                {REACT_APP_SHOW_USERS ? (
                    <Route element={<LoaderLayout />}>
                        <Route
                            path="login/"
                            element={<PageLayout page={<Login />} />}
                        />
                        <Route
                            path="register/"
                            element={<PageLayout page={<Registration />} />}
                        />
                        <Route
                            path="create-account/"
                            element={<PageLayout page={<Registration />} />}
                        />
                        <Route
                            path="user/verify-email/:hash"
                            element={<PageLayout page={<UserVerify />} />}
                        />
                        <Route
                            path="forgot-password/"
                            element={<PageLayout page={<ForgotPassword />} />}
                        />
                        <Route
                            path="user/reset-password/:hash"
                            element={<PageLayout page={<NewPassword />} />}
                        />
                        <Route element={<ProtectedRoute user={userDetails} />}>
                            <Route
                                path="logout/"
                                element={<PageLayout page={<Logout />} />}
                            />
                            <Route
                                path="profile/"
                                element={<PageLayout page={<Profile />} />}
                            />
                            <Route
                                path="profile/groups/"
                                element={<PageLayout page={<Groups />} />}
                            />
                            <Route
                                path="profile/preferences/"
                                element={<PageLayout page={<Preferences />} />}
                            />
                            <Route
                                path="change-password/"
                                element={
                                    <PageLayout page={<ChangePassword />} />
                                }
                            />
                            <Route
                                path="bookings/"
                                element={<PageLayout page={<AllBookings />} />}
                            />
                            <Route
                                path="remove-account/"
                                element={
                                    <PageLayout page={<RemoveAccount />} />
                                }
                            />
                            <Route
                                path="user/"
                                element={<PageLayout page={<UserDetails />} />}
                            />
                        </Route>
                    </Route>
                ) : null}

                <Route element={<AdminLayout />}>
                    <Route exact path="admin/" element={<AdminMap />}></Route>
                    <Route
                        exact
                        path="admin/map"
                        element={<AdminMap />}
                    ></Route>
                    <Route
                        exact
                        path="admin/bookings"
                        element={<AdminBookings />}
                    ></Route>
                    <Route
                        exact
                        path="admin/dashboard"
                        element={<AdminDashboard />}
                    ></Route>
                </Route>
                <Route
                    path="*"
                    element={<PageLayout page={<PageNotFound />} />}
                />
            </Route>
        ),
        [userDetails]
    );

    return (
        <Routes>
            <Route path="/" element={<App />}>
                <Route index element={<PageLayout page={<Page />} />} />
                {InnerRoutes}
            </Route>
            <Route path=":lang/" element={<App />}>
                {InnerRoutes}
            </Route>
            <Route path="preview/:lang/" element={<App preview={true} />}>
                {InnerRoutes}
            </Route>
            <Route path="*" element={<App />}>
                <Route
                    path="*"
                    element={<PageLayout page={<PageNotFound />} />}
                />
            </Route>
        </Routes>
    );
};

export default AppRoutes;
