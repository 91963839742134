import { ModalProvider } from './modalContext';
import { LocaleProvider } from './localeContext';
import { combineComponents } from '../combineComponents';
import { HeaderFooterDataProvider } from './headerFooterDataContext';
import { UserContextProvider } from './userContext';
import { SearchProvider } from './searchContext';
import { DaytimeContextProvider } from './daytimeContext';

const providers = [ModalProvider, LocaleProvider, HeaderFooterDataProvider, UserContextProvider, SearchProvider, DaytimeContextProvider];
export const AppContextProvider = combineComponents(...providers);
