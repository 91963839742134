import { useState, useEffect, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';

import useScreenSize from '../../core/hooks/useScreenSize.js';
import GoogleMap from '../GoogleMap.js';
import Marker from '../Marker.js';
import Login from '../Login.js';
import { Icon } from '../../common/Icon/Icon.js';
import Loader from '../../common/Loader/Loader.js';

import { GeoJsonToLatLng, ToBool, IsValidEmail } from '../../helpers.js';

import '../AdminMap/AdminMap.css';

export default function AdminBookings (props) {

    const [ isLoading, setIsLoading ] = useState(false);
    const [ user, setUser ] = useState(0);

    const tryLogin = async (payload) => {
        setIsLoading(true);

        let result = await axios.post(
            `${process.env.REACT_APP_SC_API_BASE}/login2`,
            payload
        );

        setIsLoading(false);

        if (result.status === 200) {
            let user = result.data.user;
            setUser(user);
        }
    };

    const getBookings = async () => {
        setIsLoading(true);

        let token = user.token;

        let result = await axios.get(
            `${process.env.REACT_APP_SC_API_BASE}/admin/bookings`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                responseType: 'blob',
            }
        );

        setIsLoading(false);

        resolveBlob(result);

    };

    if (!user)
        return (
            <div>
                <Login tryLogin={tryLogin} />
            </div>
        );

    return (
        <div className="admin-map">
            <div className="wrap">
                <div className="h4">Logged in as: {user.email}</div>
                <div className="admin-map__container" style={{ marginTop: 20 }}>
                    <button
                        className="btn btn--green"
                        type="button"
                        onClick={getBookings}>Download bookings</button>
                </div>
            </div>
        </div>
    );
}

function resolveBlob (response) {
    const headerval = response.headers[ 'content-disposition' ];
    if (headerval != null) {
        let filename = headerval.split(';')[ 1 ].split('=')[ 1 ].replace('"', '').replace('"', '');
        filename = decodeURI(filename);
        const url = window.URL.createObjectURL(new Blob([ response.data ]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
        link.remove();
    } else {
        handleKnownException(response);
    }
}

function handleKnownException (response) {
    var reader = new FileReader();
    reader.onload = function () {
        if (reader.result != null) {
            const responseData = JSON.parse(reader.result);
            if (responseData.code == 500) {
                alert(responseData.msg);
            }
        }
    }
    reader.readAsText(response.data);
}