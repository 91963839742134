import React from 'react';
import { Icon } from '../Icon/Icon';

import './GroupedButtons.css';

export default function GroupedButtons(props) {
    let min = props.num <= props.min;
    let max = props.num >= props.max;

    return (
        <div
            className="grouped-buttons"
            aria-label={props.label}
            role="group"
        >
            <button
                className="grouped-buttons__btn"
                type="button"
                disabled={props.disabled || min}
                onClick={() => {
                    props.setNum(props.num - 1);
                }}
            >
                <span className="sr-only">{props.displayValue || props.num} {props.pluralLabel}. Remove {props.singularLabel}</span>
                <Icon.Minus style={{ width: '20px' }} />
            </button>
            <div className="grouped-buttons__value">
                {props.displayValue || props.num}
            </div>

            <button
                className="grouped-buttons__btn"
                type="button"
                disabled={props.disabled || max}
                onClick={() => {
                    props.setNum(props.num + 1);
                }}
            >
                <span className="sr-only">{props.displayValue || props.num} {props.pluralLabel}. Add {props.singularLabel}</span>
                <Icon.Plus style={{ width: '20px' }} />
            </button>
        </div>
    );
}
