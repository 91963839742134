import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { isBefore, isSameDay, parseISO, getTime } from 'date-fns';
import { useLocaleContext } from '../../../core/context/localeContext';
import { useUserContext } from '../../../core/context/userContext';
import useScreenSize from '../../../core/hooks/useScreenSize';
import DesktopSearchbar from '../../Search/DesktopSearchbar/DesktopSearchbar';
import MobileSearchButton from '../../Search/MobileSearch/MobileSearchButton/MobileSearchButton';
import BookingItem from '../BookingItem.js';
import { Icon } from '../../../common/Icon/Icon';

import './AllBookings.css';
import CustomDropdown from '../../../common/Dropdown/CustomDropdown';

const SORT_VALUES = {
    checkIn: 'checkIn',
    reservation: 'reservation',
};

const AllBookings = () => {
    const { urlPrefix, t } = useLocaleContext();
    const { userDetails } = useUserContext();
    const { isMobile } = useScreenSize();
    const openBtnRef = useRef(null);
    const [sort, setSort] = useState(SORT_VALUES.checkIn);

    if (!userDetails.bookings) return null;

    const pastBookings = userDetails.bookings.filter(
        (booking) =>
            isBefore(parseISO(booking.booking_checkin), Date.now()) &&
            !isSameDay(parseISO(booking.booking_checkin), Date.now())
    );

    const sortedPastBookings =
        sort === SORT_VALUES.reservation
            ? [...pastBookings].sort(
                  (a, b) =>
                      getTime(parseISO(b.booking_created)) -
                      getTime(parseISO(a.booking_created))
              )
            : pastBookings;

    const upcomingBookings = userDetails.bookings.filter(
        (booking) =>
            !isBefore(parseISO(booking.booking_checkin), Date.now()) ||
            isSameDay(parseISO(booking.booking_checkin), Date.now())
    );

    const sortedUpcomingBookings =
        sort === SORT_VALUES.reservation
            ? [...upcomingBookings].sort(
                  (a, b) =>
                      getTime(parseISO(b.booking_created)) -
                      getTime(parseISO(a.booking_created))
              )
            : upcomingBookings;

    const pastBookingsList = sortedPastBookings.map((booking) => {
        return (
            <li className="bookings__item" key={booking.booking_id}>
                <BookingItem item={booking} />
            </li>
        );
    });

    const upcomingBookingsList = sortedUpcomingBookings.map((booking) => {
        return (
            <li className="bookings__item" key={booking.booking_id}>
                <BookingItem item={booking} />
            </li>
        );
    });

    const sortOptions = [
        {
            label: t('Check-in date'),
            value: SORT_VALUES.checkIn,
        },
        {
            label: t('Reservation date'),
            value: SORT_VALUES.reservation,
        },
    ];

    const sortItems = (e, val) => {
        setSort(val);
    };

    return (
        <section className="signup">
            <div className="wrap wrap--sm">
                <div className="bookings">
                    <Link
                        className="signup__back link"
                        to={`${urlPrefix}/profile`}
                    >
                        <Icon.Arrow className="icon-small icon-left flip-x" />
                        {t('Back to profile')}
                    </Link>
                    <div className="bookings__header">
                        <h1 className="bookings__title h2">{t('Bookings')}</h1>
                        {!userDetails?.bookings.length ? null : (
                            <CustomDropdown
                                items={sortOptions}
                                selected={sort}
                                handleChange={sortItems}
                                prefix={`${t('Sort by')}:`}
                                style={{
                                    fontFamily: 'ClashDisplay-Semibold',
                                }}
                                ariaLabel={t('Sort by')}
                            />
                        )}
                    </div>
                    {!userDetails?.bookings.length ? (
                        <>
                            <p className="bookings__desc">
                                {t(
                                    "You don't have any bookings yet, search for your next trip here"
                                )}
                            </p>
                            {isMobile ? (
                                <MobileSearchButton ref={openBtnRef} />
                            ) : (
                                <DesktopSearchbar isCompact />
                            )}
                        </>
                    ) : (
                        <>
                            {!upcomingBookings.length ? null : (
                                <ul className="bookings__list">
                                    {upcomingBookingsList}
                                </ul>
                            )}
                            {!pastBookings.length ? null : (
                                <>
                                    <h2 className="bookings__subtitle h3">
                                        {t('Past bookings')}
                                    </h2>
                                    <ul className="bookings__list">
                                        {pastBookingsList}
                                    </ul>
                                </>
                            )}
                        </>
                    )}
                </div>
            </div>
        </section>
    );
};

export default AllBookings;
