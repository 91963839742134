import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import { useUserContext } from '../../../core/context/userContext';
import { useLocaleContext } from '../../../core/context/localeContext';

import './RemoveAccount.css';

export default function F({ closeModal }) {
    const { userDetails, logout } = useUserContext();
    const { t } = useLocaleContext();
    let navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');

    const removeAccount = useCallback(() => {
        setLoading(true);

        const doSubmit = async () => {
            // TODO: send request to update the user
            let result = await axios.post(
                `${process.env.REACT_APP_SC_API_BASE}/user/delete`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${userDetails.token}`,
                    },
                }
            );

            setLoading(false);

            if (result.status === 204) {
                logout(); // TODO needs a clearer function for this state
                navigate('../profile');
            } else {
                console.error(result);
            }
        };

        doSubmit().catch((err) => {
            setLoading(false);
            // TODO: send error message in response data
            // setUpdateError(err.response.data.message);
        });
    }, [userDetails]);

    if (!userDetails.email && !message) return null;

    return (
        <div className="remove-account">
            {message ? (
                <div>{message}</div>
            ) : (
                <>
                    <h2 className="remove-account__title h3">
                        {t(
                            'Are you sure that you want to remove your profile?'
                        )}
                    </h2>
                    <p className="h4">
                        {t('All your personal information will be removed.')}
                    </p>
                    <div className="remove-account__btn-container">
                        <button
                            className="btn btn--outline-dark"
                            type="button"
                            onClick={removeAccount}
                        >
                            {t('Yes, remove profile')}
                        </button>
                        <button
                            className="btn btn--blue"
                            type="button"
                            onClick={closeModal}
                        >
                            {t('No, keep profile')}
                        </button>
                    </div>
                </>
            )}
        </div>
    );
}
